import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Row, Col, Layout, Modal, Skeleton } from 'antd';
import * as actionTypes from './actions';
import Login from '../Login';
import Register from '../Register';
import ResetPassword from '../ResetPassword';
import { Header } from '../../components';
import { description, diet_preference, state } from './constants';
import Background from '../../assets/images/landing_background.jpg';
import './style.scss';
import { getError } from '../../utils/commonUtils';
import queryString from 'query-string';


const LeftSide = (props) => {
  const { brandingDetails } = props;
  const [landingDetails, setLandingDetails] = useState(null);
  const [skeletonLoading, setSkeletonLoading] = useState(true);

  useEffect(() => {
    if (brandingDetails !== undefined && brandingDetails !== null) {
      setSkeletonLoading(false);
      if (!brandingDetails.err) {
        setLandingDetails(brandingDetails);
      }
    }
  }, [brandingDetails]);

  return (
    <>
      {skeletonLoading ? <Skeleton.Avatar shape="square" className="customized_banner_new" size="large" active style={{ width: 400, height: "100%", marginRight: 10 }} /> :
        <div className='con' style={{ width: '100%', backgroundColor: `${landingDetails !== null && landingDetails.brandingThemeColor !== null ? landingDetails.brandingThemeColor : "#006666"}` }}>
          <div style={{ width: '100%', backgroundColor: `${landingDetails !== null && landingDetails.brandingThemeColor !== null ? landingDetails.brandingThemeColor : "#006666"}` }}>
            <div className="middle_logo">
              <img alt="Restaurant Picture" src={landingDetails !== null && landingDetails.landingBoxLogoUrl !== null ? landingDetails.landingBoxLogoUrl : require("../../assets/images/A2GLogoApp.png")} />
            </div>
            <div className="landing-text">{landingDetails !== null && landingDetails.landingMainText !== null ? landingDetails.landingMainText : description}</div>
            <div>
              <Button
                className='btn register'
                onClick={() => props.setRegisterModal(true)}
              >
                Register
        </Button>
              <Button
                className='btn loginbtn'
                onClick={() => props.setLoginModal(true)}
              >
                Login
      </Button>
            </div>
          </div>
        </div>}
    </>
  )
}

const RegisterModal = (props) => {
  return (
    <Modal
      className="order-modal"
      visible={props.visible}
      onCancel={() => props.setRegisterModal(false)}
      maskStyle={{ backgroundColor: 'rgb(44 56 76 / 92%)' }}
      footer={null}
      maskClosable={false}
    >
      <Register {...props} diet_preference={diet_preference} states={state} />
    </Modal>
  )
}

const LoginModal = (props) => {
  return (
    <Modal
      className="order-modal"
      visible={props.visible}
      onCancel={() => props.setLoginModal(false)}
      maskStyle={{ backgroundColor: 'rgb(44 56 76 / 92%)' }}
      footer={null}
    >
      <Login  {...props} />
    </Modal>
  )
}

const ResetPasswordModal = (props) => {
  return (
    <Modal
      destroyOnClose={true}
      visible={props.visible}
      onCancel={() => {
        props.setResetPasswordModal(false);
        props.history.push(props.location && props.location.pathname ? props.location.pathname : '/landing');
      }}
      maskStyle={{ backgroundColor: 'rgb(44 56 76 / 92%)' }}
      footer={null}
    >
      <ResetPassword {...props} />
    </Modal>
  )
}

const LandingPage = (props) => {
  const [openLogin, setLoginModal] = useState(false);
  const [openRegister, setRegisterModal] = useState(false);
  const [skeletonLoading, setSkeletonLoading] = useState(true);
  const { orderGuideId } = useParams();
  const divRef = useRef(null);
  const history = useHistory();

  let visible = queryString.parse(props.location && props.location.search ? props.location.search : null)
  const [openResetPassword, setResetPasswordModal] = useState(visible.token ? true : false);

  const openModal = (openLogin = false, openRegister = false) => {
    setLoginModal(openLogin);
    setRegisterModal(openRegister);
  }

  useEffect(() => {
    document.body.classList.add('overflow-hidden');
  }, [])

  useEffect(() => {
    if (!!props.createAccountError) {
      const errorMsg = !!props.createAccountError.response ?
        props.createAccountError.response.headers["x-hedtech-message"] :
        props.createAccountError.message
      getError(errorMsg, 4)
      setRegisterModal(false);
    }
  }, [props.createAccountError])

  useEffect(() => {
    if (orderGuideId !== undefined && orderGuideId !== null) {
      props.getBrandingDetails(orderGuideId);
    }
    else if (!!localStorage.programId && !orderGuideId) {
      props.getBrandingDetailsByProgramId(localStorage.programId);
    }
    else {
      props.getSimpleBranding();
    }
  }, []);

  useEffect(() => {
    divRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (props.brandingDetails !== undefined && props.brandingDetails !== null) {
      setSkeletonLoading(false);
      if (props.brandingDetails.err) {
        getError(props.brandingDetails.err);
      }
      else {
        if (!!props.brandingDetails.guides && props.brandingDetails.guides.length > 0) {
          props.brandingDetails.guides.map((guide) => {
            if (guide.isMainGuide) {
              if (!!guide.slug) {
                localStorage.setItem("programId", guide.id);
                const visible = queryString.parse(props.location.search)
                if (visible.token) {
                  history.push(`/landing/${guide.slug}?token=${visible.token}`);
                } else {
                  history.push(`/landing/${guide.slug}`);
                }
              }
              else {
                localStorage.setItem("programId", guide.id);
              }
            }
          })
        }
      }
    }
    else {
      if (orderGuideId !== undefined && orderGuideId !== null) {
        props.getBrandingDetails(orderGuideId);
      }
      else if (!!localStorage.programId && !orderGuideId) {
        props.getBrandingDetailsByProgramId(localStorage.programId);
      }
      else {
        props.getSimpleBranding();
      }
    }
  }, [props.brandingDetails]);

  return (
    <Layout style={{ overflow: 'hidden' }}>
      {skeletonLoading ? <Skeleton.Avatar shape="square" className="header-div" active loading={!skeletonLoading} size='large' style={{ marginTop: 10 }} /> : <Header {...props} />}
      <div style={{ overflow: 'hidden', bottom: 0 }}>
        {skeletonLoading ? <Skeleton className="skeleton_for_mobile" /> : <div className="landing_content" style={{
          backgroundImage: `${props.brandingDetails !== undefined && props.brandingDetails !== null && !props.brandingDetails.err && props.brandingDetails.landingMainImage !== null ? `url(${props.brandingDetails.landingMainImage})` : `url(${Background})`}`,
        }}></div>}

        <Row ref={divRef} className="crop_landing_image_for_mobile" style={{ width: '100%' }}>
          <Col xs={24} md={8}>
            <LeftSide
              {...props}
              setLoginModal={setLoginModal}
              setRegisterModal={setRegisterModal}
            />
            <div className="bottom-links-col">
              <Row>
                <Col span={24}>
                  <ul className="footer_link">
                    <li>{skeletonLoading ? <Skeleton.Avatar shape="square" /> : <Link className="bottom-links-text" to="#">About Us</Link>}</li>
                    <li>{skeletonLoading ? <Skeleton.Avatar shape="square" /> : <Link className="bottom-links-text" to="#">Help</Link>}</li>
                    <li>{skeletonLoading ? <Skeleton.Avatar shape="square" /> : <Link className="bottom-links-text" to="#">Follow Us</Link>}</li>
                  </ul>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={24} md={16} className="landing_img">
            {skeletonLoading ? <Skeleton.Avatar shape="square" className="customized_banner_new" size="large" active style={{ width: "100%", height: "100%" }} /> :
              <div
                className='img'
                style={{
                  backgroundImage: `${props.brandingDetails !== undefined && props.brandingDetails !== null && !props.brandingDetails.err && props.brandingDetails.landingMainImage !== null ? `url(${props.brandingDetails.landingMainImage})` : `url(${Background})`}`,
                }}>
              </div>}
          </Col>
        </Row>
      </div>
      <RegisterModal
        {...props}
        visible={openRegister}
        setRegisterModal={setRegisterModal}
        openModal={openModal}
      />
      <LoginModal
        {...props}
        visible={openLogin}
        setLoginModal={setLoginModal}
        openModal={openModal}
      />
      <ResetPasswordModal
        {...props}
        visible={openResetPassword}
        setResetPasswordModal={setResetPasswordModal}
      />
    </Layout>
  )
}

const mapStateToProps = (state) => {
  return {
    loginResponse: state.LandingReducer.loginResponse,
    loginError: state.LandingReducer.loginError,
    loginLoading: state.LandingReducer.loginLoading,
    createAccountError: state.LandingReducer.createAccountError,
    userAccount: state.LandingReducer.userAccount,
    resetPasswordResponse: state.LandingReducer.resetPasswordResponse,
    dietaryOptions: state.LandingReducer.dietaryOptions,
    brandingDetails: state.LandingReducer.landingBrandingDetails,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    createAccount: (params) => dispatch(actionTypes.createAccount(params)),
    submitLogin: (params) => dispatch({ type: actionTypes.SUBMIT_LOGIN, params }),
    resetPassword: (params) => dispatch({ type: actionTypes.SUBMIT_RESET_PASSWORD, params }),
    getDietaryList: (params) => dispatch({ type: actionTypes.GET_DIETARY_OPTIONS, params }),
    getBrandingDetails: (id) => dispatch({ type: actionTypes.GET_BRANDING_DETAILS_BEFORE_LOGIN, id }),
    getBrandingDetailsByProgramId: (programId) => dispatch({ type: actionTypes.GET_BRANDING_DETAILS_BEFORE_LOGIN, programId }),
    getSimpleBranding: () => dispatch({ type: actionTypes.GET_BRANDING_DETAILS_BEFORE_LOGIN }),
    changeLoginError: () => dispatch({ type: actionTypes.REQUEST_ERROR, error: null }),
    setLoading: () => dispatch({ type: actionTypes.LOGIN_LOADING, loginLoading: true }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);