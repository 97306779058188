import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Row,
  Col,
  Layout,
  Form,
  Input,
  Select,
  notification,
  Tooltip
} from 'antd';
import states from '../../utils/states';
import { getError } from '../../utils/commonUtils';
import './style.scss';
const _ = require("lodash");

const Profile = (props) => {
  const { getUserDetails, userDetails, updateProfile, updateCredentials, updateUserResponse, onChangeTab, clearSuccessMessage, updateUserInfoSuccessMsg, getDietaryList, dietaryOptions, clearMyOrderResponse, clearUpdateErrorResponse, getAvailableMealBalance, mealPlanAvailableBalance } = props;

  const setUserDetails = (details) => {
    const user = {
      name: details && details.name ? details.name : '',
      email: details && details.name ? details.email : '',
      mobile: details && details.customer_contact ? details.customer_contact.phone : '',
      streetOne: details && details.customer_address ? details.customer_address.streetOne : '',
      streetTwo: details && details.customer_address ? details.customer_address.streetTwo : '',
      city: details && details.customer_address ? details.customer_address.city : '',
      state: details && details.customer_address ? details.customer_address.state : 'AL',
      zipCode: details && details.customer_address ? details.customer_address.zip : '',
      notificationPreference: details && details.customer_contact && details.customer_contact.notificationPreference ? details.customer_contact.notificationPreference : { 'code': 'E' },
      dietaryPreferences: details && details.dietaryPreferences ? details.dietaryPreferences : [],
      oldPassword: '',
      newPassword: '',
      passwordConfirmation: '',
      loyaltyNumber: '',
      username: details && details.username ? details.username : '',
      mealPlanBalance: !!mealPlanAvailableBalance ? mealPlanAvailableBalance.balance : 0
    }
    return user;
  }

  const [userInfo, setUserInfo] = useState(setUserDetails(userDetails))
  const { Option } = Select;
  const prevState = useRef({ userDetails }).current;
  const [updateInfoDisabled, setUpdateInfoDisabled] = useState(true);
  const [hiddenFields, setHiddenFields] = useState(true);
  const [defaultDietaryPref, setDefaultDietaryPref] = useState([]);

  useEffect(() => {
    clearMyOrderResponse();
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "hc") {
      if (mealPlanAvailableBalance === null || mealPlanAvailableBalance === undefined) {
        getAvailableMealBalance();
      }
    }
  }, [mealPlanAvailableBalance])

  useEffect(() => {
    if (!userDetails) {
      getUserDetails();
    }
    else {
      if (!userDetails.err) {
        if (userDetails !== prevState.userDetails) {
          setUserInfo(setUserDetails(userDetails));
        }
        if (process.env.REACT_APP_USE_ATTRIBUTES === 'true') {
          if (userDetails.profile && userDetails.profile.length > 0) {
            let prefs = [];
            const filteredProf = userDetails.profile
              .filter(profile => {
                if (profile.categoryName === "Dietary Preferences") {
                  return true
                }
              })
            // .map(filteredAtt => {
            //   if (prefs.indexOf(filteredAtt.name) === -1) {
            //     prefs.push(filteredAtt.name)
            //   }
            // })
            if (filteredProf[0].attributes && filteredProf[0].attributes.length > 0) {
              filteredProf[0].attributes.map(diet => {
                let dietpref = diet.name;
                if (prefs.indexOf(dietpref) === -1) {
                  prefs.push(dietpref);
                }
              })
            }
            setDefaultDietaryPref(_.uniq(prefs));
          }
        }
        if (userDetails.dietaryPreferences !== undefined && userDetails.dietaryPreferences !== null && userDetails.dietaryPreferences.length > 0) {
          let prefs = [];
          userDetails.dietaryPreferences.map((diet, index) => {
            let dietpref = diet.name;
            if (prefs.indexOf(dietpref) === -1) {
              prefs.push(dietpref);
            }
          });
          setDefaultDietaryPref(_.uniq(prefs));
        }
      }
      else {
        if (userDetails !== prevState.userDetails && userDetails.err) {
          getError(userDetails.err);
        }
      }
    }

  }, [userDetails]);

  useEffect(() => {
    if (dietaryOptions === null) {
      const params = {
        offset: 0,
        max: 9999
      }
      getDietaryList(params);
    }
    else {

    }
  }, []);

  useEffect(() => {
    if (!!updateUserInfoSuccessMsg) {
      notification.success(
        {
          message: 'Success',
          description: updateUserInfoSuccessMsg,
          duration: 4
        }
      );
      setUpdateInfoDisabled(true);
      // clearing success msg on component unmount
      return () => { clearSuccessMessage() };
    }
    if (updateUserResponse !== null) {
      if (!updateUserResponse.err) {
        setHiddenFields(true);
      }
      else {
        getError(updateUserResponse.err);
        clearUpdateErrorResponse();
      }
    }
  }, [clearSuccessMessage, updateUserInfoSuccessMsg, updateUserResponse])

  const updateUserDetail = (key, value) => {
    let userdetail = Object.assign({}, userInfo);
    userdetail[key] = value;
    setUserInfo(userdetail);
    setUpdateInfoDisabled(false);
  }

  const updateInfo = () => {
    if (!hiddenFields) {
      var regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$');
      if (userInfo.oldPassword === '') {
        notification.error(
          {
            message: 'Error',
            description: 'Please enter your current password!',
            placement: 'bottomLeft',
            duration: 3
          }
        );
      }
      else if (userInfo.newPassword === '') {
        notification.error(
          {
            message: 'Error',
            description: 'Please enter your new password!',
            placement: 'bottomLeft',
            duration: 3
          }
        );
      }
      else if (!regex.test(userInfo.newPassword)) {
        notification.error(
          {
            message: 'Error',
            description: 'Your Password must be at least 8 characters, contain at least one number, contain at least one lowercase character, contain at least one uppercase character and one symbol',
            placement: 'bottomLeft',
            duration: 4
          }
        );
      }
      else if (userInfo.passwordConfirmation === '') {
        notification.error(
          {
            message: 'Error',
            description: 'Please confirm your new password!',
            placement: 'bottomLeft',
            duration: 3
          }
        );
      }
      else if (userInfo.newPassword !== userInfo.passwordConfirmation) {
        notification.error(
          {
            message: 'Error',
            description: 'Password Mismatch!!',
            placement: 'bottomLeft',
            duration: 3
          }
        );
      }
      else {
        updateCredentials(userInfo);
        updateProfile(userInfo);
      }
    }
    else {
      if (userInfo.name === null || userInfo.name === '') {
        notification.error(
          {
            message: 'Error',
            description: 'Name field must not be blank!',
            placement: 'bottomLeft',
            duration: 3
          }
        );
      }
      else if (userInfo.username === null || userInfo.username === '') {
        notification.error(
          {
            message: 'Error',
            description: 'Student ID field must not be blank!',
            placement: 'bottomLeft',
            duration: 3
          }
        );
      }
      else {
        updateProfile(userInfo);
      }

    }
  }

  const setDietrySelection = (value, field) => {
    let arrOfSelectedDietry = [];
    let arrOfItems = []
    field.map((fieldItem, index) => {
      if (arrOfItems.indexOf(fieldItem.value) === -1) {
        arrOfItems.push(fieldItem.value);
      }
      if (dietaryOptions !== undefined && dietaryOptions !== null && dietaryOptions.length > 0) {
        dietaryOptions.map((dietary, index) => {
          if (fieldItem.value === dietary.name) {
            let setDietary = { "id": dietary.id, "name": dietary.name }
            if (arrOfSelectedDietry.indexOf(setDietary) === -1) {
              arrOfSelectedDietry.push(setDietary);
            }
          }
        });
      }
    });
    setDefaultDietaryPref(_.uniq(arrOfItems));
    userInfo.dietaryPreferences = _.uniqBy(arrOfSelectedDietry, "id");
    updateUserDetail("dietaryPreferences", userInfo.dietaryPreferences);
  }

  const validateZipCode = (e) => {
    if (e.target.value.length > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, e.target.maxLength)
    }
  }

  return (
    <Layout>
      <Row>
        <Col span={24}>
          <h1 className="tab_heading">Hello, {userInfo.name}</h1>
        </Col>
        <Col span={24}>
          <label className="my_info">My information</label>
        </Col>
      </Row>

      <Row className="mt-20">
        <Col xs={24} md={18} lg={12}>
          <label className="input_label">Name</label>
          <Form.Item className="form-item inputicon profile_info mt-0">
            <Input
              placeholder="Name"
              prefix={
                <img
                  alt="mail-icon"
                  src={require("../../assets/images/user.png")}
                  height={22}
                  width={22}
                />
              }
              className="inputStyle"
              onChange={(e) => updateUserDetail('name', e.target.value)}
              value={userInfo.name}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col xs={24} md={18} lg={12}>
          <label className="input_label">Username</label>
          <Form.Item className="form-item inputicon profile_info mt-0">
            <Input
              placeholder="Username"
              prefix={
                <img
                  alt="mail-icon"
                  src={require("../../assets/images/user.png")}
                  height={22}
                  width={22}
                />
              }
              className="inputStyle"
              onChange={(e) => updateUserDetail('username', e.target.value)}
              value={userInfo.username}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col xs={24} md={18} lg={12}>
          <label className="input_label">Address 1</label>
          <Form.Item className="form-item inputicon profile_info mt-0">
            <Input
              placeholder="Address 1"
              prefix={
                <img
                  alt="mail-icon"
                  src={require("../../assets/images/zipcode.png")}
                  height={22}
                  width={22}
                />
              }
              className="inputStyle"
              onChange={(e) => updateUserDetail('streetOne', e.target.value)}
              value={userInfo.streetOne}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col xs={24} md={18} lg={12}>
          <label className="input_label">Address 2</label>
          <Form.Item className="form-item inputicon profile_info mt-0">
            <Input
              placeholder="Address 2"
              prefix={
                <img
                  alt="mail-icon"
                  src={require("../../assets/images/zipcode.png")}
                  height={22}
                  width={22}
                />
              }
              className="inputStyle"
              onChange={(e) => updateUserDetail('streetTwo', e.target.value)}
              value={userInfo.streetTwo}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col xs={24} md={18} lg={12}>
          <label className="input_label">City</label>
          <Form.Item className="form-item inputicon profile_info mt-0">
            <Input
              placeholder="City"
              prefix={
                <img
                  alt="mail-icon"
                  src={require("../../assets/images/zipcode.png")}
                  height={22}
                  width={22}
                />
              }
              className="inputStyle"
              value={userInfo.city}
              onChange={(e) => updateUserDetail('city', e.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col xs={24} md={18} lg={12}>
          <label className="input_label">State</label>
          <Form.Item className="form-item inputicon profile_info mt-0">
            <select
              placeholder="State"
              className="simple_select"
              value={userInfo.state}
              onChange={(event) => { updateUserDetail('state', event.target.value) }}
            >
              {states.map((item, index) => {
                return (
                  <option key={index} value={item.code}>{item.name}</option>
                )
              })}
            </select>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col xs={24} md={18} lg={12}>
          <label className="input_label">Zip Code</label>
          <Form.Item className="form-item inputicon profile_info mt-0 profile_number_field" type="number">
            <Input
              placeholder="Zip Code"
              type="number"
              prefix={
                <img
                  alt="mail-icon"
                  src={require("../../assets/images/zipcode.png")}
                  height={22}
                  width={22}
                />
              }
              className="inputStyle"
              maxLength="6"
              min="1"
              max="999999"
              onInput={validateZipCode}
              onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
              onChange={(e) => updateUserDetail('zipCode', e.target.value)}
              value={userInfo.zipCode}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col xs={24} md={18} lg={12}>
          <label className="input_label">Email</label>
          <Form.Item className="form-item inputicon profile_info mt-0">
            <Input
              placeholder="Email"
              type="email"
              prefix={
                <img
                  alt="mail-icon"
                  src={require("../../assets/images/mail.png")}
                  height={22}
                  width={22}
                />
              }
              className="inputStyle"
              onChange={(e) => updateUserDetail('email', e.target.value)}
              value={userInfo.email}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col xs={24} md={18} lg={12}>
          <label className="input_label">Mobile</label>
          <Form.Item className="form-item inputicon profile_info mt-0 profile_number_field">
            <Input
              placeholder="Mobile"
              type="number"
              prefix={
                <img
                  alt="mail-icon"
                  src={require("../../assets/images/phone.png")}
                  height={22}
                  width={22}
                />
              }
              className="inputStyle"
              onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
              onChange={(e) => updateUserDetail('mobile', e.target.value)}
              value={userInfo.mobile}
            />
          </Form.Item>
        </Col>
      </Row>

      {process.env.REACT_APP_ENV === "hc" &&
        <Row>
          <Col xs={24} md={18} lg={12}>
            <label className="input_label">Meal Plan Balance</label>
            <Form.Item className="form-item inputicon profile_info mt-0 profile_number_field">
              <Input
                placeholder="Meal Plan Balance"
                type="number"
                disabled={true}
                prefix={
                  <img
                    alt="mail-icon"
                    src={require("../../assets/images/dollar.png")}
                    height={22}
                    width={22}
                  />
                }
                className="inputStyle"
                onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                onChange={(e) => updateUserDetail('mealPlanBalance', e.target.value)}
                value={userInfo.mealPlanBalance}
              />
            </Form.Item>
          </Col>
        </Row>}

      <Row>
        <Col xs={24} md={18} lg={12}>
          <label className="input_label">Notification preference</label>
          <Form.Item className="form-item ant_input mt-0">
            <Select
              placeholder="Select"
              bordered={false}
              className="select-style"
              value={userInfo.notificationPreference.code}
              showArrow={false}
              onSelect={(value) => { updateUserDetail('notificationPreference', { 'code': value }) }}
            >
              <Option value="E"> Email </Option>
              <Option value="S"> SMS </Option>
              <Option value="B"> Both </Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col xs={24} md={18} lg={12}>
          <label className="input_label">Dietary preference</label>
          <Form.Item className="form-item ant_input mt-0">
            <Select
              placeholder="Select"
              mode="multiple"
              showSearch={false}
              bordered={false}
              className="select-style"
              dropdownClassName="dietry_picker_select"
              value={defaultDietaryPref}
              onChange={setDietrySelection}
            >
              {dietaryOptions !== undefined && dietaryOptions !== null && dietaryOptions.length > 0 && dietaryOptions.map((item, index) => {
                if (item.category && item.category.name === "Dietary Preferences") {
                  return (
                    <Option key={index} value={item.name} name={item.name}>{item.name}</Option>
                  )
                }

              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {
        !(process.env.REACT_APP_REMOVE_LOYALTY === 'true') &&
        <Row>
          <Col xs={24} md={18} lg={12}>
            <label className="input_label">Loyalty Number</label>
            <Form.Item className="form-item inputicon profile_info mt-0 profile_number_field">
              <Input
                placeholder="Loyalty Number"
                type="number"
                prefix={
                  <img
                    alt="mail-icon"
                    src={require("../../assets/images/zipcode.png")}
                    height={22}
                    width={22}
                  />
                }
                className="inputStyle"
                onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                onChange={(e) => updateUserDetail('loyaltyNumber', e.target.value)}
                value={userInfo.loyaltyNumber}
              />
            </Form.Item>
          </Col>
        </Row>
      }

      <Row hidden={hiddenFields}>
        <Col xs={24} md={18} lg={12}>
          <label className="input_label">Current Password</label>
          <Form.Item className="form-item ant_input mt-0">
            <Input.Password
              placeholder="Current Password"
              className="inputStyle"
              value={userInfo.oldPassword}
              onChange={(e) => { updateUserDetail('oldPassword', e.target.value) }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row hidden={hiddenFields}>
        <Col xs={24} md={18} lg={12}>
          <label className="input_label">New Password</label>
          <Form.Item
            className="form-item ant_input mt-0"
            rules={[
              {
                required: true,
                message: 'Password is required'
              },
              ({ getFieldValue }) => ({
                validator(rule, v) {
                  let error = ''
                  error += (v && v.length < 8 && 'be at least 8 characters') || ''
                  error += (!v.match("(.*)[0-9](.*)") && ((error != '' && ', ') || '') + 'contain at least one number') || ''
                  error += (!v.match("^(.*)[a-z](.*)") && ((error != '' && ', ') || '') + 'contain at least one lowercase character') || ''
                  error += (!v.match("^(.*)[A-Z](.*)") && ((error != '' && ', ') || '') + 'contain at least one uppercase character') || ''
                  error += (!v.match("(.*)[^a-zA-Z0-9](.*)") && ((error != '' && ', ') || '') + 'contain at least one symbol') || ''
                  return (error != '') ? Promise.reject('Password must ' + error) : Promise.resolve()
                },
              })
            ]}>
            <Input.Password
              placeholder="New Password"
              className="inputStyle"
              value={userInfo.newPassword}
              onChange={(e) => { updateUserDetail('newPassword', e.target.value) }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row hidden={hiddenFields}>
        <Col xs={24} md={18} lg={12}>
          <label className="input_label">Confirm Password</label>
          <Form.Item className="form-item ant_input mt-0"
            rules={[
              {
                required: true,
                message: 'Password Confirmation is required',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('The two passwords that you entered do not match!');
                },
              }),
            ]}>
            <Input.Password
              placeholder="Confirm Password"
              className="inputStyle"
              value={userInfo.passwordConfirmation}
              onChange={(e) => { updateUserDetail('passwordConfirmation', e.target.value) }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col xs={24} md={18} lg={12}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
            <Col xs={24} md={8}>
              <Tooltip placement="top" className="profile_tooltip" title="Click Update to activate changes">
                <Button
                  block
                  className="ant-btn-primary profile_btn"
                  disabled={updateInfoDisabled}
                  onClick={updateInfo}
                  style={{ borderRadius: 8 }}
                >
                  Update
                </Button>
              </Tooltip>
            </Col>
            <Col xs={24} md={8} hidden={!hiddenFields}>
              <Button
                className="ant-btn-primary profile_btn"
                onClick={() => { setHiddenFields(!hiddenFields) }}
              >
                Change Password
              </Button>
            </Col>

            <Col xs={24} md={8} hidden={hiddenFields}>
              <Button
                className="ant-btn-primary profile_btn"
                danger
                onClick={() => { setHiddenFields(!hiddenFields) }}
              >
                Cancel
              </Button>
            </Col>
            <Col xs={24} md={8}>
              <Button
                className="ant-btn-primary profile_btn"
                onClick={() => onChangeTab("2")}
              >
                My Orders
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}

export default Profile;
