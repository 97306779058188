const pathconfig = {
  // baseUrl: 'https://farmlogix-to-go-uat.aggrega8.com',
  // baseUrl: 'https://farmlogix-to-go-dev.aggrega8.com',
  // baseUrl: 'https://farmlogix-to-go.aggrega8.com',
  baseUrl: process.env.REACT_APP_BASE_URL,
  login: '/api/login',
  register: '/api/consumer/createAccount',
  userDetails: '/api/consumer/details',
  pickupLocations: '/api/consumer/listPickupLocations?',
  pickupDates: '/api/consumer/startPickupDates?',
  pickupTimes: '/api/consumer/filterTimes?',
  listVendors: '/api/consumer/listLocations?',
  filterItems: '/api/consumer/filterValues?',
  vendorFilters: '/api/consumer/vendorFilters',
  applyFilter: '/api/consumer/listLocations?',
  getShopItems: '/api/consumer/items/',
  profile: '/api/profile',
  updateAccount: '/api/consumer/updateAccount',
  orders: '/api/orders?',
  addToCart: '/api/consumer/addToCart',
  cartDistributor: '/api/consumer/cartDistributors/',
  cartDetails: '/api/consumer/cart/',
  forgotPassword: '/api/public/forgotpassword',
  forgotUsername: '/api/public/forgotusername',
  resetPassword: '/api/public/resetpassword',
  updateCartItem: '/api/consumer/cartItem',
  getProgramInfo: (b2cType) => `/api/consumer/getProgramInfo?b2cType=${b2cType}`,
  orderDetails: '/api/consumer/orders?',
  stripePayment: (cartId) => `/api/consumer/stripePayment2/${cartId}`,
  existingPaymentMethods: (cartId) => `/api/consumer/stripePaymentMethods/${cartId}`,
  productTags: (distributorId, b2cType, customerId) => `/api/consumer/distributors/${distributorId}/product-tags?b2cType=${b2cType}${!!customerId ? ('&customerId=' + customerId) : ''}`,
  dietaryOptions: '/api/productAttributes?',
  dieteryAllergenOptions: (distributorId, b2cType, pickupDate, customerId) => `/api/consumer/distributors/${distributorId}/productAttributes?b2cType=${b2cType}&pickupDate=${pickupDate}${!!customerId ? ('&customerId=' + customerId) : ''}`, // NOTE allergen API
  dieteryWeeklyAllergenOptions: (distributorId, b2cType, pickupDate, subType, customerId) => `/api/consumer/distributors/${distributorId}/productAttributes?b2cType=${b2cType}&pickupDate=${pickupDate}&subType=${subType}${!!customerId ? ('&customerId=' + customerId) : ''}`, // NOTE allergen API
  repeatOrder: (orderId) => `/api/consumer/orders/${orderId}/repeat?`,
  branding: (orderGuideId) => `/api/consumer/branding/${orderGuideId}?version=2`,
  customizedBranding: (customURL) => `/api/consumer/branding?slug=${customURL}&version=2`,
  simpleBranding: '/api/consumer/branding?version=2',
  customizedBrandingWithProgramId: (programId) => `/api/consumer/branding/${programId}?version=2`,
  confirmReservation: (cartId) => `/api/consumer/reservation/${cartId}`,
  restaurantsList: '/api/consumer/listRestaurants?',
  // pickupWeeks: (pickupLocation, b2cType, customerId) => `/api/consumer/order/weekly/weeks?pickupLocation=${pickupLocation}&b2cType=${b2cType}&customerId=${customerId}`,
  pickupWeeks: `/api/consumer/order/weekly/weeks?`,
  // pickupWeekSlots: (pickupWeek, pickupLocation, b2cType, customerId) => `/api/consumer/order/weekly/slots?week=${pickupWeek}&pickupLocation=${pickupLocation}&b2cType=${b2cType}&customerId=${customerId}`,
  pickupWeekSlots: `/api/consumer/order/weekly/slots?`,
  weeklyProductList: '/api/consumer/order/weekly/items/',
  mealPlanBalance: '/api/consumer/balance',
  mealPlanOrderSubmit: (cartId) => `/api/consumer/mealplan/${cartId}`
}

export default pathconfig;