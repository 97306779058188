import React, { useState, useEffect } from 'react';
import {
  Layout,
  Tabs,
} from 'antd';
import {
  AuthHeader,
  PageFooter,
  Profile, OrderHistoryAccount
} from '../../components';
import './style.scss';
import { connect } from 'react-redux';
import * as HomeActions from '../../containers/Home/actions';
import * as ProfileActions from './actions';

const UserAccount = (props) => {
  const { Header, Footer, Content } = Layout;
  const { TabPane } = Tabs;
  const { brandingDetails, getUserDetails, saveReservationStatus, saveOnlyReservationAvailable, clearMyOrderResponse, clearLoadMoreResponse, reservationStatus } = props;

  const [activeTab, setActiveTab] = useState("1");
  const [optionalReservation, setOptionalReservation] = useState(false);
  const [reservationActiveTab, setReservationActiveTab] = useState("orders");

  useEffect(() => {
    if (props.location !== undefined && props.location !== null && props.location.state !== undefined && props.location.state !== null && props.location.state.showOrderHistory !== undefined && props.location.state.showOrderHistory !== null) {
      if (props.location.state.showOrderHistory) {
        setActiveTab("2");
        setReservationActiveTab("orders");
      }
      else {
        setActiveTab("2");
        setReservationActiveTab("reservations");
      }
    }
  }, [props.location]);

  useEffect(() => {
    if (brandingDetails === undefined || brandingDetails === null) {
      getUserDetails();
    }
    else {
      if (!brandingDetails.err) {
        if (brandingDetails.guides && brandingDetails.guides.length > 0) {

          if (brandingDetails.guides.length === 1) {

            if (brandingDetails.guides[0].b2cType) {
              if (brandingDetails.guides[0].b2cType === "RESERVATION") {
                saveReservationStatus(true);
                saveOnlyReservationAvailable();
                setReservationActiveTab('reservations')
              }
              else {
                setReservationActiveTab(null);
              }
            }
          }
          else if (brandingDetails.guides.length > 1) {
            brandingDetails.guides.map((orderGuide, index) => {
              if (orderGuide.b2cType === "RESERVATION") {
                setOptionalReservation(true);
                setReservationActiveTab('reservations')
              }
            })
          }
        }
      }
    }
  }, [brandingDetails]);


  useEffect(() => {
    if (reservationActiveTab === "reservations") {
      saveReservationStatus(true);
    }
    else {
      saveReservationStatus(false);
    }
  }, [reservationActiveTab])

  const changeTab = (activeKey) => {
    setActiveTab(activeKey);
  }

  const changeReservationTab = (activeKey) => {
    clearMyOrderResponse();
    clearLoadMoreResponse();
    setReservationActiveTab(activeKey);
  }

  return (
    <Layout>
      <Header>
        <AuthHeader {...props} />
      </Header>
      <Content className="full_content">
        <div className="site-layout-content">
          <Tabs activeKey={activeTab} onChange={changeTab} className="profile_tab">
            <TabPane tab="Profile" key="1">
              <Profile {...props} onChangeTab={changeTab} />
            </TabPane>
            <TabPane tab="History" key="2">
              {optionalReservation ?
                <>
                  <Tabs activeKey={reservationActiveTab} onChange={changeReservationTab} className="profile_tab">
                    <TabPane tab="Orders" key="orders">
                      <OrderHistoryAccount {...props} />
                    </TabPane>
                    <TabPane tab="Reservations" key="reservations">
                      <OrderHistoryAccount {...props} />
                    </TabPane>
                  </Tabs>
                </> : <OrderHistoryAccount {...props} />}
            </TabPane>
          </Tabs>
        </div>
      </Content>
      <Footer>
        <PageFooter {...props} />
      </Footer>
    </Layout>
  )
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.UserReducer.userDetails,
    updateUserResponse: state.UserAccountReducer.updateUserResponse,
    updateUserCrendentialResponse: state.UserAccountReducer.updateUserCrendentialResponse,
    updateUserInfoSuccessMsg: state.UserAccountReducer.updateUserInfoSuccessMsg,
    myOrderResponse: state.UserAccountReducer.myOrderResponse,
    isLoadingMore: state.UserAccountReducer.isLoadingMore,
    endOfHistoryList: state.UserAccountReducer.endOfHistoryList,
    cartDetails: state.VendorReducer.cartDetails,
    selectedOrderDetail: state.UserAccountReducer.selectedOrderDetail,
    selectedOrderDetail: state.UserAccountReducer.selectedOrderDetail,
    dietaryOptions: state.UserAccountReducer.dietaryOptions,
    brandingDetails: state.UserReducer.brandingDetails,
    reservationStatus: state.UserReducer.reservationStatus,
    onlyReservationAvailable: state.UserReducer.onlyReservationAvailable,
    mealPlanAvailableBalance: state.UserAccountReducer.mealPlanAvailableBalance,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    getUserDetails: () => dispatch({ type: HomeActions.USER_DETAILS }),
    updateProfile: (params) => dispatch({ type: ProfileActions.UPDATE_USER_INFO, params }),
    clearSuccessMessage: () => dispatch({ type: ProfileActions.CLEAR_UPDATE_USER_SUCCESS_MESSAGE }),
    updateCredentials: (params) => dispatch({ type: ProfileActions.UPDATE_USER_CREDENTIAL, params }),
    getMyOrderDetails: (params) => dispatch({ type: ProfileActions.GET_MY_ORDER, params }),
    loadMoreOrderDetails: (params) => dispatch({ type: ProfileActions.LOAD_MORE_ORDER, params }),
    setSelectedOrderDetail: (params) => dispatch({ type: ProfileActions.SAVE_SELECTED_ORDER_DETAIL, params }),
    getDietaryList: (params) => dispatch({ type: ProfileActions.GET_DIETARY_OPTIONS_FOR_PROFILE, params }),
    clearRepeatResponse: () => dispatch({ type: ProfileActions.REPEAT_ORDER_RESPONSE, repeatOrderResponse: null }),
    clearMyOrderResponse: () => dispatch({ type: ProfileActions.GET_MY_ORDER_RESPONSE, myOrderResponse: null }),
    saveBrandingDetails: (params) => dispatch({ type: HomeActions.SAVE_BRANDING_DETAILS, brandingDetails: params }),
    clearUpdateErrorResponse: () => dispatch({ type: ProfileActions.CLEAR_UPDATE_ERROR_RESPONSE }),
    saveReservationStatus: (params) => dispatch({ type: HomeActions.SAVE_RESERVATION_STATUS, reservationStatus: params }),
    saveOnlyReservationAvailable: () => dispatch({ type: HomeActions.SAVE_ONLY_RESERVATION_AVAILABLE }),
    clearLoadMoreResponse: () => dispatch({ type: ProfileActions.ORDER_HISTORY_LOAD_RESPONSE, myOrderResponse: null, endOfHistoryList: false }),
    getAvailableMealBalance: () => dispatch({ type: ProfileActions.MEAL_PLAN_AVAILABLE_BALANCE_REQUEST }),
    clearMealPlanBalance: () => dispatch({ type: ProfileActions.MEAL_PLAN_AVAILABLE_BALANCE_RESPONSE, mealPlanAvailableBalance: null })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAccount);