/**
 * fetch consumer payment detail
 */
export const GET_CONSUMER_PAYMENT_INFO = 'GET_CONSUMER_PAYMENT';
export const CONSUMER_PAYMENT_RESPONSE = 'CONSUMER_PAYMENT_RESPONSE';
export const CONSUMER_PAYMENT_INFO_ERROR = 'CONSUMER_PAYMENT_INFO_ERROR';
export const CLEAR_CHECKOUT_ERROR = 'CLEAR_CHECKOUT_ERROR';
export const SET_PAYMENT_SUCCESS_RESPONSE = 'SET_PAYMENT_SUCCESS_RESPONSE';
export const GET_EXISTING_PAYMENT_METHODS = 'GET_EXISTING_PAYMENT_METHODS';
export const SET_EXISTING_PAYMENT_METHODS = 'SET_EXISTING_PAYMENT_METHODS';
export const SET_EXISTING_PAYMENT_METHODS_ERROR = 'SET_EXISTING_PAYMENT_METHODS_ERROR';
export const GET_RESERVATION_CONFIRMATION = 'GET_RESERVATION_CONFIRMATION';
export const SAVE_RESERVATION_CONFIRMATION = 'SAVE_RESERVATION_CONFIRMATION';

/**
 * Meal Plan actions
 */
export const MEAL_PLAN_AVAILABLE_BALANCE_REQUEST = 'MEAL_PLAN_AVAILABLE_BALANCE_REQUEST';
export const MEAL_PLAN_AVAILABLE_BALANCE_RESPONSE = 'MEAL_PLAN_AVAILABLE_BALANCE_RESPONSE';
export const MEAL_PLAN_ORDER_SUBMIT_REQUEST = 'MEAL_PLAN_ORDER_SUBMIT_REQUEST';
export const MEAL_PLAN_ORDER_SUBMIT_RESPONSE = 'MEAL_PLAN_ORDER_SUBMIT_RESPONSE';
