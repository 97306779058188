import { takeLatest, put, call } from 'redux-saga/effects';
import * as actionTypes from './actions';
import { simpleGet, simplePost } from '../../utils/requests';
import pathconfig from '../../utils/path-config';

function* createAccount(params) {
  try {
    const result = yield call(simplePost, pathconfig.register, params.data);
    if (!!result && result.guide) {
      localStorage.setItem("programId", result.guide);
    }
    yield put(actionTypes.createAccountSuccess(result))
  } catch (err) {
    console.log(err)
    yield put(actionTypes.createAccountFailed(err))
  }
}

function* loginUser(params) {
  try {
    yield put({ type: actionTypes.LOGIN_LOADING })
    let result = yield call(simplePost, pathconfig.login, params.params);
    yield put({ type: actionTypes.SAVE_LOGIN_RESPONSE, saveLoginResponse: result })
  } catch (err) {
    console.log(err)
    if (err.response.status == 401 || err.response.status == 403)
      yield put({ type: actionTypes.REQUEST_ERROR, error: "Invalid username or password" })
    else
      yield put({ type: actionTypes.REQUEST_ERROR, error: err.response.data })
  }
}

function* resetPassword(params) {
  try {
    const result = yield call(simplePost, pathconfig.resetPassword, params.params);
    yield put({ type: actionTypes.RESET_PASSWORD_RESPONSE, result: { success: true } })
  } catch (err) {
    console.log(err)
    yield put({ type: actionTypes.RESET_PASSWORD_RESPONSE, result: { error: "Invalid user or reset token" } })
  }
}

function* getDietaryOptions(values) {
  try {
    var url = new URL(pathconfig.baseUrl + pathconfig.dietaryOptions),
      params = {
        offset: values.params.offset,
        max: values.params.max
      }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    const result = yield call(simpleGet, url.pathname + url.search);
    yield put({ type: actionTypes.DIETARY_OPTIONS_RESPONSE, dietryOptionsResponse: result })
  } catch (err) {
    console.log(err);
  }
}

function* getBrandingDetails(params) {
  try {
    if (!!params.id) {
      const result = yield call(simpleGet, pathconfig.customizedBranding(params.id));
      yield put({ type: actionTypes.SAVE_BRANDING_DETAILS_BEFORE_LOGIN, brandingDetails: result })
    } else if (params.programId) {
      const result = yield call(simpleGet, pathconfig.customizedBrandingWithProgramId(params.programId));
      yield put({ type: actionTypes.SAVE_BRANDING_DETAILS_BEFORE_LOGIN, brandingDetails: result })
    }
    else {
      const result = yield call(simpleGet, pathconfig.simpleBranding);
      yield put({ type: actionTypes.SAVE_BRANDING_DETAILS_BEFORE_LOGIN, brandingDetails: result })

    }
  } catch (err) {
    console.log(err.response.statusText);
    yield put({ type: actionTypes.SAVE_BRANDING_DETAILS_BEFORE_LOGIN, brandingDetails: { err: err.response.statusText } });
  }
}


export default function* watchLandingSaga() {
  yield takeLatest(actionTypes.CREATE_ACCOUNT, createAccount);
  yield takeLatest(actionTypes.SUBMIT_LOGIN, loginUser);
  yield takeLatest(actionTypes.SUBMIT_RESET_PASSWORD, resetPassword);
  yield takeLatest(actionTypes.GET_DIETARY_OPTIONS, getDietaryOptions);
  yield takeLatest(actionTypes.GET_BRANDING_DETAILS_BEFORE_LOGIN, getBrandingDetails);
}