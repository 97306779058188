import React, { useEffect, useState } from 'react';
import { Drawer, List, Spin, DatePicker, ConfigProvider } from 'antd';
import './styles.scss';
import moment from 'moment';
import en_GB from 'antd/lib/locale-provider/en_GB';

const MobilePickupInfoDrawer = (props) => {
  const {
    showMobilePickupDrawer,
    setMobilePickupDrawer,
    dataList,
    selectedCityState,
    selectedZip,
    selectedLocation,
    selectedPickupLocation,
    setZipCode,
    setLocation,
    setPickupLocation,
    pickupField,
    setCityState,
    locationOptions,
    pickupLocationOptions,
    disabledDate,
    setDate,
    setTime,
    dateSelected,
    timeSelected,
    selectedExistingMethod,
    setSelectedPaymentMethod,
    dateLoader,
    pickupTimes,
    setOrderType,
    selectedOrderType,
    disabledWeek,
    setWeek,
    weekSelected,
    weekLoader,
    pickupWeekSlot,
    pickupWeeks,
    newPickupMenuData,
    selectedStudent,
    setSelectedStudent,
    mealPlanAvailableBalance,
    mealPlanSelected,
    setMealPlanSelected
  } = props;
  const [timeDrawerData, setTimeDrawerData] = useState([]);
  const [weekDrawerData, setWeekDrawerData] = useState([]);

  useEffect(() => {
    if (process.env.REACT_APP_SELECT_PICKUP_CHANGES === "true" && !!newPickupMenuData && newPickupMenuData.orderType === "For A Week") {
      if (pickupWeekSlot !== null) {
        setTimeDrawerData(pickupWeekSlot);
      }
      else {
        setTimeDrawerData([]);
      }
    }
    else {
      if (pickupTimes !== null) {
        setTimeDrawerData(pickupTimes);
      }
      else {
        setTimeDrawerData([]);
      }
    }
  }, [pickupTimes, pickupWeekSlot]);

  useEffect(() => {
    if (process.env.REACT_APP_SELECT_PICKUP_CHANGES === "true" && !!newPickupMenuData && newPickupMenuData.orderType === "For A Week") {
      if (pickupWeeks !== null && pickupWeeks !== undefined && !pickupWeeks.err) {
        setWeekDrawerData(pickupWeeks);
      }
      else {
        setWeekDrawerData([]);
      }
    }
  }, [pickupWeeks]);

  const setMLocation = (item) => {
    let field = {};
    let value = null;
    locationOptions.map((location, index) => {
      if (item === location.name) {
        field = { value: location.id, children: location.name }
        value = location.id;
      }
    });
    setLocation(value, field);
    setMobilePickupDrawer(!showMobilePickupDrawer)
  }

  const setMPickupLocation = (item) => {
    let field = {};
    let value = null;
    pickupLocationOptions.map((location, index) => {
      if (item === location.name) {
        field = { value: location.id, name: location.name, street_one: location.street_one, street_two: location.street_two, city: location.city, state: location.state, zip: location.zip }
        value = location.id;
      }
    });
    setPickupLocation(value, field);
    setMobilePickupDrawer(!showMobilePickupDrawer)
  }

  return (
    <>
      {dataList !== null &&
        pickupField === "city/state" &&
        <Drawer
          placement="bottom"
          visible={showMobilePickupDrawer}
          closable={false}
          maskClosable={true}
          onClose={() => { setMobilePickupDrawer(!showMobilePickupDrawer) }}>
          <List
            style={{ marginTop: 20 }}
            dataSource={dataList}
            renderItem={(item, index) => (
              <List.Item style={{ backgroundColor: `${selectedCityState === item ? '#265D6C' : '#f0f0f0'}`, color: `${selectedCityState === item ? '#FFFFFF' : '#2C384C'}`, margin: 5 }} className="ant-select-item" onClick={() => { setCityState(item); setMobilePickupDrawer(!showMobilePickupDrawer) }}>
                {item}
              </List.Item>
            )} />
        </Drawer>
      }

      {dataList !== null &&
        pickupField === "zip" &&
        <Drawer
          placement="bottom"
          visible={showMobilePickupDrawer}
          closable={false}
          maskClosable={true}
          onClose={() => { setMobilePickupDrawer(!showMobilePickupDrawer) }}>
          <List
            style={{ marginTop: 20 }}
            dataSource={dataList}
            renderItem={(item, index) => (
              <List.Item style={{ backgroundColor: `${selectedZip === item ? '#265D6C' : '#f0f0f0'}`, color: `${selectedZip === item ? '#FFFFFF' : '#2C384C'}` }} className="ant-select-item" onClick={() => { setZipCode(item); setMobilePickupDrawer(!showMobilePickupDrawer) }}>
                {item}
              </List.Item>
            )} />
        </Drawer>
      }

      {pickupField === "studentName" && !!dataList &&
        <Drawer
          placement="bottom"
          visible={showMobilePickupDrawer}
          closable={false}
          maskClosable={true}
          onClose={() => { setMobilePickupDrawer(!showMobilePickupDrawer) }}>
          <List
            style={{ marginTop: 20 }}
            dataSource={dataList}
            renderItem={(item, index) => (
              <List.Item style={{ backgroundColor: `${!!selectedStudent && selectedStudent === item.customerId ? '#265D6C' : '#f0f0f0'}`, color: `${!!selectedStudent && selectedStudent === item.customerId ? '#FFFFFF' : '#2C384C'}` }} className="ant-select-item" onClick={() => { setSelectedStudent(item.customerId); setMobilePickupDrawer(!showMobilePickupDrawer) }}>
                {item.name}<br />
                {item.studentId}
              </List.Item>
            )} />
        </Drawer>
      }

      {dataList !== null && pickupField === "orderType" &&
        <Drawer
          placement="bottom"
          visible={showMobilePickupDrawer}
          closable={false}
          maskClosable={true}
          onClose={() => { setMobilePickupDrawer(!showMobilePickupDrawer) }}>
          <List
            style={{ marginTop: 20 }}
            dataSource={dataList}
            renderItem={(item, index) => (
              <List.Item style={{ backgroundColor: `${selectedOrderType === item ? '#265D6C' : '#f0f0f0'}`, color: `${selectedOrderType === item ? '#FFFFFF' : '#2C384C'}` }} className="ant-select-item" onClick={() => { setOrderType(item); setMobilePickupDrawer(!showMobilePickupDrawer) }}>
                {item}
              </List.Item>
            )} />
        </Drawer>}

      {dataList !== null &&
        pickupField === "location" &&
        <Drawer
          placement="bottom"
          visible={showMobilePickupDrawer}
          closable={false}
          maskClosable={true}
          onClose={() => { setMobilePickupDrawer(!showMobilePickupDrawer) }}>
          <List
            style={{ marginTop: 20 }}
            dataSource={dataList}
            renderItem={(item, index) => (
              <List.Item style={{ backgroundColor: `${!!selectedLocation && selectedLocation.name === item ? '#265D6C' : '#f0f0f0'}`, color: `${!!selectedLocation && selectedLocation.name === item ? '#FFFFFF' : '#2C384C'}` }} className="ant-select-item" onClick={() => setMLocation(item)}>
                {item}
              </List.Item>
            )} />
        </Drawer>
      }

      {dataList !== null &&
        pickupField === "pickupLocation" &&
        <Drawer
          placement="bottom"
          visible={showMobilePickupDrawer}
          closable={false}
          maskClosable={true}
          onClose={() => { setMobilePickupDrawer(!showMobilePickupDrawer) }}>
          <List
            style={{ marginTop: 20 }}
            dataSource={dataList}
            renderItem={(item, index) => (
              <List.Item style={{ backgroundColor: `${!!selectedPickupLocation && selectedPickupLocation.name === item ? '#265D6C' : '#f0f0f0'}`, color: `${!!selectedPickupLocation && selectedPickupLocation.name === item ? '#FFFFFF' : '#2C384C'}` }} className="ant-select-item" onClick={() => setMPickupLocation(item)}>
                {item}
              </List.Item>
            )} />
        </Drawer>
      }

      {pickupField === "date" &&
        <Drawer
          placement="bottom"
          visible={showMobilePickupDrawer}
          closable={false}
          maskClosable={true}
          onClose={() => { setMobilePickupDrawer(!showMobilePickupDrawer) }}>
          <Spin spinning={dateLoader}>
            <DatePicker
              getPopupContainer={(trigger) => trigger.parentNode}
              open={showMobilePickupDrawer}
              inputReadOnly={true}
              mode="date"
              allowClear={true}
              format="MM-DD-YYYY"
              value={dateSelected}
              className="noborder-full-width drawer-calendar"
              disabledDate={disabledDate}
              placeholder="Date"
              onChange={(date) => { setDate(date); setMobilePickupDrawer(!showMobilePickupDrawer) }}
              suffixIcon=""
              showToday={false} />
          </Spin>
        </Drawer>

      }

      {pickupField === "week" && // NOTE - mobile pick up drawer for week
        <Drawer
          placement="bottom"
          visible={showMobilePickupDrawer}
          closable={false}
          maskClosable={true}
          onClose={() => { setMobilePickupDrawer(!showMobilePickupDrawer) }}>
          <Spin spinning={weekLoader}>
            <ConfigProvider locale={en_GB}>
              <List
                className="time_picker_mobile"
                style={{ marginTop: 20, overflow: 'initial', scrollBehavior: 'smooth' }}
                itemLayout="vertical"
                loading={weekSelected !== null ? (weekDrawerData.length > 0 ? false : true) : false}
                dataSource={weekDrawerData}
                renderItem={(item, index) => (
                  <List.Item
                    style={{
                      backgroundColor: `${weekSelected === moment.utc(item).format('YYYY-MM-DD') ? '#265D6C' : '#f0f0f0'}`,
                      color: `${weekSelected === moment.utc(item).format('YYYY-MM-DD') ? '#FFFFFF' : '#2C384C'}`,
                      paddingTop: 12,
                      height: 45,
                      paddingLeft: 5,
                      paddingRight: 5,
                      margin: 5,
                      borderRadius: 32
                    }}
                    className="ant-select-item"
                    onClick={() => {
                      setWeek(moment.utc(item).format('YYYY-MM-DD'));
                      setMobilePickupDrawer(!showMobilePickupDrawer)
                    }
                    }
                  >
                    {`Week of ${moment.utc(item).format('MMMM DD')} - ${moment.utc(item).add(6, 'days').format('MMMM DD')}`}
                  </List.Item>
                )} />
            </ConfigProvider>
          </Spin>
        </Drawer>

      }

      {pickupField === "time" &&
        <Drawer
          placement="bottom"
          visible={showMobilePickupDrawer}
          closable={false}
          maskClosable={true}
          forceRender={true}
          onClose={() => { setMobilePickupDrawer(!showMobilePickupDrawer) }}>
          <List
            className="time_picker_mobile"
            style={{ marginTop: 20, overflow: 'initial', scrollBehavior: 'smooth' }}
            itemLayout="vertical"
            grid={{
              column: 3
            }}
            loading={dateSelected !== null ? (timeDrawerData.length > 0 ? false : true) : false}
            dataSource={timeDrawerData}
            renderItem={(item, index) => (
              process.env.REACT_APP_SELECT_PICKUP_CHANGES === "true" && !!newPickupMenuData && newPickupMenuData.orderType === "For A Week" ?

                <List.Item style={{ backgroundColor: `${timeSelected === moment(item).format('H:mm') ? '#265D6C' : '#f0f0f0'}`, color: `${timeSelected === moment(item).format('H:mm') ? '#FFFFFF' : '#2C384C'}`, paddingTop: 12, height: 45, paddingLeft: 5, paddingRight: 5, margin: 5, borderRadius: 32 }} className="ant-select-item" onClick={() => { setTime(item); setMobilePickupDrawer(!showMobilePickupDrawer) }}>
                  {moment.utc(item).format('h:mm A')}
                </List.Item> :
                <List.Item style={{ backgroundColor: `${timeSelected === item.userTime ? '#265D6C' : '#f0f0f0'}`, color: `${timeSelected === item.userTime ? '#FFFFFF' : '#2C384C'}`, paddingTop: 12, height: 45, paddingLeft: 5, paddingRight: 5, margin: 5, borderRadius: 32 }} className="ant-select-item" onClick={() => { setTime(item.userTime); setMobilePickupDrawer(!showMobilePickupDrawer) }}>
                  {item.userTime}
                </List.Item>
            )} />
        </Drawer>
      }

      {pickupField === "existing method" && dataList !== null && <Drawer
        placement="bottom"
        visible={showMobilePickupDrawer}
        closable={false}
        maskClosable={true}
        onClose={() => { setMobilePickupDrawer(!showMobilePickupDrawer) }}>
        <List.Item style={{ backgroundColor: `${selectedExistingMethod === null && !mealPlanSelected ? '#265D6C' : '#f0f0f0'}`, color: `${selectedExistingMethod === null && !mealPlanSelected ? '#FFFFFF' : '#2C384C'}`, margin: 5 }} className="ant-select-item" onClick={() => { setSelectedPaymentMethod(null); setMobilePickupDrawer(!showMobilePickupDrawer) }}>
          Pay with a different card
            </List.Item>
        {process.env.REACT_APP_ENV === "hc" && !!mealPlanAvailableBalance && !mealPlanAvailableBalance.err &&
          <List.Item style={{ textAlign: "left", backgroundColor: `${mealPlanSelected ? '#265D6C' : '#f0f0f0'}`, color: `${mealPlanSelected ? '#FFFFFF' : '#2C384C'}`, margin: 5 }} className="ant-select-item" onClick={() => { setSelectedPaymentMethod("mealPlan"); setMobilePickupDrawer(!showMobilePickupDrawer) }}>Meal Plan A ( $ {mealPlanAvailableBalance.balance})</List.Item>}
        <List
          style={{ marginTop: 20 }}
          dataSource={dataList}
          renderItem={(item, index) => (
            <>
              <List.Item style={{ backgroundColor: `${!mealPlanSelected && !!selectedExistingMethod && selectedExistingMethod.paymentMethodId === item.paymentMethodId ? '#265D6C' : '#f0f0f0'}`, color: `${!mealPlanSelected && !!selectedExistingMethod && selectedExistingMethod.paymentMethodId === item.paymentMethodId ? '#FFFFFF' : '#2C384C'}`, margin: 5 }} className="ant-select-item" onClick={() => { setSelectedPaymentMethod(JSON.stringify(item)); setMobilePickupDrawer(!showMobilePickupDrawer) }}>
                {`**** **** **** ${item.card.last4}`}
              </List.Item>
            </>
          )} />
      </Drawer>}
    </>
  )
}

export default MobilePickupInfoDrawer;