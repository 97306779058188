import React, { useEffect, useState } from 'react';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { getLatlng } from '../../utils/commonUtils';
import marker from '../../assets/images/marker.png';
import './styles.scss';

const Map = (props) => {
  const { orderLocatioDetails } = props;
  const [pickupLatitude, setPickupLatitude] = useState(28.535517);
  const [pickupLongitude, setPickupLongitude] = useState(77.391029);
  const [pickupFormattedAddress, setPickupFormattedAddress] = useState(null);


  useEffect(() => {
    if (orderLocatioDetails !== null) {
      getLatlngOfPickupLocation();
    }
  }, [orderLocatioDetails]);

  const getLatlngOfPickupLocation = async () => {
    const params = { latitude: '', longitude: '' };
    const latlngData = await getLatlng(orderLocatioDetails, params);
    if (latlngData !== null) {
      setPickupLatitude(latlngData.latitude);
      setPickupLongitude(latlngData.longitude);
      setPickupFormattedAddress(latlngData.formattedAddress);
    }
  }

  const GoogleMapExample = withGoogleMap(props => (
    <GoogleMap
      defaultCenter={{ lat: pickupLatitude, lng: pickupLongitude }}
      defaultZoom={16}
    >
      <Marker
        title={pickupFormattedAddress}
        icon={{
          url: marker,
        }}
        draggable={false}
        position={{ lat: pickupLatitude, lng: pickupLongitude }}
      />
    </GoogleMap>
  ));

  return (
    <>

      <GoogleMapExample
        containerElement={<div style={{ height: '285px', width: '100%', overflow: 'hidden', borderRadius: '12px', border: '1px solid #7b7b7b' }} />}
        mapElement={<div style={{ height: `81%` }} />}
      />
      <div className="pickup_address">
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0)">
            <path d="M14.8666 0.786475C11.1297 -1.90713 7.39352 3.33143 3.65672 1.50955V1.12199C3.65672 0.640669 3.26622 0.25017 2.78493 0.25017C2.30364 0.25017 1.91321 0.640669 1.91321 1.12199V16.1283C1.91321 16.6095 2.30364 17 2.78493 17C3.26622 17 3.65672 16.6095 3.65672 16.1283V9.77097C7.20848 11.502 10.7603 6.85541 14.3127 8.70455C14.4767 8.79026 14.6737 8.78458 14.8319 8.68805C14.9903 8.59217 15.0868 8.42076 15.0868 8.23513C15.0868 5.89573 15.0868 3.5562 15.0868 1.21614C15.0868 1.0465 15.0044 0.885778 14.8666 0.786475Z" fill="#7B7B7B" />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="17" height="17" fill="white" />
            </clipPath>
          </defs>
        </svg>
        {pickupFormattedAddress}
      </div>
    </>
  )
}
export default Map;






