import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button } from 'antd';
import moment from 'moment';
import './style.scss';
import { calculateDecimal, getFormattedAddress, getError } from '../../utils/commonUtils';

const SiderCartInfo = (props) => {

  const { getCartDistributor, cartDistributor, getCartDetails, cartDetails, updateCart, updateCartResponse, saveOrderDetailsLocation, cartDistributorId, reservationStatus } = props;
  const history = useHistory();
  const [subtotal, setSubTotal] = useState(null);
  const [taxTotal, setTaxTotal] = useState(null);
  const [reservation, setReservation] = useState(false);
  const prevState = useRef({ cartDetails }).current;

  useEffect(() => {
    if (updateCartResponse !== null) {
      getCartDistributor();
      if (cartDistributorId !== undefined && cartDistributorId !== null) {
        getCartDetails({ cartId: cartDistributorId });
      }
    }
  }, [updateCartResponse]);

  useEffect(() => {
    if (cartDistributorId !== undefined && cartDistributorId !== null) {
      getCartDetails({ cartId: cartDistributorId });
    }
  }, []);

  useEffect(() => {
    if (reservationStatus === undefined || reservationStatus === null || !reservationStatus) {
      setReservation(false);
    }
    else {
      setReservation(true);
    }
  }, [reservationStatus]);

  useEffect(() => {
    if (cartDetails !== undefined && cartDetails !== null && !cartDetails.err && cartDetails.items) {
      if (cartDetails.items.length > 0) {
        let subTotalOfItems = 0;
        let taxtTotalOfItems = 0;
        cartDetails.items.map((item, index) => {
          subTotalOfItems = subTotalOfItems + item.subTotal;
          taxtTotalOfItems = taxtTotalOfItems + item.salesTax;
        });
        setSubTotal(subTotalOfItems);
        setTaxTotal(taxtTotalOfItems);
      }
      if (cartDetails.cart) {
        if (cartDetails.cart.pickupLocation && cartDetails.cart.pickupLocation.address) {
          saveOrderDetailsLocation(getFormattedAddress(cartDetails.cart.pickupLocation.address));
        }
      }
    }
    if (cartDetails !== undefined && cartDetails !== null && cartDetails.err && cartDetails !== prevState.cartDetails) {
      getError(cartDetails.err);
    }
  }, [cartDetails]);

  const changeQuantity = (item, type) => {
    if (item !== null) {
      if (type === 'plus') {
        let ing = [];
        if (!!item.ingredients && item.ingredients.length > 0) {
          item.ingredients.map((cartIngredient, index) => {
            if (!!cartIngredient.items && cartIngredient.items.length > 0) {
              cartIngredient.items.map((ingInCart, index) => {
                let item = { id: ingInCart.id, quantity: ingInCart.quantity }
                if (ing.indexOf(item) === -1) {
                  ing.push(item);
                }
              })
            }
          })
        }
        const params = {
          id: item.id,
          image: item.image,
          itemTotal: item.itemTotal,
          productName: item.productName,
          quantity: item.quantity + 1,
          salesTax: item.salesTax,
          sellPrice: item.sellPrice,
          skuId: item.skuId,
          subTotal: item.subTotal,
          ingredients: ing
        }
        updateCart(params);
      }
      if (type === 'minus' && item.quantity > 0) {
        let ing = [];
        if (!!item.ingredients && item.ingredients.length > 0) {
          item.ingredients.map((cartIngredient, index) => {
            if (!!cartIngredient.items && cartIngredient.items.length > 0) {
              cartIngredient.items.map((ingInCart, index) => {
                let item = { id: ingInCart.id, quantity: ingInCart.quantity }
                if (ing.indexOf(item) === -1) {
                  ing.push(item);
                }
              })
            }
          })
        }
        const params = {
          id: item.id,
          image: item.image,
          itemTotal: item.itemTotal,
          productName: item.productName,
          quantity: item.quantity - 1,
          salesTax: item.salesTax,
          sellPrice: item.sellPrice,
          skuId: item.skuId,
          subTotal: item.subTotal,
          ingredients: ing
        }
        updateCart(params);
      }
    }
  }

  const renderOrderDetails = (cartDetails, cartIndex, date=null) => {
    return (
          <Col span={24} key={cartIndex}>
          {
            process.env.REACT_APP_WEEKLY_CART === "true" && date !== null &&
            // <Row justify="center" align="middle">
              <Col span={24} align="middle">
                <div className="weekday_div" style={{ backgroundColor: "#C6BBA7", color: "#2C384C", padding: 9, fontSize: 15, marginTop: 10 }}>
                  {moment.utc(date).format('dddd, MMMM DD')}
                  </div>
              </Col>
            // </Row>
          }
          {
            cartDetails.items.length > 0 && cartDetails.items.map((item, index) => {
              return (
                <Col span={24} className="items_price_detail" key={index}>
                  <Row>
                    <Col span={3}>
                      <span className="items">{item.quantity}</span>
                    </Col>
                    <Col span={16}>
                      <span className="items_name">{item.productName}</span>
                    </Col>
                    {!reservation && <Col span={5}>
                      <span className="items_cost">${calculateDecimal(item.subTotal)}</span>
                    </Col>}
                  </Row>
                  <Row>
                    <Col span={24}>
                      <button className="minus_btn" onClick={() => { changeQuantity(item, 'minus') }}>-</button>
                      <button className="plus_btn" onClick={() => { changeQuantity(item, 'plus') }}>+</button>
                    </Col>
                  </Row>
                </Col>
              )
            })
          }
        </Col>
    )
  }
  
  return (
    <>
      {
      cartDistributor !== undefined
      && cartDistributor !== null
      && !cartDistributor.err
      && cartDistributor.length > 0
      && cartDetails !== null
      && !cartDetails.err
      && cartDetails.cart  ? (
        <>
          <Col className="your_order_box">
            <Row>
              <Col span={24}>
                <div className="your_label">Your Order</div>
              </Col>
              {
                cartDetails !== null
                && !cartDetails.err
                && cartDetails.cart
                && cartDetails.cart.id === Number(cartDistributorId)
                ? cartDetails.items
                  && cartDetails.items.length > 0
                    ? renderOrderDetails(cartDetails, 1)
                    : cartDetails.cart.subType === "WEEKLY"
                      && process.env.REACT_APP_WEEKLY_CART === "true"
                      && cartDetails.cart.children
                      && cartDetails.cart.children.length > 0 
                        ? cartDetails.cart.children.map((cartDetail, index) => renderOrderDetails(cartDetail, index, cartDetail.cart.pickup))
                        : null
                : null
              }
              {cartDetails !== null && !cartDetails.err && cartDetails.cart &&
                <>
                  {!reservation && <>
                    <Col span={24} className="items_price_detail" style={{ border: '0px' }}>
                      <Row>
                        <Col span={12}>
                          <span className="total_text">Subtotal</span>
                        </Col>
                        <Col span={12}>
                          <span className="items_cost">${calculateDecimal(subtotal)}</span>
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: 10 }}>
                        <Col span={12} >
                          <span className="total_text">Tax</span>
                        </Col>
                        <Col span={12}>
                          <span className="items_cost">${calculateDecimal(taxTotal)}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24} className="items_price_detail" style={{ border: '0px' }}>
                      <Row>
                        <Col span={12}>
                          <span className="total_text">Total Price</span>
                        </Col>
                        <Col span={12}>
                          <span className="total_cost">${calculateDecimal(cartDetails.cart.orderTotal)}</span>
                        </Col>
                      </Row>
                    </Col>
                  </>}
                  {props.view !== "checkout-page" &&
                    <Col span={24}>
                      <Button type="primary" htmlType="submit" className="checkout_btn" onClick={() => { history.push(`/checkout/${cartDistributorId}`) }}>
                        {!reservation ? "Checkout" : "Submit"}
                      </Button>
                    </Col>
                  }
                </>
              }
          </Row>
        </Col>
        </>
      ) : (<></>)
      }


    </>
  )
}

export default SiderCartInfo;