import React, { useEffect, useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import moment from "moment";
import { Layout, Row, Col, Button } from 'antd';
import { AuthHeader, PageFooter, CartItemsMoreInfo } from '../../components';
import './styles.scss';
import { calculateDecimal, getFormattedAddressLine3 } from "../../utils/commonUtils"


const Confirmation = (props) => {
  const { paymentDetails, reservationStatus } = props;
  const { Header, Footer, Content } = Layout;
  const history = useHistory();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [moreInfoVisible, setMoreInfoVisible] = useState(false);
  const [reservation, setReservation] = useState(false);

  useEffect(() => {
    if (reservationStatus === undefined || reservationStatus === null || !reservationStatus) {
      setReservation(false);
    }
    else {
      setReservation(true);
    }
  }, [reservationStatus]);


  if (!paymentDetails && !reservationStatus) {
    return <Redirect to="/multiple-carts" />
  } else {
    const { orderListItems } = paymentDetails;


    let subTotal = 0;
    let tax = 0;
    let total = 0;
    if (process.env.REACT_APP_WEEKLY_CART === 'true' && orderListItems.cart.subType === 'WEEKLY') {
      if (orderListItems.cart.children && orderListItems.cart.children.length > 0) {
        orderListItems.cart.children.map(child => {
          if (child.items && child.items.length > 0) {
            child.items.map(item => {
              subTotal += item.subTotal ? item.subTotal : 0;
              tax += item.salesTax ? item.salesTax : 0;
              total += item.itemTotal ? item.itemTotal : 0;
            })
          }
        })
      }
    }
    else if (orderListItems?.items) {
      orderListItems.items.forEach(item => {
        subTotal += item.subTotal;
        tax += item.salesTax;
        total += item.itemTotal;
      })
    }

    let addressLine3 = null;

    if (orderListItems?.cart?.pickupLocation?.address) {
      addressLine3 = getFormattedAddressLine3(orderListItems.cart.pickupLocation.address);
    }

    const showMoreInfo = (item) => {
      setSelectedOrder(item);
      setMoreInfoVisible(true);
    }

    const goToHistory = () => {
      if (!reservation) {
        history.push({
          pathname: "/user-account",
          state: { showOrderHistory: true }
        })
      }
      else {
        history.push({
          pathname: "/user-account",
          state: { showOrderHistory: false }
        })
      }
    }

    const renderOrderConfirmationDetails = (orderListItems, orderIndex) => {
      return (
        <Col span={24} key={orderIndex}>
          {process.env.REACT_APP_WEEKLY_CART === "true" && orderListItems.cart && orderListItems.cart.pickup &&
            <Row justify="center" align="middle">
              <Col span={24} align="middle">
                <div className="weekday_div" style={{ backgroundColor: "#2C384C", borderRadius: 12, color: "#E5E5E5" }}>
                  {moment.utc(orderListItems.cart.pickup).format('dddd, MMMM DD')}
                </div>
              </Col>
            </Row>
          }
          {
            !!orderListItems?.items && orderListItems.items.map(item => {
              return (
                <Row className="our_order_list" key={item.id}>
                  <Col span={24} className="ordered_items order_information">
                    <Row>
                      <Col xs={20} md={22}>
                        <div className="ordered_item">{item.productName}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={20} md={22}>
                        <img style={{ marginRight: 5 }} src={require('../../assets/images/order_info.png')} onClick={() => showMoreInfo(item)} />
                        <span className="ordered_qua">Quantity: <span>{item.quantity}</span> </span>
                      </Col>
                      <Col xs={4} md={2}>
                        {!reservation && <div className="ordered_price">${calculateDecimal(item.subTotal)}</div>}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )
            })
          }
        </Col>
      )
    }

    return (
      <>
        <Layout>
          <Header>
            <AuthHeader {...props} />
          </Header >
          <Content className="full_content">
            <div className="site-layout-content">
              <Row gutter={40}>
                <Col xs={24} md={18} lg={20} className="top_row payment-success-msg" style={{ borderBottom: 'none' }}>
                  <h1 className="tab_heading">Thank You!</h1>
                  <div>
                    {process.env.REACT_APP_ENV === "hc" ? <span>Your order has been sent!</span> : (!reservation ? <span>Your order is in the works!</span> : <span>Your reservation has been sent!</span>)}<br />
                    <span>A confirmation has been texted to you, and you will receive a 2nd text when your order is ready.</span>
                  </div>
                  <p className="show_desktop content-sub-heading" style={{ lineHeight: 2, marginTop: 20 }}>Click on the info icon below to view order customization.</p>
                  <p className="show_mobile content-sub-heading" style={{ lineHeight: 1, marginTop: 15 }}>Click on the info icon below to view order customization.</p>
                </Col>
                <Col xs={24} md={6} lg={4} className="address-only-desktop">
                  <Row>
                    <Col xs={24}>
                      {process.env.REACT_APP_ENV === "hc" ? <h2 className="Sub_Heading">Order Info</h2> : (!reservation ? <h2 className="Sub_Heading">Pickup Info</h2> : <h2 className="Sub_Heading">Reservation Info</h2>)}
                    </Col>
                    {process.env.REACT_APP_ENV === "hc" && !!orderListItems.cart.customer && !!orderListItems.cart.customer.name &&
                      <Col xs={24}>
                        <span className="pickup_label">Student Name</span>
                        <span className="location_restraurant">{orderListItems.cart.customer.name}</span>
                      </Col>}
                    <Col xs={24}>
                      {!reservation && <span className="pickup_label">Pickup</span>}
                      {
                        process.env.REACT_APP_WEEKLY_CART === 'true' && orderListItems.cart.subType === 'WEEKLY'
                          ? !!orderListItems?.cart?.pickup && (
                            <span className="pickup_time">
                              Week of {moment.utc(orderListItems.cart.pickup).format('MMMM DD')} - {moment.utc(orderListItems.cart.pickup).add(6, 'days').format('MMMM DD')} {moment.utc(orderListItems.cart.pickup).format('h:mm A')}
                            </span>
                          ) : !!orderListItems?.cart?.pickup && <span className="pickup_time">{moment(orderListItems.cart.pickup).format("MM/DD/YYYY h:mm A")}</span>
                      }
                    </Col>
                    <Col xs={24}>
                      <span className="location_label">At</span>
                      {orderListItems?.cart?.pickupLocation?.name && <span className="location_restraurant">{orderListItems.cart.pickupLocation.name},</span>}
                      {orderListItems?.cart?.pickupLocation?.address?.streetOne && <span className="location_restraurant">{orderListItems.cart.pickupLocation.address.streetOne},</span>}
                      {orderListItems?.cart?.pickupLocation?.address?.streetTwo && <span className="location_restraurant">{orderListItems.cart.pickupLocation.address.streetTwo},</span>}
                      {addressLine3 && <span className="location_restraurant">{addressLine3}</span>}
                    </Col>
                  </Row>
                </Col>
              </Row>
              {
                process.env.REACT_APP_WEEKLY_CART === 'true'
                  && orderListItems?.cart?.subType === 'WEEKLY'
                  && orderListItems?.cart?.children
                  && orderListItems?.cart?.children.length > 0
                  ? orderListItems.cart.children.map((child, index) => renderOrderConfirmationDetails(child, index))
                  : renderOrderConfirmationDetails(orderListItems, 1)
              }

              {!reservation &&
                <>
                  <Row className="our_order_list">
                    <Col span={24} className="ordered_items total-amount-container">
                      <Row >
                        <Col xs={12} md={12}>
                          <div className="ordered_total">Subtotal</div>
                        </Col>
                        <Col xs={12} md={12}>
                          <div className="ordered_cost">$ {calculateDecimal(subTotal)}</div>
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: 20 }}>
                        <Col xs={12} md={12}>
                          <div className="ordered_total">Tax</div>
                        </Col>
                        <Col xs={12} md={12}>
                          <div className="ordered_cost">$ {calculateDecimal(tax)}</div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="our_order_list">
                    <Col span={24}>
                      <Row >
                        <Col xs={12} md={12}>
                          <div className="ordered_total"><strong>Total</strong></div>
                        </Col>
                        <Col xs={12} md={12}>
                          <div className="ordered_cost">$ {calculateDecimal(total)}</div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>}
              <Row className="our_order_list" justify="end">
                <Col xs={24} md={6} lg={4} className="address-only-mobile">
                  <Row>
                    <Col xs={24}>
                      {process.env.REACT_APP_ENV === "hc" ? <h2 className="Sub_Heading">Order Info</h2> : (!reservation ? <h2 className="Sub_Heading">Pickup Info</h2> : <h2 className="Sub_Heading">Reservation Info</h2>)}
                    </Col>
                    {process.env.REACT_APP_ENV === "hc" && !!orderListItems.cart.customer && !!orderListItems.cart.customer.name &&
                      <Col xs={24}>
                        <span className="pickup_label">Student Name</span>
                        <span className="location_restraurant">{orderListItems.cart.customer.name}</span>
                      </Col>}
                    <Col xs={24}>
                      {!reservation && <span className="pickup_label">Pickup</span>}
                      {!!orderListItems?.cart?.pickup && <span className="pickup_time">{moment(orderListItems.cart.pickup).format("MM/DD/YYYY h:mm A")}</span>}
                    </Col>
                    <Col xs={24}>
                      <span className="location_label">At</span>
                      {orderListItems?.cart?.pickupLocation?.name && <span className="location_restraurant">{orderListItems.cart.pickupLocation.name},</span>}
                      {orderListItems?.cart?.pickupLocation?.address?.streetOne && <span className="location_restraurant">{orderListItems.cart.pickupLocation.address.streetOne},</span>}
                      {orderListItems?.cart?.pickupLocation?.address?.streetTwo && <span className="location_restraurant">{orderListItems.cart.pickupLocation.address.streetTwo},</span>}
                      {addressLine3 && <span className="location_restraurant">{addressLine3}</span>}
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={16} >
                  <Button
                    type="primary"
                    htmlType="button"
                    className="repeat_your_order"
                    onClick={goToHistory}
                  >
                    {process.env.REACT_APP_ENV === "hc" || !reservation ? "Order History" : "Reservation History"}
                  </Button>
                </Col>
              </Row>
              {moreInfoVisible &&
                <CartItemsMoreInfo
                  moreInfoVisible={moreInfoVisible}
                  setMoreInfoVisible={setMoreInfoVisible}
                  selectedOrder={selectedOrder}
                  {...props}
                  page="confirmation"
                />
              }
            </div>
          </Content>
          <Footer>
            <PageFooter {...props} />
          </Footer>
        </Layout>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    paymentDetails: state.CheckoutReducer.paymentDetails,
    brandingDetails: state.UserReducer.brandingDetails,
    reservationStatus: state.UserReducer.reservationStatus,
    onlyReservationAvailable: state.UserReducer.onlyReservationAvailable
  }
}

export default connect(mapStateToProps)(Confirmation);