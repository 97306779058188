import React, {useState, Fragment, useEffect} from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import Loader from 'react-loader-spinner';
import * as actionTypes from "../ForgotPassword/actions";
import {connect} from "react-redux";

const layout = {
	labelCol: { span: 0 },
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 24 },
	},
};

const tailLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0,
		},
		sm: {
			span: 24,
			offset: 0,
		},
		border: 'none'
	},
};

const ForgotPassword = ({forgotPassword, forgotPasswordResponse, props}) => {
    const [email, setEmail] = useState('');
    const [loader, showLoader] = useState(false);

    useEffect(() => {
		console.log(forgotPasswordResponse);
		if (forgotPasswordResponse && forgotPasswordResponse.success) {
            showLoader(false);
		}
    }, [forgotPasswordResponse])

	const onFinish = values => {
        showLoader(true);
		const params = {
			'user': email
		}
		forgotPassword(params);
	};

	const onFinishFailed = errorInfo => {
		console.log(errorInfo);
    };

	return (
		<Fragment>
			<div className="page-title mb-30">
				<text> Password Reset </text>
			</div>
            { !forgotPasswordResponse &&
                <div>
                <Form {...layout}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    scrollToFirstError>
                    <Form.Item
                        name="email"
                        visible="false"
                        rules={[
                            {
                                required: true,
                                message: 'Field is required',
                            },
                            {
                                type: 'email',
                                message: 'E-mail must be valid'
                            }
                        ]}
                        className="form-item inputicon"
                    >
                        <Input placeholder="Please enter your email"
                            prefix={
                                <img
                                    alt="mail-icon"
                                    src={require("../../assets/images/mail.png")}
                                    height={24}
                                    width={24}
                                />
                            }
                            className="inputStyle"
                            onChange={(e) => { setEmail(e.target.value) }}
                        />
                    </Form.Item>
                    <div className="fixed-button-bottom" >
                        <Form.Item {...tailLayout}
                            className="submit-button">
                            <Loader
                                type="TailSpin"
                                color="#00BFFF"
                                height={50}
                                width={50}
                                color="#2C384C"
                                visible={loader}
                                style={{ margin: 10 }}
                                timeout={6000}
                            />
                            <Button disabled={loader}
                                type="primary"
                                htmlType="submit"
                                className="radius12 font_size_family btn_pad">
                                Submit
                            </Button>
                        </Form.Item >
                    </div>
                </Form>
                </div>
            }
            { forgotPasswordResponse &&
                <div className="forgot-message-div" >
                    <text>
                    If you are registered in our system we have sent you an email with instructions to setup/reset your password. Please contact support should you not receive an email.
                    </text>
                </div>
            }
		</Fragment>
	);
}

const mapStateToProps = (state) => {
	return {
        forgotPasswordResponse: state.ForgotPasswordReducer.forgotPasswordResponse
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		dispatch,
		forgotPassword: (params) => dispatch({ type: actionTypes.FORGOT_PASSWORD, params })
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)