import * as actionTypes from './actions';

const initialState = {
  userAccount: {},
  loginResponse: null,
  loginError: null,
  loginLoading: false,
  resetPasswordResponse: null,
  createAccountError: null,
  dietaryOptions: null,
  landingBrandingDetails: null,
}

const LandingPageReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.CREATE_ACCOUNT_SUCCESS:
      return { ...state, loginError: null, createAccountError: null, userAccount: action.data }
    case actionTypes.CREATE_ACCOUNT_ERROR:
      return { ...state, createAccountError: action.data }
    case actionTypes.LOGIN_LOADING:
      return { ...state, loginLoading: true }
    case actionTypes.SAVE_LOGIN_RESPONSE:
      return { ...state, loginError: null, createAccountError: null, loginResponse: action.saveLoginResponse, loginLoading: false }
    case actionTypes.REQUEST_ERROR:
      return { ...state, loginError: action.error, loginLoading: false }
    case actionTypes.RESET_PASSWORD_RESPONSE:
      return { resetPasswordResponse: action.result }
    case actionTypes.DIETARY_OPTIONS_RESPONSE:
      return { ...state, dietaryOptions: action.dietryOptionsResponse }
    case actionTypes.SAVE_BRANDING_DETAILS_BEFORE_LOGIN:
      return { ...state, landingBrandingDetails: action.brandingDetails }
    default:
      return state;
  }
}

export default LandingPageReducer;