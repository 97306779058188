import React from 'react';
import { Modal, Button } from 'antd';
import './styles.scss';

const ReservationPermission = (props) => {
  const { reservationPermissionVisible, setReservationPermissionVisible, saveReservationStatus, setLocationModalVisible, clearVendorsList, saveSelectedB2cType, getVendorsList } = props;

  const handleCancel = () => {
    setReservationPermissionVisible(false);
  }

  const saveOrder = () => {
    clearVendorsList();
    saveSelectedB2cType("ORDERING");
    saveReservationStatus(false);
    setReservationPermissionVisible(false);
    setLocationModalVisible(true);
    const params = {
      max: 5,
      offset: 0,
      b2cType: "ORDERING"
    }
    getVendorsList(params);
  }

  const saveReservation = () => {
    clearVendorsList();
    saveSelectedB2cType("RESERVATION");
    saveReservationStatus(true);
    setReservationPermissionVisible(false);
    setLocationModalVisible(true);
    const params = {
      max: 5,
      offset: 0,
      b2cType: "RESERVATION"
    }
    getVendorsList(params);
  }

  return (
    <>
      <Modal className="order-modal"
        visible={reservationPermissionVisible}
        onCancel={handleCancel}
        footer={null}>
        <div className="repeat-info">Please select your order type.</div>
        <div className="fixed-button-bottom">
          <Button
            type="primary"
            htmlType="submit"
            className="reservation_btn font_size_family btn_pad" onClick={saveOrder}>Restaurant Purchase</Button>
        </div>

        <div className="fixed-button-bottom">
          <Button
            type="primary"
            htmlType="submit"
            className="reservation_btn font_size_family btn_pad" onClick={saveReservation}>Pre-Paid Meal Plan</Button>
        </div>

        <div className="fixed-button-bottom">
          <Button
            type="primary"
            htmlType="submit"
            className="reservation_btn font_size_family btn_pad" onClick={saveReservation}>Wait List/Reservation</Button>
        </div>
      </Modal>
    </>
  )
}

export default ReservationPermission;