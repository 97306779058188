import * as actionTypes from './actions';

const initialState = {
  forgotPasswordResponse: null
}

const ForgotPasswordReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.FORGOT_PASSWORD_RESPONSE:
      return { forgotPasswordResponse: action.forgotPasswordResponse }

    default:
      return state;
  }
}

export default ForgotPasswordReducer;