import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Button } from 'antd';
import { calculateDecimal } from '../../utils/commonUtils';

const RepeatItemsNotAvailable = (props) => {
  const { showItemsNotAvailable, setShowItemsNotAvailable, selectedPickupDateTime, repeatOrder, repeatOrderResponse, id, reservationStatus } = props;
  const [reservation, setReservation] = useState(false);

  const handleCancel = () => {
    setShowItemsNotAvailable(false);
  }

  const handleOk = () => {
    const params = {
      "pickupDateTime": selectedPickupDateTime,
      "allowPartialCart": true
    }
    repeatOrder(id, params);
    setShowItemsNotAvailable(false);
  }

  useEffect(() => {
    if (reservationStatus === undefined || reservationStatus === null || !reservationStatus) {
      setReservation(false);
    }
    else {
      setReservation(true);
    }
  }, [reservationStatus]);

  return (
    <>
      <Modal
        className="order-modal"
        visible={showItemsNotAvailable}
        onCancel={handleCancel}
        footer={null}>
        <div className="repeat-info">The following items are unavailable. Would you like to proceed with the order without them?</div>
        {repeatOrderResponse !== undefined && repeatOrderResponse !== null && repeatOrderResponse.err && repeatOrderResponse.err.data && repeatOrderResponse.err.data.message &&
          repeatOrderResponse.err.data.message.map((item, index) => {
            return (
              <>
                <Row justify="space-around">
                  <Col xs={20} md={20}>
                    <div className="ordered_item">{item.name}</div>
                  </Col>
                  <Col xs={20} md={20}>
                    <Row justify="space-between">
                      <Col>
                        <div className="ordered_qua">Quantity: <span>{item.quantity}</span> </div>
                      </Col>
                      {!reservation && <Col>
                        <div className="ordered_price">${calculateDecimal(item.total)}</div>
                      </Col>}
                    </Row>
                  </Col>
                </Row>
              </>
            )
          })
        }
        <div className="fixed-button-bottom">
          <Button
            type="primary"
            htmlType="submit"
            className="radius12 font_size_family btn_pad" onClick={handleOk}>Reopen Cart</Button>
        </div>

        <div className="fixed-button-bottom">
          <Button
            htmlType="submit"
            className="radius12 font_size_family btn_pad" onClick={handleCancel}>Cancel</Button>
        </div>
      </Modal>
    </>
  )
}

export default RepeatItemsNotAvailable;