export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_ERROR = 'CREATE_ACCOUNT_ERROR';
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const SUBMIT_LOGIN = 'SUBMIT_LOGIN';
export const SAVE_LOGIN_RESPONSE = 'SAVE_LOGIN_RESPONSE';
export const REQUEST_ERROR = 'REQUEST_ERROR';
export const SUBMIT_RESET_PASSWORD = 'SUBMIT_RESET_PASSWORD';
export const RESET_PASSWORD_RESPONSE = 'RESET_PASSWORD_RESPONSE';
export const GET_DIETARY_OPTIONS = 'GET_DIETARY_OPTIONS';
export const DIETARY_OPTIONS_RESPONSE = 'DIETARY_OPTIONS_RESPONSE';

export function createAccount(data) {
	return {
		type: CREATE_ACCOUNT,
		data
	};
}

export function createAccountSuccess(data) {
	return {
		type: CREATE_ACCOUNT_SUCCESS,
		data
	};
}

export function createAccountFailed(data) {
	return {
		type: CREATE_ACCOUNT_ERROR,
		data
	};
}

/**
 * action to get branding API data
 */
export const GET_BRANDING_DETAILS_BEFORE_LOGIN = 'GET_BRANDING_DETAILS_BEFORE_LOGIN';
export const SAVE_BRANDING_DETAILS_BEFORE_LOGIN = 'SAVE_BRANDING_DETAILS_BEFORE_LOGIN';
