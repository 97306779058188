import React, { useEffect, useState } from 'react';
import { Button, Layout, Modal, Row, Col, Collapse } from 'antd';
import moment from 'moment';
import './styles.scss';
import ReactHtmlParser from 'react-html-parser';
import { calculateDecimal, getError } from '../../utils/commonUtils';

const AddtoOrder = (props) => {
  const [disabled, setDisabled] = useState(true);
  const { product, addToCart, newPickupMenuData, reservationStatus, onlyReservationAvailable, dateSelected, productRestricted } = props;
  const [productQuantity, setProductQuantity] = useState(0);
  const [ingredientsInCart, setIngredientsInCart] = useState([]);
  const [reservation, setReservation] = useState(false);
  const [onlyReservation, setOnlyReservation] = useState(false);
  const [productPrice, setProductPrice] = useState(calculateDecimal(0));

  useEffect(() => {
    if (!productRestricted) {
      if (productQuantity === 0) {
        setDisabled(true);
      }
      else {
        setDisabled(false);
      }
    }
    else {
      setDisabled(true);
      getError("You have reached the max number of items for this meal. If you would like a different item, please remove your current selection from your shopping cart.");
    }
  }, [productQuantity, productRestricted]);

  useEffect(() => {
    if (reservationStatus === undefined || reservationStatus === null || !reservationStatus) {
      setReservation(false);
    }
    else {
      setReservation(true);
    }
  }, [reservationStatus]);

  useEffect(() => {
    if (onlyReservationAvailable !== null && onlyReservationAvailable) {
      setOnlyReservation(onlyReservationAvailable);
    }
  }, [onlyReservationAvailable]);


  useEffect(() => {
    if (product !== null) {
      if (product.quantity !== null) {
        setProductQuantity(product.quantity);
        if (product.quantity > 0) {
          setProductPrice(calculateDecimal(product.sellPrice * product.quantity));
        }
        else {
          setProductPrice(calculateDecimal(product.sellPrice));
        }
      }
      else {
        setProductPrice(calculateDecimal(product.sellPrice));
      }
      if (product.ingredients && product.ingredients.length > 0) {
        let productIngredientsArr = [...product.ingredients];
        for (let i = 0; i < product.ingredients.length; i++) {
          productIngredientsArr[i] = { ...productIngredientsArr[i], price: calculateIngredientBasePrice(product.ingredients[i]) };
        }
        setIngredientsInCart(productIngredientsArr);
      }
    }
  }, [product]);


  const calculateIngredientBasePrice = (ingredient) => {
    if (ingredient.quantity === null) {
      return calculateDecimal(0);
    }
    else {
      if (ingredient.quantity > ingredient.freeQuantity) {
        let extra = ingredient.quantity - ingredient.freeQuantity;
        return calculateDecimal(extra * ingredient.price);
      }
      else {
        return calculateDecimal(0);
      }
    }
  }

  const addtoOrder = () => {
    if (product !== null && newPickupMenuData !== null && props?.page === "orderinfo") {
      let ing = [];
      if (ingredientsInCart.length > 0) {
        ingredientsInCart.map((cartIngredient, index) => {
          if (!!cartIngredient.items && cartIngredient.items.length > 0) {
            cartIngredient.items.map((ingInCart, index) => {
              let item = { id: ingInCart.id, quantity: ingInCart.quantity }
              if (ing.indexOf(item) === -1) {
                ing.push(item);
              }
            })
          }
        })
      }
      const params = {
        itemId: product.id,
        quantity: productQuantity,
        ingredients: ing,
        skuId: product.skuId,
        productName: product.productName,
        image: product.image
      }
      if (process.env.REACT_APP_WEEKLY_CART === 'true' && newPickupMenuData.orderType === "For A Week") {
        params.subType = "WEEKLY";
        params.pickupTime = (dateSelected ? dateSelected : newPickupMenuData.dateSelected) + 'T' + moment.utc(newPickupMenuData.timeSelected).format('HH:mm');
      }
      addToCart(params, product);
      setProductQuantity(0);
      props.setAddtoOrderModal(false);

    } else if (product !== null && newPickupMenuData !== null && newPickupMenuData.pickupLocation !== null && productQuantity !== null) {
      let ing = [];
      if (ingredientsInCart.length > 0) {
        ingredientsInCart.map((cartIngredient, index) => {
          if (!!cartIngredient.items && cartIngredient.items.length > 0) {
            cartIngredient.items.map((ingInCart, index) => {
              let item = { id: ingInCart.id, quantity: ingInCart.quantity }
              if (ing.indexOf(item) === -1) {
                ing.push(item);
              }
            })
          }
        })
      }
      const params = {
        itemId: product.id,
        pickupLocation: newPickupMenuData.pickupLocation.id,
        pickupTime: newPickupMenuData.dateSelected + 'T' + moment(newPickupMenuData.timeSelected, 'H:mm a').format('HH:mm'),
        quantity: productQuantity,
        ingredients: ing
      }
      if (process.env.REACT_APP_WEEKLY_CART === 'true' && newPickupMenuData.orderType === "For A Week") {
        params.subType = "WEEKLY";
        params.pickupTime = (dateSelected ? dateSelected : newPickupMenuData.dateSelected) + 'T' + moment.utc(newPickupMenuData.timeSelected).format('HH:mm');
      }
      if (!!newPickupMenuData && !!newPickupMenuData.customerId) {
        params.customerId = newPickupMenuData.customerId;
      }
      addToCart(params);
      setProductQuantity(0);
      props.setAddtoOrderModal(false);
    }
  }

  const changeQuantity = (quantity, value) => {
    if (quantity >= 0) {
      setProductQuantity(quantity);
    }
    if (quantity > 0) {
      if (value === "plus") {
        let productBasePrice = 0;
        if (quantity > 1) {
          productBasePrice = Number(productPrice) / (quantity - 1);
        }
        setProductPrice(calculateDecimal(Number(productPrice) + productBasePrice));
      }
      else {
        let productBasePrice = Number(productPrice) / (quantity + 1);
        setProductPrice(calculateDecimal(Number(productPrice) - productBasePrice));
      }
    }
  }

  let calculateIngredientPrice = (ingredient, value) => {
    let price = 0, totalPrice = 0;
    if (product !== null && product.ingredients && product.ingredients.length > 0) {
      product.ingredients.map((ingredients, index) => {
        if (!!ingredients.items && ingredients.items.length > 0) {
          ingredients.items.map((item, index) => {
            if (item.id === ingredient.id) {
              if (value === "plus") {
                if ((ingredient.quantity + 1) > item.freeQuantity) {
                  let extra = (ingredient.quantity + 1) - item.freeQuantity
                  price = (item.price) * extra;
                  if (productQuantity === 0) {
                    totalPrice = Number(productPrice) + (item.price);
                  }
                  else {
                    totalPrice = Number(productPrice) + (productQuantity * item.price);
                  }
                  setProductPrice(calculateDecimal(totalPrice));
                }
                else {
                  price = calculateDecimal(0);
                }
              }
              else {
                if ((ingredient.quantity - 1) > item.freeQuantity) {
                  let extra = (ingredient.quantity - 1) - item.freeQuantity
                  price = (item.price) * extra;
                  if (productQuantity === 0) {
                    totalPrice = Number(productPrice) - (item.price);
                  }
                  else {
                    totalPrice = Number(productPrice) - (productQuantity * item.price);
                  }
                  setProductPrice(calculateDecimal(totalPrice));
                }
                else if ((ingredient.quantity - 1) === item.freeQuantity) {
                  price = calculateDecimal(0);
                  if (productQuantity === 0) {
                    totalPrice = Number(productPrice) - (item.price);
                  }
                  else {
                    totalPrice = Number(productPrice) - (productQuantity * item.price);
                  }
                  setProductPrice(calculateDecimal(totalPrice));
                }
              }
            }
          })
        }

      });
    }
    return calculateDecimal(price);
  }

  const changeIngredientQuantity = (ingredient, index, value, categoryIndex) => {
    if (value === "plus") {
      let newIngredientArr = [...ingredientsInCart];
      let newItemsArr = [...ingredientsInCart[categoryIndex].items];
      newItemsArr[index] = { ...newItemsArr[index], quantity: ingredient.quantity + 1, price: calculateIngredientPrice(ingredient, value) }
      newIngredientArr[categoryIndex] = { ...newIngredientArr[categoryIndex], items: newItemsArr };
      setIngredientsInCart(newIngredientArr);
    }
    else if (value === "minus" && ingredient.quantity > 0) {
      let newIngredientArr = [...ingredientsInCart];
      let newItemsArr = [...ingredientsInCart[categoryIndex].items];
      newItemsArr[index] = { ...newItemsArr[index], quantity: ingredient.quantity - 1, price: calculateIngredientPrice(ingredient, value) }
      newIngredientArr[categoryIndex] = { ...newIngredientArr[categoryIndex], items: newItemsArr };
      setIngredientsInCart(newIngredientArr);

    }
  }

  return (
    <>
      {product !== null &&

        <Layout>
          <Row>
            <Col span={24} style={{ marginTop: 20 }}>
              <div className="item_qut_name">{product.productName}</div>
            </Col>
            {!reservation && <Col span={24}><div className="item_qut_rupee"> $ {productPrice}</div></Col>}
            <Col span={24}>
              {product !== null && product.biography && <div>{ReactHtmlParser(product.biography)}</div>}
            </Col>
          </Row>
          <Row className="order_plus_minus">
            <Col xs={6} md={6} style={{ textAlign: 'left' }}>
              <img width={40} height={40} src={require('../../assets/images/remove_item_48.png')} onClick={() => { !productRestricted && changeQuantity(productQuantity - 1, "minus") }} />
            </Col>
            <Col xs={12} md={12} style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
              <div className="ordered_qua_one" style={{ display: 'inline-block' }}>Quantity: <span>{productQuantity}</span> </div>
            </Col>
            <Col xs={6} md={6} style={{ textAlign: 'right' }}>
              <img width={40} height={40} src={require('../../assets/images/add_item_48.png')} onClick={() => { !productRestricted && changeQuantity(productQuantity + 1, "plus") }} />
            </Col>
          </Row>
          {product !== null && product.ingredients && product.ingredients.length > 0 && ingredientsInCart.length > 0 &&
            <>
              <Row className='restaurant_items'>
                <Col span={24}>
                  <div className="Ingridients_label">Customize</div>
                  <p className="content-sub-heading">Customize your order by selecting the options below.</p>
                </Col>
                <Col span={24} style={{ marginTop: '20px' }}>
                  <Collapse expandIconPosition="left" defaultActiveKey={product.ingredients[0].categoryId} ghost>
                    {ingredientsInCart.map((ingCategory, categoryIndex) => {
                      return (
                        <Collapse.Panel header={ingCategory.categoryName} key={ingCategory.categoryId}>
                          {!!ingCategory.items && ingCategory.items.length > 0 &&
                            <Row gutter={{ xs: 20, sm: 20, md: 16, lg: 16 }}>
                              {ingCategory.items.map((ingredient, index) => {
                                return (
                                  <Col xs={10} md={6} style={{ margin: 10, minHeight: 200, maxWidth: 130 }} className="Ingridients_div" key={index}>
                                    <div>
                                      <Row className="ingredient_image_div">
                                        <Col xs={24} style={{ display: "flex", justifyContent: 'center' }}>
                                          <div className="ing_img_div">
                                            {ingredient.photoUrl !== undefined && ingredient.photoUrl !== null
                                              && <img alt="Restaurant Picture" src={ingredient.photoUrl} className="ing_image" />}
                                          </div>
                                        </Col>
                                        <Col xs={24}>
                                          <Row justify="center">
                                            <Col>
                                              <div className="ingridients_name">{ingredient.name}</div>
                                            </Col>
                                          </Row>
                                          <Row justify="center">
                                            <Col>
                                              {!reservation && <div className="ingridients_weight">$ {calculateDecimal(ingredient.price)}</div>}
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>

                                      <Row style={{ textAlign: "center", marginTop: 10 }}>
                                        <Col xs={24}>
                                          {<div className="ingridients_weight">Quantity: <span className="ingridients_name">{ingredient.quantity}</span></div>}
                                        </Col>
                                      </Row>
                                      <Row justify="space-between" style={{ margin: 10 }}>
                                        <Col>
                                          <img width={24} height={24} src={require('../../assets/images/remove_item.png')} onClick={() => { changeIngredientQuantity(ingredient, index, "minus", categoryIndex) }} />
                                        </Col>
                                        <Col>
                                          <img width={24} height={24} src={require('../../assets/images/add_item.png')} onClick={() => { changeIngredientQuantity(ingredient, index, "plus", categoryIndex) }} />
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                )
                              })}
                            </Row>
                          }

                        </Collapse.Panel>
                      )
                    })}


                  </Collapse>
                </Col>
              </Row>
            </>}


          <Row className="order_plus_minus" style={{ textAlign: 'center' }}>
            <Col span={24}>
              <Button className="ant-btn-primary order_add" onClick={addtoOrder} disabled={disabled}>Confirm</Button>
            </Col>
          </Row>
        </Layout>
      }
    </>
  )

}

export default AddtoOrder;
