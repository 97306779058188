import React, { useEffect, useState } from 'react';
import { Badge, Image, Row, Col, Skeleton } from 'antd';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	LogoutOutlined
} from '@ant-design/icons';
import './styles.scss';
import Cookies from 'js-cookie';

const PageHeader = (props) => {
	const history = useHistory();
	const { cartDistributor, brandingDetails } = props;
	const [skeletonLoading, setSkeletonLoading] = useState(true);
	const [addtoCartQuantity, setAddtoCartQuantity] = useState(0);

	useEffect(() => {
		if (cartDistributor === undefined || cartDistributor === null || cartDistributor.err || cartDistributor.length === 0) {
			setAddtoCartQuantity(0);
		}
		else {
			let itemQuantity = 0;
			cartDistributor.map((distributor, index) => {
				if (distributor.items && distributor.items.length > 0) {
					distributor.items.map((item, index) => {
						itemQuantity = itemQuantity + item.quantity;
					});
				}
			});
			setAddtoCartQuantity(itemQuantity);
		}
	}, [cartDistributor]);

	const logout = () => {
		Cookies.remove("token");
		props.logout();
		if (!!brandingDetails && !!brandingDetails.guides && brandingDetails.guides.length > 0) {
			brandingDetails.guides.map((guide) => {
				if (guide.isMainGuide) {
					if (!!guide.slug) {
						history.push(`/landing/${guide.slug}`);
					} else history.push('/landing/');
				}
			});
		} else history.push('/landing');
	}

	const goToOrders = () => {
		history.push('/multiple-carts');
	}

	useEffect(() => {
		if (brandingDetails === undefined || brandingDetails === null) {
			setSkeletonLoading(true);
		}
		else {
			setSkeletonLoading(false);
		}
	}, [brandingDetails]);

	return (
		<div className="header-div" >
			<Row justify="space-between" gutter={16}>
				<Col xs={15} md={8} lg={8} xxl={6} style={{ cursor: 'pointer' }}>
					{skeletonLoading ?
						<Skeleton.Avatar
							shape="square" className="home_page_logo"
							active loading={!skeletonLoading} size='large' style={{ marginTop: 10 }} />
						:
						<Image
							alt="logo"
							preview="false"
							className="home_page_logo"
							src={brandingDetails !== undefined && brandingDetails !== null && !brandingDetails.err && brandingDetails.landingTopLogoUrl !== null ? brandingDetails.landingTopLogoUrl : require('../../assets/images/logo_new.png')}
							onClick={() => { history.push('/') }}
						/>
					}

				</Col>

				<Col xs={9} md={14} lg={12} xxl={12}>
					<Row justify="space-between" className="header_mobile">
						<Col xs={6} md={{ span: 2, offset: 1 }} lg={2} xxl={1} onClick={() => { history.push('/user-account') }} style={{ cursor: 'pointer' }}>
							<img
								alt="my-account"
								src={require("../../assets/images/user_black.png")}
								className="icon-img"
							/>
						</Col>

						<Col xs={6} md={{ span: 2, offset: 1 }} lg={2} xxl={1} onClick={goToOrders} style={{ cursor: 'pointer' }}>
							<Badge count={addtoCartQuantity}>
								<img
									alt="shopping-bag"
									src={require("../../assets/images/shopping-bag.png")}
									className="icon-img"
								/>
							</Badge>
						</Col>
						<Col xs={6} md={{ span: 2, offset: 1 }} lg={2} xxl={1}>
							<LogoutOutlined className="icon-img" onClick={logout} />
						</Col>
					</Row>
					<Row justify="end" className="show_desktop">
						<Col xs={6} md={{ span: 2, offset: 1 }} lg={2} xxl={1} onClick={() => { history.push('/user-account') }} style={{ cursor: 'pointer' }}>
							<img
								alt="my-account"
								src={require("../../assets/images/user_black.png")}
								className="icon-img"
							/>
						</Col>

						<Col xs={6} md={{ span: 2, offset: 1 }} lg={2} xxl={1} onClick={goToOrders} style={{ cursor: 'pointer' }}>
							<Badge count={addtoCartQuantity}>
								<img
									alt="shopping-bag"
									src={require("../../assets/images/shopping-bag.png")}
									className="icon-img"
								/>
							</Badge>
						</Col>
						<Col xs={6} md={{ span: 2, offset: 1 }} lg={2} xxl={1}>
							<LogoutOutlined className="icon-img" onClick={logout} />
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	)
}

const mapStatetoProps = (state) => {
	return {
	}
}

const mapDispatchtoProps = (dispatch) => {
	return {
		dispatch,
		logout: () => dispatch({ type: 'DESTROY_USER_DATA' })
	}
}

export default connect(mapStatetoProps, mapDispatchtoProps)(PageHeader);