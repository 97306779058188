import React, { useState, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Row, Col, Select, notification } from 'antd';
import { getLatlng } from '../../utils/commonUtils';
import './styles.scss';
const { Option } = Select;
const _ = require("lodash");

const layout = {
	labelCol: { span: 0 },
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 24 },
	},
};

const tailLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0,
		},
		sm: {
			span: 24,
			offset: 0,
		},
		border: 'none'
	},
};

const Register = (props) => {
	const [name, setName] = useState('');
	const [address1, setAddress1] = useState('');
	const [address2, setAddress2] = useState('');
	const [cityName, setCityName] = useState('');
	const [stateName, setStateName] = useState('');
	const [zipcode, setZipcode] = useState('');
	const [email, setEmail] = useState('');
	const [mobile, setMobile] = useState('');
	const [accessCode, setAccessCode] = useState('');
	const [ntfPreference, setNtfPreference] = useState('E');
	const [dietPreference, setDietPreference] = useState(null);
	const [userName, setUserName] = useState('');
	const [componentSize, setComponentSize] = useState('default');
	const [form] = Form.useForm();


	useEffect(() => {
		const params = {
			offset: 0,
			max: 9999
		}
		props.getDietaryList(params);
	}, []);

	const onFormLayoutChange = ({ size }) => {
		setComponentSize(size);
	};

	const onFinish = async values => {
		const params = {
			name: values.name,
			username: values.userName,
			address1: values.address1,
			address2: values.address2,
			city: values.cityName,
			state: values.stateName,
			zip: values.zipcode,
			mobile: values.mobile,
			latitude: '',
			longitude: '',
			formattedAddress: '',
			email: values.email,
			notificationPreference: {
				code: values.ntfPreference
			},
			dietaryPreferences: dietPreference,
			orderGuideId: null,
			attributes: [],
			selectedProgram: null,
			accessCode: accessCode
		}
		const newParams = await getLatlng(`${formatUserAddress(values.address1, values.address2, values.cityName, values.stateName, values.zipcode)}`, params);
		props.createAccount(newParams);
	};

	const formatUserAddress = (address1, address2, cityName, stateName, zipcode) => {
		let fullAddress = '';
		if (!!address1) {
			fullAddress = address1 + ", ";
		}
		if (!!address2) {
			fullAddress = fullAddress + address2 + ", ";
		}
		if (!!cityName) {
			fullAddress = fullAddress + cityName + ", ";
		}
		if (!!stateName) {
			fullAddress = fullAddress + stateName + ", ";
		}
		if (!!zipcode) {
			fullAddress = fullAddress + zipcode;
		}
		return fullAddress;
	}

	const onFinishFailed = errorInfo => {
		console.log(errorInfo);
	};

	useEffect(() => {
		if (props.userAccount && props.userAccount.message) {
			notification.open({
				message: props.userAccount.message,
			});
			form.resetFields();

			props.setRegisterModal(false);
		}

	}, [props.userAccount]);

	const setDietrySelection = (value, field) => {
		let arrOfSelectedDietry = [];
		field.map((item, index) => {
			let setDietary = { "id": item.value, "name": item.name }
			if (arrOfSelectedDietry.indexOf(setDietary) === -1) {
				arrOfSelectedDietry.push(setDietary);
			}
		})
		setDietPreference(_.uniqBy(arrOfSelectedDietry, "id"));
	}


	return (
		<Fragment>
			<div className="page-title mb-30" style={{ paddingTop: 15 }}>
				<div> Registration </div>
			</div>
			<Form
				form={form}
				{...layout}
				name="basic"
				initialValues={{ size: componentSize, ntfPreference: `${ntfPreference}` }}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				onValuesChange={onFormLayoutChange}
				size={componentSize}
				scrollToFirstError
			>
				<Form.Item
					name="name"
					rules={[{ required: true, message: 'Please input your name!' }]}
					className="form-item inputicon"
				>
					<Input
						placeholder="Name"
						prefix={
							<img
								alt="user-icon"
								src={require("../../assets/images/user.png")}
								height={24}
								width={24}
							/>
						}
						className="inputStyle"
						value={name}
						onChange={(e) => { setName(e.target.value) }}
					/>
				</Form.Item>

				<Form.Item
					name="userName"
					rules={[{ required: true, message: 'Please input your User Name!' }]}
					className="form-item inputicon"
				>
					<Input
						placeholder="User Name"
						prefix={
							<img
								alt="user-icon"
								src={require("../../assets/images/user.png")}
								height={24}
								width={24}
							/>
						}
						className="inputStyle"
						value={userName}
						onChange={(e) => { setUserName(e.target.value) }}
					/>
				</Form.Item>

				<Form.Item
					name="address1"
					className="form-item inputicon"
				>
					<Input
						placeholder="Address 1"
						prefix={
							<img
								alt="user-icon"
								src={require("../../assets/images/zipcode.png")}
								height={24}
								width={24}
							/>
						}
						className="inputStyle"
						value={address1}
						onChange={(e) => { setAddress1(e.target.value) }}
					/>
				</Form.Item>

				<Form.Item
					name="address2"
					className="form-item inputicon"
				>
					<Input
						placeholder="Address 2"
						prefix={
							<img
								alt="user-icon"
								src={require("../../assets/images/zipcode.png")}
								height={24}
								width={24}
							/>
						}
						className="inputStyle"
						value={address2}
						onChange={(e) => { setAddress2(e.target.value) }}
					/>
				</Form.Item>

				<Form.Item
					name="cityName"
					className="form-item inputicon"
				>
					<Input
						placeholder="City"
						prefix={
							<img
								alt="user-icon"
								src={require("../../assets/images/zipcode.png")}
								height={24}
								width={24}
							/>
						}
						className="inputStyle"
						value={cityName}
						onChange={(e) => { setCityName(e.target.value) }}
					/>
				</Form.Item>

				<Form.Item
					name="stateName"
					className="form-item form-item-noborder"
				>
					<select
						placeholder="State"
						className="simple_select"
						value={stateName}
						onChange={(event) => { setStateName(event.target.value) }}
					>
						{props.states.map((data, index) => (
							<option key={index} value={data.code}>{data.name}</option>
						))}
					</select>
				</Form.Item>

				<Form.Item
					name="zipcode"
					rules={[{ required: true, message: 'Please input zip code' }]}
					className="form-item inputicon"
				>
					<Input
						placeholder="Zip Code"
						prefix={
							<img
								alt="zip-icon"
								src={require("../../assets/images/zipcode.png")}
								height={20}
								width={20}
							/>
						}
						className="inputStyle"
						value={zipcode}
						onChange={(e) => { setZipcode(e.target.value) }}
					/>
				</Form.Item>

				<Form.Item
					name="email"
					rules={[
						{
							required: true,
							message: 'Field is required',
						},
						{
							type: 'email',
							message: 'E-mail must be valid'
						}
					]}
					className="form-item inputicon"
				>
					<Input
						placeholder="Email"
						prefix={
							<img
								alt="user-icon"
								src={require("../../assets/images/zipcode.png")}
								height={24}
								width={24}
							/>
						}
						className="inputStyle"
						value={email}
						onChange={(e) => { setEmail(e.target.value) }}
					/>
				</Form.Item>

				<Form.Item
					name="mobile"
					rules={[
						{
							required: true,
							message: 'Please input your mobile number'
						}
					]}
					className="form-item inputicon"
				>
					<Input
						placeholder="Mobile"
						prefix={
							<img
								alt="phone-icon"
								src={require("../../assets/images/phone.png")}
								height={20}
								width={20}
							/>
						}
						className="inputStyle"
						value={mobile}
						onChange={(e) => { setMobile(e.target.value) }}
					/>
				</Form.Item>

				<Row>
					<Col span={24}>
						<div className="signup_label"> Dietary Preference </div>
					</Col>
				</Row>

				<Form.Item
					name="dietPreference"
					className="form-item form-item-noborder"
					style={{ marginTop: '10px' }}
				>
					<Select
						placeholder="Select"
						mode="multiple"
						bordered={false}
						className="select-style"
						onChange={setDietrySelection}
						showSearch={false}
					>
						{props.dietaryOptions !== undefined && props.dietaryOptions !== null
							&& props.dietaryOptions.length > 0
							&& props.dietaryOptions.map((item, index) => {
								if (item.category && item.category.name === "Dietary Preferences") {
									return (
										<Option key={index} value={item.id} name={item.name}>{item.name}</Option>
									)
								}
							})}
					</Select>
				</Form.Item>

				<Row>
					<Col span={24}>
						<div className="signup_label"> Notification Preference </div>
					</Col>
				</Row>

				<Form.Item
					name="ntfPreference"
					rules={[{ required: true, message: 'Please select notification preference' }]}
					className="form-item form-item-noborder my-10"
				>
					<Select
						bordered={false}
						className="select-style"
						showArrow={false}
						onSelect={(value) => { setNtfPreference(value) }}
					>
						<Option value="E"> Email </Option>
						<Option value="S"> SMS </Option>
						<Option value="B"> Both </Option>
					</Select>
				</Form.Item>

				<Form.Item
					name="access-code"
					className="form-item inputicon"
				>
					<Input.Password
						placeholder="Access Code"
						prefix={
							<img
								alt="phone-icon"
								src={require("../../assets/images/secure_icon.png")}
								height={20}
								width={20}
							/>
						}
						className="inputStyle"
						value={accessCode}
						onChange={(e) => { setAccessCode(e.target.value) }}
					/>
				</Form.Item>

				<div className="fixed-button-bottom">
					<Form.Item {...tailLayout} className="submit-button">
						<Button
							type="primary"
							htmlType="submit"
							className="radius12 font_size_family btn_pad"
						>
							Register
						</Button>
					</Form.Item>

					<div className="signup-link-div">
						<div className="signup-link-label" > Already have an account ?{" "}
							<Link className="signup-link-button" onClick={() => props.openModal(true)} to="#">Log in</Link>
						</div>
					</div>
				</div>
			</Form>
		</Fragment>
	);
}


export default Register;