/**
 * Update User Info actions
 */
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const UPDATE_USER_INFO_RESPONSE = 'UPDATE_USER_INFO_RESPONSE';
export const CLEAR_UPDATE_USER_SUCCESS_MESSAGE = "CLEAR_SUCCESS_MESSAGE";
export const CLEAR_UPDATE_ERROR_RESPONSE = 'CLEAR_UPDATE_ERROR_RESPONSE';

/**
 * Update User Credential actions
 */
export const UPDATE_USER_CREDENTIAL = 'UPDATE_USER_CREDENTIAL';
export const UPDATE_USER_CREDENTIAL_RESPONSE = 'UPDATE_USER_CREDENTIAL_RESPONSE';

/**
 * User Order Info actions
 */
export const GET_MY_ORDER = 'GET_MY_ORDER';
export const GET_MY_ORDER_RESPONSE = 'GET_MY_ORDER_RESPONSE';
export const ORDER_HISTORY_LOAD_MORE = 'ORDER_HISTORY_LOAD_MORE';
export const ORDER_HISTORY_STOP_LOAD_MORE = 'ORDER_HISTORY_STOP_LOAD_MORE';
export const ORDER_HISTORY_LOAD_RESPONSE = 'ORDER_HISTORY_LOAD_RESPONSE';
export const LOAD_MORE_ORDER = 'LOAD_MORE_ORDER';

/**
 * User Order Info actions
 */
export const SAVE_SELECTED_ORDER_DETAIL = 'SAVE_SELECTED_ORDER_DETAIL';

/**
 * User Order Info actions
 */
export const GET_DIETARY_OPTIONS_FOR_PROFILE = 'GET_DIETARY_OPTIONS_FOR_PROFILE';
export const DIETARY_OPTIONS_RESPONSE = 'DIETARY_OPTIONS_RESPONSE';


/**
 * Repeat Order actions
 */
export const REPEAT_ORDER_REQUEST = 'REPEAT_ORDER_REQUEST';
export const REPEAT_ORDER_RESPONSE = 'REPEAT_ORDER_RESPONSE';
export const GET_PICKUP_DATES = 'GET_PICKUP_DATES';
export const SAVE_PICKUP_DATES = 'SAVE_PICKUP_DATES';
export const GET_PICKUP_TIME = 'GET_PICKUP_TIME';
export const SAVE_PICKUP_TIME = 'SAVE_PICKUP_TIME';

/**
 * Meal Plan actions
 */
export const MEAL_PLAN_AVAILABLE_BALANCE_REQUEST = 'MEAL_PLAN_AVAILABLE_BALANCE_REQUEST';
export const MEAL_PLAN_AVAILABLE_BALANCE_RESPONSE = 'MEAL_PLAN_AVAILABLE_BALANCE_RESPONSE';