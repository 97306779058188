import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Tooltip, Row, Col, Modal, Spin, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import './styles.scss';
import MoreInfoVendor from '../MoreInfoVendor';
import { getError } from '../../utils/commonUtils';



const MoreInfoModal = (props) => {
  return (
    <Modal
      className="order-modal"
      visible={props.visible}
      onCancel={() => props.setMoreInfoModal(false)}
      maskStyle={{ backgroundColor: 'rgb(44 56 76 / 92%)' }}
      footer={null}
    >
      <MoreInfoVendor {...props} />
    </Modal>
  )
}

const MainContent = (props) => {

  const { vendorsList, saveSelectedVendor, clearProductList, selectedVendorData, clearSelectedDietaryFilter, showVendorLoader, vendorLoading, clearVendorsList, reservationStatus, getVendorsList, newPickupMenuData, showPickupByLocation, loadMoreVendors, vendorsListEnded, refreshVendorsList } = props;
  const [moreInfoModal, setMoreInfoModal] = useState(false);
  const [itemClicked, setItemClicked] = useState(null);
  const [tooltipVisible, setToolTipVisible] = useState(null);
  const [restaurantsList, setRestaurantsList] = useState([]);
  const history = useHistory();
  const currentPageOrderCount = useRef(0);


  useEffect(() => {
    if (newPickupMenuData === null || newPickupMenuData === undefined) {
      currentPageOrderCount.current = 0;
      refreshVendorsList();
      const params = {
        max: 6,
        offset: currentPageOrderCount.current,
        b2cType: `${reservationStatus !== null && reservationStatus ? "RESERVATION" : "ORDERING"}`
      }
      getVendorsList(params);
    }
  }, [reservationStatus, newPickupMenuData]);

  useEffect(() => {
    setRestaurantsList(null);
    if (vendorsList !== undefined && vendorsList !== null) {
      showVendorLoader(false);
      if (vendorsList.err) {
        getError(vendorsList.err);
        clearVendorsList();
      }
      else {
        if (!!vendorsList.locations && vendorsList.locations.length > 0) {
          setRestaurantsList(vendorsList.locations);
        }
        else {
          setRestaurantsList(vendorsList);
        }
      }
    }
  }, [vendorsList]);

  const showMoreInfoModal = (item) => {
    if (tooltipVisible !== null) {
      if (tooltipVisible.itemId === item.id) {
        setToolTipVisible({ itemId: item.id, visibility: false });
      }
      else if (tooltipVisible.itemId === item.distributorId) {
        setToolTipVisible({ itemId: item.distributorId, visibility: false });
      }
    }
    setMoreInfoModal(true);
    setItemClicked(item);
  }

  const productsList = (item) => {

    if (newPickupMenuData !== undefined && newPickupMenuData !== null) {
      if (selectedVendorData !== null && selectedVendorData.distributorId !== item.distributorId) {
        clearSelectedDietaryFilter();
      }
      saveSelectedVendor(item);
      clearProductList();
      history.push({
        pathname: `/shop/${item.distributorId}`,
      });
    }
    else {
      showPickupByLocation();
    }
  }

  const toggleTooltip = (item, visibility) => {
    if (!!item.id) {
      const tooltipData = { itemId: item.id, visibility: visibility };
      setToolTipVisible(tooltipData);
    }
    else {
      const tooltipData = { itemId: item.distributorId, visibility: visibility };
      setToolTipVisible(tooltipData);
    }
  }

  const handleLoadMore = () => {
    showVendorLoader(true);
    currentPageOrderCount.current = currentPageOrderCount.current + 6;
    const params = {
      max: 6,
      offset: currentPageOrderCount.current,
      b2cType: `${reservationStatus !== null && reservationStatus ? "RESERVATION" : "ORDERING"}`
    }
    loadMoreVendors(params);

  }
  return (
    <Fragment>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} style={{ opacity: (vendorLoading ? 0.15 : 1) }}>
        {!!restaurantsList && restaurantsList.length > 0 ?
          (restaurantsList.map((item, index) => {
            if (!!item.distributorId) {
              return (
                <Fragment key={index}>

                  <Col xs={24} md={24} lg={12} className="mt50">
                    <Row justify="start" onClick={() => productsList(item)} style={{ cursor: 'pointer' }}>
                      <Col span={24} >

                        <div className="res_pic">
                          <img key={Date.now()} preview="false" src={item.distributorLogo} className="restaurant_pic" />
                        </div>

                      </Col>
                    </Row>
                    <Row type="flex">
                      <Col xs={21} md={21}>
                        <h6 className="restaurant_name">{item.distributorName}</h6>
                        <h6 className="restaurant_name">{item.address1}</h6>
                      </Col>
                      <Col xs={3} md={3}>
                        <div className="restaurant_tooltip" onClick={() => showMoreInfoModal(item)} onMouseEnter={() => { toggleTooltip(item, true) }} onMouseLeave={() => { toggleTooltip(item, false) }}>
                          <Tooltip title="Click for more info" color="orange"
                            visible={tooltipVisible !== null && tooltipVisible.itemId === item.distributorId && tooltipVisible.visibility}>
                            <img alt="info" src={require('../../assets/images/info.png')} />

                          </Tooltip>
                        </div>
                      </Col>
                    </Row>

                  </Col>
                </Fragment>
              )
            }
            else {
              return (
                <Fragment key={index}>

                  <Col xs={24} md={24} lg={12} className="mt50">
                    <Row justify="start" onClick={() => productsList(item)} style={{ cursor: 'pointer' }}>
                      <Col span={24} >

                        <div className="res_pic">
                          <img key={Date.now()} preview="false" src={item.logo} className="restaurant_pic" />
                        </div>

                      </Col>
                    </Row>
                    <Row type="flex">
                      <Col xs={21} md={21}>
                        <h6 className="restaurant_name">{item.name}</h6>
                        <h6 className="restaurant_name">{item.address1}</h6>
                      </Col>
                      <Col xs={3} md={3}>
                        <div className="restaurant_tooltip" onClick={() => showMoreInfoModal(item)} onMouseEnter={() => { toggleTooltip(item, true) }} onMouseLeave={() => { toggleTooltip(item, false) }}>
                          <Tooltip title="Click for more info" color="orange"
                            visible={tooltipVisible !== null && tooltipVisible.itemId === item.id && tooltipVisible.visibility}>
                            <img alt="info" src={require('../../assets/images/info.png')} />

                          </Tooltip>
                        </div>
                      </Col>
                    </Row>

                  </Col>
                </Fragment>
              )
            }
          })) : (
            <>
              {!vendorLoading && <div className="novendor">
                <h3>No Vendors Found</h3>
              </div>}
            </>
          )
        }
      </Row>
      <div style={{ display: 'flex', padding: 10, margin: 10, justifyContent: 'center', alignItems: 'center', width: '100%' }}><Spin spinning={vendorLoading} size="large" /></div>
      {!!vendorsList && vendorsListEnded !== null && !vendorsListEnded && <Row justify="center" style={{ opacity: (vendorLoading ? 0.15 : 1) }}>
        <Button
          onClick={handleLoadMore}
          disabled={vendorsListEnded}
          className="ant-btn-primary load-more-btn">Load More...</Button>
      </Row>}
      {
        itemClicked !== null &&
        <MoreInfoModal
          {...props}
          visible={moreInfoModal}
          setMoreInfoModal={setMoreInfoModal}
          vendor={itemClicked}
        />
      }
    </Fragment>
  )
}

export default MainContent;