import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Layout, Row, Col, Spin, Button } from 'antd';
import { connect } from 'react-redux';
import {
  AuthHeader,
  PageFooter,
} from '../../components';
import * as vendorActions from '../../containers/Vendor/actions';
import { calculateDecimal, getError } from '../../utils/commonUtils';
import * as homeActions from '../Home/actions';
import { cloneDeep } from 'lodash';

const MultipleCart = (props) => {
  const { Header, Footer, Content } = Layout;
  const { getCartDistributor, cartDistributor, clearCartDetails, saveReservationStatus, brandingDetails, getUserDetails, selectedVendorData } = props;
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const prevState = useRef({ cartDistributor }).current;

  useEffect(() => {
    clearCartDetails();
    getCartDistributor();
  }, []);

  useEffect(() => {
    if (cartDistributor !== null) {
      setLoading(false);
      if (!cartDistributor.err && cartDistributor.length === 1 && !!cartDistributor.items && cartDistributor.items.length > 0) {
        history.push(`/orderinfo/${cartDistributor[0].id}`)
      }
      if (cartDistributor.err && cartDistributor !== prevState.cartDistributor) {
        getError(cartDistributor.err);
      }
    }
    else {
      setLoading(true);
      getCartDistributor();
    }
  }, [cartDistributor]);


  const goToCartInfo = (distributor) => {
    if (distributor.b2cType === "RESERVATION") {
      saveReservationStatus(true);
    }
    else {
      saveReservationStatus(false);
    }
    history.push(`/orderinfo/${distributor.id}`)
  }

  useEffect(() => {
    if (brandingDetails === undefined || brandingDetails === null) {
      getUserDetails();
    }
  }, [brandingDetails]);

  const continueShopping = () => {
    if (selectedVendorData === undefined || selectedVendorData === null) {
      history.push("/");
    } else {
      history.push({
        pathname: `/shop/${selectedVendorData.distributorId}`,
      });
    }
  }

  const calculateCartPrice = (items) => {
    let price = 0;
    if (!!items && items.length > 0) {
      items.map((item, index) => {
        price = price + item.subTotal;
      })
    }
    return calculateDecimal(price);
  }

  const renderCartDetails = (distributor, index, parent = null) => {
    return (
      <Row key={index} className="myorder_list" gutter={{ xs: 0, md: 40 }}>
        <Col xs={24} md={8}>
          <img alt="mail-icon" src={distributor.distributorLogo} className="myorder_pic" />
        </Col>
        <Col xs={24} md={16}>
          <Row justify="space-between" align="middle">
            <Col xs={16} md={20}>
              {!!distributor.children && distributor.children.length > 0 ?
                <>
                  <Row justify="space-between" style={{ marginBottom: 20 }}>
                    <Col xs={20} md={12}>
                      <div className="order_title">{distributor.distributorName}</div>
                    </Col>
                    <Col xs={4} md={12}>
                      <div className="order_title">{distributor.customer.name}</div>
                    </Col>
                  </Row>
                </> :
                <div className="order_title">{distributor.distributorName}</div>
              }
              <div className="order_description">
                {distributor.items && distributor.items.map((item, index) => {
                  return (
                    <p key={index}>{item.quantity} x {item.productName}<br /> </p>
                  )
                })}
              </div>
              <div style={{ marginTop: 10 }}>
                <Button className="radius8 font_size_family btn_pad1" type="primary" htmlType="button" onClick={() => { goToCartInfo(parent ? parent : distributor) }}> View Cart</Button>
              </div>
            </Col>
            {distributor.b2cType === "ORDERING" && <Col xs={8} md={4}>
              <div className="order_price">$ {calculateCartPrice(distributor.items)}</div>
              <div className="order_price_mobile">$ {calculateCartPrice(distributor.items)}</div>
            </Col>}
          </Row>
        </Col>
      </Row>
    )
  }

  return (
    <Layout>
      <Header>
        <AuthHeader {...props} />
      </Header>
      <Content className="full_content">
        <div className="site-layout-content">
          <Row>
            <Col span={24}>
              <h1 className="tab_heading">Active Carts</h1>
            </Col>
            <Col span={24}>
              <Button ghost onClick={continueShopping}>
                <Col xs={24}>
                  <div className="back_search"><svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.3992 10.3496H4.68625L15.288 1.14648C15.4575 0.998145 15.3546 0.722656 15.1306 0.722656H12.4514C12.3333 0.722656 12.2213 0.765039 12.1335 0.840722L0.69318 10.7674C0.588399 10.8582 0.504366 10.9705 0.446774 11.0966C0.389181 11.2228 0.359375 11.3598 0.359375 11.4985C0.359375 11.6372 0.389181 11.7742 0.446774 11.9003C0.504366 12.0265 0.588399 12.1388 0.69318 12.2296L12.2001 22.2168C12.2455 22.2562 12.3 22.2773 12.3575 22.2773H15.1276C15.3516 22.2773 15.4545 21.9988 15.285 21.8535L4.68625 12.6504H22.3992C22.5324 12.6504 22.6414 12.5414 22.6414 12.4082V10.5918C22.6414 10.4586 22.5324 10.3496 22.3992 10.3496Z" fill="black" />
                  </svg> Continue Shopping
              </div>
                </Col>
              </Button>
            </Col>
          </Row>
          <Spin spinning={loading} size="large" >
            <Row className="order_list">
              <Col span={24}>
                {cartDistributor !== undefined && cartDistributor !== null && !cartDistributor.err && cartDistributor.length > 0 ?
                  (<>
                    {
                      cartDistributor.map((distributor, index) => {
                        if (process.env.REACT_APP_WEEKLY_CART === 'true') {
                          if (distributor.subType === 'DAILY') {
                            return renderCartDetails(distributor, index)
                          } else if (distributor.subType === 'WEEKLY') {
                            const tempCartDetail = cloneDeep(distributor);
                            tempCartDetail.items = [];
                            tempCartDetail.customer = {}
                            if (distributor.children && distributor.children.length > 0) {
                              distributor.children.map(child => {
                                if (child.customer !== null) {
                                  tempCartDetail.customer = child.customer
                                }
                                if (child.items && child.items.length > 0) {
                                  child.items.map(item => {
                                    tempCartDetail.items.push(item)
                                  })
                                }
                              })
                            }
                            return renderCartDetails(tempCartDetail, index)
                            // return distributor.children && distributor.children.length > 0 
                            //     ? distributor.children.map((childCartDistributor, otherIndex) => renderCartDetails(childCartDistributor, String(index)+String(otherIndex), distributor))
                            //     : null
                          }
                        } else {
                          return renderCartDetails(distributor, index)
                        }
                      })
                    }
                  </>) :
                  (<>
                    <Row>
                      <Col span={24} className="ordered_items order_information">
                        <h3 style={{ color: '#929A9A', textAlign: 'center', margin: '15%' }}>Your Shopping Cart is empty.</h3>
                      </Col>
                    </Row>
                  </>)
                }
              </Col>
            </Row>
          </Spin>
        </div>
      </Content>
      <Footer>
        <PageFooter {...props} />
      </Footer>
    </Layout>
  )
}

const mapStatetoProps = (state) => {
  return {
    cartDistributor: state.VendorReducer.cartDistributor,
    onlyReservationAvailable: state.UserReducer.onlyReservationAvailable,
    brandingDetails: state.UserReducer.brandingDetails,
    selectedVendorData: state.UserReducer.selectedVendorData,
  }
}

const mapDispatchtoProps = (dispatch) => {
  return {
    dispatch,
    getCartDistributor: () => dispatch({ type: vendorActions.GET_CART_DISTRIBUTOR_DETAILS }),
    clearCartDetails: () => dispatch({ type: vendorActions.SAVE_CART_DETAILS, cartDetails: null }),
    saveReservationStatus: (params) => dispatch({ type: homeActions.SAVE_RESERVATION_STATUS, reservationStatus: params }),
    getUserDetails: () => dispatch({ type: homeActions.USER_DETAILS }),
  }
}
export default connect(mapStatetoProps, mapDispatchtoProps)(MultipleCart);