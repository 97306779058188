import React from 'react';
import { Modal, Button } from 'antd';

const RepeatItemsAnotherCart = (props) => {
  const { showAnotherCartInfo, setShowAnotherCartInfo, selectedPickupDateTime, repeatOrder, id } = props;

  const handleCancel = () => {
    setShowAnotherCartInfo(false);
  }

  const handleOk = () => {
    const params = {
      "pickupDateTime": selectedPickupDateTime,
      "useExistingCart": true
    }
    repeatOrder(id, params);
    setShowAnotherCartInfo(false);
  }

  return (
    <>
      <Modal
        className="order-modal"
        visible={showAnotherCartInfo}
        onCancel={handleCancel}
        footer={null}>
        <div className="repeat-info">You already have a cart open. Are you sure you want to reopen this cart?</div>
        <div className="fixed-button-bottom">
          <Button
            type="primary"
            htmlType="submit"
            className="radius12 font_size_family btn_pad" onClick={handleOk}>Reopen Cart</Button>
        </div>

        <div className="fixed-button-bottom">
          <Button
            htmlType="submit"
            className="radius12 font_size_family btn_pad" onClick={handleCancel}>Cancel</Button>
        </div>
      </Modal>
    </>
  )
}

export default RepeatItemsAnotherCart;