import React, { useState, useEffect } from 'react';
import { Row, Col, Modal, Layout, Collapse } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import './styles.scss';
import { calculateDecimal } from '../../utils/commonUtils';

const CartItemsMoreInfo = (props) => {
  const { moreInfoVisible, setMoreInfoVisible, selectedOrder, reservationStatus } = props;
  const [reservation, setReservation] = useState(false);
  const [ingredientsInCart, setIngredientsInCart] = useState([]);

  const handleOk = e => {
    setMoreInfoVisible(false)
  };

  const handleCancel = e => {
    setMoreInfoVisible(false)
  };

  useEffect(() => {
    if (reservationStatus === undefined || reservationStatus === null || !reservationStatus) {
      setReservation(false);
    }
    else {
      setReservation(true);
    }
  }, [reservationStatus]);

  useEffect(() => {
    if (selectedOrder !== undefined && selectedOrder !== null && selectedOrder.ingredients && selectedOrder.ingredients.length > 0) {
      let productIngredientsArr = [...selectedOrder.ingredients];
      for (let i = 0; i < selectedOrder.ingredients.length; i++) {
        if (selectedOrder.ingredients[i].quantity > 0) {
          productIngredientsArr[i] = { ...productIngredientsArr[i], price: calculateDecimal(selectedOrder.ingredients[i].price * selectedOrder.ingredients[i].quantity) };
        }
      }
      setIngredientsInCart(productIngredientsArr);
    }
  }, [selectedOrder]);

  return (
    <>
      <Modal
        className="order-modal"
        visible={moreInfoVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        {selectedOrder !== null &&
          <Layout>
            <Row>
              <Col span={24} style={{ marginTop: 20 }}>
                <div className="item_Name">{selectedOrder.productName || selectedOrder.name}</div>
              </Col>
              {!reservation && <Col span={24}><div className="item_rupee"> $ {calculateDecimal(selectedOrder.sellPrice)}</div></Col>}
            </Row>
            <Row>
              <Col span={24}>
                {selectedOrder !== null && selectedOrder.biography && <div>{ReactHtmlParser(selectedOrder.biography)}</div>}
              </Col>
            </Row>

            {selectedOrder !== null && ingredientsInCart.length > 0 &&
              <Row className='restaurant_items'>
                <Col span={24}>
                  <div className="Ingridients_label">Customize</div>
                </Col>
                <Col span={24} style={{ marginTop: '20px' }}>
                  <Collapse expandIconPosition="left" defaultActiveKey={ingredientsInCart[0].categoryId} ghost>
                    {
                      ingredientsInCart.map((ingCategory, categoryIndex) => {
                        return (
                          <Collapse.Panel header={ingCategory.categoryName} key={ingCategory.categoryId}>
                            {!!ingCategory.items && ingCategory.items.length > 0 &&
                              <Row gutter={{ xs: 20, sm: 20, md: 16, lg: 16 }}>
                                {ingCategory.items.map((ingredient, index) => {
                                  return (
                                    <Col xs={10} md={6} style={{ margin: 10, minHeight: 170, maxWidth: 130 }} className="Ingridients_div" key={index}>
                                      <div>
                                        <Row className="ingredient_image_div">
                                          <Col xs={24} style={{ display: "flex", justifyContent: 'center' }}>
                                            <div className="ing_img_div">
                                              {ingredient.photoUrl !== undefined && ingredient.photoUrl !== null &&
                                                <img alt="Restaurant Picture" src={ingredient.photoUrl} className="ing_image" />}
                                            </div>
                                          </Col>
                                          <Col xs={24}>
                                            <Row justify="center">
                                              <Col>
                                                <div className="ingridients_name">{ingredient.name}</div>
                                              </Col>
                                            </Row>
                                            <Row justify="center">
                                              <Col>
                                                {!reservation && <div className="ingridients_weight">$ {calculateDecimal(ingredient.price)}</div>}
                                              </Col>
                                            </Row>
                                          </Col>
                                        </Row>

                                        <Row style={{ textAlign: "center", marginTop: 10 }}>
                                          <Col xs={24}>
                                            {<div className="ingridients_weight">Quantity: <span className="ingridients_name">{ingredient.quantity}</span></div>}
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  )
                                })}
                              </Row>
                            }
                          </Collapse.Panel>
                        )
                      })
                    }
                  </Collapse>
                </Col>
              </Row>
            }

          </Layout>}
      </Modal>

    </>
  )
}
export default CartItemsMoreInfo;