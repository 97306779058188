import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Select, Button } from 'antd';
import PickupByDateTime from '../PickupByDateTime';
import MobilePickupInfoDrawer from '../MobilePickupInfoDrawer';
const _ = require("lodash");

const PickupByOrderType = (props) => {

  const { pickupByOrderTypeVisible, setPickupByOrderTypeVisible, saveNewPickupMenuData, programInfo, clearPickupDates, clearPickupTimes, userDetails, getStudentBrandingDetails } = props;
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedPickupLocation, setSelectedPickupLocation] = useState(null);
  const [locationOptions, setLocationOptions] = useState(null);
  const [pickupLocationOptions, setPickupLocationOptions] = useState(null);
  const [pickupByDateTimeVisible, setPickupByDateTimeVisible] = useState(false);
  const [hiddenPickup, setHiddenPickup] = useState(true);
  const [showMobilePickupDrawer, setMobilePickupDrawer] = useState(false);
  const [dataList, setDataList] = useState(null);
  const [pickupField, setPickupField] = useState("city/state");
  const [orderType, setOrderType] = useState("For A Week");
  const [orderTypeArray, setOrderTypeArray] = useState(["Single Day", "For A Week"]);
  const [studentsArray, setStudentsArray] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);

  useEffect(() => {
    setBtnDisabled(!selectedProgram || !orderType || !selectedLocation || !selectedPickupLocation || (studentsArray.length > 0 && !selectedStudent));
  }, [selectedProgram, orderType, selectedLocation, selectedPickupLocation, studentsArray, selectedStudent]);

  useEffect(() => {
    if (selectedLocation !== null) {
      setHiddenPickup(false);
    }
    else {
      setHiddenPickup(true);
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (!!userDetails) {
      if (!!userDetails.children && userDetails.children.length > 0) {
        setStudentsArray(userDetails.children);
        if (!!selectedStudent) {
          userDetails.children.find((child, index) => {
            if (child.customerId === selectedStudent) {
              if (!!child.defaultLocation) {
                let field = { value: child.defaultLocation.id, children: child.defaultLocation.name, }
                setLocation(child.defaultLocation.id, field);
              }
              if (!!child.defaultPickupLocation) {
                let pickupObj = { value: child.defaultPickupLocation.id, name: child.defaultPickupLocation.name, street_one: child.defaultPickupLocation.street_one, street_two: child.defaultPickupLocation.street_two, city: child.defaultPickupLocation.city, state: child.defaultPickupLocation.state, zip: child.defaultPickupLocation.zip }
                setPickupLocation(child.defaultPickupLocation.id, pickupObj)
              }
            }
          })
        }
      }
      else {
        setStudentsArray([]);
        if (!!userDetails.customer) {
          if (!!userDetails.customer.defaultLocation) {
            let field = { value: userDetails.customer.defaultLocation.id, children: userDetails.customer.defaultLocation.name, }
            setLocation(userDetails.customer.defaultLocation.id, field);
          }
          if (!!userDetails.customer.defaultPickupLocation) {
            let pickupObj = { value: userDetails.customer.defaultPickupLocation.id, name: userDetails.customer.defaultPickupLocation.name, street_one: userDetails.customer.defaultPickupLocation.street_one, street_two: userDetails.customer.defaultPickupLocation.street_two, city: userDetails.customer.defaultPickupLocation.city, state: userDetails.customer.defaultPickupLocation.state, zip: userDetails.customer.defaultPickupLocation.zip }
            setPickupLocation(userDetails.customer.defaultPickupLocation.id, pickupObj)
          }
        }

      }
    }
    else {
      setStudentsArray([]);
    }
  }, [userDetails, selectedStudent]);
  useEffect(() => {
    if (programInfo !== null && !programInfo.err) {
      let arrOfLocations = [];
      let arrOfPickupLocations = [];
      if (programInfo.program) {
        setSelectedProgram(programInfo.program);
      }

      if (programInfo.locations && programInfo.locations.length > 0) {
        let locationObj = null;
        let pickupObj = null;
        programInfo.locations.map((location, index) => {
          locationObj = { id: location.id, name: location.name }
          if (arrOfLocations.indexOf(locationObj === -1)) {
            arrOfLocations.push(locationObj);
          }
          if (location.hubs && location.hubs.length > 0) {
            location.hubs.map((pickup, index) => {
              pickupObj = { id: pickup.id, name: pickup.name, street_one: pickup.street_one, street_two: pickup.street_two, city: pickup.city, state: pickup.state, zip: pickup.zip }
              if (arrOfPickupLocations.indexOf(pickupObj === -1)) {
                arrOfPickupLocations.push(pickupObj);
              }
            });
          }
          else {
            pickupObj = { id: location.id, name: location.name, street_one: location.street_one, street_two: location.street_two, city: location.city, state: location.state, zip: location.zip }
            if (arrOfPickupLocations.indexOf(pickupObj === -1)) {
              arrOfPickupLocations.push(pickupObj);
            }
          }
        });

        setLocationOptions(_.uniqBy(arrOfLocations, 'id'));
        setPickupLocationOptions(_.uniqBy(arrOfPickupLocations, 'id'));
      }

    }
  }, [programInfo]);

  useEffect(() => {
    if (selectedLocation !== null) {
      let pickupObj = null;
      let arrOfPickupLocations = [];
      if (programInfo !== null && !programInfo.err) {
        if (programInfo.locations && programInfo.locations.length > 0) {
          programInfo.locations.map((location, index) => {
            if (selectedLocation.id === location.id) {
              if (location.hubs && location.hubs.length > 0) {
                location.hubs.map((pickup, index) => {
                  pickupObj = { id: pickup.id, name: pickup.name, street_one: pickup.street_one, street_two: pickup.street_two, city: pickup.city, state: pickup.state, zip: pickup.zip }
                  if (arrOfPickupLocations.indexOf(pickupObj === -1)) {
                    arrOfPickupLocations.push(pickupObj);
                  }
                });
              }
              else {
                pickupObj = { id: location.id, name: location.name, street_one: location.street_one, street_two: location.street_two, city: location.city, state: location.state, zip: location.zip }
                if (arrOfPickupLocations.indexOf(pickupObj === -1)) {
                  arrOfPickupLocations.push(pickupObj);
                }
              }
            }
          });
        }
      }
      setPickupLocationOptions(_.uniqBy(arrOfPickupLocations, 'id'));
    }
  }, [selectedLocation]);

  const setLocation = (value, field) => {
    let locationObj = { id: field.value, name: field.children }
    setSelectedLocation(locationObj);
    setSelectedPickupLocation(null);
    let pickupObj = null;
    let arrOfPickupLocations = [];
    if (programInfo !== null && !programInfo.err) {
      if (programInfo.locations && programInfo.locations.length > 0) {
        programInfo.locations.map((location, index) => {
          if (location.id === value) {
            if (location.hubs && location.hubs.length > 0) {
              location.hubs.map((pickup, index) => {
                pickupObj = { id: pickup.id, name: pickup.name, street_one: pickup.street_one, street_two: pickup.street_two, city: pickup.city, state: pickup.state, zip: pickup.zip }
                if (arrOfPickupLocations.indexOf(pickupObj === -1)) {
                  arrOfPickupLocations.push(pickupObj);
                }
              });
            }
            else {
              pickupObj = { id: location.id, name: location.name, street_one: location.street_one, street_two: location.street_two, city: location.city, state: location.state, zip: location.zip }
              if (arrOfPickupLocations.indexOf(pickupObj === -1)) {
                arrOfPickupLocations.push(pickupObj);
              }
            }
          }
        });
      }
    }
    setPickupLocationOptions(_.uniqBy(arrOfPickupLocations, 'id'));
  }

  const setPickupLocation = (value, field) => {
    let pickupObj = { id: field.value, name: field.name, street_one: field.street_one, street_two: field.street_two, city: field.city, state: field.state, zip: field.zip }
    let locationObj = null;
    setSelectedPickupLocation(pickupObj);
    if (programInfo !== null && !programInfo.err) {
      if (programInfo.locations && programInfo.locations.length > 0) {
        programInfo.locations.map((location, index) => {
          if (location.hubs && location.hubs.length > 0) {
            location.hubs.map((pickup, index) => {
              if (pickup.id === value) {
                locationObj = { id: location.id, name: location.name }
                setSelectedLocation(locationObj);
              }
            })
          }
          else {
            if (location.id === value) {
              locationObj = { id: location.id, name: location.name }
              setSelectedLocation(locationObj);
            }
          }
        })
      }
      // if (programInfo.pickupAddresses && programInfo.pickupAddresses.length > 0) {
      //   programInfo.pickupAddresses.map((item, index) => {
      //     if (item.cities && item.cities.length > 0) {
      //       item.cities.map((city, index) => {
      //         if (city.zipCodes && city.zipCodes.length > 0) {
      //           city.zipCodes.map((zip, index) => {
      //             if (zipOfSelectedPickup === zip) {
      //               setSelectedCityState(city.name + ", " + item.state);
      //               setSelectedZip(zip);
      //             }
      //           })
      //         }
      //       })
      //     }
      //   })
      // }

    }
  }

  const getStudentName = (customerId) => {
    if (!!customerId && !!studentsArray) {
      let student = studentsArray.find((student) => student.customerId === customerId);
      return student.name;
    }
  }

  const handleCancel = e => {
    setPickupByOrderTypeVisible(!pickupByOrderTypeVisible)
  };

  const handleOk = () => { // NOTE - handle okay
    clearPickupDates();
    clearPickupTimes();
    if (!!selectedStudent) {

      studentsArray.find((student, index) => {
        if (student.customerId === selectedStudent) {
          const params = {
            programId: student.programId
          }
          getStudentBrandingDetails(params);

        }
      })
    }
    const data = {
      program: selectedProgram,
      customerId: selectedStudent,
      orderType: orderType,
      location: selectedLocation,
      pickupLocation: selectedPickupLocation,
      studentName: getStudentName(selectedStudent)
    }
    saveNewPickupMenuData(data);
    setSelectedPickupLocation(null);
    setSelectedLocation(null);
    // setOrderType(null);
    setPickupByDateTimeVisible(true);

  }

  const showMobilePopup = (field) => {
    if (field === "orderType") {
      if (!!orderTypeArray && orderTypeArray.length > 0) {
        setDataList(orderTypeArray);
        setMobilePickupDrawer(true);
        setPickupField(field);
      }
    }
    else if (field === "location") {
      if (!!locationOptions && locationOptions.length > 0) {
        let arrLocation = [], uniqArrLocation = [];
        locationOptions.map((location, index) => {
          if (arrLocation.indexOf(location.name) === -1) {
            arrLocation.push(location.name);
          }
        });
        uniqArrLocation = _.uniq(arrLocation);
        setDataList(uniqArrLocation);
      }
      setMobilePickupDrawer(true);
      setPickupField(field);
    }
    else if (field === "pickupLocation") {
      if (!!pickupLocationOptions && pickupLocationOptions.length > 0) {
        let arrLocation = [], uniqArrLocation = [];
        pickupLocationOptions.map((location, index) => {
          if (arrLocation.indexOf(location.name) === -1) {
            arrLocation.push(location.name);
          }
        });
        uniqArrLocation = _.uniq(arrLocation);
        setDataList(uniqArrLocation);
      }
      setMobilePickupDrawer(true);
      setPickupField(field);
    }

    else if (field === "studentName") {
      setDataList(studentsArray);
      setMobilePickupDrawer(true);
      setPickupField(field);
    }
  }
  return (
    <>
      <Modal className="order-modal" title="Select a pick up location"
        visible={pickupByOrderTypeVisible}
        footer={null}
        onCancel={handleCancel}
      >

        <Row className="rowborder inputicon">
          <Col span={2} className="pickup_icon">
            {/* <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M26.125 5.3335H6.875C5.35622 5.3335 4.125 6.5274 4.125 8.00016V26.6668C4.125 28.1396 5.35622 29.3335 6.875 29.3335H26.125C27.6438 29.3335 28.875 28.1396 28.875 26.6668V8.00016C28.875 6.5274 27.6438 5.3335 26.125 5.3335Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M22 2.6665V7.99984" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M11 2.6665V7.99984" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M4.125 13.3335H28.875" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
             */}
            <svg id="Layer_1" height="32" viewBox="0 0 512 512" width="32" xmlns="http://www.w3.org/2000/svg"><g><g><path d="m433 512c-11.046 0-20-8.954-20-20 0-78.299-63.701-142-142-142h-30c-78.299 0-142 63.701-142 142 0 11.046-8.954 20-20 20s-20-8.954-20-20c0-100.355 81.645-182 182-182h30c100.355 0 182 81.645 182 182 0 11.046-8.954 20-20 20z" /></g><g><path d="m254 270c-74.439 0-135-60.561-135-135s60.561-135 135-135 135 60.561 135 135-60.561 135-135 135zm0-230c-52.383 0-95 42.617-95 95s42.617 95 95 95 95-42.617 95-95-42.617-95-95-95z" /></g></g></svg>
          </Col>
          <Col span={22}>
            {selectedProgram !== null &&
              <Select
                disabled={true}
                placeholder="Program"
                suffixIcon=""
                getPopupContainer={(trigger) => trigger.parentNode}
                defaultValue={selectedProgram.name}>
                <Select.Option key={1} value={selectedProgram.id}>{selectedProgram.name}</Select.Option>
              </Select>
            }
          </Col>
        </Row>
        {!!studentsArray && studentsArray.length > 0 &&
          <Row className="rowborder">
            <Col span={2} className="pickup_icon">
              <svg id="Layer_1" height="32" viewBox="0 0 512 512" width="32" xmlns="http://www.w3.org/2000/svg"><g><g><path d="m433 512c-11.046 0-20-8.954-20-20 0-78.299-63.701-142-142-142h-30c-78.299 0-142 63.701-142 142 0 11.046-8.954 20-20 20s-20-8.954-20-20c0-100.355 81.645-182 182-182h30c100.355 0 182 81.645 182 182 0 11.046-8.954 20-20 20z" /></g><g><path d="m254 270c-74.439 0-135-60.561-135-135s60.561-135 135-135 135 60.561 135 135-60.561 135-135 135zm0-230c-52.383 0-95 42.617-95 95s42.617 95 95 95 95-42.617 95-95-42.617-95-95-95z" /></g></g></svg>

            </Col>
            {/* <Col span={22} className="show_desktop"> */}
            <Col span={22} className="show_desktop">
              <Select
                placeholder="Student Name"
                onSelect={(value) => { setSelectedStudent(value) }}
                suffixIcon=""
                getPopupContainer={(trigger) => trigger.parentNode}>
                {!!studentsArray && studentsArray.length > 0 &&
                  studentsArray.map((item, index) => {
                    return (
                      <Select.Option key={index} value={item.customerId}>
                        {item.name}<br />
                        {item.studentId}
                      </Select.Option>
                    )
                  })}
              </Select>
            </Col>
            <Col span={22} className="show_mobile">
              <div style={{ paddingLeft: 11, paddingRight: 11 }} className="ant-select ant-select-selector ant-select-selection-item" onClick={() => { showMobilePopup("studentName") }}>
                {!!selectedStudent && !!studentsArray ? getStudentName(selectedStudent) : "Student Name"}
              </div>
            </Col>
          </Row>
        }


        <Row className="rowborder">
          <Col span={2} className="pickup_icon">
            <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M26.125 5.3335H6.875C5.35622 5.3335 4.125 6.5274 4.125 8.00016V26.6668C4.125 28.1396 5.35622 29.3335 6.875 29.3335H26.125C27.6438 29.3335 28.875 28.1396 28.875 26.6668V8.00016C28.875 6.5274 27.6438 5.3335 26.125 5.3335Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M22 2.6665V7.99984" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M11 2.6665V7.99984" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M4.125 13.3335H28.875" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

          </Col>
          {/* <Col span={22} className="show_desktop"> */}
          <Col span={22} >
            <Select
              placeholder="Order Type"
              // disabled={true}
              onSelect={(value) => { setOrderType(value) }}
              suffixIcon=""
              defaultValue={orderType}
              getPopupContainer={(trigger) => trigger.parentNode}
              value={orderType !== null ? orderType : null}>
              {orderTypeArray !== null && orderTypeArray.map((item, index) => {
                return (
                  <Select.Option key={index} value={item}>{item}</Select.Option>
                )
              })}
            </Select>
          </Col>
          {/* <Col span={22} className="show_mobile">
            <div style={{ paddingLeft: 11, paddingRight: 11 }} disable={true} className="ant-select ant-select-selector ant-select-selection-item" onClick={() => { showMobilePopup("orderType") }}>
              {orderType !== null ? orderType : "Order Type"}
            </div>
          </Col> */}
        </Row>

        <Row className="rowborder">
          <Col span={2} className="pickup_icon">
            {/* <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M26.125 5.3335H6.875C5.35622 5.3335 4.125 6.5274 4.125 8.00016V26.6668C4.125 28.1396 5.35622 29.3335 6.875 29.3335H26.125C27.6438 29.3335 28.875 28.1396 28.875 26.6668V8.00016C28.875 6.5274 27.6438 5.3335 26.125 5.3335Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M22 2.6665V7.99984" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M11 2.6665V7.99984" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M4.125 13.3335H28.875" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg> */}
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M425.951,89.021C386.864,32.451,324.917,0,256.006,0S125.148,32.451,86.061,89.021
                c-38.895,56.284-47.876,127.541-24.072,190.496c6.367,17.192,16.488,33.895,30.01,49.547l150.378,176.634
                c3.401,3.998,8.384,6.302,13.629,6.302c5.245,0,10.228-2.303,13.629-6.302l150.336-176.586
                c13.582-15.742,23.69-32.427,30.004-49.481C473.827,216.562,464.846,145.305,425.951,89.021z M416.451,267.093
                c-4.869,13.158-12.818,26.167-23.613,38.68c-0.03,0.03-0.06,0.06-0.084,0.096L256.006,466.487L119.174,305.768
                c-10.789-12.502-18.738-25.51-23.655-38.794c-19.686-52.065-12.215-110.981,19.991-157.592
                c32.307-46.76,83.519-73.578,140.496-73.578c56.976,0,108.182,26.817,140.49,73.578
                C428.708,155.993,436.185,214.909,416.451,267.093z"/>
              <path d="M256.006,106.219c-55.276,0-100.252,44.97-100.252,100.252s44.97,100.252,100.252,100.252s100.252-44.97,100.252-100.252
                C356.258,151.195,311.282,106.219,256.006,106.219z M256.006,270.918c-35.536,0-64.448-28.912-64.448-64.448
                c0-35.536,28.912-64.448,64.448-64.448c35.536,0,64.448,28.912,64.448,64.448S291.542,270.918,256.006,270.918z"/>
            </svg>
          </Col>
          <Col span={22} className="show_desktop">
            <Select
              placeholder="Location"
              onSelect={setLocation}
              suffixIcon=""
              getPopupContainer={(trigger) => trigger.parentNode}
              value={selectedLocation !== null ? selectedLocation.name : null}>
              {locationOptions !== null && locationOptions.map((item, index) => {
                return (
                  <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                )
              })}
            </Select>
          </Col>
          <Col span={22} className="show_mobile">
            <div style={{ paddingLeft: 11, paddingRight: 11 }} className="ant-select ant-select-selector ant-select-selection-item" onClick={() => { showMobilePopup("location") }}>
              {!!selectedLocation && selectedLocation.name ? selectedLocation.name : "Location"}
            </div>
          </Col>
        </Row>

        <Row className="rowborder" hidden={hiddenPickup}>
          <Col span={2} className="pickup_icon">
            {/* <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M26.125 5.3335H6.875C5.35622 5.3335 4.125 6.5274 4.125 8.00016V26.6668C4.125 28.1396 5.35622 29.3335 6.875 29.3335H26.125C27.6438 29.3335 28.875 28.1396 28.875 26.6668V8.00016C28.875 6.5274 27.6438 5.3335 26.125 5.3335Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M22 2.6665V7.99984" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M11 2.6665V7.99984" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M4.125 13.3335H28.875" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg> */}
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M425.951,89.021C386.864,32.451,324.917,0,256.006,0S125.148,32.451,86.061,89.021
                c-38.895,56.284-47.876,127.541-24.072,190.496c6.367,17.192,16.488,33.895,30.01,49.547l150.378,176.634
                c3.401,3.998,8.384,6.302,13.629,6.302c5.245,0,10.228-2.303,13.629-6.302l150.336-176.586
                c13.582-15.742,23.69-32.427,30.004-49.481C473.827,216.562,464.846,145.305,425.951,89.021z M416.451,267.093
                c-4.869,13.158-12.818,26.167-23.613,38.68c-0.03,0.03-0.06,0.06-0.084,0.096L256.006,466.487L119.174,305.768
                c-10.789-12.502-18.738-25.51-23.655-38.794c-19.686-52.065-12.215-110.981,19.991-157.592
                c32.307-46.76,83.519-73.578,140.496-73.578c56.976,0,108.182,26.817,140.49,73.578
                C428.708,155.993,436.185,214.909,416.451,267.093z"/>
              <path d="M256.006,106.219c-55.276,0-100.252,44.97-100.252,100.252s44.97,100.252,100.252,100.252s100.252-44.97,100.252-100.252
                C356.258,151.195,311.282,106.219,256.006,106.219z M256.006,270.918c-35.536,0-64.448-28.912-64.448-64.448
                c0-35.536,28.912-64.448,64.448-64.448c35.536,0,64.448,28.912,64.448,64.448S291.542,270.918,256.006,270.918z"/>
            </svg>
          </Col>
          <Col span={22} className="show_desktop">
            <Select
              placeholder="Pick-Up Location"
              onSelect={setPickupLocation}
              suffixIcon=""
              getPopupContainer={(trigger) => trigger.parentNode}
              value={selectedPickupLocation !== null ? selectedPickupLocation.name : null}>
              {pickupLocationOptions !== null && pickupLocationOptions.map((item, index) => {
                return (
                  <Select.Option key={index} value={item.id} name={item.name} street_one={item.street_one} street_two={item.street_two} city={item.city} state={item.state} zip={item.zip}>
                    <div>{item.name !== null && item.name}</div>
                    <p className="pickup-location-subtext">{item.street_one !== null && <>{item.street_one}, </>}{item.street_two !== null && <>{item.street_two}<br /> </>}{item.city !== null && <>{item.city}, </>}{item.state !== null && <>{item.state} </>}{item.zip !== null && item.zip}</p>
                  </Select.Option>
                )
              })}
            </Select>
          </Col>
          <Col span={22} className="show_mobile">
            <div style={{ paddingLeft: 11, paddingRight: 11 }} className="ant-select ant-select-selector ant-select-selection-item" onClick={() => { showMobilePopup("pickupLocation") }}>
              {!!selectedPickupLocation && selectedPickupLocation.name ? selectedPickupLocation.name : "Pick-Up Location"}
            </div>
          </Col>
        </Row>

        <div className="fixed-button-bottom">
          <Button
            type="primary"
            htmlType="submit"
            disabled={btnDisabled}
            className="radius12 font_size_family btn_pad" onClick={handleOk}>Continue</Button>
        </div>
        <PickupByDateTime fromTime={false} dateTimeModalVisible={pickupByDateTimeVisible} setDateTimeModalVisible={setPickupByDateTimeVisible} {...props} />
        <MobilePickupInfoDrawer
          showMobilePickupDrawer={showMobilePickupDrawer}
          setMobilePickupDrawer={setMobilePickupDrawer}
          dataList={dataList}
          selectedOrderType={orderType}
          setOrderType={setOrderType}
          selectedLocation={selectedLocation}
          setLocation={setLocation}
          selectedPickupLocation={selectedPickupLocation}
          setPickupLocation={setPickupLocation}
          pickupField={pickupField}
          locationOptions={locationOptions}
          pickupLocationOptions={pickupLocationOptions}
          setSelectedStudent={setSelectedStudent}
          selectedStudent={selectedStudent} />
      </Modal>

    </>
  )
}

export default PickupByOrderType;