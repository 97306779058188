import pathconfig from './path-config';
import axios from 'axios';
import Cookies from 'js-cookie';
function checkAuth(error) {
  if (error.response) {
    // this part is to let the saga take care of the error
    const { data } = error.response;
    const err = new Error(data.errorMessage);
    err.response = error;
    // throw err;
    if (data) {
      const { headers } = error.response;
      let errData = null;
      if (headers && headers["x-hedtech-message"]) {
        // return { err: headers["x-hedtech-message"] }
        errData = { data: data, message: headers["x-hedtech-message"] }
      }
      else {
        errData = data;
      }
      return { err: errData };
    }
    else {
      const { headers } = error.response;
      if (headers && headers["x-hedtech-message"]) {
        return { err: headers["x-hedtech-message"] }
      }
      return { err: "Something went wrong" };
    }
  } else {
    // throw new Error('network');
    return { err: "Network Error" };
  }
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300 && !response.errorMessage) {
    return response.data;
  }

  const error = new Error(response.errorMessage);
  error.response = response;
  throw error;
}

export function simplePost(path, data) {
  return axios.post(pathconfig.baseUrl + path, data)
    .then((response) => checkStatus(response)).catch(err => { throw err });
}

export function simpleGet(path, data) {
  return axios.get(pathconfig.baseUrl + path, data)
    .then((response) => checkStatus(response, data));
}

export function getRequest(path) {
  const accessToken = Cookies.get('token');
  const getOptions = {
    url: pathconfig.baseUrl + path,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'authorization': `Bearer ${accessToken}`
    },
  };

  return axios(getOptions)
    .then(checkStatus)
    .catch(checkAuth);
}

export function postRequest(path, data) {
  const accessToken = Cookies.get('token');
  const postOptions = {
    url: pathconfig.baseUrl + path,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'authorization': `Bearer ${accessToken}`
    },
    data,
  };

  return axios(postOptions)
    .then(checkStatus)
    .catch(checkAuth);
}

export function deleteRequest(path) {
  const accessToken = Cookies.get('token');
  const getOptions = {
    url: pathconfig.baseUrl + path,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'authorization': `Bearer ${accessToken}`
    },
  };

  return axios(getOptions)
    .then(checkStatus)
    .catch(checkAuth);
}
