export const title1 = 'around the world';
export const title2 = 'down the block';
export const description = 'turnkey menu innovation through local restaurant collaborations ';
export const mainContentHeading = 'Available ';
export const searchCriteria = "Options:"
export const mainContentSubHeading1 = 'Use the info icons to learn more about restaurant results.';
export const mainContentSubHeading2 = 'Dietary icons identify dairy free, gluten free, halal, kosher, vegetarian and vegan selections.';
export const noDataText = 'No Vendors Fond';
export const locationText = 'Search By Location';
export const dayText = 'Select A Date ';
export const timeText = 'Select A Time';
export const distanceText = "Select Distance";
export const locationSubText = "Search by location, city/state or zip";
export const dateSubText = "after you select a location";
export const timeSubText = "after you select a date";
export const vendorsListInstruction1 = "Please use the filter bar above to narrow your search to your selected delivery location, date and time."
export const vendorsListInstruction2 = "Available options can be further filtered by the menu at left after choosing your pickup location."