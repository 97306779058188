import React, { useEffect, useState } from 'react';
import { Collapse, Checkbox, Button, Row, Col } from 'antd';
import moment from 'moment';

const { Panel } = Collapse;

const SiderAccordian = (props) => {
  const { filtersList, newPickupMenuData, showFilterModal, selectedFiltersList, reservationStatus, showVendorLoader, clearVendorsList } = props;
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [localFiltersList, setLocalFiltersList] = useState([]);

  useEffect(() => {
    if (!!filtersList && filtersList.length > 0) {
      const allFiltersList = [];
      filtersList.map((data, index) => {
        let updatedData = data;
        if (!!data.items && data.items.length > 0) {
          const changedItems = data.items.map(({ name: label, id: value }) => ({ label, value }));
          updatedData = { ...updatedData, items: changedItems };
          allFiltersList.push(updatedData);
        }
      });
      setLocalFiltersList(allFiltersList);
    }
  }, [filtersList]);

  useEffect(() => {
    if (!!selectedFiltersList && selectedFiltersList.attributes) {
      setSelectedAttributes(selectedFiltersList.attributes);
    }
  }, [selectedFiltersList]);


  const applyFilters = (selectedAttributes) => {
    showVendorLoader(true);
    clearVendorsList();
    const selectedFilters = {
      attributes: selectedAttributes,
    }
    props.saveSelectedFilters(selectedFilters);
    const params = {
      miles: 9999,
      pickupLocation: `${newPickupMenuData && newPickupMenuData.pickupLocation !== null ? newPickupMenuData.pickupLocation.id : null}`,
      pickupDate: `${newPickupMenuData ? newPickupMenuData.dateSelected : ''}`,
      pickupTime: `${newPickupMenuData ? moment(newPickupMenuData.timeSelected, 'H:mm a').format('H:mm') : ''}`,
      offset: 0,
      selectedAttributes: selectedAttributes,
      b2cType: `${reservationStatus !== null && reservationStatus ? "RESERVATION" : "ORDERING"}`,
      customerId: `${!!newPickupMenuData && !!newPickupMenuData.customerId && newPickupMenuData.customerId}`
    }
    props.applyFilters(params);
    showFilterModal(false);
  }

  const clearFilters = () => {
    setSelectedAttributes([]);
    props.saveSelectedFilters(null);
    applyFilters([]);
  }

  const changeCheckBoxValue = (e) => {
    let selectedValues = [...selectedAttributes];
    let index = selectedValues.indexOf(e.target.value);
    if (e.target.checked) {
      if (index === -1) {
        selectedValues.push(e.target.value);
      }
    }
    else {
      if (index !== -1) {
        selectedValues.splice(index, 1);
      }
    }
    setSelectedAttributes(selectedValues);
  }

  return (
    <div className="sider-div">
      <h6 className="heading">Filter by</h6>
      <Collapse accordion expandIconPosition="right" defaultActiveKey={['category']}>
        {filtersList !== null && !filtersList.err &&
          filtersList.map((data) => {
            return (
              <Panel header={data.name} key={data.name} className="panel-div">
                {!!localFiltersList && localFiltersList.length > 0 &&
                  localFiltersList.map((filters, i) => {
                    if (filters.name === data.name && !!filters.items && filters.items.length > 0) {
                      return (
                        <Checkbox.Group value={selectedAttributes} key={i}>
                          {filters.items.map((item, index) => {
                            return (
                              <Row key={index}>
                                <Col span={24}>
                                  <Checkbox value={item.value} checked={selectedAttributes.includes(item.value)} onChange={changeCheckBoxValue}>{item.label}</Checkbox>
                                </Col>
                              </Row>
                            )
                          })}
                        </Checkbox.Group>
                      )
                    }

                  })}
              </Panel>
            )
          })}
      </Collapse>
      {filtersList !== null && !filtersList.err && newPickupMenuData && props.vendorsList !== null &&
        <Row justify="space-between" align="middle">
          <Col span={12}>
            <div className="mr-top">
              <Button className="ant-btn-primary" onClick={() => { applyFilters(selectedAttributes) }}>Apply Filters</Button>
            </div>
          </Col>
          <Col span={12}>
            <div className="mr-top">
              <Button className="ant-btn-primary" onClick={() => { clearFilters() }}>Clear Filters</Button>
            </div>
          </Col>
        </Row>
      }
    </div>
  )
}

export default SiderAccordian;
