import Geocode from "react-geocode";
import { message } from 'antd';
import moment from 'moment';
import * as _ from "lodash";

export let getLatlng = (addr, params) => {
  Geocode.setApiKey("AIzaSyBPwY6FH7hmt6WBy7PwnfoWUmKBdsEm-Ts");
  Geocode.setLanguage("en");
  return new Promise((resolve, reject) => {
    Geocode.fromAddress(addr).then(
      response => {
        const { lat, lng } = response.results[0].geometry.location;
        const formattedAddress = response.results[0].formatted_address;
        params.latitude = lat;
        params.longitude = lng;
        params.formattedAddress = formattedAddress;
        resolve(params);
      },
      error => {
        resolve(params);
      }
    )
  })
}

export const getError = (errorMsg, visibleTime = 2) => {
  return (
    message.error({
      content: errorMsg,
      style: {
        marginTop: '20vh'
      },
      duration: visibleTime
    })
  );
}

export const calculateDecimal = (value) => {
  // if (value % 1 !== 0) {
  //   return (Math.round(value * 100) / 100).toFixed(2);
  // }
  // else {
  //   return value;
  // }
  return (Math.round(value * 100) / 100).toFixed(2);
}

export const changeTimetoAMPMFormat = (date) => {
  return moment(date).format('hh:mm A');
}

export const getFormattedAddressLine3 = (addr) => {
  const address3Components = [];
  address3Components.push(addr.city);

  const temp = [];
  temp.push(addr.state);
  temp.push(addr.zip);

  address3Components.push(_.join(_.filter(temp, (t) => !_.isUndefined(t) && !_.isNull(t)), ' '));

  return _.join(_.filter(address3Components, (t) => !_.isUndefined(t) && !_.isNull(t)), ', ');
}

export const getFormattedAddress = (addr) => {
  const addressComponents = [];

  addressComponents.push(addr.streetOne);
  addressComponents.push(addr.streetTwo);
  addressComponents.push(getFormattedAddressLine3(addr));

  return _.join(_.filter(addressComponents, (t) => !_.isUndefined(t) && !_.isNull(t)), ', ');
}

export const getFirstDayOfWeek = (weekDay) => {
  let pickupWeek = null;
  let selectedDayOfWeek = moment(weekDay).format('dddd');
  let requiredDate = null;
  switch (selectedDayOfWeek) {
    case "Monday":
      pickupWeek = (moment(weekDay).format("YYYY-MM-DD"));
      break;
    case "Tuesday":
      requiredDate = moment(weekDay).subtract(1, 'days');
      pickupWeek = (moment(requiredDate).format("YYYY-MM-DD"));
      break;
    case "Wednesday":
      requiredDate = moment(weekDay).subtract(2, 'days');
      pickupWeek = (moment(requiredDate).format("YYYY-MM-DD"));
      break;
    case "Thursday":
      requiredDate = moment(weekDay).subtract(3, 'days');
      pickupWeek = (moment(requiredDate).format("YYYY-MM-DD"));
      break;
    case "Friday":
      requiredDate = moment(weekDay).subtract(4, 'days');
      pickupWeek = (moment(requiredDate).format("YYYY-MM-DD"));
      break;
    case "Saturday":
      requiredDate = moment(weekDay).subtract(5, 'days');
      pickupWeek = (moment(requiredDate).format("YYYY-MM-DD"));
      break;
    case "Sunday":
      requiredDate = moment(weekDay).subtract(6, 'days');
      pickupWeek = (moment(requiredDate).format("YYYY-MM-DD"));
      break;
  }
  return pickupWeek;
}