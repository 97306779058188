import { combineReducers } from 'redux';
import UserReducer from '../containers/Home/reducer';
import LandingReducer from '../containers/LandingPage/reducer';
import UserAccountReducer from '../containers/UserAccount/reducer';
import VendorReducer from '../containers/Vendor/reducer';
import ForgotPasswordReducer from '../containers/ForgotPassword/reducer';
import ForgotUsernameReducer from '../containers/ForgotUsername/reducer';
import OrderReducer from '../containers/OrderInfo/reducer';
import CheckoutReducer from '../containers/Checkout/reducer';

const appReducer = combineReducers({
  UserReducer: UserReducer,
  LandingReducer: LandingReducer,
  UserAccountReducer: UserAccountReducer,
  VendorReducer: VendorReducer,
  ForgotPasswordReducer: ForgotPasswordReducer,
  ForgotUsernameReducer: ForgotUsernameReducer,
  OrderReducer: OrderReducer,
  CheckoutReducer: CheckoutReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'DESTROY_USER_DATA') {
    state = undefined;
  }
  return appReducer(state, action);
}

export default rootReducer;
