/**
 * action to delete cart
 */
export const DELETE_CART_REQUEST = 'DELETE_CART_REQUEST';
export const DELETE_CART_RESPONSE = 'DELETE_CART_RESPONSE';

/**
 * action to delete cart
 */
export const UPDATE_CART_REQUEST = 'UPDATE_CART_REQUEST';
export const UPDATE_CART_RESPONSE = 'UPDATE_CART_RESPONSE';

/**
 * Action to save Order Location Details
 */
export const SAVE_ORDER_LOCATION_DETAILS = 'SAVE_ORDER_LOCATION_DETAILS';
export const GET_ORDER_LOCATION_DETAILS = 'GET_ORDER_LOCATION_DETAILS';
