import React, {useState, Fragment, useEffect} from 'react';
import { Form, Input, Button } from 'antd';
import Loader from 'react-loader-spinner';
import queryString from 'query-string';

const layout = {
	labelCol: { span: 0 },
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 24 },
	},
};

const tailLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0,
		},
		sm: {
			span: 24,
			offset: 0,
		},
		border: 'none'
	},
};

const ResetPassword = (props) => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('123456');
	const [confirmPassword, setConfirmPassword] = useState('123456');
	const [loader, showLoader] = useState(false);
	// const token = props.match.params.token
	const resetPasswordResponse = props.resetPasswordResponse

    useEffect(() => {
		if (resetPasswordResponse && (resetPasswordResponse.success || resetPasswordResponse.error)) {
            showLoader(false);
		}
    }, [resetPasswordResponse])

	const onFinish = values => {
        showLoader(true);
				const visible = queryString.parse(props.location.search)
		const params = {
			'user': email,
			'password': password,
			'token': visible && visible.token ? visible.token : null
		}
		props.resetPassword(params);
	};

	const onFinishFailed = errorInfo => {
		console.log(errorInfo);
	};

	const validatePassword = (rule, v) => {
		if (!v)
			return Promise.reject('New password is required');
		if (v.length < 8)
			return Promise.reject('Password must be at least 8 characters');
		if (!v.match("(.*)[0-9](.*)"))
			return Promise.reject('Password must contain at least one digit');
		if (!v.match("(.*)[a-z](.*)"))
			return Promise.reject('Password must contain at least one lowercase character');
		if (!v.match("(.*)[A-Z](.*)"))
			return Promise.reject('Password must contain at least one uppercase character');
		if (!v.match("(.*)[^a-zA-Z0-9](.*)"))
			return Promise.reject('Password must contain at least one symbol');

		return Promise.resolve();
	};

	return (
		<Fragment>
			<div className="page-title mb-30">
				<text> Reset Password</text>
			</div>
            { resetPasswordResponse && resetPasswordResponse.error &&
                <div className="reset-error-div" >
                    <text className="reset-error-label">Invalid user or reset token</text>
                </div>
            }			
            { (!resetPasswordResponse || (resetPasswordResponse && !resetPasswordResponse.success)) &&
                <div>
                <Form {...layout}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    scrollToFirstError>
                    <Form.Item
                        name="email"
                        visible="false"
                        rules={[
                            {
                                required: true,
                                message: 'Field is required',
                            },
                            {
                                type: 'email',
                                message: 'E-mail must be valid'
                            }
                        ]}
                        className="form-item inputicon"
                    >
                        <Input placeholder="Please enter your email"
                            prefix={
                                <img
                                    alt="mail-icon"
                                    src={require("../../assets/images/mail.png")}
                                    height={24}
                                    width={24}
                                />
                            }
                            className="inputStyle"
                            onChange={(e) => { setEmail(e.target.value) }}
                        />
                    </Form.Item>

					<Form.Item 
						name="password"
						rules={[{ validator: validatePassword } ]}
						className="form-item inputicon" 
					>
						<Input.Password
							placeholder="Password"
							prefix={
								<img
									alt="user-icon"
									src={require("../../assets/images/lock.png")}
									height={24}
									width={24}
								/>
							}
							className="inputStyle"
							value={password}
							onChange={(e) => { setPassword(e.target.value) }} />
					</Form.Item>

					<Form.Item
						name="confirmPassword"
						rules={[
							{
								required: true,
								message: 'Password Confirmation is required',
							},
							({ getFieldValue }) => ({
								validator(rule, value) {
									if (!value || getFieldValue('password') === value) {
										return Promise.resolve();
									}
									return Promise.reject('The two passwords that you entered do not match!');
								},
							}),
						]}
						className="form-item inputicon"
					>
						<Input.Password
							placeholder="Confirm Password"
							prefix={
								<img
									alt="user-icon"
									src={require("../../assets/images/lock.png")}
									height={24}
									width={24}
								/>
							}
							className="inputStyle"
							value={confirmPassword}
							onChange={(e) => { setConfirmPassword(e.target.value) }}
						/>
					</Form.Item>

                    <div className="fixed-button-bottom" >
                        <Form.Item {...tailLayout}
                            className="submit-button">
                            <Loader
                                type="TailSpin"
                                color="#00BFFF"
                                height={50}
                                width={50}
                                color="#2C384C"
                                visible={loader}
                                style={{ margin: 10 }}
                                timeout={6000}
                            />
                            <Button disabled={loader}
                                type="primary"
                                htmlType="submit"
                                className="radius12 font_size_family btn_pad">
                                Submit
                            </Button>
                        </Form.Item >
                    </div>
                </Form>
                </div>
            }
			{ resetPasswordResponse && resetPasswordResponse.success &&
				<div>
					<div className="reset-message-div" >
						<text>Your password has been changed. Please use your new credentials to login.</text>
					</div>
					<div className="fixed-button-bottom" >
					<Button
						type="primary"
						htmlType="submit"
						className="radius12 font_size_family btn_pad"
						onClick={() => {
							props.setResetPasswordModal(false);
							// props.history.push('/landing');
							props.history.push(props.location && props.location.pathname ? props.location.pathname : '/landing');
						}}
						>
						Close
					</Button>
					</div>
				</div>				
            }
		</Fragment>
	);
}

export default ResetPassword;