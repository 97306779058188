import { takeLatest, put, call } from 'redux-saga/effects';
import * as actionTypes from './actions';
import { getRequest, postRequest } from '../../utils/requests';
import pathconfig from '../../utils/path-config';
import * as _ from 'lodash';
function* getProductList(values) {
  try {
    if (
      values.params
      && values.params.orderType
      && values.params.orderType === "For A Week"
      && process.env.REACT_APP_WEEKLY_RESTAURANT_API === 'true'
    ) {
      if (!!values.params.customerId) {
        var url = new URL(pathconfig.baseUrl + pathconfig.weeklyProductList + values.params.productId),
          params = {
            b2cType: values.params.b2cType,
            pickupDateTime: values.params.pickupDateTime,
            pickupLocation: values.params.pickupLocation.id,
            customerId: values.params.customerId || null
          }
      }
      else {
        var url = new URL(pathconfig.baseUrl + pathconfig.weeklyProductList + values.params.productId),
          params = {
            b2cType: values.params.b2cType,
            pickupDateTime: values.params.pickupDateTime,
            pickupLocation: values.params.pickupLocation.id
          }
      }
      Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
      if (!!values.params.filterParams && values.params.filterParams.selectedAttributes.length > 0) {
        values.params.filterParams.selectedAttributes.map((item) => {
          url.searchParams.append("selectedAttributes", item);
        });
        let result = yield call(getRequest, url.pathname + url.search);
        yield put({ type: actionTypes.SAVE_SHOP_ITEMS, shopItems: result })
      }
      else {
        let result = yield call(getRequest, url.pathname + url.search);
        yield put({ type: actionTypes.SAVE_SHOP_ITEMS, shopItems: result })
      }
    } else {
      var url = new URL(pathconfig.baseUrl + pathconfig.getShopItems + values.params.productId),
        params = {
          b2cType: values.params.b2cType,
          pickupDateTime: values.params.pickupDateTime,
          pickupLocation: values.params.pickupLocation.id,
        }
      Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
      if (!!values.params.filterParams && values.params.filterParams.selectedAttributes.length > 0) {
        values.params.filterParams.selectedAttributes.map((item) => {
          url.searchParams.append("selectedAttributes", item);
        });
        const result = yield call(getRequest, url.pathname + url.search);
        yield put({ type: actionTypes.SAVE_SHOP_ITEMS, shopItems: result })
      }
      else {
        const result = yield call(getRequest, url.pathname + url.search);
        yield put({ type: actionTypes.SAVE_SHOP_ITEMS, shopItems: result })
      }
    }
  }
  catch (err) {
    console.log(err);
  }
}
function* addToCart(params) {
  const result = yield call(postRequest, pathconfig.addToCart, params.params);
  yield put({ type: actionTypes.ADD_TO_CART_RESPONSE, addToCartResponse: result });
}
function* getCartDistributor() {
  const result = yield call(getRequest, pathconfig.cartDistributor);
  yield put({ type: actionTypes.SAVE_CART_DISTRIBUTOR_DETAILS, cartDistributor: result });
  if (!!result && !result.err) {
    let cartItems = [];
    result.map((distributor, index) => {
      if (process.env.REACT_APP_WEEKLY_CART === 'true') {
        if (distributor.subType === 'DAILY') {
          console.log('distributor of Daily found****', distributor);
          if (!!distributor.items && distributor.items.length > 0) {
            distributor.items.map((item, index) => {
              cartItems.push(item);
            })
          }

          // return renderCartDetails(distributor, index)
          // yield put({ type: actionTypes.SAVE_CART_ITEM_DETAILS, cartItems: result });
        } else if (distributor.subType === 'WEEKLY') {
          const tempCartDetail = _.cloneDeep(distributor);
          tempCartDetail.items = [];
          tempCartDetail.customer = {}
          if (distributor.children && distributor.children.length > 0) {
            distributor.children.map(child => {
              if (child.items && child.items.length > 0) {
                child.items.map(item => {
                  cartItems.push(item);
                })
              }
            })
          }
        }
      } else {
        // return renderCartDetails(distributor, index)
        cartItems.push(distributor);
      }
    })
    yield put({ type: actionTypes.SAVE_CART_ITEM_DETAILS, filteredCartItems: cartItems });
  }
}
function* getCartDetails(params) {
  const result = yield call(getRequest, pathconfig.cartDetails + params.params.cartId);
  yield put({ type: actionTypes.SAVE_CART_DETAILS, cartDetails: result });
}
function* getProductSKUTags(params) {
  try {
    if (process.env.REACT_APP_ALLERGEN_FILTER === 'true') {
      if (params.params.subType === 'WEEKLY') {
        const result = yield call(getRequest, pathconfig.dieteryWeeklyAllergenOptions(params.params.distributorId, params.params.b2cType, params.params.pickupDate, params.params.subType, params.params.customerId));
        yield put({ type: actionTypes.SAVE_SKU_TAGS, productSKUTags: result });
      }
      const result = yield call(getRequest, pathconfig.dieteryAllergenOptions(params.params.distributorId, params.params.b2cType, params.params.pickupDate, params.params.customerId));
      yield put({ type: actionTypes.SAVE_SKU_TAGS, productSKUTags: result });
    }
    else {
      const result = yield call(getRequest, pathconfig.productTags(params.params.distributorId, params.params.b2cType, params.params.customerId));
      yield put({ type: actionTypes.SAVE_SKU_TAGS, productSKUTags: result });
    }
  }
  catch (err) {
    console.log(err);
  }
}

// function* getProductSKUTags(params){
//   try{
//     if (process.env.REACT_APP_ALLERGEN_FILTER === 'true'){
//       if (params.params.subType === 'WEEKLY') {
//         var url = new URL(pathconfig.baseUrl+pathconfig.productTags)
//       }
//     }
//     else{

//     }
//   }catch(err){
//     console.log(err);
//   }
// }
export default function* watchVendorSaga() {
  yield takeLatest(actionTypes.GET_SHOP_ITEMS, getProductList);
  yield takeLatest(actionTypes.ADD_TO_CART, addToCart);
  yield takeLatest(actionTypes.GET_CART_DISTRIBUTOR_DETAILS, getCartDistributor);
  yield takeLatest(actionTypes.GET_CART_DETAILS, getCartDetails);
  yield takeLatest(actionTypes.GET_SKU_TAGS, getProductSKUTags);
}
