import React from 'react';
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'
import { LandingPage, Home, Vendor, History, OrderInfo, UserAccount, Checkout, Confirmation, MultipleCart } from '../containers';
import ScrollToTop from "../components/ScrollToTop";
import Cookies from 'js-cookie';

/* 
  Private route checks for the token
  if not, redirect to Login page 
*/
const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          Cookies.get('token') ?
            (<Component {...props} />) :
            (<Redirect to={{ pathname: "/landing" }} />)
        )
      }
      }
    />
  )
};

const AuthRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          !Cookies.get('token') ?
            (<Component {...props} />) :
            (<Redirect to={{ pathname: "/" }} />)
        )
      }
      }
    />
  )
};

const Routes = () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <AuthRoute path="/landing/:orderGuideId" component={LandingPage} />
        <AuthRoute path="/landing" component={LandingPage} />
        <AuthRoute path="/resetpassword/:token" component={LandingPage} />
        <PrivateRoute exact path="/" component={Home} />
        <PrivateRoute exact path="/user-account" component={UserAccount} />
        <PrivateRoute path="/shop/:id" component={Vendor} />
        <PrivateRoute exact path="/history/:id" component={History} />
        <PrivateRoute exact path="/orderinfo/:id" component={OrderInfo} />
        <PrivateRoute exact path="/checkout/:id" component={Checkout} />
        <PrivateRoute exact path="/confirm-order" component={Confirmation} />
        <PrivateRoute exact path="/multiple-carts" component={MultipleCart} />
      </Switch>
    </Router>
  )
}

export { Routes }