import React, { useEffect, useState } from 'react';
import { Row, Col, Image, Skeleton } from 'antd';
import { Link } from 'react-router-dom';
import './styles.scss';

const PageFooter = (props) => {
  const { brandingDetails } = props;
  const [skeletonLoading, setSkeletonLoading] = useState(true);

  useEffect(() => {
    if (brandingDetails === undefined || brandingDetails === null) {
      setSkeletonLoading(true);
    }
    else {
      setSkeletonLoading(false);
    }
  }, [brandingDetails]);

  return (
    <div>
      {skeletonLoading ? <Skeleton /> : <div style={{ backgroundColor: `${brandingDetails !== undefined && brandingDetails !== null && !brandingDetails.err && brandingDetails.brandingThemeColor !== null ? brandingDetails.brandingThemeColor : "#265D6C"}` }}>
        <Row className="footer-row">
          <Col sm={4} md={3} xs={24} lg={2}>
            {skeletonLoading ?
              <Skeleton.Avatar
                shape="square" className="home_page_logo"
                active loading={!skeletonLoading} size='large' style={{ marginTop: 10 }} />
              :
              <Image className="img-div"
                alt="menu"
                src={brandingDetails !== undefined && brandingDetails !== null && !brandingDetails.err && brandingDetails.homeFooterLogoUrl !== null ? brandingDetails.homeFooterLogoUrl : require('../../assets/images/logo_new.png')} />
            }
          </Col>
          <Col sm={20} md={21} xs={24} lg={22}>
            <div className="footer-link-container">
              <ul className="footer_link">
                <li><Link className="bottom-links-text" to="#">About Us</Link></li>
                <li><a className="bottom-links-text" href="http://www.handcutmealsup.com">Help</a></li>
                <li><Link className="bottom-links-text" to="#">Follow Us</Link></li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>

      }
    </div>

  )
}
export default PageFooter;
