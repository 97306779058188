import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducers from './rootReducer';
import RootSaga from './rootSaga';

// redux development tool for visulizing redux state timeline
import { composeWithDevTools } from "redux-devtools-extension";

const sagaMiddleWare = createSagaMiddleware();

// In development mode we are using "redux-devtools-extension" middleware
export const store = createStore(
  rootReducers,
  process.env.NODE_ENV === "production"
    ? compose(applyMiddleware(sagaMiddleWare))
    : composeWithDevTools(applyMiddleware(sagaMiddleWare))
);

sagaMiddleWare.run(RootSaga);
