import React, { useEffect, useState, } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Row, Col, Layout, Modal } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { AuthHeader, PageFooter, OrderHistoryMoreInfo, RepeatOrderPickupInfo, CartItemsMoreInfo } from '../../components';
import { calculateDecimal } from '../../utils/commonUtils';
import * as UserAccountActions from '../UserAccount/actions';
import * as vendorActions from '../Vendor/actions';

const OrderInfoModal = (props) => {
  return (
    <Modal
      className="order-modal"
      visible={props.visible}
      onCancel={() => props.showOrderInfo(false)}
      maskStyle={{ backgroundColor: 'rgb(44 56 76 / 92%)' }}
      footer={null}
    >
      {<OrderHistoryMoreInfo {...props} />}
    </Modal>
  )
}

const History = (props) => {
  const { selectedOrderDetail, repeatOrderResponse, clearRepeatResponse, cartDetails, reservationStatus } = props;
  const { Header, Footer, Content } = Layout;
  const [orderInfo, showOrderInfo] = useState(false);
  const [showPickupInfo, setShowPickupInfo] = useState(false);
  const [reservation, setReservation] = useState(false);
  const [moreInfoVisible, setMoreInfoVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (selectedOrderDetail === undefined || selectedOrderDetail === null) {
      history.push("/user-account");
    }
  }, [selectedOrderDetail]);

  useEffect(() => {
    if (repeatOrderResponse !== null) {
      if (cartDetails !== null && !cartDetails.err) {
        history.push("/multiple-carts");
      }
    }
  }, [cartDetails]);

  useEffect(() => {
    if (reservationStatus === undefined || reservationStatus === null || !reservationStatus) {
      setReservation(false);
    }
    else {
      setReservation(true);
    }
  }, [reservationStatus]);

  const backToOrderHistory = () => {
    clearRepeatResponse();
    if (reservation) {
      history.push({
        pathname: "/user-account",
        state: { showOrderHistory: false }
      })
    }
    else {
      history.push({
        pathname: "/user-account",
        state: { showOrderHistory: true }
      })
    }
  }

  const requestRepeatOrder = () => {
    setShowPickupInfo(true);
  }

  const showMoreInfo = (item) => {
    setSelectedOrder(item);
    setMoreInfoVisible(true);
  }

  const renderDetails = (selectedOrderDetail, orderIndex) => {
    return (
      <Col span={24} key={orderIndex}>
        {process.env.REACT_APP_WEEKLY_CART === "true" && selectedOrderDetail.pickupSlot && selectedOrderDetail.pickupSlot.startingTime &&
          <Row justify="center" align="middle">
            <Col span={24} align="middle">
              <div className="weekday_div">
                {moment.utc(selectedOrderDetail.pickupSlot.startingTime).format('dddd, MMMM DD')}
              </div>
            </Col>
          </Row>
        }
        {
          selectedOrderDetail.items.map((item, index) => {
            return (
              <Col span={24} className="ordered_items" key={index}>
                <Row>
                  <Col xs={20} md={22}>
                    <div className="ordered_item">{item.name}</div>
                  </Col>
                  <Col xs={4} md={2} style={{ textAlign: 'right' }}>
                  </Col>
                </Row>
                <Row>
                  <Col xs={20} md={22}>
                    <img style={{ marginRight: 5 }} src={require('../../assets/images/order_info.png')} onClick={() => showMoreInfo(item)} />
                    <span className="ordered_qua">Quantity: <span>{item.quantity}</span> </span>
                  </Col>
                  {!reservation && <Col xs={4} md={2}>
                    <div className="ordered_price">${calculateDecimal(item.sellPrice)}</div>
                  </Col>}
                </Row>
              </Col>
            )
          })
        }
        {!reservation && <>
          <Col span={24} className="ordered_items">
            <Row>
              <Col xs={12} md={12}>
                <div className="ordered_total">Subtotal:</div>
              </Col>
              <Col xs={12} md={12}>
                <div className="ordered_cost">$ {calculateDecimal(selectedOrderDetail.subTotalCharge)}</div>
              </Col>
            </Row>
            <Row style={{ paddingTop: 20 }}>
              <Col xs={12} md={12}>
                <div className="ordered_total">Tax:</div>
              </Col>
              <Col xs={12} md={12}>
                <div className="ordered_cost">$ {calculateDecimal(selectedOrderDetail.salesTaxCharge)}</div>
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ paddingTop: '20px' }}>
            <Row>
              <Col xs={12} md={12}>
                <div className="ordered_total">Total:</div>
              </Col>
              <Col xs={12} md={12}>
                <div className="ordered_cost">${calculateDecimal(selectedOrderDetail.totalSellCharge)}</div>
              </Col>
            </Row>
          </Col>
        </>
        }
      </Col>
    );
  }

  return (
    <>
      <Layout>
        <Header>
          <AuthHeader {...props} />
        </Header >
        <Content className="full_content">
          <div className="site-layout-content">
            <Row>
              <Col xs={24} md={20}>
                <Row>
                  <Col xs={24}>
                    <Button ghost onClick={backToOrderHistory}>
                      <div className="back_search"><svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.3992 10.3496H4.68625L15.288 1.14648C15.4575 0.998145 15.3546 0.722656 15.1306 0.722656H12.4514C12.3333 0.722656 12.2213 0.765039 12.1335 0.840722L0.69318 10.7674C0.588399 10.8582 0.504366 10.9705 0.446774 11.0966C0.389181 11.2228 0.359375 11.3598 0.359375 11.4985C0.359375 11.6372 0.389181 11.7742 0.446774 11.9003C0.504366 12.0265 0.588399 12.1388 0.69318 12.2296L12.2001 22.2168C12.2455 22.2562 12.3 22.2773 12.3575 22.2773H15.1276C15.3516 22.2773 15.4545 21.9988 15.285 21.8535L4.68625 12.6504H22.3992C22.5324 12.6504 22.6414 12.5414 22.6414 12.4082V10.5918C22.6414 10.4586 22.5324 10.3496 22.3992 10.3496Z" fill="black" />
                      </svg> {!reservation ? "Back to Order History" : "Back to History"}
                      </div>
                    </Button>
                  </Col>
                  <Col xs={24}>
                    {selectedOrderDetail !== undefined && selectedOrderDetail !== null && selectedOrderDetail.distributor && selectedOrderDetail.distributor.name &&
                      <h1 className="tab_heading">{selectedOrderDetail.distributor.name}</h1>}
                  </Col>
                </Row>
              </Col>
              <Col xs={24} md={4} className="show_desktop">
                <Button type="primary" htmlType="button" className="filters_mobile_btn detail_info" onClick={() => { showOrderInfo(true) }}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 21.9999C17.5228 21.9999 22 17.5227 22 11.9999C22 6.47703 17.5228 1.99988 12 1.99988C6.47715 1.99988 2 6.47703 2 11.9999C2 17.5227 6.47715 21.9999 12 21.9999Z" stroke="#2C384C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M12 16V12" stroke="#2C384C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M12 7.99988H12.01" stroke="#2C384C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
More Info
</Button>
              </Col>

            </Row>

            <OrderInfoModal
              {...props}
              visible={orderInfo}
              showOrderInfo={showOrderInfo}
            />
            <Row>
              <Col xs={24} className="show_mobile">
                <Button type="primary" htmlType="button" className="filters_mobile_btn detail_info" onClick={() => { showOrderInfo(true) }}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 21.9999C17.5228 21.9999 22 17.5227 22 11.9999C22 6.47703 17.5228 1.99988 12 1.99988C6.47715 1.99988 2 6.47703 2 11.9999C2 17.5227 6.47715 21.9999 12 21.9999Z" stroke="#2C384C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M12 16V12" stroke="#2C384C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M12 7.99988H12.01" stroke="#2C384C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
More Info
</Button>
              </Col>
              <p className="show_mobile content-sub-heading" style={{ lineHeight: 1, marginTop: 10 }}>Click on the info icon below to view order customization.</p>
            </Row>

            <Row className="show_desktop mt-40">
              <Col span={12}>
                <div className="our_order">
                  Your order
   </div>
                <p className="show_desktop content-sub-heading" style={{ lineHeight: 2 }}>Click on the info icon below to view order customization.</p>
              </Col>
            </Row>

            {
              selectedOrderDetail !== undefined
              && selectedOrderDetail !== null
              &&
              <>
                <Row style={{ marginTop: '20px' }}>
                  {
                    selectedOrderDetail.items
                      && selectedOrderDetail.items.length > 0
                      ? renderDetails(selectedOrderDetail)
                      : selectedOrderDetail.subType === 'WEEKLY'
                        && process.env.REACT_APP_WEEKLY_CART === "true"
                        && selectedOrderDetail.children
                        && selectedOrderDetail.children.length > 0
                        ? selectedOrderDetail.children.map((orderDetail, index) => {
                          return renderDetails(orderDetail, index)
                        })
                        : null
                  }
                </Row>
                <Row style={{ marginTop: '50px' }}>
                  <Col xs={24}>
                    <Row justify="space-between">
                      <Col>
                        <h2 className="Sub_Heading" style={{ fontSize: '22px' }}>
                          {process.env.REACT_APP_ENV === "hc" ? "Order Info" : (!reservation ? "Pickup Info" : "Reservation Info")}
                        </h2>
                      </Col>
                      <Col className="show_desktop">
                        {process.env.REACT_APP_ENV != "hc" && <Button type="primary" htmlType="button" className="repeat_your_order" onClick={requestRepeatOrder}> Repeat
	                    </Button>}
                      </Col>
                    </Row>
                  </Col>
                  {process.env.REACT_APP_ENV === "hc" && !!selectedOrderDetail.customer && !!selectedOrderDetail.customer.name &&
                    <Col xs={24}>
                      <span className="pickup_label">Student Name</span>
                      <span className="location_restraurant">{selectedOrderDetail.customer.name}</span>
                    </Col>}
                  <Col xs={24}>
                    {!reservation && <span className="pickup_label">Pickup</span>}
                    {
                      process.env.REACT_APP_WEEKLY_CART === "true"
                        && selectedOrderDetail.subType === 'WEEKLY'
                        // ? selectedOrderDetail.submissionDt && <span className="pickup_time">{`Week of ${moment.utc(selectedOrderDetail.submissionDt).subtract(7, 'days').format('MMMM DD')} - ${moment.utc(selectedOrderDetail.submissionDt).add(1, 'days').format('MMMM DD')}`}</span>
                        ? selectedOrderDetail.requestDt && (
                          <span className="pickup_time">
                            Week of {moment.utc(selectedOrderDetail.requestDt).format('MMMM DD')} - {moment.utc(selectedOrderDetail.requestDt).add(6, 'days').format('MMMM DD')} {moment.utc(selectedOrderDetail.requestDt).format('h:mm A')}
                          </span>
                        )
                        : selectedOrderDetail.pickupSlot && selectedOrderDetail.pickupSlot.startingTime && <span className="pickup_time">{moment(selectedOrderDetail.pickupSlot.startingTime).format("MM/DD/YYYY h:mm A")}</span>}
                  </Col>
                  {/* } */}
                  {selectedOrderDetail.pickupLocation &&
                    <Col xs={24}>
                      <span className="location_label">At</span>
                      {selectedOrderDetail.pickupLocation.name && <span className="location_restraurant">{selectedOrderDetail.pickupLocation.name}, </span>}
                      {selectedOrderDetail.pickupLocation.streetOne && <span className="location_restraurant">{selectedOrderDetail.pickupLocation.streetOne}, </span>}
                      {selectedOrderDetail.pickupLocation.streetTwo && <span className="location_restraurant">{selectedOrderDetail.pickupLocation.streetTwo}, </span>}
                      <span className="location_restraurant">
                        {selectedOrderDetail.pickupLocation.city && <>{selectedOrderDetail.pickupLocation.city}, </>}
                        {selectedOrderDetail.pickupLocation.state && <>{selectedOrderDetail.pickupLocation.state} </>}
                        {selectedOrderDetail.pickupLocation.zip && <>{selectedOrderDetail.pickupLocation.zip}</>}
                      </span>
                    </Col>}
                </Row>
              </>
            }
            <Row className="show_mobile mt-40">
              <Col span={24} style={{ textAlign: 'center' }}>
                {process.env.REACT_APP_ENV != "hc" && <Button type="primary" htmlType="button" className="repeat_your_order" onClick={requestRepeatOrder}> Repeat
	</Button>}
              </Col>
            </Row>
            {moreInfoVisible &&
              <CartItemsMoreInfo
                moreInfoVisible={moreInfoVisible}
                setMoreInfoVisible={setMoreInfoVisible}
                selectedOrder={selectedOrder}
                {...props}
                page="order-history"
              />
            }
          </div>
          <RepeatOrderPickupInfo id={id} showPickupInfo={showPickupInfo} setShowPickupInfo={setShowPickupInfo} {...props} />

        </Content>
        <Footer>
          <PageFooter {...props} />
        </Footer>
      </Layout>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    selectedOrderDetail: state.UserAccountReducer.selectedOrderDetail,
    repeatOrderResponse: state.UserAccountReducer.repeatOrderResponse,
    pickupDates: state.UserAccountReducer.pickupDates,
    pickupTimes: state.UserAccountReducer.pickupTimes,
    cartDetails: state.VendorReducer.cartDetails,
    brandingDetails: state.UserReducer.brandingDetails,
    reservationStatus: state.UserReducer.reservationStatus,
    onlyReservationAvailable: state.UserReducer.onlyReservationAvailable
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    repeatOrder: (id, params) => dispatch({ type: UserAccountActions.REPEAT_ORDER_REQUEST, id, params }),
    clearRepeatResponse: () => dispatch({ type: UserAccountActions.REPEAT_ORDER_RESPONSE, repeatOrderResponse: null }),
    getPickupDates: (params) => dispatch({ type: UserAccountActions.GET_PICKUP_DATES, params }),
    getPickupTimes: (params) => dispatch({ type: UserAccountActions.GET_PICKUP_TIME, params }),
    getCartDetails: (params) => dispatch({ type: vendorActions.GET_CART_DETAILS, params }),
    getCartDistributor: () => dispatch({ type: vendorActions.GET_CART_DISTRIBUTOR_DETAILS }),
    clearPickupDates: () => dispatch({ type: UserAccountActions.SAVE_PICKUP_DATES, pickupDates: null }),
    clearPickupTimes: () => dispatch({ type: UserAccountActions.SAVE_PICKUP_TIME, pickupTimes: null })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(History);