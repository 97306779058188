import React, { useEffect, useState, useRef } from 'react';
import { Button, Row, Col, Layout, Modal, Collapse, Tooltip, Spin, message } from 'antd';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import * as _ from 'lodash';
import { AuthHeader, PageFooter, MoreInfoVendor, AddtoOrder, SiderCartInfo, DietryFilterModal } from '../../components';
import './style.scss';
import * as vendorActions from './actions';
import { getError, calculateDecimal } from '../../utils/commonUtils';
import * as OrderActions from '../OrderInfo/action';
import { mainContentHeading, searchCriteria, mainContentSubHeading2 } from '../Home/constants';
import moment from "moment";

const { Panel } = Collapse;

const MoreInfoModal = (props) => {
  return (
    <Modal
      className="order-modal"
      visible={props.visible}
      onCancel={() => props.setMoreInfoModal(false)}
      maskStyle={{ backgroundColor: 'rgb(44 56 76 / 92%)' }}
      footer={null}
    >
      <MoreInfoVendor {...props} />
    </Modal>
  )
}

const AddtoOrderModal = (props) => {
  return (
    <Modal
      className="order-modal"
      visible={props.visible}
      onCancel={() => props.setAddtoOrderModal(false)}
      maskStyle={{ backgroundColor: 'rgb(44 56 76 / 92%)' }}
      footer={null}
    >
      <AddtoOrder {...props} />
    </Modal>
  )
}

const Vendor = (props) => {

  const { getProductsList, productsList, clearProductList, addToCartResponse, getCartDistributor, getCartDetails, cartDistributor, selectedVendorData, newPickupMenuData, getProductSKUTags, selectedDietaryFilter, reservationStatus, filteredCartItems, brandingDetails } = props;
  const { Header, Footer, Content } = Layout;
  const [moreInfoVendor, setMoreInfoVendor] = useState(false);
  const [addtoOrderModal, setAddtoOrderModal] = useState(false);
  const [itemClicked, setItemClicked] = useState(null);
  const [dateSelected, setDateSelected] = useState(null);
  const [noProductsFound, setNoProductsFound] = useState(false);
  const [filterModal, showFilterModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tooltipVisible, setToolTipVisible] = useState(null);
  const { id } = useParams();
  const history = useHistory();
  const [cartDistributorId, setCartDistributorId] = useState(null);
  const [reservation, setReservation] = useState(false);
  const [productRestricted, setProductRestricted] = useState(false);
  const prevState = useRef({ addToCartResponse, cartDistributor }).current;


  useEffect(() => {
    if (newPickupMenuData === null) {
      history.push('/');
    }
  }, [newPickupMenuData]);

  useEffect(() => {
    if (reservationStatus === undefined || reservationStatus === null || !reservationStatus) {
      setReservation(false);
    }
    else {
      setReservation(true);
    }
  }, [reservationStatus]);

  useEffect(() => {
    if (productsList !== null) {
      setLoading(false);
      if (!productsList.err) {
        if (!!newPickupMenuData && newPickupMenuData.orderType === "For A Week") {
          if (productsList.length > 0) {
            setNoProductsFound(false);
          }
        }
        else {
          if (!!productsList.items && productsList.items.length > 0) {
            setNoProductsFound(false);
          }
          else {
            setNoProductsFound(true);
          }
        }

      }
      else {
        getError(productsList.err);
      }

    }
    else { // NOTE - get product list

      if (!!newPickupMenuData && !!newPickupMenuData.dateSelected) {
        let params = {};
        if (selectedDietaryFilter !== undefined && selectedDietaryFilter !== null) {
          const filterParams = { selectedAttributes: selectedDietaryFilter.selectedAttributes }
          if (reservationStatus) {
            params = {
              productId: id,
              filterParams: filterParams,
              b2cType: "RESERVATION",
              pickupDateTime: newPickupMenuData.dateSelected + 'T' + moment(newPickupMenuData.timeSelected, 'H:mm A').format('HH:mm:ss'),
              pickupLocation: newPickupMenuData.pickupLocation,
              customerId: !!newPickupMenuData.customerId && newPickupMenuData.customerId
            }
          }
          else {
            params = {
              productId: id,
              filterParams: filterParams,
              b2cType: "ORDERING",
              pickupDateTime: newPickupMenuData.dateSelected + 'T' + moment(newPickupMenuData.timeSelected, 'H:mm A').format('HH:mm:ss'),
              pickupLocation: newPickupMenuData.pickupLocation,
              customerId: !!newPickupMenuData.customerId && newPickupMenuData.customerId
            }
          }
        } else {
          if (reservationStatus) {
            params = {
              productId: id,
              b2cType: "RESERVATION",
              pickupDateTime: newPickupMenuData.dateSelected + 'T' + moment(newPickupMenuData.timeSelected, 'H:mm A').format('HH:mm:ss'),
              pickupLocation: newPickupMenuData.pickupLocation,
              customerId: !!newPickupMenuData.customerId && newPickupMenuData.customerId
            }
          }
          else {
            params = {
              productId: id,
              b2cType: "ORDERING",
              pickupDateTime: newPickupMenuData.dateSelected + 'T' + moment(newPickupMenuData.timeSelected, 'H:mm A').format('HH:mm:ss'),
              pickupLocation: newPickupMenuData.pickupLocation,
              customerId: !!newPickupMenuData.customerId && newPickupMenuData.customerId
            }
          }
        }
        if (process.env.REACT_APP_WEEKLY_RESTAURANT_API === 'true') {
          if (!!newPickupMenuData
            && newPickupMenuData.orderType
            && newPickupMenuData.orderType === "For A Week"
          ) {
            params.orderType = 'For A Week';
            params.pickupDateTime = newPickupMenuData.dateSelected + 'T' + moment.utc(newPickupMenuData.timeSelected,).format('HH:mm:ss');
          }
          else {
            params.pickupDateTime = newPickupMenuData.dateSelected + 'T' + moment(newPickupMenuData.timeSelected, 'H:mm A').format('HH:mm:ss');
          }
          if (!!newPickupMenuData && !!newPickupMenuData.customerId) {
            params.customerId = newPickupMenuData.customerId
          }
        }
        getProductsList(params);
      }
      else {
        history.push('/');
      }

    }
  }, [productsList, reservationStatus, newPickupMenuData]);

  useEffect(() => {
    if (addToCartResponse !== null) {
      if (addToCartResponse !== prevState.addToCartResponse) {
        if (!addToCartResponse.err) {
          message.success({
            content: 'Item added to cart successfully.',
            duration: 2
          });
          getCartDistributor();
        }
        else {
          getError(addToCartResponse.err);
        }
      }
    }
  }, [addToCartResponse]);

  useEffect(() => {
    if (cartDistributor !== null) {
      if (!cartDistributor.err) {
        if (cartDistributor.length > 0) {
          cartDistributor.map((distributor, index) => {
            if (distributor.distributorId === Number(id)) {
              setCartDistributorId(distributor.id);
              getCartDetails({ cartId: distributor.id });
            }
          })
        }
      }
      else {
        if (cartDistributor !== prevState.cartDistributor) {
          getError(cartDistributor.err);
        }
      }
    }
    else {
      getCartDistributor();
    }
  }, [cartDistributor]);

  useEffect(() => {
    let params = {}
    if (reservationStatus) {
      params = { distributorId: id, b2cType: "RESERVATION", customerId: !!newPickupMenuData && !!newPickupMenuData.customerId && newPickupMenuData.customerId }
    }
    else {
      params = { distributorId: id, b2cType: "ORDERING", customerId: !!newPickupMenuData && !!newPickupMenuData.customerId && newPickupMenuData.customerId }
    }
    if (process.env.REACT_APP_PRODUCT_ALLERGEN_TAG === 'true') {
      if (newPickupMenuData && newPickupMenuData.orderType === "For A Week") {
        params.subType = 'WEEKLY';
      }
      if (newPickupMenuData && newPickupMenuData.dateSelected) {
        params.pickupDate = newPickupMenuData.dateSelected;
      }
    }
    getProductSKUTags(params);
  }, [reservationStatus]);

  const showAddtoOrderModal = (item, date = null, productRestrictions) => {
    if (tooltipVisible !== null) {
      if (tooltipVisible.itemId === item.id) {
        setToolTipVisible({ itemId: item.id, visibility: false });
      }
    }
    if (date) {
      setDateSelected(date);
    }

    if (process.env.REACT_APP_ENV === "hc" && !!productRestrictions) {
      if (!!filteredCartItems && filteredCartItems.length > 0) {
        productRestrictions.map((restriction, index) => {
          if (!!restriction.productCategories) {
            restriction.productCategories.map((itemCategory, index) => {
              filteredCartItems.find((cartItem, index) => {

                if (item.category.id === cartItem.category.id && cartItem.category.id === itemCategory.id && cartItem.quantity >= restriction.maxQuantity) {
                  setProductRestricted(true);
                }
                else {
                  setProductRestricted(false);
                }
              })
            });
          }
        })

      }
    }
    setAddtoOrderModal(true);
    setItemClicked(item);
  }


  const getBacktoVendor = () => {
    clearProductList();
    history.push('/');
  }

  const toggleTooltip = (item, visibility) => {
    const tooltipData = { itemId: item.id, visibility: visibility };
    setToolTipVisible(tooltipData);
  }

  const sortAttributes = (attributes) => {
    const returnAttributes = []
    attributes.map(attribute => {
      if (attribute.category.name === "Allergens") {
        returnAttributes.push(attribute);
      }
      else {
        returnAttributes.unshift(attribute);
      }
    })
    return returnAttributes
  }

  const renderProductList = (productsList, productIndex, productRestrictions, date = null) => {
    // console.log('product Restrictiones&&&**', productsList, productRestrictions);
    return (
      !!productsList && !productsList.err && productsList.length > 0 &&
      <Collapse
        expandIconPosition="left"
        // defaultActiveKey={productsList[0].id}
        defaultActiveKey={
          process.env.REACT_APP_WEEKLY_RESTAURANT_API === 'true'
            && date !== null
            ? productsList.map(product => product.id)
            : productsList[0].id
        }
        ghost
        key={productIndex}
      >
        {process.env.REACT_APP_WEEKLY_RESTAURANT_API === "true" && date !== null &&
          <Row justify="center" align="middle">
            <Col span={24} align="middle">
              <div className="weekday_div" style={{ backgroundColor: "#2C384C", borderRadius: 12, color: "#E5E5E5" }}>
                {moment.utc(date).format('dddd, MMMM DD')}
              </div>
            </Col>
          </Row>
        }
        {productsList.map((product, index) => {
          return (
            <>
              <Panel header={product.name} key={product.id}>
                {product.children.length === 0 ?
                  <Row className="items_menu" gutter={{ xs: 0, sm: 30, md: 30, lg: 30 }}>
                    {!!product.items && product.items.length > 0 &&
                      product.items.map((item, index) => {
                        return (
                          <Col xs={24} md={12} lg={8} key={index}>
                            <div className="menu_image" onClick={() => showAddtoOrderModal(item, date, productRestrictions)} style={{ cursor: "pointer" }}>
                              <img alt="Restaurant Picture" src={item.image} />
                            </div>
                            <div className="item_name_price">
                              <div className="name_price">
                                <h6 className="item_name">{item.productName}</h6>
                                <div className="item_price_Dietary">
                                  {!reservation && <div className="item_price"> $ {calculateDecimal(item.sellPrice)}</div>}
                                  <div className="tag-container">
                                    {process.env.REACT_APP_PRODUCT_ALLERGEN_TAG === 'true'
                                      && item?.attributes?.length > 0
                                      && sortAttributes(item.attributes).map((tag) => { // NOTE - attributes sorting
                                        let tagAbbr = tag.acronym;
                                        return (
                                          <div className={tag.category?.name === "Allergens" ? 'allergen_Dietary' : "item_Dietary"} key={tag.id}>
                                            <Tooltip placement="top" title={tag.name} color="orange">
                                              {tagAbbr}
                                            </Tooltip>
                                          </div>
                                        )
                                      })
                                    }
                                    {
                                      item?.tags?.length > 0 && item.tags.map((tag) => {
                                        let tagAbbr = tag.acronym;
                                        return (
                                          <div className="item_Dietary" key={tag.id}>
                                            <Tooltip placement="top" title={tag.name} color="orange">
                                              {tagAbbr}
                                            </Tooltip>
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className="add_item_tooltip" onClick={() => showAddtoOrderModal(item, date, productRestrictions)} onMouseEnter={() => { toggleTooltip(item, true) }} onMouseLeave={() => { toggleTooltip(item, false) }}>
                                <Tooltip title="Click to add to order" color="orange"
                                  visible={tooltipVisible !== null && tooltipVisible.itemId === item.id && tooltipVisible.visibility}
                                >
                                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0)">
                                      <path d="M13.2731 8.15354C13.9937 7.433 14.3985 6.45574 14.3985 5.43674C14.3985 4.41774 13.9937 3.44048 13.2731 2.71994C12.5526 1.9994 11.5753 1.5946 10.5563 1.5946C9.53733 1.5946 8.56007 1.9994 7.83953 2.71994L3.51953 7.03994V12.4799H8.95953L13.2731 8.15354Z" stroke="black" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                      <path d="M10.5596 5.43976L1.59961 14.3998" stroke="black" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                      <path d="M11.5201 9.91974H6.08008" stroke="black" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0">
                                        <rect width="15.36" height="15.36" fill="white" transform="translate(0.320312 0.319763)" />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </Tooltip>
                              </div>
                            </div>
                          </Col>
                        )
                      })
                    }
                  </Row> :
                  <Collapse
                      defaultActiveKey={
                        product.children?.map(subcat => subcat.name)
                      }
                  >
                    {product.children.map(subcat => {
                      return (
                        <Panel disabled={true} header={subcat.name} forceRender={true} key={subcat.name} showArrow={false}>
                          <Row className="items_menu" gutter={{ xs: 0, sm: 30, md: 30, lg: 30 }}>
                            {!!subcat.items && subcat.items.length > 0 &&
                              subcat.items.map((item, index) => {
                                return (
                                  <Col xs={24} md={12} lg={8} key={index}>
                                    <div className="menu_image" onClick={() => showAddtoOrderModal(item, date, productRestrictions)} style={{ cursor: "pointer" }}>
                                      <img alt="Restaurant Picture" src={item.image} />
                                    </div>
                                    <div className="item_name_price">
                                      <div className="name_price">
                                        <h6 className="item_name">{item.productName}</h6>
                                        <div className="item_price_Dietary">
                                          {!reservation && <div className="item_price"> $ {calculateDecimal(item.sellPrice)}</div>}
                                          <div className="tag-container">
                                            {process.env.REACT_APP_PRODUCT_ALLERGEN_TAG === 'true'
                                            && item?.attributes?.length > 0
                                            && sortAttributes(item.attributes).map((tag) => { // NOTE - attributes sorting
                                              let tagAbbr = tag.acronym;
                                              return (
                                                  <div className={tag.category?.name === "Allergens" ? 'allergen_Dietary' : "item_Dietary"} key={tag.id}>
                                                    <Tooltip placement="top" title={tag.name} color="orange">
                                                      {tagAbbr}
                                                    </Tooltip>
                                                  </div>
                                              )
                                            })
                                            }
                                            {
                                              item?.tags?.length > 0 && item.tags.map((tag) => {
                                                let tagAbbr = tag.acronym;
                                                return (
                                                    <div className="item_Dietary" key={tag.id}>
                                                      <Tooltip placement="top" title={tag.name} color="orange">
                                                        {tagAbbr}
                                                      </Tooltip>
                                                    </div>
                                                )
                                              })
                                            }
                                          </div>
                                        </div>
                                      </div>
                                      <div className="add_item_tooltip" onClick={() => showAddtoOrderModal(item, date, productRestrictions)} onMouseEnter={() => { toggleTooltip(item, true) }} onMouseLeave={() => { toggleTooltip(item, false) }}>
                                        <Tooltip title="Click to add to order" color="orange"
                                          visible={tooltipVisible !== null && tooltipVisible.itemId === item.id && tooltipVisible.visibility}
                                        >
                                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0)">
                                              <path d="M13.2731 8.15354C13.9937 7.433 14.3985 6.45574 14.3985 5.43674C14.3985 4.41774 13.9937 3.44048 13.2731 2.71994C12.5526 1.9994 11.5753 1.5946 10.5563 1.5946C9.53733 1.5946 8.56007 1.9994 7.83953 2.71994L3.51953 7.03994V12.4799H8.95953L13.2731 8.15354Z" stroke="black" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                              <path d="M10.5596 5.43976L1.59961 14.3998" stroke="black" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                              <path d="M11.5201 9.91974H6.08008" stroke="black" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                            </g>
                                            <defs>
                                              <clipPath id="clip0">
                                                <rect width="15.36" height="15.36" fill="white" transform="translate(0.320312 0.319763)" />
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        </Tooltip>
                                      </div>
                                    </div>
                                  </Col>
                                )
                              })
                            }
                          </Row>
                        </Panel>
                      )
                    })}
                  </Collapse>
                }

              </Panel>
            </>
          )
        })}
      </Collapse>
    )
  }

  const renderReservationInfo = () => {
    return (
      <Col xs={24} md={6} lg={4}>
        <Row>
          <Col xs={24}>
            {process.env.REACT_APP_ENV === "hc" ? <h2 className="Sub_Heading">Order Info</h2> : (!reservation ? <h2 className="Sub_Heading">Pickup Info</h2> : <h2 className="Sub_Heading">Reservation Info</h2>)}
          </Col>
          {process.env.REACT_APP_ENV === "hc" && !!newPickupMenuData && !!newPickupMenuData.studentName &&
            <Col xs={24}>
              <span className="pickup_label">Student Name</span>
              <span className="location_restraurant">{newPickupMenuData.studentName}</span>
            </Col>}
          <Col xs={24}>
            {!reservation && <span className="pickup_label">Pickup</span>}
            {newPickupMenuData !== null &&
              newPickupMenuData.dateSelected &&
              newPickupMenuData.timeSelected && (
                <span className="pickup_time">
                  {
                    newPickupMenuData
                      && newPickupMenuData.orderType
                      && newPickupMenuData.orderType === "For A Week"
                      && process.env.REACT_APP_WEEKLY_RESTAURANT_API === 'true'
                      ? `Week of ${moment.utc(newPickupMenuData.dateSelected).format('MMMM DD')} - ${moment.utc(newPickupMenuData.dateSelected).add(6, 'days').format('MMMM DD')}`
                      : moment(newPickupMenuData.dateSelected).format("MM/DD/YYYY")
                  }{" "}
                  {
                    newPickupMenuData
                      && newPickupMenuData.orderType
                      && newPickupMenuData.orderType === "For A Week"
                      && process.env.REACT_APP_WEEKLY_RESTAURANT_API === 'true'
                      ? moment.utc(newPickupMenuData.timeSelected).format('h:mm A')
                      : newPickupMenuData.timeSelected
                  }
                </span>
              )}
          </Col>
          <Col xs={24}>
            <span className="location_label">At</span>
            {newPickupMenuData !== null &&
              newPickupMenuData.pickupLocation &&
              newPickupMenuData.pickupLocation.name && (
                <span className="location_restraurant">
                  {newPickupMenuData.pickupLocation.name},
                </span>
              )}
            {newPickupMenuData !== null &&
              newPickupMenuData.pickupLocation &&
              newPickupMenuData.pickupLocation.street_one && (
                <span className="location_restraurant">
                  {newPickupMenuData.pickupLocation.street_one},{" "}
                  {newPickupMenuData.pickupLocation.street_two &&
                    newPickupMenuData.pickupLocation.street_two}
                </span>
              )}
            {newPickupMenuData !== null &&
              newPickupMenuData.pickupLocation &&
              newPickupMenuData.pickupLocation.city &&
              newPickupMenuData.pickupLocation.state &&
              newPickupMenuData.pickupLocation.zip && (
                <span className="location_restraurant">
                  {newPickupMenuData.pickupLocation.city},{" "}
                  {newPickupMenuData.pickupLocation.state} {newPickupMenuData.zip}
                </span>
              )}
          </Col>
        </Row>
      </Col>
    );
  };

  return (
    <Layout>
      <Header>
        <AuthHeader {...props} />
      </Header >
      <Content className="full_content">
        <div className="site-layout-content">
          <Row className="top_row">
            <Col xs={24} md={18} lg={20}>
              <Row>
                <Col xs={24} onClick={getBacktoVendor}>
                  <Button ghost style={{ padding: '0px', border: 'none' }}>
                    <div className="back_search"><svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M22.3992 10.3496H4.68625L15.288 1.14648C15.4575 0.998145 15.3546 0.722656 15.1306 0.722656H12.4514C12.3333 0.722656 12.2213 0.765039 12.1335 0.840722L0.69318 10.7674C0.588399 10.8582 0.504366 10.9705 0.446774 11.0966C0.389181 11.2228 0.359375 11.3598 0.359375 11.4985C0.359375 11.6372 0.389181 11.7742 0.446774 11.9003C0.504366 12.0265 0.588399 12.1388 0.69318 12.2296L12.2001 22.2168C12.2455 22.2562 12.3 22.2773 12.3575 22.2773H15.1276C15.3516 22.2773 15.4545 21.9988 15.285 21.8535L4.68625 12.6504H22.3992C22.5324 12.6504 22.6414 12.5414 22.6414 12.4082V10.5918C22.6414 10.4586 22.5324 10.3496 22.3992 10.3496Z" fill="black" />
                    </svg> Back to the search list
</div>
                  </Button>
                </Col>
                <Col xs={24}>
                  {selectedVendorData !== null && <h1 className="tab_heading">{selectedVendorData.distributorName}</h1>}
                </Col>
                <Col xs={24}>
                  {selectedVendorData !== null && <img alt="Restaurant Picture" src={selectedVendorData.distributorLogo} className="restautant_pic" />}

                </Col>
                <Col xs={24}>
                  <Row justify="space-between">
                    <Col md={6} xs={24} lg={6}>
                      <Button
                        type="primary" htmlType="button" className="filters_mobile_btn detail_info" onClick={() => { setMoreInfoVendor(true) }}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 21.9999C17.5228 21.9999 22 17.5227 22 11.9999C22 6.47703 17.5228 1.99988 12 1.99988C6.47715 1.99988 2 6.47703 2 11.9999C2 17.5227 6.47715 21.9999 12 21.9999Z" stroke="#2C384C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M12 16V12" stroke="#2C384C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M12 7.99988H12.01" stroke="#2C384C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    More Info
									</Button>
                    </Col>
                    <Col md={18} xs={24} lg={18}>
                      <Button onClick={() => { showFilterModal(true) }}
                        type="primary" htmlType="button" className="filters_mobile_btn detail_info">
                        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M21 1H1L9 10.46V17L13 19V10.46L21 1Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>Add Filters
									</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            {renderReservationInfo()}
          </Row>
          <Spin spinning={loading} size="large" >
            <Row className="restaurant_items">
              {!noProductsFound ? (
                <>
                  <Col xs={24} md={15} lg={18}>
                    <Row>
                      <Col span={24}>
                        <div className="content-heading-col">
                          <div className="content-heading-div">
                            <h2 className="content-heading">{mainContentHeading}
                              <span className="content-heading-distance">{searchCriteria}</span>
                            </h2>
                          </div>
                          {brandingDetails === null || brandingDetails.shopAvailableOptions === null || brandingDetails.err ?
                              <p className="content-sub-heading">{mainContentSubHeading2}</p> :
                              <p className="content-sub-heading">{brandingDetails.shopAvailableOptions}</p>}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </>
              ) : (
                  <Col xs={24} md={15} lg={18}>
                    <h3 style={{ color: '#929A9A', textAlign: 'center', margin: '15%' }}>No products found for selected vendor</h3>
                  </Col>
                )}
              <Col xs={24} md={15} lg={18}>
                {
                  newPickupMenuData
                    && newPickupMenuData.orderType
                    && newPickupMenuData.orderType === "For A Week"
                    && process.env.REACT_APP_WEEKLY_RESTAURANT_API === 'true'
                    ? !!productsList
                    && !productsList.err
                    && productsList.length > 0
                    && productsList.map((productList, index) => {
                      return renderProductList(productList.items, index, productList.productRestrictionRules, productList.date)
                    })
                    : !!productsList && !productsList.err && renderProductList(productsList.items, 1, productsList.productRestrictionRules)
                }
              </Col>
              <DietryFilterModal dietryFilter={filterModal} showDietryFilter={showFilterModal} distributorId={id} {...props} />
              {addtoOrderModal && <AddtoOrderModal
                {...props}
                visible={addtoOrderModal}
                setAddtoOrderModal={setAddtoOrderModal}
                dateSelected={dateSelected}
                product={itemClicked}
                productRestricted={productRestricted}
              />}

              <MoreInfoModal
                {...props}
                visible={moreInfoVendor}
                setMoreInfoModal={setMoreInfoVendor}
                vendor={selectedVendorData}
              />
              <Col xs={24} md={9} lg={6}>
                <SiderCartInfo cartDistributorId={cartDistributorId} {...props} />
              </Col>
            </Row>
          </Spin>
        </div>
      </Content>
      <Footer>
        <PageFooter {...props} />
      </Footer>
    </Layout>
  )
}

const mapStateToProps = (state) => {
  return {
    productsList: state.VendorReducer.productsList,
    addToCartResponse: state.VendorReducer.addToCartResponse,
    cartDistributor: state.VendorReducer.cartDistributor,
    cartDetails: state.VendorReducer.cartDetails,
    filtersList: state.UserReducer.filtersList,
    selectedFiltersList: state.UserReducer.selectedFiltersList,
    pickupData: state.UserReducer.selectPickupData,
    selectedVendorData: state.UserReducer.selectedVendorData,
    newPickupMenuData: state.UserReducer.newPickupMenuData,
    updateCartResponse: state.OrderReducer.updateCartResponse,
    deleteCartResponse: state.OrderReducer.deleteCartResponse,
    productSKUTags: state.VendorReducer.productSKUTags,
    selectedDietaryFilter: state.VendorReducer.selectedDietaryFilter,
    brandingDetails: state.UserReducer.brandingDetails,
    reservationStatus: state.UserReducer.reservationStatus,
    onlyReservationAvailable: state.UserReducer.onlyReservationAvailable,
    filteredCartItems: state.VendorReducer.filteredCartItems,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    getProductsList: (params) => dispatch({ type: vendorActions.GET_SHOP_ITEMS, params }),
    clearProductList: () => dispatch({ type: vendorActions.SAVE_SHOP_ITEMS, shopItems: null }),
    addToCart: (params) => dispatch({ type: vendorActions.ADD_TO_CART, params }),
    getCartDistributor: () => dispatch({ type: vendorActions.GET_CART_DISTRIBUTOR_DETAILS }),
    getCartDetails: (params) => dispatch({ type: vendorActions.GET_CART_DETAILS, params }),
    updateCart: (params) => dispatch({ type: OrderActions.UPDATE_CART_REQUEST, params }),
    saveOrderDetailsLocation: (params) => dispatch({ type: OrderActions.SAVE_ORDER_LOCATION_DETAILS, params }),
    deleteCart: (params) => dispatch({ type: OrderActions.DELETE_CART_REQUEST, params }),
    getProductSKUTags: (params) => dispatch({ type: vendorActions.GET_SKU_TAGS, params }),
    saveSelectedDietaryFilter: (params) => dispatch({ type: vendorActions.SAVE_SELECTED_DIETARY_FILTER, dietaryFilter: params }),
    clearSelectedDietaryFilter: () => dispatch({ type: vendorActions.SAVE_SELECTED_DIETARY_FILTER, dietaryFilter: null })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Vendor)