import { takeLatest, put, call } from 'redux-saga/effects';
import * as actionTypes from './actions';
import { getRequest, postRequest } from '../../utils/requests';
import pathconfig from '../../utils/path-config';

function* updateUserInfo(params) {
  try {
    const result = yield call(postRequest, pathconfig.updateAccount, params.params);
    yield put({ type: actionTypes.UPDATE_USER_INFO_RESPONSE, updateUserResponse: result })
    if (!result.err) yield put({ type: "USER_DETAILS" });
  }
  catch (err) {
    console.log(err);
  }
}

function* updateUserCredential(params) {
  try {
    const result = yield call(postRequest, pathconfig.profile, params.params);
    yield put({ type: actionTypes.UPDATE_USER_CREDENTIAL_RESPONSE, updateUserCredentialResponse: result })
  }
  catch (err) {
    console.log(err);
  }
}

function* getOrderDetails(values) {
  try {
    var url = new URL(pathconfig.baseUrl + pathconfig.orderDetails),
      params = {
        offset: values.params.offset,
        max: values.params.max,
        b2cType: values.params.b2cType
      }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    const result = yield call(getRequest, url.pathname + url.search);
    // const result = yield call(getRequest, pathconfig.orderDetails)
    yield put({ type: actionTypes.GET_MY_ORDER_RESPONSE, myOrderResponse: result })

  } catch (err) {
    console.log(err);
  }
}

function* loadMoreOrders(values) {
  try {
    var url = new URL(pathconfig.baseUrl + pathconfig.orderDetails),
      params = {
        offset: values.params.offset,
        max: values.params.max,
        b2cType: values.params.b2cType
      }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    yield put({ type: actionTypes.ORDER_HISTORY_LOAD_MORE })

    const result = yield call(getRequest, url.pathname + url.search);
    if (result?.length === values.params.max) {
      yield put({ type: actionTypes.ORDER_HISTORY_LOAD_RESPONSE, myOrderResponse: result, endOfHistoryList: false })
    } else {
      yield put({ type: actionTypes.ORDER_HISTORY_LOAD_RESPONSE, myOrderResponse: result, endOfHistoryList: true })
    }
    // const result = yield call(getRequest, pathconfig.orderDetails)

  } catch (err) {
    yield put({ type: actionTypes.ORDER_HISTORY_STOP_LOAD_MORE })
    console.log(err);
  }
}

function* getDietaryOptions(values) {
  try {
    var url = new URL(pathconfig.baseUrl + pathconfig.dietaryOptions),
      params = {
        offset: values.params.offset,
        max: values.params.max
      }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    const result = yield call(getRequest, url.pathname + url.search);
    yield put({ type: actionTypes.DIETARY_OPTIONS_RESPONSE, dietryOptionsResponse: result })
  } catch (err) {
    console.log(err);
  }
}

function* repeatOrder(params) {
  try {
    const result = yield call(postRequest, pathconfig.repeatOrder(params.id), params.params);
    yield put({ type: actionTypes.REPEAT_ORDER_RESPONSE, repeatOrderResponse: result })
  } catch (err) {
    console.log("In catch", err);
  }
}

function* getPickupDates(values) {
  try {
    var url = new URL(pathconfig.baseUrl + pathconfig.pickupDates),
      params = {
        pickupLocation: values.params.pickupLocation,
        b2cType: values.params.b2cType,
        childLocation: values.params.childLocation
      }
    Object.keys(params).filter(k => params[k]).forEach(key => url.searchParams.append(key, params[key]));
    const result = yield call(getRequest, url.pathname + url.search);
    yield put({ type: actionTypes.SAVE_PICKUP_DATES, pickupDates: result })
  } catch (err) {
    console.log(err);
  }
}

function* getPickupTime(values) {
  try {
    var url = new URL(pathconfig.baseUrl + pathconfig.pickupTimes),
      params = {
        pickupLocation: values.params.pickupLocation,
        pickupDate: values.params.date,
        b2cType: values.params.b2cType,
        childLocation: values.params.childLocation
      }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    let result = yield call(getRequest, url.pathname + url.search);
    yield put({ type: actionTypes.SAVE_PICKUP_TIME, pickupTimes: result })
  } catch (err) {
    console.log(err)
  }
}

function* getAvailableMealBalance() {
  try {
    const result = yield call(getRequest, pathconfig.mealPlanBalance);
    yield put({ type: actionTypes.MEAL_PLAN_AVAILABLE_BALANCE_RESPONSE, mealPlanAvailableBalance: result });
  } catch (err) {
    console.log(err);
  }
}

export default function* watchUserAccountSaga() {
  yield takeLatest(actionTypes.UPDATE_USER_INFO, updateUserInfo);
  yield takeLatest(actionTypes.UPDATE_USER_CREDENTIAL, updateUserCredential);
  yield takeLatest(actionTypes.GET_MY_ORDER, getOrderDetails);
  yield takeLatest(actionTypes.LOAD_MORE_ORDER, loadMoreOrders);
  yield takeLatest(actionTypes.GET_DIETARY_OPTIONS_FOR_PROFILE, getDietaryOptions);
  yield takeLatest(actionTypes.REPEAT_ORDER_REQUEST, repeatOrder);
  yield takeLatest(actionTypes.GET_PICKUP_DATES, getPickupDates);
  yield takeLatest(actionTypes.GET_PICKUP_TIME, getPickupTime);
  yield takeLatest(actionTypes.MEAL_PLAN_AVAILABLE_BALANCE_REQUEST, getAvailableMealBalance);
}
