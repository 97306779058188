import * as actionTypes from './actions';

const initialState = {
  forgotUsernameResponse: null
}

const ForgotUsernameReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.FORGOT_USERNAME_RESPONSE:
      return { forgotUsernameResponse: action.forgotUsernameResponse }

    default:
      return state;
  }
}

export default ForgotUsernameReducer;