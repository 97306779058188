import * as actionTypes from './actions';

const initialState = {
  consumerPaymentInfo: null,
  consumerPaymentError: null,
  paymentDetails: null,
  existingPaymentMethods: [],
  existingPaymentError: null,
  reservationConfirmation: null,
  mealPlanAvailableBalance: null,
  mealPlanOrderResponse: null,
}

const CheckoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CONSUMER_PAYMENT_RESPONSE:
      return {
        ...state,
        consumerPaymentInfo: action.result,
        consumerPaymentError: null
      }

    case actionTypes.CONSUMER_PAYMENT_INFO_ERROR:
      return { ...state, consumerPaymentError: action.err }

    case actionTypes.CLEAR_CHECKOUT_ERROR:
      return { ...state, consumerPaymentError: null }

    case actionTypes.SET_PAYMENT_SUCCESS_RESPONSE:
      return {
        paymentDetails: action.paymentSuccessRes,
        existingPaymentMethods: [],
        existingPaymentError: null,
        consumerPaymentInfo: null,
        consumerPaymentError: null,
      }

    case actionTypes.SET_EXISTING_PAYMENT_METHODS:
      return { ...state, existingPaymentMethods: action.result, existingPaymentError: null }

    case actionTypes.SET_EXISTING_PAYMENT_METHODS_ERROR:
      return { ...state, existingPaymentError: action.err }

    case actionTypes.SAVE_RESERVATION_CONFIRMATION:
      return { ...state, reservationConfirmation: action.reservationConfirmation };

    case actionTypes.MEAL_PLAN_AVAILABLE_BALANCE_RESPONSE:
      return { ...state, mealPlanAvailableBalance: action.mealPlanAvailableBalance };

    case actionTypes.MEAL_PLAN_ORDER_SUBMIT_RESPONSE:
      return { ...state, mealPlanOrderResponse: action.mealPlanOrderResponse }
    default:
      return state;
  }
}

export default CheckoutReducer;