import React from 'react';
import { Image, Row, Col } from 'antd';
import './style.scss';

const PageHeader = (props) => {
	const { brandingDetails } = props;
	return (
		<div className="header-div">
			<Row justify="start" gutter={16}>
				<Col xs={15} md={8} lg={8} xxl={6}>
					<Image
						alt="logo"
						preview="false"
						className="landing_page_logo"
						src={brandingDetails !== undefined && brandingDetails !== null && !brandingDetails.err && brandingDetails.landingTopLogoUrl !== null ? brandingDetails.landingTopLogoUrl : require('../../assets/images/logo_new.png')}
					/>
				</Col>
			</Row>
		</div>
	)
}

export default PageHeader;