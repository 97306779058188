import * as actionTypes from './actions';

const initialState = {
  productsList: null,
  addToCartResponse: null,
  cartDistributor: null,
  cartDetails: null,
  productSKUTags: null,
  selectedDietaryFilter: null,
  filteredCartItems: null,
}

const VendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_SHOP_ITEMS:
      return { ...state, productsList: action.shopItems }

    case actionTypes.ADD_TO_CART_RESPONSE:
      return { ...state, addToCartResponse: action.addToCartResponse }

    case actionTypes.SAVE_CART_DISTRIBUTOR_DETAILS:
      return { ...state, cartDistributor: action.cartDistributor };

    case actionTypes.SAVE_CART_DETAILS:
      return { ...state, cartDetails: action.cartDetails };
    case actionTypes.SAVE_SKU_TAGS:
      return { ...state, productSKUTags: action.productSKUTags };

    // NOTE: delete cart for which payment has been successful
    case "SET_PAYMENT_SUCCESS_RESPONSE":
      const updatedCartDistributers = state.cartDistributor.filter((item) => item.id !== action?.paymentSuccessRes?.orderListItems?.cart?.id);
      return { ...state, cartDistributor: updatedCartDistributers, cartDetails: null };

    case actionTypes.SAVE_SELECTED_DIETARY_FILTER:
      return { ...state, selectedDietaryFilter: action.dietaryFilter };

    case actionTypes.SAVE_CART_ITEM_DETAILS:
      return { ...state, filteredCartItems: action.filteredCartItems }

    default:
      return state;
  }
}

export default VendorReducer;