import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Select, Button } from 'antd';
import PickupByDateTime from '../PickupByDateTime';
import MobilePickupInfoDrawer from '../MobilePickupInfoDrawer';
const _ = require("lodash");

const PickupByLocation = (props) => {

  const { locationModalVisible, setLocationModalVisible, saveNewPickupMenuData, programInfo, clearPickupDates, clearPickupTimes } = props;
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [selectedCityState, setSelectedCityState] = useState(null);
  const [selectedZip, setSelectedZip] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedPickupLocation, setSelectedPickupLocation] = useState(null);
  const [cityStateOptions, setCityStateOptions] = useState(null);
  const [zipOptions, setZipOptions] = useState(null);
  const [locationOptions, setLocationOptions] = useState(null);
  const [pickupLocationOptions, setPickupLocationOptions] = useState(null);
  const [pickupByDateTimeVisible, setPickupByDateTimeVisible] = useState(false);
  const [hiddenPickup, setHiddenPickup] = useState(true);
  const [showMobilePickupDrawer, setMobilePickupDrawer] = useState(false);
  const [dataList, setDataList] = useState(null);
  const [pickupField, setPickupField] = useState("city/state");
  useEffect(() => {
    setBtnDisabled(!selectedProgram || !selectedLocation || !selectedPickupLocation);
  }, [selectedProgram, selectedLocation, selectedPickupLocation]);

  useEffect(() => {
    if (selectedLocation !== null) {
      setHiddenPickup(false);
    }
    else {
      setHiddenPickup(true);
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (programInfo !== null && !programInfo.err) {
      let arrOfCityState = [];
      let arrOfZip = [];
      let arrOfLocations = [];
      let arrOfPickupLocations = [];
      if (programInfo.program) {
        setSelectedProgram(programInfo.program);
      }

      if (programInfo.pickupAddresses) {
        programInfo.pickupAddresses.map((item, index) => {
          if (item.cities && item.cities.length > 0) {
            item.cities.map((city, index) => {
              if (arrOfCityState.indexOf(city.name + ", " + item.state === -1)) {
                arrOfCityState.push(city.name + ", " + item.state);
              }
              if (city.zipCodes && city.zipCodes.length > 0) {
                city.zipCodes.map((zip, index) => {
                  if (arrOfZip.indexOf(zip === -1)) {
                    arrOfZip.push(zip);
                  }
                })
              }
            });
          }
        });
        setCityStateOptions(_.uniq(arrOfCityState));
        setZipOptions(_.uniq(arrOfZip));
      }

      if (programInfo.locations && programInfo.locations.length > 0) {
        let locationObj = null;
        let pickupObj = null;
        programInfo.locations.map((location, index) => {
          locationObj = { id: location.id, name: location.name }
          if (arrOfLocations.indexOf(locationObj === -1)) {
            arrOfLocations.push(locationObj);
          }
          if (location.hubs && location.hubs.length > 0) {
            location.hubs.map((pickup, index) => {
              pickupObj = { id: pickup.id, name: pickup.name, street_one: pickup.street_one, street_two: pickup.street_two, city: pickup.city, state: pickup.state, zip: pickup.zip }
              if (arrOfPickupLocations.indexOf(pickupObj === -1)) {
                arrOfPickupLocations.push(pickupObj);
              }
            });
          }
          else {
            pickupObj = { id: location.id, name: location.name, street_one: location.street_one, street_two: location.street_two, city: location.city, state: location.state, zip: location.zip }
            if (arrOfPickupLocations.indexOf(pickupObj === -1)) {
              arrOfPickupLocations.push(pickupObj);
            }
          }
        });

        setLocationOptions(_.uniqBy(arrOfLocations, 'id'));
        setPickupLocationOptions(_.uniqBy(arrOfPickupLocations, 'id'));
      }

    }
  }, [programInfo]);

  useEffect(() => {
    if (selectedCityState !== null) {
      if (programInfo !== null && !programInfo.err) {
        let arrOfZip = [];
        let arrOfLocations = [];
        let arrOfPickupLocations = [];
        let locationObj = null;
        let pickupObj = null;
        if (programInfo.pickupAddresses && programInfo.pickupAddresses.length > 0) {
          programInfo.pickupAddresses.map((item, index) => {
            if (item.state === selectedCityState.substring(selectedCityState.lastIndexOf(',') + 2, selectedCityState.length)) {
              if (item.cities && item.cities.length > 0) {
                item.cities.map((city, index) => {
                  if (city.name === selectedCityState.substring(0, selectedCityState.indexOf(','))) {
                    if (city.zipCodes && city.zipCodes.length > 0) {
                      city.zipCodes.map((zip, index) => {
                        if (arrOfZip.indexOf(zip === -1)) {
                          arrOfZip.push(zip);
                        }
                      });
                    }
                  }
                });
              }
            }

          })
        }

        if (programInfo.locations && programInfo.locations.length > 0) {
          programInfo.locations.map((location, index) => {
            if (location.hubs && location.hubs.length > 0) {
              location.hubs.map((pickup, index) => {
                if ((pickup.city + ", " + pickup.state) === selectedCityState) {
                  locationObj = { id: location.id, name: location.name }
                  if (arrOfLocations.indexOf(locationObj === -1)) {
                    arrOfLocations.push(locationObj);
                  }
                  pickupObj = { id: pickup.id, name: pickup.name, street_one: pickup.street_one, street_two: pickup.street_two, city: pickup.city, state: pickup.state, zip: pickup.zip }
                  if (arrOfPickupLocations.indexOf(pickupObj === -1)) {
                    arrOfPickupLocations.push(pickupObj);
                  }
                }
              });
            }
            else {
              if ((location.city + ", " + location.state) === selectedCityState) {
                locationObj = { id: location.id, name: location.name }
                if (arrOfLocations.indexOf(locationObj === -1)) {
                  arrOfLocations.push(locationObj);
                }
                pickupObj = { id: location.id, name: location.name, street_one: location.street_one, street_two: location.street_two, city: location.city, state: location.state, zip: location.zip }
                if (arrOfPickupLocations.indexOf(pickupObj === -1)) {
                  arrOfPickupLocations.push(pickupObj);
                }
              }
            }
          })
        }
        setZipOptions(_.uniq(arrOfZip));
        setLocationOptions(_.uniqBy(arrOfLocations, 'id'));
        setPickupLocationOptions(_.uniqBy(arrOfPickupLocations, 'id'));
      }
    }
  }, [selectedCityState]);

  useEffect(() => {
    if (selectedZip !== null) {
      if (programInfo !== null && !programInfo.err) {
        let arrOfLocations = [];
        let arrOfPickupLocations = [];
        let locationObj = null;
        let pickupObj = null;
        if (programInfo.locations && programInfo.locations.length > 0) {
          programInfo.locations.map((location, index) => {
            if (location.hubs && location.hubs.length > 0) {
              location.hubs.map((pickup, index) => {
                if (pickup.zip === selectedZip) {
                  locationObj = { id: location.id, name: location.name }
                  if (arrOfLocations.indexOf(locationObj === -1)) {
                    arrOfLocations.push(locationObj);
                  }
                  pickupObj = { id: pickup.id, name: pickup.name, street_one: pickup.street_one, street_two: pickup.street_two, city: pickup.city, state: pickup.state, zip: pickup.zip }
                  if (arrOfPickupLocations.indexOf(pickupObj === -1)) {
                    arrOfPickupLocations.push(pickupObj);
                  }

                }
              })
            }
            else {
              if (location.zip === selectedZip) {
                locationObj = { id: location.id, name: location.name }
                if (arrOfLocations.indexOf(locationObj === -1)) {
                  arrOfLocations.push(locationObj);
                }
                pickupObj = { id: location.id, name: location.name, street_one: location.street_one, street_two: location.street_two, city: location.city, state: location.state, zip: location.zip }
                if (arrOfPickupLocations.indexOf(pickupObj === -1)) {
                  arrOfPickupLocations.push(pickupObj);
                }
              }
            }
          })
        }
        setLocationOptions(_.uniqBy(arrOfLocations, 'id'));
        setPickupLocationOptions(_.uniqBy(arrOfPickupLocations, 'id'));
      }
    }
  }, [selectedZip]);


  useEffect(() => {
    if (selectedLocation !== null) {
      let pickupObj = null;
      let arrOfPickupLocations = [];
      if (programInfo !== null && !programInfo.err) {
        if (programInfo.locations && programInfo.locations.length > 0) {
          programInfo.locations.map((location, index) => {
            if (selectedLocation.id === location.id) {
              if (location.hubs && location.hubs.length > 0) {
                location.hubs.map((pickup, index) => {
                  pickupObj = { id: pickup.id, name: pickup.name, street_one: pickup.street_one, street_two: pickup.street_two, city: pickup.city, state: pickup.state, zip: pickup.zip }
                  if (arrOfPickupLocations.indexOf(pickupObj === -1)) {
                    arrOfPickupLocations.push(pickupObj);
                  }
                });
              }
              else {
                pickupObj = { id: location.id, name: location.name, street_one: location.street_one, street_two: location.street_two, city: location.city, state: location.state, zip: location.zip }
                if (arrOfPickupLocations.indexOf(pickupObj === -1)) {
                  arrOfPickupLocations.push(pickupObj);
                }
              }
            }
          });
        }
      }
      setPickupLocationOptions(_.uniqBy(arrOfPickupLocations, 'id'));
    }
  }, [selectedLocation]);

  const setCityState = (value) => {
    setSelectedCityState(value);
    setSelectedZip(null);
    setSelectedLocation(null);
    setSelectedPickupLocation(null);
    if (programInfo !== null && !programInfo.err) {
      let arrOfZip = [];
      let arrOfLocations = [];
      let arrOfPickupLocations = [];
      let locationObj = null;
      let pickupObj = null;
      if (programInfo.pickupAddresses && programInfo.pickupAddresses.length > 0) {
        programInfo.pickupAddresses.map((item, index) => {
          if (item.state === value.substring(value.lastIndexOf(',') + 2, value.length)) {
            if (item.cities && item.cities.length > 0) {
              item.cities.map((city, index) => {
                if (city.name === value.substring(0, value.indexOf(','))) {
                  if (city.zipCodes && city.zipCodes.length > 0) {
                    city.zipCodes.map((zip, index) => {
                      if (arrOfZip.indexOf(zip === -1)) {
                        arrOfZip.push(zip);
                      }
                    });
                  }
                }
              });
            }
          }

        })
      }

      if (programInfo.locations && programInfo.locations.length > 0) {
        programInfo.locations.map((location, index) => {
          if (location.hubs && location.hubs.length > 0) {
            location.hubs.map((pickup, index) => {
              if ((pickup.city + ", " + pickup.state) === value) {
                locationObj = { id: location.id, name: location.name }
                if (arrOfLocations.indexOf(locationObj === -1)) {
                  arrOfLocations.push(locationObj);
                }
                pickupObj = { id: pickup.id, name: pickup.name, street_one: pickup.street_one, street_two: pickup.street_two, city: pickup.city, state: pickup.state, zip: pickup.zip }
                if (arrOfPickupLocations.indexOf(pickupObj === -1)) {
                  arrOfPickupLocations.push(pickupObj);
                }
              }
            });
          }
          else {
            if ((location.city + ", " + location.state) === value) {
              locationObj = { id: location.id, name: location.name }
              if (arrOfLocations.indexOf(locationObj === -1)) {
                arrOfLocations.push(locationObj);
              }
              pickupObj = { id: location.id, name: location.name, street_one: location.street_one, street_two: location.street_two, city: location.city, state: location.state, zip: location.zip }
              if (arrOfPickupLocations.indexOf(pickupObj === -1)) {
                arrOfPickupLocations.push(pickupObj);
              }
            }
          }
        })
      }
      setZipOptions(_.uniq(arrOfZip));
      setLocationOptions(_.uniqBy(arrOfLocations, 'id'));
      setPickupLocationOptions(_.uniqBy(arrOfPickupLocations, 'id'));
    }
  }

  const setZipCode = (value) => {
    setSelectedZip(value);
    setSelectedLocation(null);
    setSelectedPickupLocation(null);
    let arrOfLocations = [];
    let arrOfPickupLocations = [];
    let locationObj = null;
    let pickupObj = null;
    if (programInfo !== null && !programInfo.err) {

      if (programInfo.pickupAddresses && programInfo.pickupAddresses.length > 0) {
        programInfo.pickupAddresses.map((item, index) => {
          if (item.cities && item.cities.length > 0) {
            item.cities.map((city, index) => {
              if (city.zipCodes && city.zipCodes.length > 0) {
                city.zipCodes.map((zip, index) => {
                  if (zip === value) {
                    setSelectedCityState(city.name + ", " + item.state);
                  }
                })
              }
            })
          }
        })
      }

      if (programInfo.locations && programInfo.locations.length > 0) {
        programInfo.locations.map((location, index) => {
          if (location.hubs && location.hubs.length > 0) {
            location.hubs.map((pickup, index) => {
              if (pickup.zip === value) {
                locationObj = { id: location.id, name: location.name }
                if (arrOfLocations.indexOf(locationObj === -1)) {
                  arrOfLocations.push(locationObj);
                }
                pickupObj = { id: pickup.id, name: pickup.name, street_one: pickup.street_one, street_two: pickup.street_two, city: pickup.city, state: pickup.state, zip: pickup.zip }
                if (arrOfPickupLocations.indexOf(pickupObj === -1)) {
                  arrOfPickupLocations.push(pickupObj);
                }

              }
            })
          }
          else {
            if (location.zip === value) {
              locationObj = { id: location.id, name: location.name }
              if (arrOfLocations.indexOf(locationObj === -1)) {
                arrOfLocations.push(locationObj);
              }
              pickupObj = { id: location.id, name: location.name, street_one: location.street_one, street_two: location.street_two, city: location.city, state: location.state, zip: location.zip }
              if (arrOfPickupLocations.indexOf(pickupObj === -1)) {
                arrOfPickupLocations.push(pickupObj);
              }
            }
          }
        })
      }
    }
    setLocationOptions(_.uniqBy(arrOfLocations, 'id'));
    setPickupLocationOptions(_.uniqBy(arrOfPickupLocations, 'id'));
  }

  const setLocation = (value, field) => {
    let locationObj = { id: field.value, name: field.children }
    setSelectedLocation(locationObj);
    setSelectedPickupLocation(null);
    let pickupObj = null;
    let arrOfPickupLocations = [];
    let zipOfSelectedLocation = null;
    if (programInfo !== null && !programInfo.err) {
      if (programInfo.locations && programInfo.locations.length > 0) {
        programInfo.locations.map((location, index) => {
          if (location.id === value) {
            zipOfSelectedLocation = location.zip;
            if (location.hubs && location.hubs.length > 0) {
              location.hubs.map((pickup, index) => {
                pickupObj = { id: pickup.id, name: pickup.name, street_one: pickup.street_one, street_two: pickup.street_two, city: pickup.city, state: pickup.state, zip: pickup.zip }
                if (arrOfPickupLocations.indexOf(pickupObj === -1)) {
                  arrOfPickupLocations.push(pickupObj);
                }
              });
            }
            else {
              pickupObj = { id: location.id, name: location.name, street_one: location.street_one, street_two: location.street_two, city: location.city, state: location.state, zip: location.zip }
              if (arrOfPickupLocations.indexOf(pickupObj === -1)) {
                arrOfPickupLocations.push(pickupObj);
              }
            }
          }
        });
      }
    }
    setPickupLocationOptions(_.uniqBy(arrOfPickupLocations, 'id'));
  }

  const setPickupLocation = (value, field) => {
    let pickupObj = { id: field.value, name: field.name, street_one: field.street_one, street_two: field.street_two, city: field.city, state: field.state, zip: field.zip }
    let locationObj = null;
    let zipOfSelectedPickup = null;
    setSelectedPickupLocation(pickupObj);
    if (programInfo !== null && !programInfo.err) {
      if (programInfo.locations && programInfo.locations.length > 0) {
        programInfo.locations.map((location, index) => {
          if (location.hubs && location.hubs.length > 0) {
            location.hubs.map((pickup, index) => {
              if (pickup.id === value) {
                locationObj = { id: location.id, name: location.name }
                setSelectedLocation(locationObj);
                zipOfSelectedPickup = pickup.zip;
              }
            })
          }
          else {
            if (location.id === value) {
              locationObj = { id: location.id, name: location.name }
              setSelectedLocation(locationObj);
              zipOfSelectedPickup = location.zip;
            }
          }
        })
      }
      if (programInfo.pickupAddresses && programInfo.pickupAddresses.length > 0) {
        programInfo.pickupAddresses.map((item, index) => {
          if (item.cities && item.cities.length > 0) {
            item.cities.map((city, index) => {
              if (city.zipCodes && city.zipCodes.length > 0) {
                city.zipCodes.map((zip, index) => {
                  if (zipOfSelectedPickup === zip) {
                    setSelectedCityState(city.name + ", " + item.state);
                    setSelectedZip(zip);
                  }
                })
              }
            })
          }
        })
      }

    }
  }

  const handleCancel = e => {
    setLocationModalVisible(!locationModalVisible);
  };

  const handleOk = () => {
    clearPickupDates();
    clearPickupTimes();
    const data = {
      program: selectedProgram,
      cityState: selectedCityState,
      zip: selectedZip,
      location: selectedLocation,
      pickupLocation: selectedPickupLocation
    }
    saveNewPickupMenuData(data);
    setSelectedCityState(null);
    setSelectedZip(null);
    setSelectedPickupLocation(null);
    setSelectedLocation(null);
    setPickupByDateTimeVisible(true);
  }

  const showMobilePopup = (field) => {
    if (field === "city/state") {
      setDataList(cityStateOptions);
      setMobilePickupDrawer(true);
      setPickupField(field);
    }
    else if (field === "zip") {
      setDataList(zipOptions);
      setMobilePickupDrawer(true);
      setPickupField(field);
    }
    else if (field === "location") {
      if (!!locationOptions && locationOptions.length > 0) {
        let arrLocation = [], uniqArrLocation = [];
        locationOptions.map((location, index) => {
          if (arrLocation.indexOf(location.name) === -1) {
            arrLocation.push(location.name);
          }
        });
        uniqArrLocation = _.uniq(arrLocation);
        setDataList(uniqArrLocation);
      }
      setMobilePickupDrawer(true);
      setPickupField(field);
    }
    else if (field === "pickupLocation") {
      if (!!pickupLocationOptions && pickupLocationOptions.length > 0) {
        let arrLocation = [], uniqArrLocation = [];
        pickupLocationOptions.map((location, index) => {
          if (arrLocation.indexOf(location.name) === -1) {
            arrLocation.push(location.name);
          }
        });
        uniqArrLocation = _.uniq(arrLocation);
        setDataList(uniqArrLocation);
      }
      setMobilePickupDrawer(true);
      setPickupField(field);
    }
  }

  return (
    <>
      <Modal className="order-modal" title="Select a pick up location"
        visible={locationModalVisible}
        footer={null}
        onCancel={handleCancel}>

        <Row className="rowborder inputicon">
          <Col span={2} className="pickup_icon">
            <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M26.125 5.3335H6.875C5.35622 5.3335 4.125 6.5274 4.125 8.00016V26.6668C4.125 28.1396 5.35622 29.3335 6.875 29.3335H26.125C27.6438 29.3335 28.875 28.1396 28.875 26.6668V8.00016C28.875 6.5274 27.6438 5.3335 26.125 5.3335Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M22 2.6665V7.99984" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M11 2.6665V7.99984" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M4.125 13.3335H28.875" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

          </Col>
          <Col span={22}>
            {selectedProgram !== null &&
              <Select
                disabled={true}
                placeholder="Program"
                suffixIcon=""
                getPopupContainer={(trigger) => trigger.parentNode}
                defaultValue={selectedProgram.name}>
                <Select.Option key={1} value={selectedProgram.id}>{selectedProgram.name}</Select.Option>
              </Select>
            }
          </Col>
        </Row>

        <Row className="rowborder inputicon">
          <Col span={2} className="pickup_icon">
            <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
              <path className="cls-1" d="M16,30a1,1,0,0,1-.71-.29L7.68,22.09A11.77,11.77,0,1,1,24.32,5.45a11.76,11.76,0,0,1,0,16.64l-7.61,7.62A1,1,0,0,1,16,30ZM16,4A9.77,9.77,0,0,0,9.09,20.68L16,27.59l6.91-6.91h0A9.77,9.77,0,0,0,16,4Z" />
              <path className="cls-1" d="M16,18a5,5,0,1,1,5-5A5,5,0,0,1,16,18Zm0-8a3,3,0,1,0,3,3A3,3,0,0,0,16,10Z" />
            </svg>
          </Col>
          <Col span={22} className="show_desktop">
            <Select
              placeholder="City/State (optional)"
              onSelect={setCityState}
              suffixIcon=""
              getPopupContainer={(trigger) => trigger.parentNode}
              value={selectedCityState}>
              {cityStateOptions !== null && cityStateOptions.map((item, index) => {
                return (
                  <Select.Option key={index} value={item}>{item}</Select.Option>
                )
              })}
            </Select>
          </Col>
          <Col span={22} className="show_mobile">
            <div style={{ paddingLeft: 11, paddingRight: 11 }} className="ant-select ant-select-selector ant-select-selection-item" onClick={() => { showMobilePopup("city/state") }}>
              {!!selectedCityState ? selectedCityState : "City/State (optional)"}
            </div>
          </Col>
        </Row>



        <Row className="rowborder inputicon">
          <Col span={2} className="pickup_icon">
            <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
              <path className="cls-1" d="M16,30a1,1,0,0,1-.71-.29L7.68,22.09A11.77,11.77,0,1,1,24.32,5.45a11.76,11.76,0,0,1,0,16.64l-7.61,7.62A1,1,0,0,1,16,30ZM16,4A9.77,9.77,0,0,0,9.09,20.68L16,27.59l6.91-6.91h0A9.77,9.77,0,0,0,16,4Z" />
              <path className="cls-1" d="M16,18a5,5,0,1,1,5-5A5,5,0,0,1,16,18Zm0-8a3,3,0,1,0,3,3A3,3,0,0,0,16,10Z" />
            </svg>

          </Col>
          <Col span={22} className="show_desktop">

            <Select
              placeholder="Zip (optional)"
              onSelect={setZipCode}
              suffixIcon=""
              getPopupContainer={(trigger) => trigger.parentNode}
              value={selectedZip}>
              {zipOptions !== null && zipOptions.map((item, index) => {
                return (
                  <Select.Option key={index} value={item}>{item}</Select.Option>
                )
              })}
            </Select>
          </Col>
          <Col span={22} className="show_mobile">
            <div style={{ paddingLeft: 11, paddingRight: 11 }} className="ant-select ant-select-selector ant-select-selection-item" onClick={() => { showMobilePopup("zip") }}>
              {!!selectedZip ? selectedZip : "Zip (optional)"}
            </div>
          </Col>
        </Row>

        <Row className="rowborder">
          <Col span={2} className="pickup_icon">
            <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M26.125 5.3335H6.875C5.35622 5.3335 4.125 6.5274 4.125 8.00016V26.6668C4.125 28.1396 5.35622 29.3335 6.875 29.3335H26.125C27.6438 29.3335 28.875 28.1396 28.875 26.6668V8.00016C28.875 6.5274 27.6438 5.3335 26.125 5.3335Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M22 2.6665V7.99984" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M11 2.6665V7.99984" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M4.125 13.3335H28.875" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

          </Col>
          <Col span={22} className="show_desktop">
            <Select
              placeholder="Location"
              onSelect={setLocation}
              suffixIcon=""
              getPopupContainer={(trigger) => trigger.parentNode}
              value={selectedLocation !== null ? selectedLocation.name : null}>
              {locationOptions !== null && locationOptions.map((item, index) => {
                return (
                  <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                )
              })}
            </Select>
          </Col>
          <Col span={22} className="show_mobile">
            <div style={{ paddingLeft: 11, paddingRight: 11 }} className="ant-select ant-select-selector ant-select-selection-item" onClick={() => { showMobilePopup("location") }}>
              {!!selectedLocation && selectedLocation.name ? selectedLocation.name : "Location"}
            </div>
          </Col>
        </Row>

        <Row className="rowborder" hidden={hiddenPickup}>
          <Col span={2} className="pickup_icon">
            <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M26.125 5.3335H6.875C5.35622 5.3335 4.125 6.5274 4.125 8.00016V26.6668C4.125 28.1396 5.35622 29.3335 6.875 29.3335H26.125C27.6438 29.3335 28.875 28.1396 28.875 26.6668V8.00016C28.875 6.5274 27.6438 5.3335 26.125 5.3335Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M22 2.6665V7.99984" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M11 2.6665V7.99984" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M4.125 13.3335H28.875" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

          </Col>
          <Col span={22} className="show_desktop">
            <Select
              placeholder="Pick-Up Location"
              onSelect={setPickupLocation}
              suffixIcon=""
              getPopupContainer={(trigger) => trigger.parentNode}
              value={selectedPickupLocation !== null ? selectedPickupLocation.name : null}>
              {pickupLocationOptions !== null && pickupLocationOptions.map((item, index) => {
                return (
                  <Select.Option key={index} value={item.id} name={item.name} street_one={item.street_one} street_two={item.street_two} city={item.city} state={item.state} zip={item.zip}>
                    <div>{item.name !== null && item.name}</div>
                    <p className="pickup-location-subtext">{item.street_one !== null && <>{item.street_one}, </>}{item.street_two !== null && <>{item.street_two}<br /> </>}{item.city !== null && <>{item.city}, </>}{item.state !== null && <>{item.state} </>}{item.zip !== null && item.zip}</p>
                  </Select.Option>
                )
              })}
            </Select>
          </Col>
          <Col span={22} className="show_mobile">
            <div style={{ paddingLeft: 11, paddingRight: 11 }} className="ant-select ant-select-selector ant-select-selection-item" onClick={() => { showMobilePopup("pickupLocation") }}>
              {!!selectedPickupLocation && selectedPickupLocation.name ? selectedPickupLocation.name : "Pick-Up Location"}
            </div>
          </Col>
        </Row>

        <div className="fixed-button-bottom">
          <Button
            type="primary"
            htmlType="submit"
            disabled={btnDisabled}
            className="radius12 font_size_family btn_pad" onClick={handleOk}>Continue</Button>
        </div>
        <PickupByDateTime fromTime={false} dateTimeModalVisible={pickupByDateTimeVisible} setDateTimeModalVisible={setPickupByDateTimeVisible} {...props} />
        <MobilePickupInfoDrawer
          showMobilePickupDrawer={showMobilePickupDrawer}
          setMobilePickupDrawer={setMobilePickupDrawer}
          dataList={dataList}
          selectedCityState={selectedCityState}
          setCityState={setCityState}
          selectedZip={selectedZip}
          setZipCode={setZipCode}
          selectedLocation={selectedLocation}
          setLocation={setLocation}
          selectedPickupLocation={selectedPickupLocation}
          setPickupLocation={setPickupLocation}
          pickupField={pickupField}
          locationOptions={locationOptions}
          pickupLocationOptions={pickupLocationOptions} />
      </Modal>

    </>
  )
}


export default PickupByLocation


