import React from 'react';
import { Layout } from 'antd';
import './styles.scss';
import ReactHtmlParser from 'react-html-parser';


const MoreInfoVendor = (props) => {
  const { vendor } = props;
  const address = `${vendor.address1}${vendor.address2 || ""}, ${vendor.city || ""}, ${vendor.state || ""}, ${vendor.zip || ""}`
  return (
    <>
      <Layout className="more-info-vendor">
        <Layout.Header>
          {!!vendor.distributorName ? <h1 className="tab_heading">{vendor.distributorName}</h1> : <h1 className="tab_heading">{vendor.name}</h1>}
        </Layout.Header>
        <Layout.Content>
          <div>{ReactHtmlParser(vendor.biography)}</div>
        </Layout.Content>
        <div className="vendor-footer">
          <div className="address_icon">
            <svg width="30" height="39" viewBox="0 0 30 39" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.5 15.031C0.5 7.0179 7.00595 0.5 15 0.5C22.9941 0.5 29.5 7.0179 29.5 15.031C29.5 19.5281 27.1725 24.8332 22.4537 30.8133C19.0069 35.181 15.6086 38.2013 15.4736 38.3209C15.2012 38.5611 14.7956 38.5584 14.5262 38.3208C14.3896 38.1997 10.9922 35.1799 7.54627 30.8133C2.8275 24.8332 0.5 19.5281 0.5 15.031ZM7.60339 16.5392L7.60345 16.5391L7.8107 16.3611V21.3281C7.8107 22.2764 8.57808 23.0469 9.52694 23.0469H20.4731C21.4219 23.0469 22.1893 22.2764 22.1893 21.3281V16.3611L22.3965 16.5391L22.3966 16.5392C23.1138 17.1551 24.1969 17.0759 24.8168 16.3528L24.8172 16.3523C25.4335 15.6316 25.35 14.5472 24.6307 13.9295L16.1171 6.61709L16.1169 6.61695C15.4743 6.06543 14.5257 6.06543 13.8831 6.61695L13.8829 6.61709L5.36929 13.9295C5.36928 13.9296 5.36926 13.9296 5.36925 13.9296C4.65 14.5472 4.56651 15.6317 5.18276 16.3523C5.79941 17.0734 6.88334 17.1575 7.60339 16.5392ZM8.00907 16.1907C8.00897 16.1908 8.00887 16.1909 8.00876 16.191L8.00907 16.1907L7.93164 16.1006L8.00907 16.1907ZM21.9909 16.1907L21.9912 16.191C21.9911 16.1909 21.991 16.1908 21.9909 16.1907L22.0683 16.1006L21.9909 16.1907Z" fill="#2C384C" stroke="black" />
            </svg>
          </div>
          <h3 className="address_text">{address}</h3>
        </div>
      </Layout>
    </>
  )
}
export default MoreInfoVendor