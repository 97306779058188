import { fork, all } from 'redux-saga/effects'
import UserSaga from '../containers/Home/saga';
import LandingSaga from '../containers/LandingPage/saga';
import UserAccountSaga from '../containers/UserAccount/saga';
import VendorSaga from '../containers/Vendor/saga';
import ForgotPasswordSaga from '../containers/ForgotPassword/saga';
import ForgotUsernameSaga from '../containers/ForgotUsername/saga';
import OrderInfoSaga from '../containers/OrderInfo/saga';
import CheckoutSaga from '../containers/Checkout/saga';

export default function* RootSaga() {
  yield all([
    fork(UserSaga),
    fork(LandingSaga),
    fork(UserAccountSaga),
    fork(VendorSaga),
    fork(ForgotPasswordSaga),
    fork(ForgotUsernameSaga),
    fork(OrderInfoSaga),
    fork(CheckoutSaga),
  ])
}