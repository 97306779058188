/**
 * User details actions
 */
export const USER_DETAILS = 'USER_DETAILS';
export const USER_DETAILS_RESPONSE = 'USER_DETAILS_RESPONSE';

/**
 * Pickup Location actions
 */
export const PICKUP_LOCATION_REQUEST = 'PICKUP_LOCATION_REQUEST';
export const PICKUP_LOCATION_RESPONSE = 'PICKUP_LOCATION_RESPONSE';

/**
 * Pickup Dates actions
 */
export const PICKUP_DATE_REQUEST = 'PICKUP_DATE_REQUEST';
export const PICKUP_DATE_RESPONSE = 'PICKUP_DATE_RESPONSE';

/**
 * Pickup Time actions
 */
export const PICKUP_TIME_REQUEST = 'PICKUP_TIME_REQUEST';
export const PICKUP_TIME_RESPONSE = 'PICKUP_TIME_RESPONSE';

/**
 * Pickup Vendor actions
 */
export const PICKUP_VENDOR_REQUEST = 'PICKUP_VENDOR_REQUEST';
export const PICKUP_VENDOR_RESPONSE = 'PICKUP_VENDOR_RESPONSE';
export const PICKUP_VENDOR_REQUEST_WITH_INFO = 'PICKUP_VENDOR_REQUEST_WITH_INFO';
export const LOAD_MORE_VENDORS = 'LOAD_MORE_VENDORS';
export const LOAD_MORE_VENDORS_RESPONSE = 'LOAD_MORE_VENDORS_RESPONSE';
export const END_OF_RESTAURANTS_LIST = 'END_OF_RESTAURANTS_LIST';
/**
 * Filter Items actions
 */
export const FILTER_ITEM_REQUEST = 'FILTER_ITEM_REQUEST';
export const FILTER_ITEM_RESPONSE = 'FILTER_ITEM_RESPONSE';

/**
 * Apply Filter Items actions
 */
export const APPLY_FILTER_REQUEST = 'APPLY_FILTER_REQUEST';
export const APPLY_FILTER_RESPONSE = 'APPLY_FILTER_RESPONSE';

/**
 * action to store select pickup data
 */
export const DISPATCH_PICKUP_DATA = 'DISPATCH_PICKUP_DATA';
export const SAVE_PICKUP_DATA = 'SAVE_PICKUP_DATA';


/**
 * action to store seleted Filters List
 */
export const SELECTED_FILTERS_LIST = 'SELECTED_FILTERS_LIST';

/**
 * action to save selected vendor
 */
export const SAVE_SELECTED_VENDOR = 'SAVE_SELECTED_VENDOR';

/**
 * Vendor filter items actions
 */
export const VENDOR_FILTER_ITEMS_REQUEST = 'VENDOR_FILTER_ITEMS_REQUEST';
export const VENDOR_FILTER_ITEM_SELECTED = 'VENDOR_FILTER_ITEM_SELECTED';
export const VENDOR_FILTER_ITEM_UNSELECTED = 'VENDOR_FILTER_ITEM_UNSELECTED';
export const VENDOR_FILTER_ITEMS_CLEARED = 'VENDOR_FILTER_ITEMS_CLEARED';

/**
 * action to save new pickup menu data
 */
export const SAVE_NEW_PICKUP_MENU = 'SAVE_NEW_PICKUP_MENU';

/**
 * action to get program info for new pickup menu data
 */
export const GET_PROGRAM_INFO = 'GET_PROGRAM_INFO';
export const SAVE_PROGRAM_INFO = 'SAVE_PROGRAM_INFO';

/**
 * action to get branding API data
 */
export const GET_BRANDING_DETAILS = 'GET_BRANDING_DETAILS';
export const SAVE_BRANDING_DETAILS = 'SAVE_BRANDING_DETAILS';

/**
 * action to save reservation status
 */
export const SAVE_RESERVATION_STATUS = 'SAVE_RESERVATION_STATUS';
export const SAVE_ONLY_RESERVATION_AVAILABLE = 'SAVE_ONLY_RESERVATION_AVAILABLE';
export const SELECTED_B2C_TYPE = 'SELECTED_B2C_TYPE';

/**
 * action to show loader for vendor
 */
export const SHOW_VENDOR_LOADER = 'SHOW_VENDOR_LOADER';

/**
 * action to get pickup weeks for vendor
 */
export const PICKUP_WEEK_REQUEST = 'PICKUP_WEEK_REQUEST';
export const PICKUP_WEEK_RESPONSE = 'PICKUP_WEEK_RESPONSE';

/**
 * action to get pickup week slots for vendor
 */
export const PICKUP_WEEK_SLOT_REQUEST = 'PICKUP_WEEK_SLOT_REQUEST';
export const PICKUP_WEEK_SLOT_RESPONSE = 'PICKUP_WEEK_SLOT_RESPONSE';