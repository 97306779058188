import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Layout, Row, Col, Button, Typography, Modal, Select } from 'antd';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements, useStripe,
  useElements, CardNumberElement, CardExpiryElement, CardCvcElement
} from "@stripe/react-stripe-js";
import * as checkOutActions from './actions';
import './styles.scss';
import { AuthHeader, PageFooter, Map, SiderCartInfo, MobilePickupInfoDrawer } from '../../components';
import { getError } from "../../utils/commonUtils";
import moment from 'moment';
import Loader from 'react-loader-spinner';
// Cart SiderCartInfo Actions
import * as vendorActions from '../Vendor/actions'
import * as OrderActions from '../OrderInfo/action';


const Checkout = (props) => {

  const { Header, Footer, Content } = Layout;
  const history = useHistory();
  const { consumerPaymentError, clearPaymentError, cartDetails, fetchConsumerPayInfo, consumerPaymentInfo, existingPaymentMethods, fetchExistingPaymentMethods, existingPaymentError, reservationStatus, getAvailableMealBalance, mealPlanAvailableBalance } = props;

  // * Load Stripe...
  const [stripe, setStripe] = useState(null);
  const prevState = useRef({ consumerPaymentInfo }).current;
  const { id } = useParams();

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "hc") {
      if (mealPlanAvailableBalance === null || mealPlanAvailableBalance === undefined) {
        getAvailableMealBalance();
      }
      // else {
      //   console.log('meal Plan Balance***', mealPlanAvailableBalance);
      // }
    }
  }, [mealPlanAvailableBalance]);
  // Redirect to Order Info page if cart is not loaded
  useEffect(() => {
    if (
      process.env.REACT_APP_WEEKLY_CART === 'true'
      && cartDetails
      && cartDetails.cart
      && cartDetails.cart.subType === "WEEKLY"
    ) {
      if (
        cartDetails === undefined
        || cartDetails === null
        || cartDetails.err
        || !cartDetails.cart
        || !cartDetails.cart.children
        || cartDetails.cart.children.length === 0
      ) {
        history.push("/multiple-carts");
      }
    }
    else if (
      cartDetails === undefined
      || cartDetails === null
      || cartDetails.err
      || !cartDetails.items
      || cartDetails.items.length === 0
    ) {
      history.push("/multiple-carts");
    }
  }, [cartDetails]);

  useEffect(() => {
    if (consumerPaymentInfo?.accountId && !stripe) {
      const stripeLoad = loadStripe(consumerPaymentInfo.publicKey, {
        stripeAccount: consumerPaymentInfo.accountId
      })
      setStripe(stripeLoad)
    }
    if (consumerPaymentError) {
      getError("Something went wrong")
    }
  }, [consumerPaymentError, consumerPaymentInfo, stripe]);

  useEffect(() => {
    if (existingPaymentMethods && existingPaymentMethods.length === 0 && !existingPaymentError && !reservationStatus) {
      fetchExistingPaymentMethods(id)
    }
  }, []);

  useEffect(() => {
    if (existingPaymentError) {
      getError("Failed to fetch existing payment methods", 3);
    }
  }, [existingPaymentError])

  useEffect(() => {
    if (consumerPaymentError) {
      getError("Something went wrong", 4);
      return () => {
        clearPaymentError()
      }
    }
    if (cartDetails && cartDetails.cart && cartDetails.cart.id && !consumerPaymentInfo && !reservationStatus) {
      fetchConsumerPayInfo(cartDetails.cart.id);
    }
  }, [cartDetails, clearPaymentError, consumerPaymentError, consumerPaymentInfo, fetchConsumerPayInfo, reservationStatus]);

  const goToProductList = () => {
    history.push("/multiple-carts");
  }

  useEffect(() => {
    const prevInfo = prevState.consumerPaymentInfo && prevState.consumerPaymentInfo.accountId
    if (consumerPaymentInfo && consumerPaymentInfo.accountId !== prevInfo) {
      const stripe = loadStripe(
        `${consumerPaymentInfo.publicKey + consumerPaymentInfo.accountId}`
      );
      setStripe(stripe)
    }
  }, [])

  return (
    <Layout>
      <Header>
        <AuthHeader {...props} />
      </Header >
      <Content className="full_content">
        <div className="site-layout-content">
          <Row className="top_row" style={{ paddingBottom: '0' }}>
            <Col xs={24}>
              <Button ghost style={{ padding: '0', border: '0' }} onClick={goToProductList}>
                <div className="back_search"><svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22.3992 10.3496H4.68625L15.288 1.14648C15.4575 0.998145 15.3546 0.722656 15.1306 0.722656H12.4514C12.3333 0.722656 12.2213 0.765039 12.1335 0.840722L0.69318 10.7674C0.588399 10.8582 0.504366 10.9705 0.446774 11.0966C0.389181 11.2228 0.359375 11.3598 0.359375 11.4985C0.359375 11.6372 0.389181 11.7742 0.446774 11.9003C0.504366 12.0265 0.588399 12.1388 0.69318 12.2296L12.2001 22.2168C12.2455 22.2562 12.3 22.2773 12.3575 22.2773H15.1276C15.3516 22.2773 15.4545 21.9988 15.285 21.8535L4.68625 12.6504H22.3992C22.5324 12.6504 22.6414 12.5414 22.6414 12.4082V10.5918C22.6414 10.4586 22.5324 10.3496 22.3992 10.3496Z" fill="black" />
                </svg> {process.env.REACT_APP_ENV === "hc" || !props.reservationStatus ? "Order Information" : "Reservation Information"}
                </div>
              </Button>
            </Col>
            <Col xs={24} className="our_order_div" style={{ marginTop: '10px' }}>
              {!!cartDetails && cartDetails.cart && cartDetails.cart.distributor && <h1 className="our_order mb-10">{cartDetails.cart.distributor.name || "McDonalds Asia Prix"}</h1>}
            </Col>
          </Row>

          <Row className="restaurant_items" gutter={{ xs: 0, sm: 30, md: 30, lg: 30 }}>
            <Col xs={24} md={15} lg={18}>
              <Row>
                <Col xs={24} md={24} lg={24}>
                  <h2 className="Sub_Heading">{props.reservationStatus ? "Location" : "Pick-Up Location"}</h2>
                </Col>
                <Col xs={24} md={24} lg={24}>
                  <div className="map_box">
                    <Map {...props} />
                  </div>
                </Col>
              </Row>
              {/* Card Details Component */}
              {!reservationStatus ? !!stripe && <Elements stripe={stripe}>
                <CheckoutForm {...props} />
              </Elements> : <ReservationForm {...props} />}
            </Col>

            <Col xs={24} md={9} lg={6}>
              <SiderCartInfo cartDistributorId={id} {...props} view="checkout-page" />
            </Col>
          </Row>
        </div>
      </Content>
      <Footer>
        <PageFooter {...props} />
      </Footer>
    </Layout>
  )
}

const CheckoutForm = ({ userDetails, consumerPaymentInfo, getUserDetails, cartDetails, setPaymentInfo, existingPaymentMethods, mealPlanAvailableBalance, submitMealPlanOrderRequest, mealPlanOrderResponse }) => {
  const history = useHistory();
  const { id } = useParams();

  // Load User Info
  const formatedUserAddress = ((userData) => {
    const userAddress = userData?.customer_address;
    let address = !!userAddress ?
      `${userAddress.streetOne !== null ? userAddress.streetOne + ", " : ""} ${userAddress.streetTwo !== null ? userAddress.streetTwo + ", " : ""} ${userAddress.city !== null ? userAddress.city + ", " : ""} ${userAddress.state !== null ? userAddress.state + ", " : ""} ${userAddress.zip !== null ? userAddress.zip : ""}`
      : "";
    return address
  })(userDetails);

  const [userInfo, setUserInfo] = useState({
    name: userDetails?.name || "",
    email: userDetails?.email || "",
    phone: userDetails?.customer_contact?.phone || "",
    address: formatedUserAddress,
    street1: userDetails?.customer_address?.streetOne || "",
    street2: userDetails?.customer_address?.streetTwo || "",
    city: userDetails?.customer_address?.city || "",
    state: userDetails?.customer_address?.state || "",
    zipcode: userDetails?.customer_address?.zip || ""
  });

  // Handle User Info Errors
  const [paymentResponse, setPaymentResponse] = useState({})
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [paymentValidationErrors, setPaymentValidationErrors] = useState({})
  const [existingPaymentMethodSelected, setExistingPaymentMethodSelected] = useState("");
  const [showMobilePickupDrawer, setMobilePickupDrawer] = useState(false);
  const [dataList, setDataList] = useState(null);
  const [pickupField, setPickupField] = useState("existing method");
  const [mealPlanSelected, setMealPlanSelected] = useState(false);

  useEffect(() => {
    if (!userDetails) {
      getUserDetails();
    }
    if (!!userDetails) {
      setUserInfo({
        name: userDetails.name,
        email: userDetails?.email,
        phone: userDetails?.customer_contact?.phone,
        address: formatedUserAddress,
        street1: userDetails?.customer_address?.streetOne,
        street2: userDetails?.customer_address?.streetTwo,
        city: userDetails?.customer_address?.city,
        state: userDetails?.customer_address?.state,
        zipcode: userDetails?.customer_address?.zip
      })
    }
  }, [userDetails, getUserDetails, formatedUserAddress]);

  const setUserInfoData = (e) => {
    let updatedData = { ...userInfo };
    updatedData[e.target.name] = e.target.value;
    setUserInfo(updatedData);
  }

  // Stripe Stuff
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    if (!stripe && !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    const userBillingInfo = {
      address: {
        line1: userInfo.address,
      },
      email: userInfo.email,
      name: userInfo.name,
      phone: userInfo.phone
    }
    // Get a reference to a mounted CardElement. Which have cardInfo
    const cardElement = elements.getElement(CardNumberElement);

    const { clientSecret } = consumerPaymentInfo;
    setPaymentProcessing(true);
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: userBillingInfo,
      }
    });
    if (payload) setPaymentProcessing(false);
    setPaymentResponse(payload);

    if (!payload.error) {
      setPaymentInfo({ paymentResponse: { ...payload }, orderListItems: cartDetails })
      history.push("/confirm-order")
    } else {
      // getError("Something Went Wrong", 4);
    }

  };

  const setCardInfo = ({ elementType, error }) => {
    // Handle Card Errors
    let currentErrors = { ...paymentValidationErrors }
    if (error) {
      currentErrors[elementType] = error.message
      setPaymentValidationErrors(currentErrors);
    } else {
      delete currentErrors[elementType]
      setPaymentValidationErrors(currentErrors)
    }
  }

  const createOptions = (fontSize, padding) => {
    return {
      style: {
        base: {
          fontSize,
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
          padding,
        },
        invalid: {
          color: '#9e2146',
        },
      },
      showIcon: true
    };
  };

  const setSelectedPaymentMethod = (item) => {
    if (item === "mealPlan") {
      setMealPlanSelected(true);
    } else {
      setMealPlanSelected(false);
      setExistingPaymentMethodSelected(JSON.parse(item));
    }
  }

  const payWithExistingMethod = async () => {

    if (!stripe && !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const { clientSecret } = consumerPaymentInfo;
    const paymentMethodId = existingPaymentMethodSelected.paymentMethodId;
    const cardCvcElement = elements.getElement(CardCvcElement);
    setPaymentProcessing(true);
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: paymentMethodId,
      payment_method_options: {
        card: {
          cvc: cardCvcElement
        }
      },
    });
    if (payload) setPaymentProcessing(false);
    setPaymentResponse(payload);

    if (!payload.error) {
      setPaymentInfo({ paymentResponse: { ...payload }, orderListItems: cartDetails })
      history.push("/confirm-order")
    } else {
      // getError("Something Went Wrong", 4);
    }
  }

  const getBtnDisabled = (() => {
    if (mealPlanSelected) {
      if (userInfo?.phone === "") {
        return true
      }
      else {
        return false
      }
    }
    else if (!!existingPaymentMethodSelected) {

      return elements?.getElement(CardCvcElement)?._empty;
    } else {
      return !stripe
        || !elements
        || Object.keys(paymentValidationErrors).length > 0
        || elements?.getElement(CardCvcElement)?._empty
        || elements?.getElement(CardExpiryElement)?._empty
        || elements?.getElement(CardNumberElement)?._empty
        || paymentProcessing
    }
  })();

  const showMobileDrawer = () => {
    setDataList(existingPaymentMethods);
    setMobilePickupDrawer(true);
  }

  const submitMealPlanOrder = () => {
    setPaymentProcessing(true);
    const params = {
      cartId: id
    }
    submitMealPlanOrderRequest(params);
  }

  useEffect(() => {
    if (!!mealPlanOrderResponse) {
      setPaymentProcessing(false);
      if (!!mealPlanOrderResponse.err) {
        getError(mealPlanOrderResponse.err);
      }
      else {
        setPaymentInfo({ orderListItems: cartDetails })
        history.push("/confirm-order");
      }
    }
  }, [mealPlanOrderResponse])

  return (
    <Form
      name="user-payment-info-form"
      onFinish={mealPlanSelected ? submitMealPlanOrder : (!!existingPaymentMethodSelected ? payWithExistingMethod : handleSubmit)}
      initialValues={{ remember: true }}
    >
      {/* payment failure message */}
      <>
        <Modal
          className="order-modal"
          className="payment-confirmation-model"
          footer={null}
          visible={!!paymentResponse?.error}
          onCancel={() => setPaymentResponse(preState => ({ ...preState, error: null }))}
        >
          <Row className="confimation-model-msg">
            Invalid Payment Method. Please verify payment details or use a different payment method!
          </Row>
          {!!paymentResponse?.error?.message &&
            <Row className="confimation-model-msg">
              <Typography.Text type="warning">
                {paymentResponse.error.message}
              </Typography.Text>
            </Row>
          }
        </Modal>
      </>
      {/* <Row> */}
      <Col xs={24} md={24} lg={24}>
        <Form.Item className="form-item inputicon">
          <Input
            placeholder="Pick-Up Date and Time"
            value={!!cartDetails?.cart?.pickup ? moment(cartDetails.cart.pickup).format("dddd, MMMM Do, YYYY h:mm a") : ""}
            prefix={
              <img
                alt="user-icon"
                src={require("../../assets/images/checkout-clock.png")}
                height={22}
                width={22}
              />
            }
            className="inputStyle"
          />
        </Form.Item>
      </Col>

      {/* Existing payment Methods option */}
      {existingPaymentMethods && existingPaymentMethods.length > 0 &&
        <Col xs={24} md={24} lg={24}>
          <Form.Item className="form-item inputicon show_desktop">
            <label className="input_label">Payment Method (card or meal plan)</label>
            <Select
              placeholder="Choose an existing card for payment."
              onSelect={setSelectedPaymentMethod}
              showArrow
              className="existing-method-select"
            >
              {!!existingPaymentMethodSelected && <Select.Option>Pay with a different card</Select.Option>}
              {process.env.REACT_APP_ENV === "hc" && !!mealPlanAvailableBalance && !mealPlanAvailableBalance.err &&
                <Select.Option value="mealPlan">Meal Plan A ( $ {mealPlanAvailableBalance.balance})</Select.Option>}
              {existingPaymentMethods.map((item, index) => {
                return (
                  <Select.Option key={index} value={JSON.stringify(item)}>{`**** **** **** ${item.card.last4}`}</Select.Option>
                )
              })}
            </Select>
            <img
              alt="user-icon"
              src={require("../../assets/images/credit-card.png")}
              height={30}
              width={30}
              className="select-card-icon"
            />
          </Form.Item>
          <Form.Item className="form-item inputicon show_mobile">
            <label className="input_label">Payment Method (card or meal plan)</label>
            <div className="mobile-existing-method" onClick={showMobileDrawer} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <img
                alt="user-icon"
                src={require("../../assets/images/credit-card.png")}
                height={30}
                width={30}
                className="select-card-icon"
              />
              <div className="ant-select-selection-placeholder" style={{ display: 'flex', alignItems: 'center' }}>
                {mealPlanSelected ? `Meal Plan A ( $ ${mealPlanAvailableBalance.balance})` : !!existingPaymentMethodSelected ?
                  `**** **** **** ${existingPaymentMethodSelected.card.last4}` : "Choose an existing card."}

              </div>
            </div>
          </Form.Item>
          <MobilePickupInfoDrawer
            showMobilePickupDrawer={showMobilePickupDrawer}
            setMobilePickupDrawer={setMobilePickupDrawer}
            dataList={dataList}
            pickupField={pickupField}
            selectedExistingMethod={existingPaymentMethodSelected}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
            mealPlanAvailableBalance={mealPlanAvailableBalance}
            mealPlanSelected={mealPlanSelected}
            setMealPlanSelected={setMealPlanSelected} />
        </Col>
      }

      {!existingPaymentMethodSelected && !mealPlanSelected && <Col xs={24} md={24} lg={24}>
        <Form.Item className="form-item inputicon" style={{ marginTop: '0' }}>
          <Input
            placeholder="Name on Card"
            name="name"
            value={userInfo.name}
            onChange={setUserInfoData}
            required
            prefix={
              <img
                alt="user-icon"
                src={require("../../assets/images/checkout-user.png")}
                height={22}
                width={22}
              />
            }
            className="inputStyle"
          />
        </Form.Item>
      </Col>}

      {!existingPaymentMethodSelected && !mealPlanSelected && <Col xs={24} md={24} lg={24}>
        <Form.Item className="form-item inputicon" style={{ marginTop: '0' }}>
          <Input
            placeholder="Address 1"
            name="street1"
            value={userInfo.street1}
            required
            onChange={setUserInfoData}
            prefix={
              <img
                alt="user-icon"
                src={require("../../assets/images/checkout-Address.png")}
                height={20}
                width={20}
              />
            }
            className="inputStyle"
          />
        </Form.Item>
      </Col>}

      {!existingPaymentMethodSelected && !mealPlanSelected && <Col xs={24} md={24} lg={24}>
        <Form.Item className="form-item inputicon" style={{ marginTop: '0' }}>
          <Input
            placeholder="Address 2"
            name="street2"
            value={userInfo.street2}
            onChange={setUserInfoData}
            prefix={
              <img
                alt="user-icon"
                src={require("../../assets/images/checkout-Address.png")}
                height={20}
                width={20}
              />
            }
            className="inputStyle"
          />
        </Form.Item>
      </Col>}

      {!existingPaymentMethodSelected && !mealPlanSelected && <Col xs={24} md={24} lg={24}>
        <Form.Item className="form-item inputicon" style={{ marginTop: '0' }}>
          <Input
            placeholder="City"
            name="city"
            value={userInfo.city}
            required
            onChange={setUserInfoData}
            prefix={
              <img
                alt="user-icon"
                src={require("../../assets/images/checkout-Address.png")}
                height={20}
                width={20}
              />
            }
            className="inputStyle"
          />
        </Form.Item>
      </Col>}

      {!existingPaymentMethodSelected && !mealPlanSelected && <Col xs={24} md={24} lg={24}>
        <Form.Item className="form-item inputicon" style={{ marginTop: '0' }}>
          <Input
            placeholder="State"
            name="state"
            value={userInfo.state}
            required
            onChange={setUserInfoData}
            prefix={
              <img
                alt="user-icon"
                src={require("../../assets/images/checkout-Address.png")}
                height={20}
                width={20}
              />
            }
            className="inputStyle"
          />
        </Form.Item>
      </Col>}

      {!existingPaymentMethodSelected && !mealPlanSelected && <Col xs={24} md={24} lg={24}>
        <Form.Item className="form-item inputicon" style={{ marginTop: '0' }}>
          <Input
            placeholder="Zipcode"
            name="zipcode"
            value={userInfo.zipcode}
            required
            onChange={setUserInfoData}
            prefix={
              <img
                alt="user-icon"
                src={require("../../assets/images/checkout-Address.png")}
                height={20}
                width={20}
              />
            }
            className="inputStyle"
          />
        </Form.Item>
      </Col>}

      <Col xs={24} md={24} lg={24}>
        <label className="input_label">Contact Info</label>
        <Form.Item
          className="form-item inputicon"
          style={{ marginTop: '0' }}
        >
          <Input
            placeholder="Phone"
            name="phone"
            type="tel"
            pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
            value={userInfo.phone}
            required
            onChange={setUserInfoData}
            prefix={
              <img
                alt="user-icon"
                src={require("../../assets/images/checkout-contact.png")}
                height={22}
                width={22}
              />
            }
          />
        </Form.Item>
      </Col>

      {!existingPaymentMethodSelected && !mealPlanSelected && <Col xs={24} md={24} lg={24}>
        <label className="input_label">Card Number</label>
        <Form.Item
          className="form-item"
          style={{ marginTop: '0' }}
        >
          <CardNumberElement onChange={setCardInfo} options={createOptions('18px')} />
          {!!paymentValidationErrors.cardNumber && <Typography.Text type="danger">{paymentValidationErrors.cardNumber || "Card invalid error"}</Typography.Text>}
        </Form.Item>
      </Col>}

      <Col xs={24} md={24} lg={24}>
        <Row gutter={{ xs: 30, sm: 30, md: 30, lg: 30 }}>
          {!mealPlanSelected && <Col xs={12} md={12} lg={12}>
            <label className="input_label">CVV Code</label>
            <Form.Item
              style={{ marginTop: '0' }}
            >
              <CardCvcElement onChange={setCardInfo} className="stripe-items-with-icon" />
              <img src={require("../../assets/images/cvv.png")} alt="" className="stripe-items-icon" />
              {!!paymentValidationErrors.cardCvc && <Typography.Text type="danger">{paymentValidationErrors.cardCvc || "CVV code error"}</Typography.Text>}
            </Form.Item>
          </Col>}
          {!existingPaymentMethodSelected && !mealPlanSelected && <Col xs={12} md={12} lg={12}>
            <label className="input_label">Expiry Date</label>
            <Form.Item
              style={{ marginTop: '0' }}
            >
              <CardExpiryElement onChange={setCardInfo} className="stripe-items-with-icon" />
              <img src={require("../../assets/images/cvv.png")} alt="" className="stripe-items-icon" />
              {!!paymentValidationErrors.cardExpiry && <Typography.Text type="danger">{paymentValidationErrors.cardExpiry || "Invalid Expiry Date"}</Typography.Text>}
            </Form.Item>
          </Col>}
        </Row>
      </Col>

      <Col xs={24} md={24} lg={24} justify="end">
        <Loader
          type="TailSpin"
          height={50}
          width={50}
          color="#2C384C"
          visible={paymentProcessing}
          style={{ margin: "20px", marginLeft: "auto", width: "140px" }}
        />
        <button
          htmltype="submit"
          className="ant-btn ant-btn-primary checkout"
          disabled={getBtnDisabled}
        >
          <span>Confirm</span>
        </button>

      </Col>
    </Form>
  )
}

const ReservationForm = (props) => {
  const { cartDetails, confirmReservation, userDetails, reservationConfirmation, clearReservationError, setPaymentInfo } = props;
  const { id } = useParams();
  const history = useHistory();
  const [reservationProcessing, setReservationProcessing] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);

  const formatedUserAddress = ((userData) => {
    const userAddress = userData?.customer_address;
    let address = !!userAddress ?
      `${userAddress.streetOne !== null ? userAddress.streetOne + ", " : ""} ${userAddress.streetTwo !== null ? userAddress.streetTwo + ", " : ""} ${userAddress.city !== null ? userAddress.city + ", " : ""} ${userAddress.state !== null ? userAddress.state + ", " : ""} ${userAddress.zip !== null ? userAddress.zip : ""}`
      : "";
    return address
  })(userDetails);

  const [userInfo, setUserInfo] = useState({
    name: userDetails?.name || "",
    email: userDetails?.email || "",
    phone: userDetails?.customer_contact?.phone || "",
    address: formatedUserAddress,
    street1: userDetails?.customer_address?.streetOne || "",
    street2: userDetails?.customer_address?.streetTwo || "",
    city: userDetails?.customer_address?.city || "",
    state: userDetails?.customer_address?.state || "",
    zipcode: userDetails?.customer_address?.zip || ""
  });

  useEffect(() => {
    if (userInfo?.phone !== "") {
      setBtnDisabled(false);
    }
    else {
      setBtnDisabled(true)
    }
  }, [userInfo]);

  const setUserInfoData = (e) => {
    let updatedData = { ...userInfo };
    updatedData[e.target.name] = e.target.value;
    setUserInfo(updatedData);
  }

  const submitReservation = () => {
    setReservationProcessing(true);
    confirmReservation(id);
  }

  useEffect(() => {
    if (reservationConfirmation !== undefined && reservationConfirmation !== null) {
      setReservationProcessing(false);
      if (reservationConfirmation.err) {
        getError(reservationConfirmation.err);
        clearReservationError();
      } else {
        setPaymentInfo({ orderListItems: cartDetails })
        history.push("/confirm-order");
      }
    }
  }, [reservationConfirmation]);

  return (
    <Form
      name="user-payment-info-form"
      onFinish={submitReservation}
      initialValues={{ remember: true }}
    >
      <Col xs={24} md={24} lg={24}>
        <Form.Item className="form-item inputicon">
          <Input
            placeholder="Pick-Up Date and Time"
            value={!!cartDetails?.cart?.pickup ? moment(cartDetails.cart.pickup).format("dddd, MMMM Do, YYYY h:mm a") : ""}
            prefix={
              <img
                alt="user-icon"
                src={require("../../assets/images/checkout-clock.png")}
                height={22}
                width={22}
              />
            }
            className="inputStyle"
          />
        </Form.Item>
      </Col>


      <Col xs={24} md={24} lg={24}>
        <label className="input_label">Contact Info</label>
        <Form.Item
          className="form-item inputicon"
          style={{ marginTop: '0' }}
        >
          <Input
            placeholder="Phone"
            name="phone"
            type="tel"
            pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
            value={userInfo.phone}
            required
            onChange={setUserInfoData}
            prefix={
              <img
                alt="user-icon"
                src={require("../../assets/images/checkout-contact.png")}
                height={22}
                width={22}
              />
            }
          />
        </Form.Item>
      </Col>

      <Col xs={24} md={24} lg={24} justify="end">
        <Loader
          type="TailSpin"
          height={50}
          width={50}
          color="#2C384C"
          visible={reservationProcessing}
          style={{ margin: "20px", marginLeft: "auto", width: "140px" }}
        />
        <button
          htmltype="submit"
          className="ant-btn ant-btn-primary checkout"
          disabled={btnDisabled}
        >
          <span>Confirm</span>
        </button>
      </Col>
    </Form>
  )
}

const mapStateToProps = (state) => {
  return {
    orderLocatioDetails: state.OrderReducer.orderLocatioDetails,
    userDetails: state.UserReducer.userDetails,
    consumerPaymentInfo: state.CheckoutReducer.consumerPaymentInfo,
    consumerPaymentError: state.CheckoutReducer.consumerPaymentError,
    existingPaymentMethods: state.CheckoutReducer.existingPaymentMethods,
    existingPaymentError: state.CheckoutReducer.existingPaymentError,
    newPickupMenuData: state.UserReducer.newPickupMenuData,
    // SideCartInfo props
    cartDistributor: state.VendorReducer.cartDistributor,
    cartDetails: state.VendorReducer.cartDetails,
    selectedVendorData: state.UserReducer.selectedVendorData,
    updateCartResponse: state.OrderReducer.updateCartResponse,
    deleteCartResponse: state.OrderReducer.deleteCartResponse,
    brandingDetails: state.UserReducer.brandingDetails,
    reservationStatus: state.UserReducer.reservationStatus,
    reservationConfirmation: state.CheckoutReducer.reservationConfirmation,
    mealPlanAvailableBalance: state.CheckoutReducer.mealPlanAvailableBalance,
    mealPlanOrderResponse: state.CheckoutReducer.mealPlanOrderResponse,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPaymentInfo: (paymentSuccessRes) => dispatch({ type: checkOutActions.SET_PAYMENT_SUCCESS_RESPONSE, paymentSuccessRes }),
    clearPaymentError: () => dispatch({ type: checkOutActions.CLEAR_CHECKOUT_ERROR }),
    fetchConsumerPayInfo: (cartId) => dispatch({ type: checkOutActions.GET_CONSUMER_PAYMENT_INFO, cartId }),
    fetchExistingPaymentMethods: (cartId) => dispatch({ type: checkOutActions.GET_EXISTING_PAYMENT_METHODS, cartId }),
    getUserDetails: () => dispatch({ type: "USER_DETAILS" }),
    // SideCartInfo props
    getCartDistributor: () => dispatch({ type: vendorActions.GET_CART_DISTRIBUTOR_DETAILS }),
    getCartDetails: (params) => dispatch({ type: vendorActions.GET_CART_DETAILS, params }),
    updateCart: (params) => dispatch({ type: OrderActions.UPDATE_CART_REQUEST, params }),
    saveOrderDetailsLocation: (params) => dispatch({ type: OrderActions.SAVE_ORDER_LOCATION_DETAILS, params }),
    deleteCart: (params) => dispatch({ type: OrderActions.DELETE_CART_REQUEST, params }),
    confirmReservation: (cartId) => dispatch({ type: checkOutActions.GET_RESERVATION_CONFIRMATION, cartId }),
    clearReservationError: () => dispatch({ type: checkOutActions.SAVE_RESERVATION_CONFIRMATION, reservationConfirmation: null }),
    getAvailableMealBalance: () => dispatch({ type: checkOutActions.MEAL_PLAN_AVAILABLE_BALANCE_REQUEST }),
    submitMealPlanOrderRequest: (params) => dispatch({ type: checkOutActions.MEAL_PLAN_ORDER_SUBMIT_REQUEST, params })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);