import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { Form, Input, Button, Modal } from 'antd';
import ForgotPassword from '../ForgotPassword';
import ForgotUsername from '../ForgotUsername';
import './styles.scss';
import Cookies from 'js-cookie';

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 24 },
};

const tailLayout = {
	wrapperCol: { offset: 0, span: 24 },
};

const ForgotPasswordModal = (props) => {
	return (
		<Modal
			destroyOnClose={true}
			visible={props.visible}
			onCancel={() => props.setForgotPasswordModal(false)}
			maskStyle={{ backgroundColor: 'rgb(44 56 76 / 92%)' }}
			footer={null}
		>
			<ForgotPassword {...props} />
		</Modal>
	)
}

const ForgotUsernameModal = (props) => {
	return (
		<Modal
			destroyOnClose={true}
			visible={props.visible}
			onCancel={() => props.setForgotUsernameModal(false)}
			maskStyle={{ backgroundColor: 'rgb(44 56 76 / 92%)' }}
			footer={null}
		>
			<ForgotUsername {...props} />
		</Modal>
	)
}

const Login = ({ submitLogin, loginResponse, loginError, loginLoading, openModal, props, changeLoginError, setLoading }) => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [openForgotPassword, setForgotPasswordModal] = useState(false);
	const [openForgotUsername, setForgotUsernameModal] = useState(false);
	const [btnDisabled, setBtnDisabled] = useState(true);

	const openForgotModal = (openForgotPassword = false, openForgotUsername = false) => {
		setForgotPasswordModal(openForgotPassword);
		setForgotUsernameModal(openForgotUsername);
	}

	const history = useHistory();

	useEffect(() => {
		if (username === '' || password === '') {
			setBtnDisabled(true);
		}
		else {
			setBtnDisabled(false);
		}
	}, [username, password])

	useEffect(() => {
		if (loginResponse && loginResponse.access_token) {
			Cookies.set("token", loginResponse.access_token, { expires: 7 });
			if (Cookies.get('token')) {
				history.push("/");
			}
		}
	}, [history, loginResponse]);

	const changeUsername = (e) => {
		changeLoginError();
		setUsername(e.target.value);
	}

	const changePassword = (e) => {
		changeLoginError();
		setPassword(e.target.value);
	}

	const onFinish = values => {
		setLoading();
		const params = {
			'username': username,
			'password': password,
			'programId': localStorage.programId
		}
		submitLogin(params);
	};

	const onFinishFailed = errorInfo => {
		console.log(errorInfo);
	};

	return (
		<Fragment>
			<div className="page-title" style={{ paddingTop: 15 }}>
				<div> Login </div>
			</div>
			<Form {...layout}
				name="basic"
				initialValues={{ remember: true }}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed} >
				<Form.Item name="username"
					rules={[{ required: true, message: 'Please input your username!' }]
					}
					className="form-item inputicon">
					<Input
						placeholder="Username"
						prefix={
							<img
								alt="user-icon"
								src={require("../../assets/images/user.png")}
								height={22}
								width={22}
							/>
						}
						className="inputStyle"
						onChange={changeUsername}
					/>
				</Form.Item>

				<Form.Item
					name="password"
					rules={
						[{ required: true, message: 'Please input your password!' }]
					}
					className="form-item inputicon" >
					<Input.Password
						placeholder="Password"
						prefix={
							<img
								alt="lock-icon"
								src={require("../../assets/images/lock.png")}
								height={20}
								width={20}
							/>
						}
						className="inputStyle"
						type="password"
						onChange={changePassword}
					/>
				</Form.Item>
				{loginError &&
					<div className="login-error-div" >
						<div className="login-error-label">{loginError}</div>
					</div>
				}
				<div className="text-center" >
					<Form.Item {...tailLayout}
						className="submit-button lonin_button" >
						<Loader
							type="TailSpin"
							color="#00BFFF"
							height={50}
							width={50}
							color="#2C384C"
							visible={loginLoading}
							style={{ margin: '10px' }}
							timeout={6000}
						/>
						<Button disabled={btnDisabled}
							type="primary"
							htmlType="submit"
							className="radius12 font_size_family btn_pad"
						>
							Log in
						</Button>
					</Form.Item >
				</div>
				<div className="signup-link-div" >
					<div className="signup-link-label" > Do not have an account ? {" "}
						<Link className="signup-link-button" onClick={() => openModal(false, true)} to="#">Register</Link>
					</div>
				</div>


				<div className="signup-link-div" >
					<Link className="reset-link" onClick={() => setForgotPasswordModal(true)} to="#">Forgot your password?</Link>&nbsp;·&nbsp;
					<Link className="reset-link" onClick={() => setForgotUsernameModal(true)} to="#">Forgot your username?</Link>
				</div>

				<ForgotPasswordModal
					{...props}
					visible={openForgotPassword}
					setForgotPasswordModal={setForgotPasswordModal}
					openForgotModal={openForgotModal}
				/>
				<ForgotUsernameModal
					{...props}
					visible={openForgotUsername}
					setForgotUsernameModal={setForgotUsernameModal}
					openForgotModal={openForgotModal}
				/>

			</Form>
		</Fragment>
	);
}

export default Login;