import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Row,
  Col,
  Layout,
  Spin
} from 'antd';
import Loader from "react-loader-spinner"
import { useHistory } from 'react-router-dom';
import { calculateDecimal, getError } from '../../utils/commonUtils';
import moment from "moment";
import { cloneDeep } from 'lodash';

const OrderHistoryAccount = (props) => {
  const { getMyOrderDetails, myOrderResponse, setSelectedOrderDetail, isLoadingMore, endOfHistoryList, loadMoreOrderDetails, clearRepeatResponse, reservationStatus, clearMyOrderResponse, clearMealPlanBalance } = props;
  const [loading, setLoading] = useState(true);
  const [reservation, setReservation] = useState(false);
  const history = useHistory();
  const currentPageOrderCount = useRef(0);

  useEffect(() => {
    if (myOrderResponse === null) {
    }
    else {
      if (myOrderResponse.err && loading) {
        getError(myOrderResponse.err);
        clearMyOrderResponse();
      }
      setLoading(false);
    }
  }, [myOrderResponse]);

  useEffect(() => {
    currentPageOrderCount.current = 0;
    if (reservationStatus === undefined || reservationStatus === null || !reservationStatus) {
      const params = {
        offset: currentPageOrderCount.current,
        max: 5,
        b2cType: "ORDERING"
      }
      getMyOrderDetails(params);
    }
    else {
      const params = {
        offset: currentPageOrderCount.current,
        max: 5,
        b2cType: "RESERVATION"
      }
      getMyOrderDetails(params);
    }
    currentPageOrderCount.current = currentPageOrderCount.current + 5
  }, [reservationStatus]);

  useEffect(() => {
    if (reservationStatus === undefined || reservationStatus === null || !reservationStatus) {
      setReservation(false);
    }
    else {
      setReservation(true);
    }
  }, [reservationStatus]);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "hc") {
      clearMealPlanBalance();
    }
  }, []);

  const handleLoadMore = () => {
    if (reservationStatus === undefined || reservationStatus === null || !reservationStatus) {
      const params = {
        offset: currentPageOrderCount.current,
        max: 5,
        b2cType: "ORDERING"
      }
      loadMoreOrderDetails(params);
    }
    else {
      const params = {
        offset: currentPageOrderCount.current,
        max: 5,
        b2cType: "RESERVATION"
      }
      loadMoreOrderDetails(params);
    }

    currentPageOrderCount.current = currentPageOrderCount.current + 5
  }

  const goToDetails = (item) => {
    clearRepeatResponse();
    setSelectedOrderDetail(item);
    history.push(`/history/${item.id}`);
  }

  const renderHistory = (item, index, parent = null) => {
    return (
      <Row key={index} className="myorder_list" gutter={{ xs: 0, md: 40 }}>
        <Col xs={24} md={8}>
          <img alt="mail-icon" src={item.distributor.logo} className="myorder_pic" />
        </Col>
        <Col xs={24} md={16}>
          <Row>
            <Col xs={24} md={20}>
              <div className="order_title">{item.distributor.name}</div>
              <div className="order_description">
                {item.items && item.items.length > 0 &&
                  item.items.map((product, index) => {
                    return (
                      <p key={index}>{product.quantity ? product.quantity : ''} x {product.name ? product.name : ''}<br /> </p>
                    )
                  })}
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="order_date">{moment(item.submissionDt).format("MM/DD/YYYY")}</div>
            </Col>
            <Col xs={12} md={4}>
              {!reservation && <div className="order_price_mobile">$ {calculateDecimal(item.totalSellCharge)}</div>}
            </Col>
          </Row>
          <Row className="price_button">
            <Col xs={24} md={8}>
              {!reservation && <div className="order_price">$ {calculateDecimal(item.totalSellCharge)}</div>}
            </Col>
            <Col xs={24} md={16}>
              <Button type="primary" htmlType="button" className="repeat_your_order" onClick={() => goToDetails(parent ? parent : item)}>
                {process.env.REACT_APP_ENV === "hc" || !reservation ? "Order Details" : "Reservation Details"}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  return (
    <>
      <Layout>
        <Row>
          <Col span={24}>
            {process.env.REACT_APP_ENV === "hc" || !reservation ? <h1 className="tab_heading">Order History</h1> : <h1 className="tab_heading">Reservation History</h1>}
          </Col>
        </Row>
        <Spin spinning={loading} size="large" >
          <Row className="order_list">
            <Col span={24}>
              {myOrderResponse !== null && myOrderResponse !== undefined && !myOrderResponse.err && myOrderResponse.length > 0 ? (
                <>
                  {myOrderResponse.map((item, index) => {
                    if (item.subType === 'WEEKLY' && process.env.REACT_APP_WEEKLY_CART === 'true') {
                      if (item.children && item.children.length > 0) {
                        const tempCartDetail = cloneDeep(item);
                        tempCartDetail.items = [];
                        item.children.map(child => {
                          if (child.items && child.items.length > 0) {
                            child.items.map(childItem => {
                              tempCartDetail.items.push(childItem)
                            })
                          }
                        });
                        return renderHistory(tempCartDetail, index, item);
                      }
                    }
                    else {
                      return renderHistory(item, index)
                    }
                  })}
                  {isLoadingMore && <Row justify="center">
                    <Loader
                      type="TailSpin"
                      height={50}
                      width={50}
                      color="#2C384C"
                      visible={isLoadingMore}
                      style={{ margin: "auto", marginBottom: "20px" }}
                    />
                  </Row>}
                  {!endOfHistoryList && <Row justify="center">
                    <Button
                      onClick={handleLoadMore}
                      disabled={isLoadingMore || endOfHistoryList}
                      className="ant-btn-primary load-more-btn"
                    >Load More...</Button>
                  </Row>}
                </>
              ) : (
                  <>

                    <Row>
                      <Col span={24} className="ordered_items order_information">
                        {process.env.REACT_APP_ENV === "hc" || !reservation ? <h3 style={{ color: '#929A9A', textAlign: 'center', margin: '15%' }}>No Order History</h3> : <h3 style={{ color: '#929A9A', textAlign: 'center', margin: '15%' }}>No Reservation History</h3>}
                      </Col>
                    </Row>
                  </>
                )}
            </Col>
          </Row>
        </Spin>
      </Layout>
    </>
  )
}

export default OrderHistoryAccount;