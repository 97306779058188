import * as actionTypes from './actions';

const initialState = {
  userDetails: null,
  pickupLocationDetails: null,
  pickupDates: null,
  pickupTimes: null,
  vendorsList: null,
  filtersList: null,
  selectPickupData: null,
  selectedFiltersList: null,
  selectedVendorData: null,
  newPickupMenuData: null,
  programInfo: null,
  brandingDetails: null,
  reservationStatus: null,
  onlyReservationAvailable: null,
  selectedB2cType: null,
  showVendorLoading: true,
  vendorsListEnded: false,
  pickupWeeks: null,
  pickupWeekSlot: null,
}

const UserReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.USER_DETAILS_RESPONSE:
      return { ...state, userDetails: action.saveUserDetails }

    case actionTypes.PICKUP_LOCATION_RESPONSE:
      return { ...state, pickupLocationDetails: action.pickupLocationDetails }

    case actionTypes.PICKUP_DATE_RESPONSE:
      return { ...state, pickupDates: action.pickupDates }

    case actionTypes.PICKUP_TIME_RESPONSE:
      return { ...state, pickupTimes: action.pickupTimes }

    case actionTypes.PICKUP_VENDOR_RESPONSE:
      return { ...state, vendorsList: action.pickupVendors }

    case actionTypes.FILTER_ITEM_RESPONSE:
      return { ...state, filtersList: action.filterItems }

    case actionTypes.APPLY_FILTER_RESPONSE:
      return { ...state, vendorsList: action.applyFilters }

    case actionTypes.SAVE_PICKUP_DATA:
      return { ...state, selectPickupData: action.pickupData }

    case actionTypes.SELECTED_FILTERS_LIST:
      return { ...state, selectedFiltersList: action.params }

    case actionTypes.SAVE_SELECTED_VENDOR:
      return { ...state, selectedVendorData: action.selectedVendor }

    case actionTypes.SAVE_NEW_PICKUP_MENU:
      return { ...state, newPickupMenuData: action.data }

    case actionTypes.SAVE_PROGRAM_INFO:
      return { ...state, programInfo: action.programInfo }

    case actionTypes.SAVE_BRANDING_DETAILS:
      return { ...state, brandingDetails: action.brandingDetails }

    case actionTypes.SAVE_RESERVATION_STATUS:
      return { ...state, reservationStatus: action.reservationStatus }

    case actionTypes.SAVE_ONLY_RESERVATION_AVAILABLE:
      return { ...state, onlyReservationAvailable: true }

    case actionTypes.SELECTED_B2C_TYPE:
      return { ...state, selectedB2cType: action.selectedB2cType }

    case actionTypes.SHOW_VENDOR_LOADER:
      return { ...state, showVendorLoading: action.showLoader };

    case actionTypes.LOAD_MORE_VENDORS_RESPONSE:
      return { ...state, vendorsList: [...state.vendorsList, ...action.pickupVendors] }

    case actionTypes.END_OF_RESTAURANTS_LIST:
      return { ...state, vendorsListEnded: action.vendorsListEnded }

    case actionTypes.PICKUP_WEEK_RESPONSE:
      return { ...state, pickupWeeks: action.pickupWeeks }

    case actionTypes.PICKUP_WEEK_SLOT_RESPONSE: {
      return { ...state, pickupWeekSlot: action.pickupWeekSlot }
    }

    default:
      return state;
  }
}

export default UserReducer;