import React from 'react';
import { Row, Col, Layout } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import './style.scss';
const OrderHistoryMoreInfo = (props) => {
  const { selectedOrderDetail } = props;
  return (
    <>
      {selectedOrderDetail !== undefined && selectedOrderDetail !== null &&
        <Layout>
          <Row>
            <Col span={24} style={{ marginTop: 20 }}>
              {selectedOrderDetail !== undefined && selectedOrderDetail !== null && selectedOrderDetail.distributor && selectedOrderDetail.distributor.name &&
                <h2 className="modal_heading">{selectedOrderDetail.distributor.name}</h2>
              }
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {selectedOrderDetail.distributor && selectedOrderDetail.distributor.biography !== null &&
                <div>
                  {ReactHtmlParser(selectedOrderDetail.distributor.biography)}
                </div>
              }

            </Col>
          </Row>
        </Layout>
      }
    </>
  )
}

export default OrderHistoryMoreInfo;