import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Row, Col, Layout, message, Modal, Spin } from 'antd';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { AuthHeader, PageFooter, AddtoOrder } from '../../components';
import './style.scss';
import * as OrderActions from './action';
import * as vendorActions from '../../containers/Vendor/actions';
import * as homeActions from '../../containers/Home/actions';
import { calculateDecimal, getError, changeTimetoAMPMFormat, getFormattedAddressLine3, getFormattedAddress } from '../../utils/commonUtils';
import moment from "moment";

const AddtoOrderModal = (props) => {
  return (
    <Modal
      className="order-modal"
      visible={props.visible}
      onCancel={() => props.setAddtoOrderModal(false)}
      maskStyle={{ backgroundColor: 'rgb(44 56 76 / 92%)' }}
      footer={null}
    >
      <AddtoOrder {...props} />
    </Modal>
  )
}

const OrderInfo = (props) => {
  const { Header, Footer, Content } = Layout;
  const history = useHistory();
  const { cartDetails, selectedVendorData, getCartDistributor, getCartDetails, cartDistributor, deleteCart, deleteCartResponse, updateCart, updateCartResponse, saveOrderDetailsLocation, clearDeleteCartDetails, reservationStatus, getUserDetails, brandingDetails, saveReservationStatus, saveOnlyReservationAvailable, clearCartDistributor } = props;
  const [moreInfoVisible, setMoreInfoVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [subtotal, setSubTotal] = useState(null);
  const [taxTotal, setTaxTotal] = useState(null);
  const { id } = useParams();
  const [reservation, setReservation] = useState(false);
  const [addressLine3, setAddressLine3] = useState('');
  const prevState = useRef({ cartDetails }).current;

  useEffect(() => {
    if (cartDistributor !== null && !cartDistributor.err) {
      if (cartDistributor.length > 0) {
        getCartDetails({ cartId: id });
      }
      else {
        setLoading(false);
        history.push("/multiple-carts");
      }
    }
    else {
      history.push("/multiple-carts");
    }
  }, [cartDistributor]);

  useEffect(() => {
    if (updateCartResponse !== null) {
      if (updateCartResponse.err) {
        setLoading(false);
        getError(updateCartResponse.err);
      }
      else {
        getCartDistributor();
      }
    }
  }, [updateCartResponse]);

  useEffect(() => {
    if (deleteCartResponse !== null && !deleteCartResponse.err) {
      clearCartDistributor();
      clearDeleteCartDetails();
      history.push("/multiple-carts");
    }
  }, [deleteCartResponse]);

  useEffect(() => {
    if (cartDetails !== null) {
      setLoading(false);
      if (!cartDetails.err) {
        if (process.env.REACT_APP_WEEKLY_CART === 'true' && cartDetails.cart && cartDetails.cart.subType === 'WEEKLY') {
          if (cartDetails.cart.children && cartDetails.cart.children.length === 0) {
            message.info({
              content: 'No items found in your cart',
              duration: 2
            });
          } else if (cartDetails.cart.children && cartDetails.cart.children.length > 0) {
            let subTotalOfItems = 0;
            let taxtTotalOfItems = 0;
            cartDetails.cart.children.map(cartDetail => {
              if (cartDetail.items && cartDetail.items.length > 0) {
                cartDetail.items.map(dayDetail => {
                  subTotalOfItems = subTotalOfItems + dayDetail.subTotal;
                  taxtTotalOfItems = taxtTotalOfItems + dayDetail.salesTax;
                })
              }
            })
            setSubTotal(subTotalOfItems);
            setTaxTotal(taxtTotalOfItems);
          }
          if (cartDetails.cart) {
            if (cartDetails.cart.b2cType === "RESERVATION") {
              saveReservationStatus(true);
            } else {
              saveReservationStatus(false);
            }

            if (cartDetails.cart.pickupLocation && cartDetails.cart.pickupLocation.address) {
              setAddressLine3(getFormattedAddressLine3(cartDetails.cart.pickupLocation.address));
              saveOrderDetailsLocation(getFormattedAddress(cartDetails.cart.pickupLocation.address));
            } else {
              saveOrderDetailsLocation('');
            }
          }
        }
        else {
          if (!cartDetails.items) {
            message.info({
              content: 'No items found in your cart',
              duration: 2
            });
          }
          else {
            if (cartDetails.items.length > 0) {
              let subTotalOfItems = 0;
              let taxtTotalOfItems = 0;
              cartDetails.items.map((item, index) => {
                subTotalOfItems = subTotalOfItems + item.subTotal;
                taxtTotalOfItems = taxtTotalOfItems + item.salesTax;
              });
              setSubTotal(subTotalOfItems);
              setTaxTotal(taxtTotalOfItems);
            }
          }

          if (cartDetails.cart) {
            if (cartDetails.cart.b2cType === "RESERVATION") {
              saveReservationStatus(true);
            } else {
              saveReservationStatus(false);
            }

            if (cartDetails.cart.pickupLocation && cartDetails.cart.pickupLocation.address) {
              setAddressLine3(getFormattedAddressLine3(cartDetails.cart.pickupLocation.address));
              saveOrderDetailsLocation(getFormattedAddress(cartDetails.cart.pickupLocation.address));
            } else {
              saveOrderDetailsLocation('');
            }
          }
        }
      }
      else {
        if (cartDetails !== prevState.cartDetails) {
          getError(cartDetails.err);
        }
      }
    }
  }, [cartDetails]);

  useEffect(() => {
    if (reservationStatus === undefined || reservationStatus === null || !reservationStatus) {
      setReservation(false);
    }
    else {
      setReservation(true);
    }
  }, [reservationStatus]);

  const goToProductList = () => {
    if (selectedVendorData === undefined || selectedVendorData === null) {
      history.push("/");
    }
    else {
      history.push({
        pathname: `/shop/${selectedVendorData.distributorId}`
      });
    }
  }

  const goBackToMultipleCarts = () => {
    if (cartDistributor !== null && !cartDistributor.err && cartDistributor.length > 1) {
      history.push("/multiple-carts")
    }
    else {
      if (selectedVendorData === undefined || selectedVendorData === null) {
        history.push("/");
      }
      else {
        history.push({
          pathname: `/shop/${selectedVendorData.distributorId}`
        });
      }
    }
  }

  const sendDeleteCartRequest = () => {
    setLoading(true);
    deleteCart({ cartId: id });
  }

  const changeQuantity = (item, type) => {
    setLoading(true);
    if (item !== null) {
      if (type === 'plus') {
        let ing = [];
        if (!!item.ingredients && item.ingredients.length > 0) {
          item.ingredients.map((cartIngredient, index) => {
            if (!!cartIngredient.items && cartIngredient.items.length > 0) {
              cartIngredient.items.map((ingInCart, index) => {
                let item = { id: ingInCart.id, quantity: ingInCart.quantity }
                if (ing.indexOf(item) === -1) {
                  ing.push(item);
                }
              })
            }
          })
        }
        const params = {
          id: item.id,
          image: item.image,
          itemTotal: item.itemTotal,
          productName: item.productName,
          quantity: item.quantity + 1,
          salesTax: item.salesTax,
          sellPrice: item.sellPrice,
          skuId: item.skuId,
          subTotal: item.subTotal,
          ingredients: ing
        }
        updateCart(params);
      }
      if (type === 'minus' && item.quantity > 0) {
        let ing = [];
        if (!!item.ingredients && item.ingredients.length > 0) {
          item.ingredients.map((cartIngredient, index) => {
            if (!!cartIngredient.items && cartIngredient.items.length > 0) {
              cartIngredient.items.map((ingInCart, index) => {
                let item = { id: ingInCart.id, quantity: ingInCart.quantity }
                if (ing.indexOf(item) === -1) {
                  ing.push(item);
                }
              })
            }
          })
        }
        const params = {
          id: item.id,
          image: item.image,
          itemTotal: item.itemTotal,
          productName: item.productName,
          quantity: item.quantity - 1,
          salesTax: item.salesTax,
          sellPrice: item.sellPrice,
          skuId: item.skuId,
          subTotal: item.subTotal,
          ingredients: ing
        }
        updateCart(params);
      }
    }
  }

  const removeItem = (item, cartLength) => {
    if (cartLength === 1) {
      sendDeleteCartRequest();
    }
    else {
      setLoading(true);
      const params = {
        id: item.id,
        image: item.image,
        itemTotal: item.itemTotal,
        productName: item.productName,
        quantity: item.quantity - item.quantity,
      }
      updateCart(params);
    }
  }

  const updateIngredients = (params, item) => {
    setLoading(true);
    const updateCartParams = {
      id: params.itemId,
      image: params.image,
      ingredients: params.ingredients || [],
      productName: params.productName,
      quantity: params.quantity,
      skuId: params.skuId,
      itemTotal: item.itemTotal,
      salesTax: item.salesTax,
      sellPrice: item.sellPrice,
      subTotal: item.subTotal
    }
    updateCart(updateCartParams)
  }

  const showMoreInfo = (item) => {
    setSelectedOrder(item);
    setMoreInfoVisible(true);
  }

  useEffect(() => {
    if (brandingDetails === undefined || brandingDetails === null) {
      getUserDetails();
    }
    else {
      if (!brandingDetails.err) {
        if (brandingDetails.guides && brandingDetails.guides.length > 0) {

          if (brandingDetails.guides.length === 1) {

            if (brandingDetails.guides[0].b2cType) {
              if (brandingDetails.guides[0].b2cType === "RESERVATION") {
                saveReservationStatus(true);
                saveOnlyReservationAvailable();
              }
            }
          }
        }
      }
    }
  }, [brandingDetails]);

  const renderCart = (cartDetails, orderIndex) => {
    return (
      cartDetails !== undefined
        && cartDetails !== null
        && !cartDetails.err
        && cartDetails.items
        && cartDetails.items.length > 0
        ? (
          <Col span={24} key={orderIndex}>
            {process.env.REACT_APP_WEEKLY_CART === "true" &&
              <Row justify="center" align="middle">
                <Col span={24} align="middle">
                  <div className="weekday_div">
                    {moment.utc(cartDetails.cart.pickup).format('dddd, MMMM DD')}
                  </div>
                </Col>
              </Row>
            }
            {
              cartDetails.items.map((item, index) => {
                return (
                  <Col span={24} className="ordered_items order_information" key={index}>
                    <Row>
                      <Col xs={20} md={22}>
                        <div className="ordered_item">{item.productName}</div>
                      </Col>
                      <Col xs={4} md={2} style={{ textAlign: 'right' }}>
                        <button className="remove_ordered_item" onClick={() => removeItem(item, cartDetails.items.length)}>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.4">
                              <path d="M12 4L4 12" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M4 4L12 12" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                          </svg>
                        </button>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={20} md={22}>
                        <Row align="middle">
                          <Col xs={24} md={8} style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ marginRight: 7 }}>
                              <img src={require('../../assets/images/order_info.png')} onClick={() => showMoreInfo(item)} />
                            </div>
                            <div style={{ marginRight: 5 }}>
                              <img src={require('../../assets/images/remove_item.png')} onClick={() => { changeQuantity(item, 'minus') }} />
                            </div>
                            <div >
                              <img src={require('../../assets/images/add_item.png')} onClick={() => { changeQuantity(item, 'plus') }} />
                            </div>
                            <div className="ordered_qua" style={{ display: 'inline-block' }}>Quantity: <span>{item.quantity}</span></div>
                          </Col>
                        </Row>
                      </Col>
                      {!reservation && <Col xs={4} md={2}>
                        <div className="ordered_price">${calculateDecimal(item.subTotal)}</div>
                      </Col>}
                    </Row>
                  </Col>
                );
              })
            }
            {
              cartDetails !== undefined && cartDetails !== null && !cartDetails.err && cartDetails.cart && cartDetails.items && cartDetails.items.length > 0 && !reservation &&
              <><Col span={24} className="ordered_items">
                <Row>
                  <Col xs={12} md={12}>
                    <div className="ordered_total">Subtotal</div>
                  </Col>
                  <Col xs={12} md={12}>
                    <div className="ordered_cost">$ {calculateDecimal(subtotal)}</div>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 20 }}>
                  <Col xs={12} md={12}>
                    <div className="ordered_total">Tax</div>
                  </Col>
                  <Col xs={12} md={12}>
                    <div className="ordered_cost">$ {calculateDecimal(taxTotal)}</div>
                  </Col>
                </Row>
              </Col>
                <Col span={24} style={{ paddingTop: '20px' }}>
                  <Row>
                    <Col xs={12} md={12}>
                      <div className="ordered_total">Total:</div>
                    </Col>
                    <Col xs={12} md={12}>
                      <div className="ordered_cost">$ {calculateDecimal(cartDetails.cart.orderTotal)}</div>
                    </Col>
                  </Row>
                </Col>
              </>
            }
          </Col>
        ) : null
    )
  }

  return (
    <>
      <Layout>
        <Header>
          <AuthHeader {...props} />
        </Header >
        <Content className="full_content">
          <div className="site-layout-content">
            <Row className="top_row">
              <Col xs={24} md={18} lg={20}>
                <Row>
                  <Button ghost onClick={goBackToMultipleCarts}>
                    <Col xs={24} className="show_desktop">
                      <div className="back_search"><svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.3992 10.3496H4.68625L15.288 1.14648C15.4575 0.998145 15.3546 0.722656 15.1306 0.722656H12.4514C12.3333 0.722656 12.2213 0.765039 12.1335 0.840722L0.69318 10.7674C0.588399 10.8582 0.504366 10.9705 0.446774 11.0966C0.389181 11.2228 0.359375 11.3598 0.359375 11.4985C0.359375 11.6372 0.389181 11.7742 0.446774 11.9003C0.504366 12.0265 0.588399 12.1388 0.69318 12.2296L12.2001 22.2168C12.2455 22.2562 12.3 22.2773 12.3575 22.2773H15.1276C15.3516 22.2773 15.4545 21.9988 15.285 21.8535L4.68625 12.6504H22.3992C22.5324 12.6504 22.6414 12.5414 22.6414 12.4082V10.5918C22.6414 10.4586 22.5324 10.3496 22.3992 10.3496Z" fill="black" />
                      </svg> Order Information
        </div>
                    </Col>
                  </Button>
                  <Col xs={24} className="our_order_div">
                    <h1 className="our_order">Your Order</h1>
                    {cartDetails !== undefined && cartDetails !== null && !cartDetails.err && cartDetails.items && cartDetails.items.length > 0 &&
                      <p className="show_desktop content-sub-heading" style={{ lineHeight: 1 }}>Click on the info icon below to view order customization.</p>}
                  </Col>
                </Row>
              </Col>
              <Col xs={24} md={6} lg={4}>
                <Row>
                  <Col xs={24}>
                    {process.env.REACT_APP_ENV === "hc" ? <h2 className="Sub_Heading">Order Info</h2> : (!reservation ? <h2 className="Sub_Heading">Pickup Info</h2> : <h2 className="Sub_Heading">Reservation Info</h2>)}
                  </Col>
                  {cartDetails !== undefined && cartDetails !== null && !cartDetails.err && cartDetails.cart &&
                    <>
                      {process.env.REACT_APP_ENV === "hc" && !!cartDetails.cart.customer && !!cartDetails.cart.customer.name &&
                        <Col xs={24}>
                          <span className="pickup_label">Student Name</span>
                          <span className="location_restraurant">{cartDetails.cart.customer.name}</span>
                        </Col>
                      }
                      <Col xs={24}>
                        {!reservation && <span className="pickup_label">Pickup</span>}
                        {
                          process.env.REACT_APP_WEEKLY_CART === 'true'
                            && cartDetails.cart.subType === 'WEEKLY'
                            ? cartDetails.cart.pickup && <span className="pickup_time">Week of {moment.utc(cartDetails.cart.pickup).format('MMMM DD')} - {moment.utc(cartDetails.cart.pickup).add(6, 'days').format('MMMM DD')}</span>
                            : cartDetails.cart.pickup && <span className="pickup_time">{moment(cartDetails.cart.pickup).format("MM/DD/YYYY h:mm A")}</span>}
                      </Col>
                      <Col xs={24}>
                        <span className="location_label">At</span>
                        {cartDetails.cart.pickupLocation && cartDetails.cart.pickupLocation.name && <span className="location_restraurant">{cartDetails.cart.pickupLocation.name},</span>}
                        {cartDetails.cart.pickupLocation && cartDetails.cart.pickupLocation.address && cartDetails.cart.pickupLocation.address.streetOne && <span className="location_restraurant">{cartDetails.cart.pickupLocation.address.streetOne},</span>}
                        {cartDetails.cart.pickupLocation && cartDetails.cart.pickupLocation.address && cartDetails.cart.pickupLocation.address.streetTwo && <span className="location_restraurant">{cartDetails.cart.pickupLocation.address.streetTwo},</span>}
                        {addressLine3 && <span className="location_restraurant">{addressLine3}</span>}
                      </Col>
                    </>}
                </Row>
                {cartDetails !== undefined && cartDetails !== null && !cartDetails.err && cartDetails.items && cartDetails.items.length > 0 &&
                  <p className="show_mobile content-sub-heading" style={{ lineHeight: 1 }}>Click on the info icon below to view order customization.</p>}

              </Col>
            </Row>

            <Spin spinning={loading} size="large" >
              {
                (cartDistributor !== undefined && cartDistributor !== null && !cartDistributor.err && cartDistributor.length > 0) ? (
                  <>
                    <Row className="our_order_list">
                      {
                        cartDetails !== undefined
                          && cartDetails !== null
                          && !cartDetails.err
                          ? cartDetails.items
                            && cartDetails.items.length > 0
                            ? renderCart(cartDetails, 1)
                            : process.env.REACT_APP_WEEKLY_CART === "true"
                            && cartDetails.cart.subType === "WEEKLY"
                            && cartDetails.cart.children
                            && cartDetails.cart.children.length > 0
                            && cartDetails.cart.children.map((cartDetail, index) => renderCart(cartDetail, index))
                          : null
                      }
                    </Row>
                    {
                      cartDetails !== undefined
                        && cartDetails !== null
                        && !cartDetails.err
                        && cartDetails.cart
                        && cartDetails.items
                        && cartDetails.items.length > 0
                        ? (<>
                          <Row className="mt-40">
                            <Col span={24} className="cart_shopping">
                              <Button
                                type="primary"
                                htmlType="button"
                                className="checkout"
                                onClick={() => { history.push(`/checkout/${id}`) }}
                              >
                                {!reservation ? "Checkout" : "Submit"}
                              </Button>

                              <Button htmlType="button" className="shopping" onClick={goToProductList}>
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g clipPath="url(#clip0)">
                                    <path d="M9 22.3503C9.55228 22.3503 10 21.9105 10 21.3679C10 20.8253 9.55228 20.3854 9 20.3854C8.44772 20.3854 8 20.8253 8 21.3679C8 21.9105 8.44772 22.3503 9 22.3503Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M20 22.3503C20.5523 22.3503 21 21.9105 21 21.3679C21 20.8253 20.5523 20.3854 20 20.3854C19.4477 20.3854 19 20.8253 19 21.3679C19 21.9105 19.4477 22.3503 20 22.3503Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M1 1.71881H5L7.68 14.8739C7.77144 15.3262 8.02191 15.7325 8.38755 16.0217C8.75318 16.3109 9.2107 16.4645 9.68 16.4557H19.4C19.8693 16.4645 20.3268 16.3109 20.6925 16.0217C21.0581 15.7325 21.3086 15.3262 21.4 14.8739L23 6.63109H6" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0">
                                      <rect width="24" height="23.5789" fill="white" transform="translate(0 0.736328)" />
                                    </clipPath>
                                  </defs>
                                </svg>
                            Continue Shopping
                          </Button>
                              <Button htmlType="button" className="delete_cart" onClick={sendDeleteCartRequest}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M3 6H5H21" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M10 11V17" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M14 11V17" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            Delete Cart
                          </Button>
                            </Col>
                          </Row>
                        </>) : (
                          cartDetails !== undefined
                            && cartDetails !== null
                            && !cartDetails.err
                            && process.env.REACT_APP_WEEKLY_CART === "true"
                            && cartDetails.cart.subType === "WEEKLY"
                            && cartDetails.cart.children
                            && cartDetails.cart.children.length > 0
                            ? (<>
                              <Row className="mt-40">
                                <Col span={24} className="cart_shopping">
                                  <Button
                                    type="primary"
                                    htmlType="button"
                                    className="checkout"
                                    onClick={() => { history.push(`/checkout/${id}`) }}
                                  >
                                    {!reservation ? "Checkout" : "Submit"}
                                  </Button>

                                  <Button htmlType="button" className="shopping" onClick={goToProductList}>
                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g clipPath="url(#clip0)">
                                        <path d="M9 22.3503C9.55228 22.3503 10 21.9105 10 21.3679C10 20.8253 9.55228 20.3854 9 20.3854C8.44772 20.3854 8 20.8253 8 21.3679C8 21.9105 8.44772 22.3503 9 22.3503Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M20 22.3503C20.5523 22.3503 21 21.9105 21 21.3679C21 20.8253 20.5523 20.3854 20 20.3854C19.4477 20.3854 19 20.8253 19 21.3679C19 21.9105 19.4477 22.3503 20 22.3503Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M1 1.71881H5L7.68 14.8739C7.77144 15.3262 8.02191 15.7325 8.38755 16.0217C8.75318 16.3109 9.2107 16.4645 9.68 16.4557H19.4C19.8693 16.4645 20.3268 16.3109 20.6925 16.0217C21.0581 15.7325 21.3086 15.3262 21.4 14.8739L23 6.63109H6" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0">
                                          <rect width="24" height="23.5789" fill="white" transform="translate(0 0.736328)" />
                                        </clipPath>
                                      </defs>
                                    </svg>
                              Continue Shopping
                            </Button>
                                  <Button htmlType="button" className="delete_cart" onClick={sendDeleteCartRequest}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M3 6H5H21" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                      <path d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                      <path d="M10 11V17" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                      <path d="M14 11V17" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                              Delete Cart
                            </Button>
                                </Col>
                              </Row>
                            </>) :
                            <>
                              <Row>
                                <Col span={24} className="ordered_items order_information">
                                  <h3 style={{ color: '#929A9A', textAlign: 'center', margin: '15%' }}>Your Shopping Cart is empty.</h3>
                                </Col>

                                <Col span={24} className="ordered_items order_information">
                                  <Button htmlType="button" className="shopping" onClick={goToProductList}>
                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g clipPath="url(#clip0)">
                                        <path d="M9 22.3503C9.55228 22.3503 10 21.9105 10 21.3679C10 20.8253 9.55228 20.3854 9 20.3854C8.44772 20.3854 8 20.8253 8 21.3679C8 21.9105 8.44772 22.3503 9 22.3503Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M20 22.3503C20.5523 22.3503 21 21.9105 21 21.3679C21 20.8253 20.5523 20.3854 20 20.3854C19.4477 20.3854 19 20.8253 19 21.3679C19 21.9105 19.4477 22.3503 20 22.3503Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M1 1.71881H5L7.68 14.8739C7.77144 15.3262 8.02191 15.7325 8.38755 16.0217C8.75318 16.3109 9.2107 16.4645 9.68 16.4557H19.4C19.8693 16.4645 20.3268 16.3109 20.6925 16.0217C21.0581 15.7325 21.3086 15.3262 21.4 14.8739L23 6.63109H6" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0">
                                          <rect width="24" height="23.5789" fill="white" transform="translate(0 0.736328)" />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  Continue Shopping
                                </Button>
                                </Col>
                              </Row>
                            </>)
                    }
                  </>
                ) : (
                    <Row>
                      <Col span={24} className="ordered_items order_information">
                        <h3 style={{ color: '#929A9A', textAlign: 'center', margin: '15%' }}>Your Shopping Cart is empty.</h3>
                      </Col>

                      <Col span={24} className="ordered_items order_information">
                        <Button htmlType="button" className="shopping" onClick={goToProductList}>
                          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0)">
                              <path d="M9 22.3503C9.55228 22.3503 10 21.9105 10 21.3679C10 20.8253 9.55228 20.3854 9 20.3854C8.44772 20.3854 8 20.8253 8 21.3679C8 21.9105 8.44772 22.3503 9 22.3503Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M20 22.3503C20.5523 22.3503 21 21.9105 21 21.3679C21 20.8253 20.5523 20.3854 20 20.3854C19.4477 20.3854 19 20.8253 19 21.3679C19 21.9105 19.4477 22.3503 20 22.3503Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M1 1.71881H5L7.68 14.8739C7.77144 15.3262 8.02191 15.7325 8.38755 16.0217C8.75318 16.3109 9.2107 16.4645 9.68 16.4557H19.4C19.8693 16.4645 20.3268 16.3109 20.6925 16.0217C21.0581 15.7325 21.3086 15.3262 21.4 14.8739L23 6.63109H6" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                            <defs>
                              <clipPath id="clip0">
                                <rect width="24" height="23.5789" fill="white" transform="translate(0 0.736328)" />
                              </clipPath>
                            </defs>
                          </svg>
                          Continue Shopping
                        </Button>
                      </Col>
                    </Row>
                  )
              }
            </Spin>
            {cartDetails && cartDetails.cart && moreInfoVisible &&
              <AddtoOrderModal
                {...props}
                addToCart={updateIngredients}
                visible={moreInfoVisible}
                setAddtoOrderModal={setMoreInfoVisible}
                product={selectedOrder}
                page="orderinfo"
                newPickupMenuData={{ dateSelected: cartDetails.cart.pickup.substring(0, cartDetails.cart.pickup.indexOf("T")), timeSelected: changeTimetoAMPMFormat(cartDetails.cart.pickup) }}
              />
            }
          </div>
        </Content>
        <Footer>
          <PageFooter {...props} />
        </Footer>
      </Layout>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    cartDetails: state.VendorReducer.cartDetails,
    selectedVendorData: state.UserReducer.selectedVendorData,
    cartDistributor: state.VendorReducer.cartDistributor,
    addToCartResponse: state.VendorReducer.addToCartResponse,
    productsList: state.VendorReducer.productsList,
    deleteCartResponse: state.OrderReducer.deleteCartResponse,
    updateCartResponse: state.OrderReducer.updateCartResponse,
    orderLocatioDetails: state.OrderReducer.orderLocatioDetails,
    brandingDetails: state.UserReducer.brandingDetails,
    reservationStatus: state.UserReducer.reservationStatus,
    newPickupMenuData: state.UserReducer.newPickupMenuData,
    onlyReservationAvailable: state.UserReducer.onlyReservationAvailable
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    getCartDistributor: () => dispatch({ type: vendorActions.GET_CART_DISTRIBUTOR_DETAILS }),
    getCartDetails: (params) => dispatch({ type: vendorActions.GET_CART_DETAILS, params }),
    addToCart: (params) => dispatch({ type: vendorActions.ADD_TO_CART, params }),
    deleteCart: (params) => dispatch({ type: OrderActions.DELETE_CART_REQUEST, params }),
    updateCart: (params) => dispatch({ type: OrderActions.UPDATE_CART_REQUEST, params }),
    saveOrderDetailsLocation: (params) => dispatch({ type: OrderActions.SAVE_ORDER_LOCATION_DETAILS, params }),
    clearDeleteCartDetails: () => dispatch({ type: OrderActions.DELETE_CART_RESPONSE, deleteCartResponse: null }),
    getUserDetails: () => dispatch({ type: homeActions.USER_DETAILS }),
    saveReservationStatus: (params) => dispatch({ type: homeActions.SAVE_RESERVATION_STATUS, reservationStatus: params }),
    saveOnlyReservationAvailable: () => dispatch({ type: homeActions.SAVE_ONLY_RESERVATION_AVAILABLE }),
    clearCartDistributor: () => dispatch({ type: vendorActions.SAVE_CART_DISTRIBUTOR_DETAILS, cartDistributor: null })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderInfo);