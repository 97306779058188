import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Select, DatePicker, Button, message } from 'antd';
import moment from 'moment';
import { getError } from '../../utils/commonUtils';
import RepeatItemsNotAvailable from '../RepeatItemsNotAvailable';
import './styles.scss';
import RepeatItemsAnotherCart from '../RepeatItemsAnotherCart';
import { MobilePickupInfoDrawer } from '../../components';

const RepeatOrderPickupInfo = (props) => {
  const { showPickupInfo, setShowPickupInfo, selectedOrderDetail, getPickupDates, getPickupTimes, pickupDates, pickupTimes, repeatOrder, repeatOrderResponse, getCartDetails, id, getCartDistributor, reservationStatus, clearPickupDates, clearPickupTimes } = props;
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [showItemsNotAvailable, setShowItemsNotAvailable] = useState(false);
  const [showAnotherCartInfo, setShowAnotherCartInfo] = useState(false);
  const [dateSelected, setDateSelected] = useState(null);
  const [timeSelected, setTimeSelected] = useState(null);
  const [selectedPickupDateTime, setPickupDateTime] = useState(null);
  const [showMobilePickupDrawer, setMobilePickupDrawer] = useState(false);
  const [dataList, setDataList] = useState(null);
  const [pickupField, setPickupField] = useState("date");

  useEffect(() => {
    if (showPickupInfo) {
      if (selectedOrderDetail !== undefined && selectedOrderDetail !== null && selectedOrderDetail.distributor && selectedOrderDetail.distributor.id) {
        if (reservationStatus) {
          const params = {
            b2cType: "RESERVATION",
            pickupLocation: selectedOrderDetail.pickupLocation.id,
            childLocation: selectedOrderDetail.distributor.id
          }
          getPickupDates(params);
        }
        else {
          const params = {
            b2cType: "ORDERING",
            pickupLocation: selectedOrderDetail.pickupLocation.id,
            childLocation: selectedOrderDetail.distributor.id
          }
          getPickupDates(params);
        }
      }
    }
  }, [reservationStatus, selectedOrderDetail, showPickupInfo]);

  useEffect(() => {
    setBtnDisabled(!dateSelected || !timeSelected);
  }, [dateSelected, timeSelected]);

  useEffect(() => {
    if (pickupDates !== null) {
      if (pickupDates.err && showPickupInfo) {
        getError(pickupDates.err);
        clearPickupDates();
      }
    }
  }, [pickupDates, showPickupInfo]);

  useEffect(() => {
    if (pickupTimes !== null) {
      if (pickupTimes.err && showPickupInfo) {
        getError(pickupTimes.err);
        clearPickupTimes();
      }
    }
  }, [pickupTimes, showPickupInfo]);


  useEffect(() => {
    if (repeatOrderResponse !== null) {
      if (repeatOrderResponse.err) {
        if (repeatOrderResponse.err.message && repeatOrderResponse.err.message === "Some order items are not available anymore") {
          setShowItemsNotAvailable(true);
        }
        else if (repeatOrderResponse.err === "There is another shopping cart for distributor") {
          setShowAnotherCartInfo(true);
        }
        else {
          getError(repeatOrderResponse.err, 2);
          setDateSelected(null);
          setTimeSelected(null);
          setShowPickupInfo(false);

        }
      }
      else {
        if (repeatOrderResponse.cartId) {
          message.success({
            content: 'Item added to cart successfully.',
            duration: 2
          });
          getCartDistributor();
          getCartDetails({ cartId: repeatOrderResponse.cartId });
          setDateSelected(null);
          setTimeSelected(null);
        }
        setShowPickupInfo(false);
      }
    }
  }, [repeatOrderResponse]);

  const handleCancel = () => {
    setShowPickupInfo(false);
  }

  const handleOk = () => {
    const pickupDateTime = moment(dateSelected).format("YYYY-MM-DD") + 'T' + moment(timeSelected, 'H:mm a').format('HH:mm');
    setPickupDateTime(pickupDateTime);
    const params = {
      "pickupDateTime": pickupDateTime
    }
    repeatOrder(id, params);
  }

  const disabledDate = (current) => {
    let arrOfDay = [];
    if (pickupDates !== null && !pickupDates.err) {
      let customDate = null;
      pickupDates.map((item) => {
        customDate = item.gmtDate.substring(0, item.gmtDate.indexOf('T'));
        arrOfDay.push(customDate);
      })
    }
    let index = arrOfDay.findIndex(date => date === moment(current).format('YYYY-MM-DD'))
    return index === -1 && true
  }
  const setDate = (date, dateString) => {
    setDateSelected(date);
    if (selectedOrderDetail !== undefined && selectedOrderDetail !== null && selectedOrderDetail.distributor && selectedOrderDetail.distributor.id) {
      if (reservationStatus) {
        const params = {
          pickupLocation: selectedOrderDetail.pickupLocation.id,
          date: moment(date).format("YYYY-MM-DD"),
          b2cType: "RESERVATION",
          childLocation: selectedOrderDetail.distributor.id
        }
        getPickupTimes(params);
      }
      else {
        const params = {
          pickupLocation: selectedOrderDetail.pickupLocation.id,
          date: moment(date).format("YYYY-MM-DD"),
          b2cType: "ORDERING",
          childLocation: selectedOrderDetail.distributor.id
        }
        getPickupTimes(params);
      }
    }
  }
  const setTime = (time) => {
    setTimeSelected(time);
  }

  const showMobilePopup = (field) => {
    if (field === "date" && !!disabledDate) {
      setDataList(pickupDates);
      setMobilePickupDrawer(true);
      setPickupField(field);
    }
    else if (field === "time") {
      setDataList(pickupTimes);
      setMobilePickupDrawer(true);
      setPickupField(field)
    }
  }

  return (
    <>
      <Modal
        className="order-modal"
        title="Select a date"
        className="order-modal"
        visible={showPickupInfo}
        footer={null}
        onCancel={handleCancel}>
        {selectedOrderDetail !== undefined && selectedOrderDetail !== null && selectedOrderDetail.pickupLocation &&
          <Row className="rowborder">
            <Col span={2} className="pickup_icon">
              <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <path className="cls-1" d="M16,30a1,1,0,0,1-.71-.29L7.68,22.09A11.77,11.77,0,1,1,24.32,5.45a11.76,11.76,0,0,1,0,16.64l-7.61,7.62A1,1,0,0,1,16,30ZM16,4A9.77,9.77,0,0,0,9.09,20.68L16,27.59l6.91-6.91h0A9.77,9.77,0,0,0,16,4Z" />
                <path className="cls-1" d="M16,18a5,5,0,1,1,5-5A5,5,0,0,1,16,18Zm0-8a3,3,0,1,0,3,3A3,3,0,0,0,16,10Z" />
              </svg>

            </Col>
            <Col span={22}>
              {selectedOrderDetail.pickupLocation.name && <div className="history-location-div">{selectedOrderDetail.pickupLocation.name}</div>}
              <p className="search-card-subtext">{selectedOrderDetail.pickupLocation.streetOne && <>{selectedOrderDetail.pickupLocation.streetOne}, </>}{selectedOrderDetail.pickupLocation.streetTwo && <>{selectedOrderDetail.pickupLocation.streetTwo}, </>}{selectedOrderDetail.pickupLocation.city !== null && <>{selectedOrderDetail.pickupLocation.city}, </>}{selectedOrderDetail.pickupLocation.state !== null && selectedOrderDetail.pickupLocation.state} {selectedOrderDetail.pickupLocation.zip !== null && selectedOrderDetail.pickupLocation.zip}</p>
            </Col>
          </Row>}

        <Row className="rowborder">
          <Col span={2} className="pickup_icon">
            <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M26.125 5.3335H6.875C5.35622 5.3335 4.125 6.5274 4.125 8.00016V26.6668C4.125 28.1396 5.35622 29.3335 6.875 29.3335H26.125C27.6438 29.3335 28.875 28.1396 28.875 26.6668V8.00016C28.875 6.5274 27.6438 5.3335 26.125 5.3335Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M22 2.6665V7.99984" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M11 2.6665V7.99984" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M4.125 13.3335H28.875" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

          </Col>
          <Col span={22} className="show_desktop">
            <DatePicker getPopupContainer={(trigger) => trigger.parentNode} inputReadOnly={true} mode="date" allowClear={true} value={dateSelected} className="noborder-full-width" format="MM-DD-YYYY" disabledDate={disabledDate} placeholder="Date" onChange={setDate} suffixIcon="" showToday={false} />
          </Col>
          <Col span={22} className="show_mobile">
            <div style={{ paddingLeft: 11, paddingRight: 11 }} className="ant-select ant-select-selector ant-select-selection-item" onClick={() => { showMobilePopup("date") }}>
              {!!dateSelected ? moment(dateSelected).format("YYYY-MM-DD") : "Date"}
            </div>
          </Col>
        </Row>

        <Row className="rowborder">
          <Col span={2} className="pickup_icon">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.9993 29.3332C23.3631 29.3332 29.3327 23.3636 29.3327 15.9998C29.3327 8.63604 23.3631 2.6665 15.9993 2.6665C8.63555 2.6665 2.66602 8.63604 2.66602 15.9998C2.66602 23.3636 8.63555 29.3332 15.9993 29.3332Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M16 8V16L21.3333 18.6667" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

          </Col>
          <Col span={22} className="show_desktop">
            <Select
              placeholder="Time"
              onSelect={setTime}
              suffixIcon=""
              getPopupContainer={(trigger) => trigger.parentNode}
              value={timeSelected}
              dropdownClassName="time-picker-select"
              virtual={false}>
              {pickupTimes !== null && pickupTimes !== undefined && !pickupTimes.err &&

                pickupTimes.map((item) => {
                  return (
                    <Select.Option key={item.userTime}>{item.userTime}</Select.Option>
                  )
                })}
            </Select>
          </Col>
          <Col span={22} className="show_mobile">
            <div style={{ paddingLeft: 11, paddingRight: 11 }} className="ant-select ant-select-selector ant-select-selection-item" onClick={() => { showMobilePopup("time") }}>
              {!!timeSelected ? timeSelected : "Time"}
            </div>
          </Col>
        </Row>

        <div className="fixed-button-bottom">
          <Button
            type="primary"
            htmlType="submit"
            disabled={btnDisabled}
            className="radius12 font_size_family btn_pad" onClick={handleOk}>Continue</Button>
        </div>
        <RepeatItemsNotAvailable id={id} selectedPickupDateTime={selectedPickupDateTime} showItemsNotAvailable={showItemsNotAvailable} setShowItemsNotAvailable={setShowItemsNotAvailable} {...props} />
        <RepeatItemsAnotherCart id={id} selectedPickupDateTime={selectedPickupDateTime} showAnotherCartInfo={showAnotherCartInfo} setShowAnotherCartInfo={setShowAnotherCartInfo} {...props} />
        <MobilePickupInfoDrawer
          showMobilePickupDrawer={showMobilePickupDrawer}
          setMobilePickupDrawer={setMobilePickupDrawer}
          dataList={dataList === null ? [] : dataList}
          pickupField={pickupField}
          disabledDate={disabledDate}
          setDate={setDate}
          setTime={setTime}
          dateSelected={dateSelected}
          timeSelected={timeSelected}
          dateLoader={pickupDates !== null ? false : true}
          pickupTimes={pickupTimes}
        />
      </Modal>
    </>
  )
}
export default RepeatOrderPickupInfo;