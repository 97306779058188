import { takeLatest, put, call, take } from 'redux-saga/effects';
import * as actionTypes from './actions';
import { simpleGet, getRequest } from '../../utils/requests';
import pathconfig from '../../utils/path-config';

function* userDetails() {
  try {
    let result = yield call(getRequest, pathconfig.userDetails);
    yield put({ type: actionTypes.USER_DETAILS_RESPONSE, saveUserDetails: result })
    // if (!!result && result.childs === undefined) {
    //   result["childs"] = [];
    //   result["childs"] = [
    //     {
    //       "name": 'John Lennon',
    //       "username": "jlennon",
    //       "customerId": 123,
    //       "studentId": "sssddd",
    //       "programId": 555,
    //       "programName": "Latin Schools"
    //     },
    //     {
    //       "name": "Elton John",
    //       "username": "ejohn",
    //       "customerId": 124,
    //       "studentId": "sssddd",
    //       "programId": 555,
    //       "programName": "Latin Schools"
    //     }
    //   ]
    // }
    if (result.currentProgram && result.currentProgram.programId) {
      try {
        localStorage.setItem("programId", result.currentProgram.programId);
        const brandingRes = yield call(simpleGet, pathconfig.customizedBrandingWithProgramId(result.currentProgram.programId));
        yield put({ type: "SAVE_BRANDING_DETAILS", brandingDetails: brandingRes })
      } catch (err) {
        console.log(err)
        yield put({ type: "SAVE_BRANDING_DETAILS", brandingDetails: { err: err.response.statusText } })

      }
    }

  } catch (err) {
    console.log(err)
  }
}

function* pickupLocations(values) {
  try {
    var url = new URL(pathconfig.baseUrl + pathconfig.pickupLocations),
      params = {
        miles: values.params.miles || null,
        // v: 2
      }
    Object.keys(params).filter(k => params[k]).forEach(key => url.searchParams.append(key, params[key]));
    let result = yield call(getRequest, url.pathname + url.search);
    yield put({ type: actionTypes.PICKUP_LOCATION_RESPONSE, pickupLocationDetails: result })
  } catch (err) {
    console.log(err)
  }
}

function* pickupDates(values) {
  try {
    var url = new URL(pathconfig.baseUrl + pathconfig.pickupDates),
      params = {
        miles: values.params.miles || null,
        pickupLocation: values.params.location,
        b2cType: values.params.b2cType
      }
    Object.keys(params).filter(k => params[k]).forEach(key => url.searchParams.append(key, params[key]));
    let result = yield call(getRequest, url.pathname + url.search);
    yield put({ type: actionTypes.PICKUP_DATE_RESPONSE, pickupDates: result })
  } catch (err) {
    console.log(err)
  }
}

function* pickupTimes(values) {
  try {
    var url = new URL(pathconfig.baseUrl + pathconfig.pickupTimes),
      params = {
        miles: values.params.miles || null,
        pickupLocation: values.params.location,
        pickupDate: values.params.date,
        b2cType: values.params.b2cType
      }
    Object.keys(params).filter(k => params[k]).forEach(key => url.searchParams.append(key, params[key]));
    let result = yield call(getRequest, url.pathname + url.search);
    yield put({ type: actionTypes.PICKUP_TIME_RESPONSE, pickupTimes: result })
  } catch (err) {
    console.log(err)
  }
}

function* listVendors(values) {
  try {
    var url = new URL(pathconfig.baseUrl + pathconfig.restaurantsList),
      params = {
        offset: values.params.offset,
        max: values.params.max,
        b2cType: values.params.b2cType
      }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    let result = yield call(getRequest, url.pathname + url.search);
    yield put({ type: actionTypes.PICKUP_VENDOR_RESPONSE, pickupVendors: result })
    if (result?.length < values.params.max) {
      yield put({ type: actionTypes.END_OF_RESTAURANTS_LIST, vendorsListEnded: true });
    }
  } catch (err) {
    console.log(err)
  }
}

function* listVendorsWithPickupInfo(values) {
  try {
    // var url = new URL(pathconfig.baseUrl + pathconfig.listVendors),
    var url = new URL(pathconfig.baseUrl + pathconfig.listVendors),
      params = {
        miles: values.params.miles || null,
        pickupLocation: values.params.location || null,
        pickupDate: values.params.date || null,
        pickupTime: values.params.time || null,
        offset: values.params.offset,
        max: values.params.max,
        b2cType: values.params.b2cType,
        customerId: values.params.customerId || null,
      }
    if (process.env.REACT_APP_SELECT_PICKUP_CHANGES === 'true' && values.params.subType) {
      params.subType = values.params.subType
    }
    Object.keys(params).filter(k => params[k]).forEach(key => url.searchParams.append(key, params[key]));
    let result = yield call(getRequest, url.pathname + url.search);
    yield put({ type: actionTypes.PICKUP_VENDOR_RESPONSE, pickupVendors: result })
  } catch (err) {
    console.log(err)
  }
}

function* applyFilterItems(values) {

  try {
    var url = new URL(pathconfig.baseUrl + pathconfig.listVendors),
      params = {
        miles: values.params.miles || null,
        pickupLocation: values.params.pickupLocation,
        pickupDate: values.params.pickupDate,
        pickupTime: values.params.pickupTime,
        offset: values.params.offset,
        max: values.params.max,
        b2cType: values.params.b2cType,
        customerId: values.params.customerId
      }
    Object.keys(params).filter(k => params[k]).forEach(key => url.searchParams.append(key, params[key]));
    if (values.params.selectedAttributes.length > 0) {
      values.params.selectedAttributes.map((item) => {
        url.searchParams.append('selectedAttributes', item);
      })
    }

    let result = yield call(getRequest, url.pathname + url.search);
    yield put({ type: actionTypes.APPLY_FILTER_RESPONSE, applyFilters: result })

  } catch (err) {
    console.log(err);
  }
}

function* savePickupData(params) {
  try {
    yield put({ type: actionTypes.SAVE_PICKUP_DATA, pickupData: params.params })
  } catch (err) {
    console.log(err)
  }
}

function* listVendorFilters() {
  try {
    let result = yield call(getRequest, pathconfig.vendorFilters);
    yield put({ type: actionTypes.FILTER_ITEM_RESPONSE, filterItems: result })
  } catch (err) {
    console.log(err)
  }
}

function* getProgramInformation(params) {
  try {
    let result = yield call(getRequest, pathconfig.getProgramInfo(params.b2ctype));
    yield put({ type: actionTypes.SAVE_PROGRAM_INFO, programInfo: result });
  }
  catch (err) {
    console.log(err)
  }
}

function* loadMoreRestaurants(values) {
  try {
    var url = new URL(pathconfig.baseUrl + pathconfig.restaurantsList),
      params = {
        offset: values.params.offset,
        max: values.params.max,
        b2cType: values.params.b2cType
      }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    const result = yield call(getRequest, url.pathname + url.search);
    if (result !== null && !result.err) {
      yield put({ type: actionTypes.LOAD_MORE_VENDORS_RESPONSE, pickupVendors: result })
      if (result?.length < values.params.max) {
        yield put({ type: actionTypes.END_OF_RESTAURANTS_LIST, vendorsListEnded: true });
      }
    }

  } catch (err) {
    console.log(err)
  }
}

function* getPickupWeeks(values) {
  try {
    if (!!values.params.customerId) {
      var url = new URL(pathconfig.baseUrl + pathconfig.pickupWeeks),
        params = {
          pickupLocation: values.params.pickupLocation,
          b2cType: values.params.b2cType,
          customerId: values.params.customerId
        }
    }
    else {
      var url = new URL(pathconfig.baseUrl + pathconfig.pickupWeeks),
        params = {
          pickupLocation: values.params.pickupLocation,
          b2cType: values.params.b2cType
        }
    }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    const result = yield call(getRequest, url.pathname + url.search);
    yield put({ type: actionTypes.PICKUP_WEEK_RESPONSE, pickupWeeks: result })
  } catch (err) {
    console.log(err)
  }
}

function* getPickupWeekSlots(values) {
  try {

    if (!!values.params.customerId) {
      var url = new URL(pathconfig.baseUrl + pathconfig.pickupWeekSlots),
        params = {
          week: values.params.pickupWeek,
          pickupLocation: values.params.pickupLocation,
          b2cType: values.params.b2cType,
          customerId: values.params.customerId
        }
    }
    else {
      var url = new URL(pathconfig.baseUrl + pathconfig.pickupWeekSlots),
        params = {
          week: values.params.pickupWeek,
          pickupLocation: values.params.pickupLocation,
          b2cType: values.params.b2cType
        }
    }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    const result = yield call(getRequest, url.pathname + url.search);
    // const result = yield call(getRequest, pathconfig.pickupWeekSlots(values.params.pickupWeek, values.params.pickupLocation, values.params.b2cType, values.params.customerId));
    // yield put({ type: actionTypes.PICKUP_WEEK_SLOT_RESPONSE, pickupWeekSlot: [
    //   "1970-01-01T11:00:00Z",
    //   "1970-01-01T11:15:00Z",
    //   "1970-01-01T11:30:00Z",
    //   "1970-01-01T11:45:00Z"
    // ]})
    yield put({ type: actionTypes.PICKUP_WEEK_SLOT_RESPONSE, pickupWeekSlot: result });
  } catch (err) {

  }
}

function* getStudentBrandingDetails(params) {
  try {
    const brandingRes = yield call(simpleGet, pathconfig.customizedBrandingWithProgramId(params.params.programId));
    yield put({ type: "SAVE_BRANDING_DETAILS", brandingDetails: brandingRes })
  } catch (err) {
    console.log(err);
    yield put({ type: "SAVE_BRANDING_DETAILS", brandingDetails: { err: err.response.statusText } });
  }
}

export default function* watchUserSaga() {
  yield takeLatest(actionTypes.USER_DETAILS, userDetails);
  yield takeLatest(actionTypes.PICKUP_LOCATION_REQUEST, pickupLocations);
  yield takeLatest(actionTypes.PICKUP_DATE_REQUEST, pickupDates);
  yield takeLatest(actionTypes.PICKUP_TIME_REQUEST, pickupTimes);
  yield takeLatest(actionTypes.PICKUP_VENDOR_REQUEST, listVendors);
  // yield takeLatest(actionTypes.FILTER_ITEM_REQUEST, listFilterItems);
  yield takeLatest(actionTypes.APPLY_FILTER_REQUEST, applyFilterItems);
  yield takeLatest(actionTypes.DISPATCH_PICKUP_DATA, savePickupData);
  yield takeLatest(actionTypes.VENDOR_FILTER_ITEMS_REQUEST, listVendorFilters);
  yield takeLatest(actionTypes.GET_PROGRAM_INFO, getProgramInformation);
  yield takeLatest(actionTypes.PICKUP_VENDOR_REQUEST_WITH_INFO, listVendorsWithPickupInfo);
  yield takeLatest(actionTypes.LOAD_MORE_VENDORS, loadMoreRestaurants);
  yield takeLatest(actionTypes.PICKUP_WEEK_REQUEST, getPickupWeeks);
  yield takeLatest(actionTypes.PICKUP_WEEK_SLOT_REQUEST, getPickupWeekSlots);
  yield takeLatest(actionTypes.GET_BRANDING_DETAILS, getStudentBrandingDetails);
}