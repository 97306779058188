import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Select, DatePicker, Button, ConfigProvider } from 'antd';
import moment from 'moment';
import { MobilePickupInfoDrawer } from '../../components';
import './styles.scss';
import 'moment/locale/en-gb';
import en_GB from 'antd/lib/locale-provider/en_GB';
const _ = require("lodash");

const PickupByDateTime = (props) => {
  const {
    fromTime,
    dateTimeModalVisible,
    setDateTimeModalVisible,
    newPickupMenuData,
    getPickupDates,
    pickupDates,
    getPickupTimes,
    pickupTimes,
    saveNewPickupMenuData,
    selectedFiltersList,
    applyFilters,
    reservationStatus,
    showVendorLoader,
    clearPickupTimes,
    getVendorsListWithPickupInfo,
    removeLoadMore,
    getPickupWeeks,
    pickupWeeks,
    getPickupWeekSlots,
    pickupWeekSlot,
  } = props;
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [selectedPickupLocation, setSelectedPickupLocation] = useState(null);
  const [dateSelected, setDateSelected] = useState(null);
  const [timeSelected, setTimeSelected] = useState(null);
  const [showMobilePickupDrawer, setMobilePickupDrawer] = useState(false);
  const [dataList, setDataList] = useState(null);
  const [pickupField, setPickupField] = useState("date");
  const [weekSelected, setWeekSelected] = useState(null);

  useEffect(() => {
    if (newPickupMenuData !== null) {
      setSelectedPickupLocation(newPickupMenuData.pickupLocation);
      if (process.env.REACT_APP_SELECT_PICKUP_CHANGES === "true" && newPickupMenuData.orderType === "For A Week") {
        if (reservationStatus === undefined || reservationStatus === null) {
          const params = {
            pickupLocation: newPickupMenuData.pickupLocation.id,
            b2cType: "ORDERING",
            customerId: !!newPickupMenuData && !!newPickupMenuData.customerId && newPickupMenuData.customerId
          }
          getPickupWeeks(params);
        }
        else {
          if (reservationStatus) {
            const params = {
              pickupLocation: newPickupMenuData.pickupLocation.id,
              b2cType: "RESERVATION",
              customerId: !!newPickupMenuData && !!newPickupMenuData.customerId && newPickupMenuData.customerId
            }
            getPickupWeeks(params);
          }
          else {
            const params = {
              pickupLocation: newPickupMenuData.pickupLocation.id,
              b2cType: "ORDERING",
              customerId: !!newPickupMenuData && !!newPickupMenuData.customerId && newPickupMenuData.customerId
            }
            getPickupWeeks(params);
          }
        }
      }
      else {
        if (reservationStatus === undefined || reservationStatus === null) {
          const params = {
            location: newPickupMenuData.pickupLocation.id,
            b2cType: "ORDERING"
          }
          getPickupDates(params);
        }
        else {
          if (reservationStatus) {
            const params = {
              location: newPickupMenuData.pickupLocation.id,
              b2cType: "RESERVATION"
            }
            getPickupDates(params);
          }
          else {
            const params = {
              location: newPickupMenuData.pickupLocation.id,
              b2cType: "ORDERING"
            }
            getPickupDates(params);
          }
        }
      }

    }
  }, [newPickupMenuData]);


  useEffect(() => {
    process.env.REACT_APP_SELECT_PICKUP_CHANGES === "true" && !!newPickupMenuData && newPickupMenuData.orderType === "For A Week" ?
      setBtnDisabled(!selectedPickupLocation || !weekSelected || !timeSelected) :
      setBtnDisabled(!selectedPickupLocation || !dateSelected || !timeSelected);
  }, [selectedPickupLocation, dateSelected, timeSelected, weekSelected]);


  const disabledDate = (current) => {
    let arrOfDay = [];
    if (pickupDates !== null && !pickupDates.err) {
      let customDate = null;
      pickupDates.map((item) => {
        customDate = item.gmtDate.substring(0, item.gmtDate.indexOf('T'));
        arrOfDay.push(customDate);
      })
    }
    let index = arrOfDay.findIndex(date => date === moment(current).format("YYYY-MM-DD"));
    return index === -1 && true
  }

  const disabledWeek = (current) => {
    let enabledDaysOfWeek = [];
    if (pickupWeeks !== null && !pickupWeeks.err) {
      pickupWeeks.map((item, index) => {
        let day = new Date(item);
        enabledDaysOfWeek.push(moment(day).format("YYYY-MM-DD"));
        for (let i = 1; i < 7; i++) {
          day.setDate(day.getDate() + i);
          enabledDaysOfWeek.push(moment(day).format("YYYY-MM-DD"));
          day = new Date(item);
        }
      })
    }
    let index = enabledDaysOfWeek.findIndex(date => date === moment(current).format("YYYY-MM-DD"));
    return index === -1 && true
  }
  const setDate = (date) => {
    setDateSelected(moment.utc(date));
    clearPickupTimes();
    if (reservationStatus !== null && reservationStatus) {
      const params = {
        miles: 9999,
        location: selectedPickupLocation.id,
        date: moment.utc(date).format("YYYY-MM-DD"),
        b2cType: "RESERVATION"
      }
      getPickupTimes(params);
    }
    else {
      const params = {
        miles: 9999,
        location: selectedPickupLocation.id,
        date: moment.utc(date).format("YYYY-MM-DD"),
        b2cType: "ORDERING"
      }
      getPickupTimes(params);
    }
  }

  const setTime = (time) => {
    setTimeSelected(time);
  }

  const setWeek = (week) => {
    setWeekSelected(week);
    if (reservationStatus !== null && reservationStatus) {
      const params = {
        // pickupWeek: getFirstDayOfWeek(week),
        pickupWeek: week,
        pickupLocation: selectedPickupLocation.id,
        b2cType: "RESERVATION",
        customerId: !!newPickupMenuData && !!newPickupMenuData.customerId && newPickupMenuData.customerId
      }
      getPickupWeekSlots(params);
    }
    else {
      const params = {
        pickupWeek: week,
        pickupLocation: selectedPickupLocation.id,
        b2cType: "ORDERING",
        customerId: !!newPickupMenuData && !!newPickupMenuData.customerId && newPickupMenuData.customerId
      }
      getPickupWeekSlots(params);
    }
  }
  const handleCancel = e => {
    setDateTimeModalVisible(!dateTimeModalVisible);
    setDateSelected(null);
    setTimeSelected(null);
    setWeekSelected(null);
  };

  const handleOk = () => {
    showVendorLoader(true);
    removeLoadMore();
    let data = null
    if (process.env.REACT_APP_SELECT_PICKUP_CHANGES === "true" && !!newPickupMenuData && newPickupMenuData.orderType === "For A Week") {
      data = { ...newPickupMenuData, dateSelected: moment(weekSelected).format("YYYY-MM-DD"), timeSelected: timeSelected };
    }
    else {
      data = { ...newPickupMenuData, dateSelected: moment(dateSelected).format("YYYY-MM-DD"), timeSelected: timeSelected };
    }
    saveNewPickupMenuData(data);
    if (selectedFiltersList !== null) {
      const params = {
        miles: 9999,
        pickupLocation: selectedPickupLocation.id,
        pickupDate: process.env.REACT_APP_SELECT_PICKUP_CHANGES === "true" && !!newPickupMenuData && newPickupMenuData.orderType === "For A Week" ? moment.utc(weekSelected).format("YYYY-MM-DD") : moment(dateSelected).format("YYYY-MM-DD"),
        pickupTime: process.env.REACT_APP_SELECT_PICKUP_CHANGES === "true" && !!newPickupMenuData && newPickupMenuData.orderType === "For A Week" ? moment.utc(timeSelected).format('H:mm') : moment(timeSelected, 'H:mm A').format('H:mm'),
        offset: 0,
        selectedAttributes: selectedFiltersList.attributes,
        b2cType: `${reservationStatus !== null && reservationStatus ? "RESERVATION" : "ORDERING"}`,
        customerId: process.env.REACT_APP_SELECT_PICKUP_CHANGES === "true" && !!newPickupMenuData && !!newPickupMenuData.customerId && newPickupMenuData.customerId
      }
      if (process.env.REACT_APP_SELECT_PICKUP_CHANGES === "true" && !!newPickupMenuData && newPickupMenuData.orderType === "For A Week") {
        params.subType = 'WEEKLY';
      }
      applyFilters(params);
    } else {
      const params = {
        miles: 9999,
        location: selectedPickupLocation.id,
        date: process.env.REACT_APP_SELECT_PICKUP_CHANGES === "true" && !!newPickupMenuData && newPickupMenuData.orderType === "For A Week" ? moment.utc(weekSelected).format("YYYY-MM-DD") : moment(dateSelected).format("YYYY-MM-DD"),
        time: process.env.REACT_APP_SELECT_PICKUP_CHANGES === "true" && !!newPickupMenuData && newPickupMenuData.orderType === "For A Week" ? moment.utc(timeSelected).format('H:mm') : moment(timeSelected, 'H:mm a').format('H:mm'),
        offset: 0,
        b2cType: `${reservationStatus !== null && reservationStatus ? "RESERVATION" : "ORDERING"}`,
        customerId: process.env.REACT_APP_SELECT_PICKUP_CHANGES === "true" && !!newPickupMenuData && !!newPickupMenuData.customerId && newPickupMenuData.customerId
      }
      if (process.env.REACT_APP_SELECT_PICKUP_CHANGES === "true" && !!newPickupMenuData && newPickupMenuData.orderType === "For A Week") {
        params.subType = 'WEEKLY';
      }
      getVendorsListWithPickupInfo(params);
    }
    setDateSelected(null);
    setTimeSelected(null);
    setWeekSelected(null);
    setDateTimeModalVisible(!dateTimeModalVisible);
    if (props.setLocationModalVisible) {
      props.setLocationModalVisible(false);
    }
    if (props.setPickupByOrderTypeVisible) {
      props.setPickupByOrderTypeVisible(false);
    }
  }

  const pickupLocationFormat = (selectedPickupLocation) => {
    return (
      <>
        <div>
          {selectedPickupLocation.name}
        </div>
        <p className="pickup-location-subtext">
          {selectedPickupLocation.street_one !== null && <>{selectedPickupLocation.street_one}, </>}{selectedPickupLocation.street_two !== null && <>{selectedPickupLocation.street_two}<br /> </>}{selectedPickupLocation.city !== null && <>{selectedPickupLocation.city}, </>}{selectedPickupLocation.state !== null && <>{selectedPickupLocation.state} </>}{selectedPickupLocation.zip !== null && selectedPickupLocation.zip}
        </p>

      </>
    )
  }

  const showMobilePopup = (field) => { // NOTE - mobile pop up for week
    if (field === "date" && !!disabledDate) {
      setDataList(pickupDates);
      setMobilePickupDrawer(true);
      setPickupField(field);
    }
    else if (field === "week" && !!disabledDate) {
      setDataList(pickupDates);
      setMobilePickupDrawer(true);
      setPickupField(field);
    }
    else if (field === "time") {
      setDataList(pickupTimes);
      setMobilePickupDrawer(true);
      setPickupField(field)

    }
  }

  return (
    <>
      <Modal className="order-modal" title={fromTime ? "Select a time" : (!!newPickupMenuData && newPickupMenuData.orderType === "For A Week") ? "Select a week" : "Select a date"}
        visible={dateTimeModalVisible}
        footer={null}
        onCancel={handleCancel}>

        <Row className="rowborder">
          <Col span={2} className="pickup_icon">
            {/* <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M26.125 5.3335H6.875C5.35622 5.3335 4.125 6.5274 4.125 8.00016V26.6668C4.125 28.1396 5.35622 29.3335 6.875 29.3335H26.125C27.6438 29.3335 28.875 28.1396 28.875 26.6668V8.00016C28.875 6.5274 27.6438 5.3335 26.125 5.3335Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M22 2.6665V7.99984" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M11 2.6665V7.99984" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M4.125 13.3335H28.875" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg> */}
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M425.951,89.021C386.864,32.451,324.917,0,256.006,0S125.148,32.451,86.061,89.021
                c-38.895,56.284-47.876,127.541-24.072,190.496c6.367,17.192,16.488,33.895,30.01,49.547l150.378,176.634
                c3.401,3.998,8.384,6.302,13.629,6.302c5.245,0,10.228-2.303,13.629-6.302l150.336-176.586
                c13.582-15.742,23.69-32.427,30.004-49.481C473.827,216.562,464.846,145.305,425.951,89.021z M416.451,267.093
                c-4.869,13.158-12.818,26.167-23.613,38.68c-0.03,0.03-0.06,0.06-0.084,0.096L256.006,466.487L119.174,305.768
                c-10.789-12.502-18.738-25.51-23.655-38.794c-19.686-52.065-12.215-110.981,19.991-157.592
                c32.307-46.76,83.519-73.578,140.496-73.578c56.976,0,108.182,26.817,140.49,73.578
                C428.708,155.993,436.185,214.909,416.451,267.093z"/>
              <path d="M256.006,106.219c-55.276,0-100.252,44.97-100.252,100.252s44.97,100.252,100.252,100.252s100.252-44.97,100.252-100.252
                C356.258,151.195,311.282,106.219,256.006,106.219z M256.006,270.918c-35.536,0-64.448-28.912-64.448-64.448
                c0-35.536,28.912-64.448,64.448-64.448c35.536,0,64.448,28.912,64.448,64.448S291.542,270.918,256.006,270.918z"/>
            </svg>
          </Col>
          <Col span={22}>
            {selectedPickupLocation !== null &&
              <Select
                disabled={true}
                placeholder="Pick-Up Location"
                suffixIcon=""
                getPopupContainer={(trigger) => trigger.parentNode}
                className="dateSelectorPickupLocation"
                value={selectedPickupLocation !== null && pickupLocationFormat(selectedPickupLocation)}>
                <Select.Option value={selectedPickupLocation.id}>
                  <>
                    <div>
                      {selectedPickupLocation.name}
                    </div>
                    <p className="pickup-location-subtext">
                      {selectedPickupLocation.street_one !== null && <>{selectedPickupLocation.street_one}, </>}{selectedPickupLocation.street_two !== null && <>{selectedPickupLocation.street_two}<br /> </>}{selectedPickupLocation.city !== null && <>{selectedPickupLocation.city}, </>}{selectedPickupLocation.state !== null && <>{selectedPickupLocation.state} </>}{selectedPickupLocation.zip !== null && selectedPickupLocation.zip}
                    </p>
                  </>
                </Select.Option>
              </Select>}
          </Col>
        </Row>
        {process.env.REACT_APP_SELECT_PICKUP_CHANGES === "true" && !!newPickupMenuData && newPickupMenuData.orderType === "For A Week" ?
          <Row className="rowborder">
            <Col span={2} className="pickup_icon">
              <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M26.125 5.3335H6.875C5.35622 5.3335 4.125 6.5274 4.125 8.00016V26.6668C4.125 28.1396 5.35622 29.3335 6.875 29.3335H26.125C27.6438 29.3335 28.875 28.1396 28.875 26.6668V8.00016C28.875 6.5274 27.6438 5.3335 26.125 5.3335Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M22 2.6665V7.99984" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11 2.6665V7.99984" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M4.125 13.3335H28.875" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>

            </Col>
            <Col span={22} className="show_desktop">
              <ConfigProvider locale={en_GB}>
                <Select
                  placeholder="Week"
                  onSelect={setWeek}
                  value={weekSelected}
                  suffixIcon=""
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownClassName="week-picker-select"
                  virtual={false}>
                  {
                    pickupWeeks !== null && pickupWeeks !== undefined && !pickupWeeks.err &&
                    pickupWeeks.map((item) => {
                      return (
                        <Select.Option key={moment.utc(item).format('YYYY-MM-DD')}>{`Week of ${moment.utc(item).format('MMMM DD')} - ${moment.utc(item).add(6, 'days').format('MMMM DD')}`}</Select.Option>
                      )
                    })
                  }
                </Select>
              </ConfigProvider>
            </Col>
            <Col span={22} className="show_mobile">
              <div style={{ paddingLeft: 11, paddingRight: 11 }} className="ant-select ant-select-selector ant-select-selection-item" onClick={() => { showMobilePopup("week") }}>
                {!!weekSelected ? `Week of ${moment.utc(weekSelected).format('MMMM DD')} - ${moment.utc(weekSelected).add(6, 'days').format('MMMM DD')}` : "Week"}
              </div>
            </Col>
          </Row> :
          <Row className="rowborder">
            <Col span={2} className="pickup_icon">
              <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M26.125 5.3335H6.875C5.35622 5.3335 4.125 6.5274 4.125 8.00016V26.6668C4.125 28.1396 5.35622 29.3335 6.875 29.3335H26.125C27.6438 29.3335 28.875 28.1396 28.875 26.6668V8.00016C28.875 6.5274 27.6438 5.3335 26.125 5.3335Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M22 2.6665V7.99984" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11 2.6665V7.99984" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M4.125 13.3335H28.875" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>

            </Col>
            <Col span={22} className="show_desktop">
              <DatePicker getPopupContainer={(trigger) => trigger.parentNode} inputReadOnly={true} mode="date" allowClear={true} format="MM-DD-YYYY" value={dateSelected} className="noborder-full-width" disabledDate={disabledDate} placeholder="Date" onChange={setDate} suffixIcon="" showToday={false} />
            </Col>
            <Col span={22} className="show_mobile">
              <div style={{ paddingLeft: 11, paddingRight: 11 }} className="ant-select ant-select-selector ant-select-selection-item" onClick={() => { showMobilePopup("date") }}>
                {!!dateSelected ? moment(dateSelected).format("YYYY-MM-DD") : "Date"}
              </div>
            </Col>
          </Row>}


        <Row className="rowborder">
          <Col span={2} className="pickup_icon">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.9993 29.3332C23.3631 29.3332 29.3327 23.3636 29.3327 15.9998C29.3327 8.63604 23.3631 2.6665 15.9993 2.6665C8.63555 2.6665 2.66602 8.63604 2.66602 15.9998C2.66602 23.3636 8.63555 29.3332 15.9993 29.3332Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M16 8V16L21.3333 18.6667" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Col>
          <Col span={22} className="show_desktop">
            <Select
              placeholder="Time"
              onSelect={setTime}
              value={timeSelected}
              suffixIcon=""
              getPopupContainer={(trigger) => trigger.parentNode}
              dropdownClassName="time-picker-select"
              virtual={false}>
              {process.env.REACT_APP_SELECT_PICKUP_CHANGES === "true" && !!newPickupMenuData && newPickupMenuData.orderType === "For A Week" ?
                pickupWeekSlot !== null && pickupWeekSlot !== undefined && !pickupWeekSlot.err &&

                pickupWeekSlot.map((item) => {
                  return (
                    <Select.Option key={item}>{moment.utc(item).format('h:mm A')}</Select.Option>
                  )
                }) :
                pickupTimes !== null && pickupTimes !== undefined && !pickupTimes.err &&

                pickupTimes.map((item) => {
                  return (
                    <Select.Option key={item.userTime}>{item.userTime}</Select.Option>
                  )
                })}
            </Select>
          </Col>
          <Col span={22} className="show_mobile">
            <div style={{ paddingLeft: 11, paddingRight: 11 }} className="ant-select ant-select-selector ant-select-selection-item" onClick={() => { showMobilePopup("time") }}>
              {
                !!timeSelected
                  ? process.env.REACT_APP_SELECT_PICKUP_CHANGES === "true" && !!newPickupMenuData && newPickupMenuData.orderType === "For A Week" ? moment.utc(timeSelected).format('h:mm A') : timeSelected
                  : "Time"
              }
            </div>
          </Col>
        </Row>

        <div className="fixed-button-bottom">
          <Button
            type="primary"
            htmlType="submit"
            disabled={btnDisabled}
            className="radius12 font_size_family btn_pad" onClick={handleOk}>Continue</Button>
        </div>
        <MobilePickupInfoDrawer
          showMobilePickupDrawer={showMobilePickupDrawer}
          setMobilePickupDrawer={setMobilePickupDrawer}
          dataList={dataList}
          pickupField={pickupField}
          disabledDate={disabledDate}
          setDate={setDate}
          setTime={setTime}
          dateSelected={dateSelected}
          timeSelected={timeSelected}
          dateLoader={pickupDates !== null ? false : true}
          weekLoader={!!pickupWeeks ? false : true}
          pickupTimes={pickupTimes}
          disabledWeek={disabledWeek}
          setWeek={setWeek}
          weekSelected={weekSelected}
          {...props}
        />
      </Modal>
    </>
  )
}

export default PickupByDateTime;