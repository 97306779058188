import * as actionTypes from './action';

const initialState = {
  deleteCartResponse: null,
  updateCartResponse: null,
  orderLocatioDetails: null,
}

const OrderReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.DELETE_CART_RESPONSE:
      return { ...state, deleteCartResponse: action.deleteCartResponse }

    case actionTypes.UPDATE_CART_RESPONSE:
      return { ...state, updateCartResponse: action.updateCartResponse };

    case actionTypes.SAVE_ORDER_LOCATION_DETAILS:
      return { ...state, orderLocatioDetails: action.params };

    default: return state;
  }
}

export default OrderReducer;