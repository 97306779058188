import * as actionTypes from './actions';

const initialState = {
  updateUserResponse: null,
  updateUserCrendentialResponse: null,
  myOrderResponse: null,
  updateUserInfoSuccessMsg: null,
  selectedOrderDetail: null,
  isLoadingMore: false,
  endOfHistoryList: false,
  dietaryOptions: null,
  repeatOrderResponse: null,
  pickupDates: null,
  pickupTimes: null,
  mealPlanAvailableBalance: null,
}

const UserAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_USER_INFO_RESPONSE:
      return {
        ...state, updateUserResponse: action.updateUserResponse,
        updateUserInfoSuccessMsg: !!action.updateUserResponse.err ? null : "Your Profile Info Updated!!"
      }

    case actionTypes.CLEAR_UPDATE_USER_SUCCESS_MESSAGE:
      return { ...state, updateUserInfoSuccessMsg: null }

    case actionTypes.UPDATE_USER_CREDENTIAL_RESPONSE:
      return { ...state, updateUserCrendentialResponse: action.updateUserCredentialResponse }

    case actionTypes.GET_MY_ORDER_RESPONSE:
      return { ...state, myOrderResponse: action.myOrderResponse }

    case actionTypes.ORDER_HISTORY_LOAD_MORE:
      return { ...state, isLoadingMore: true }
    case actionTypes.ORDER_HISTORY_STOP_LOAD_MORE:
      return { ...state, isLoadingMore: false }
    case actionTypes.ORDER_HISTORY_LOAD_RESPONSE:
      if (action.myOrderResponse !== null) {
        return { ...state, isLoadingMore: false, myOrderResponse: [...state.myOrderResponse, ...action.myOrderResponse], endOfHistoryList: action.endOfHistoryList }
      }
      else {
        return { ...state, isLoadingMore: false, myOrderResponse: action.myOrderResponse, endOfHistoryList: action.endOfHistoryList }
      }
    case actionTypes.SAVE_SELECTED_ORDER_DETAIL:
      return { ...state, selectedOrderDetail: action.params }

    case actionTypes.DIETARY_OPTIONS_RESPONSE:
      return { ...state, dietaryOptions: action.dietryOptionsResponse }

    case actionTypes.REPEAT_ORDER_RESPONSE:
      return { ...state, repeatOrderResponse: action.repeatOrderResponse }

    case actionTypes.SAVE_PICKUP_DATES:
      return { ...state, pickupDates: action.pickupDates }

    case actionTypes.SAVE_PICKUP_TIME:
      return { ...state, pickupTimes: action.pickupTimes }

    case actionTypes.CLEAR_UPDATE_ERROR_RESPONSE:
      return { ...state, updateUserResponse: null }

    case actionTypes.MEAL_PLAN_AVAILABLE_BALANCE_RESPONSE:
      return { ...state, mealPlanAvailableBalance: action.mealPlanAvailableBalance };

    default:
      return state;
  }
}

export default UserAccountReducer;