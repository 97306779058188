import { put, call, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './action';
import { deleteRequest, postRequest } from '../../utils/requests';
import pathconfig from '../../utils/path-config';

function* deleteCart(params) {
  try {
    const result = yield call(deleteRequest, pathconfig.cartDetails + params.params.cartId);
    yield put({ type: actionTypes.DELETE_CART_RESPONSE, deleteCartResponse: result });
  }
  catch (err) {
    console.log(err);
  }
}

function* updateCart(params) {
  try {
    const result = yield call(postRequest, pathconfig.updateCartItem, params.params);
    yield put({ type: actionTypes.UPDATE_CART_RESPONSE, updateCartResponse: result });
  }
  catch (err) {
    console.log(err);
  }
}

export default function* watchOrderSaga() {
  yield takeLatest(actionTypes.DELETE_CART_REQUEST, deleteCart);
  yield takeLatest(actionTypes.UPDATE_CART_REQUEST, updateCart);
}