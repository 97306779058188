import { takeLatest, put, call } from 'redux-saga/effects';
import * as actionTypes from './actions';
import { getRequest, postRequest } from '../../utils/requests';
import pathconfig from '../../utils/path-config';

function* getConsumerPaymentInfo(params) {
  try {
    const result = yield call(getRequest, pathconfig.stripePayment(params.cartId));
    // console.log('result', result)
    yield put({ type: actionTypes.CONSUMER_PAYMENT_RESPONSE, result })

  }
  catch (err) {
    yield put({ type: actionTypes.CONSUMER_PAYMENT_INFO_ERROR, err })
  }
}

function* getExistingPaymentMethods(params) {
  // console.log('getConsumerPaymentInfo', "/111/" +params.cartId + "/125")
  try {
    const result = yield call(postRequest, pathconfig.existingPaymentMethods(params.cartId));
    if (result.err) {
      yield put({ type: actionTypes.SET_EXISTING_PAYMENT_METHODS_ERROR, err: result.err })
    }
    yield put({ type: actionTypes.SET_EXISTING_PAYMENT_METHODS, result })

  }
  catch (err) {
    yield put({ type: actionTypes.SET_EXISTING_PAYMENT_METHODS_ERROR, err })
  }
}

function* getReservationConfirmation(params) {
  try {
    const result = yield call(postRequest, pathconfig.confirmReservation(params.cartId));
    yield put({ type: actionTypes.SAVE_RESERVATION_CONFIRMATION, reservationConfirmation: result });
  } catch (err) {
    console.log(err);
  }
}

function* getAvailableMealBalance() {
  try {
    const result = yield call(getRequest, pathconfig.mealPlanBalance);
    yield put({ type: actionTypes.MEAL_PLAN_AVAILABLE_BALANCE_RESPONSE, mealPlanAvailableBalance: result });
  } catch (err) {
    console.log(err);
  }
}

function* submitMealPlanOrder(params) {
  try {
    const result = yield call(postRequest, pathconfig.mealPlanOrderSubmit(params.params.cartId));
    yield put({ type: actionTypes.MEAL_PLAN_ORDER_SUBMIT_RESPONSE, mealPlanOrderResponse: result })

  } catch (err) {
    console.log(err);
  }
}

export default function* watchCheckoutSaga() {
  yield takeLatest(actionTypes.GET_CONSUMER_PAYMENT_INFO, getConsumerPaymentInfo);
  yield takeLatest(actionTypes.GET_EXISTING_PAYMENT_METHODS, getExistingPaymentMethods);
  yield takeLatest(actionTypes.GET_RESERVATION_CONFIRMATION, getReservationConfirmation);
  yield takeLatest(actionTypes.MEAL_PLAN_AVAILABLE_BALANCE_REQUEST, getAvailableMealBalance);
  yield takeLatest(actionTypes.MEAL_PLAN_ORDER_SUBMIT_REQUEST, submitMealPlanOrder);
}