import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Button, Row, Col, Layout, Skeleton } from 'antd';
import { connect } from 'react-redux';
import {
	AuthHeader,
	PageFooter,
	SiderAccordian,
	MainContent,
	FilterModal,
	PickupByLocation,
	PickupByDateTime,
	ReservationPermission,
	PickupByOrderType
} from '../../components';
import {
	mainContentHeading,
	dayText,
	timeText,
	locationText,
	locationSubText,
	dateSubText,
	timeSubText,
	searchCriteria,
	vendorsListInstruction1,
	vendorsListInstruction2,
	title1, title2, description
} from './constants';
import './style.scss';
import * as actionTypes from './actions';
import * as vendorActions from '../../containers/Vendor/actions';
import moment from "moment";
import { getError } from '../../utils/commonUtils';

const Home = (props) => {
	const { getUserDetails, userDetails, newPickupMenuData, loadVendorFilters, vendorsList, getCartDistributor, getProgramInfo, reservationStatus, brandingDetails, saveReservationStatus, onlyReservationAvailable, saveOnlyReservationAvailable, selectedB2cType, vendorLoading, programInfo, clearProgramInfo, pickupDates, clearPickupDates, pickupTimes, clearPickupTimes } = props;
	const { Header, Footer, Sider, Content } = Layout;
	const [filterModal, showFilterModal] = useState(false);
	const [pickupByLocationVisible, setPickupByLocationVisible] = useState(false);
	const [pickupByDateTimeVisible, setPickupByDateTimeVisible] = useState(false);
	const [pickupByOrderTypeVisible, setPickupByOrderTypeVisible] = useState(false);
	const [reservationPermissionVisible, setReservationPermissionVisible] = useState(false);
	const [btnHidden, setBtnHidden] = useState(true);
	const [fromTime, setFromTime] = useState(false);
	const [skeletonLoading, setSkeletonLoading] = useState(true);
	const [reservationAvailable, setReservationAvailable] = useState(false);
	const prevState = useRef({ brandingDetails, userDetails, programInfo, pickupDates }).current;
	const layoutRef = useRef(null);

	const showPickupByLocation = () => {
		if (process.env.REACT_APP_SELECT_PICKUP_CHANGES === "true") {
			setPickupByOrderTypeVisible(!pickupByOrderTypeVisible);
		}
		else {
			if (reservationAvailable) {
				setReservationPermissionVisible(true);
			}
			else {
				setPickupByLocationVisible(!pickupByLocationVisible);
			}
		}

	}

	const showPickupByDate = () => {
		setFromTime(false);
		if (newPickupMenuData !== null) {
			setPickupByDateTimeVisible(!pickupByDateTimeVisible);
		}
		else {
			showPickupByLocation();
		}
	}

	const showPickupByTime = () => {
		setFromTime(true);
		if (newPickupMenuData !== null) {
			setPickupByDateTimeVisible(!pickupByDateTimeVisible);
		}
		else {
			showPickupByLocation();
		}
	}
	useEffect(() => {
		document.body.classList.remove('overflow-hidden');
	}, [])

	useEffect(() => {
		if (userDetails === null) {
			getUserDetails();
		}
		else {
			if (userDetails.err && userDetails !== prevState.userDetails) {
				getError(userDetails.err);
			}
		}
	}, [userDetails]);

	useEffect(() => {
		loadVendorFilters();
	}, []);

	useEffect(() => {
		getCartDistributor();
	}, []);

	useEffect(() => {
		if (!reservationAvailable) {
			getProgramInfo("ORDERING");
		}
	}, [reservationAvailable]);

	useEffect(() => {
		if (reservationStatus !== null && reservationStatus !== undefined) {
			if (reservationStatus) {
				getProgramInfo("RESERVATION");
			}
			else {
				getProgramInfo("ORDERING");
			}
		}
	}, [reservationStatus]);

	useEffect(() => {
		window.addEventListener("scroll", () => {
			const y = window.scrollY;
			if (y >= 650) {
				setBtnHidden(false);
			} else {
				setBtnHidden(true);
			}
		})
	}, []);

	useEffect(() => {
		if (brandingDetails === undefined || brandingDetails === null) {
			setSkeletonLoading(true);
		}
		else {
			setSkeletonLoading(false);
			if (!brandingDetails.err) {
				if (brandingDetails.guides && brandingDetails.guides.length > 0) {

					if (brandingDetails.guides.length === 1) {

						if (brandingDetails.guides[0].b2cType) {
							if (brandingDetails.guides[0].b2cType === "RESERVATION") {
								saveReservationStatus(true);
								saveOnlyReservationAvailable();
							}
							else {
								setReservationAvailable(false);
							}
						}
					}
					else if (brandingDetails.guides.length > 1) {
						brandingDetails.guides.map((orderGuide, index) => {
							if (orderGuide.b2cType === "RESERVATION") {
								setReservationAvailable(true);
							}
						})
					}
				}
			}
			else {
				if (brandingDetails !== prevState.brandingDetails) {
					getError(brandingDetails.err);
				}
			}
		}
	}, [brandingDetails]);

	useEffect(() => {
		if (onlyReservationAvailable !== null && onlyReservationAvailable) {
			setReservationPermissionVisible(false);
			getProgramInfo("RESERVATION");
		}
	}, [onlyReservationAvailable]);

	useEffect(() => {
		if (selectedB2cType !== undefined && selectedB2cType !== null) {
			if (selectedB2cType === "RESERVATION") {
				saveReservationStatus(true);
			}
			else {
				saveReservationStatus(false);
			}
		}
	}, [selectedB2cType]);

	useEffect(() => {
		if (programInfo !== null && programInfo.err && programInfo !== prevState.programInfo && pickupByLocationVisible) {
			getError(programInfo.err);
			clearProgramInfo();
		}
	}, [programInfo, pickupByLocationVisible]);

	useEffect(() => {
		if (pickupDates !== null && pickupDates.err && pickupDates !== prevState.pickupDates) {
			getError(pickupDates.err);
			clearPickupDates();
		}
	}, [pickupDates, pickupByDateTimeVisible]);

	useEffect(() => {
		if (pickupTimes !== null && pickupTimes.err && pickupTimes !== prevState.pickupTimes) {
			getError(pickupTimes.err);
			clearPickupTimes();
		}
	}, [pickupTimes, pickupByDateTimeVisible]);

	useEffect(() => {
		if (!!layoutRef.current && !skeletonLoading) {

			if (!!newPickupMenuData && !!newPickupMenuData.timeSelected) {

				window.scrollTo(0, (layoutRef.current.offsetTop - 100), { behaviour: "smooth" });
			}
		}
	}, [layoutRef, skeletonLoading, newPickupMenuData]);

	return (
		<Fragment>
			<Layout>
				<Header>
					<AuthHeader {...props} />
					<section className="mobile_scroll_filter show_mobile" hidden={btnHidden}>
						<Row className="height100">
							<Col span={12} className="height100" style={{ borderRight: '1px solid rgb(196 196 196 / 50%)', borderTop: '1px solid rgb(196 196 196 / 50%)' }}>
								<Button className="scroll_pickup" type="text" onClick={showPickupByLocation}>
									<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<g clipPath="url(#clip0)">
											<path d="M2.6665 14.0002V9.3335" stroke="#2C384C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
											<path d="M2.6665 6.66667V2" stroke="#2C384C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
											<path d="M8 14V8" stroke="#2C384C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
											<path d="M8 5.33333V2" stroke="#2C384C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
											<path d="M13.3335 13.9998V10.6665" stroke="#2C384C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
											<path d="M13.3335 8V2" stroke="#2C384C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
											<path d="M0.666504 9.3335H4.6665" stroke="#2C384C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
											<path d="M6 5.3335H10" stroke="#2C384C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
											<path d="M11.3335 10.6665H15.3335" stroke="#2C384C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
										</g>
										<defs>
											<clipPath id="clip0">
												<rect width="16" height="16" fill="white" />
											</clipPath>
										</defs>
									</svg>
						Change Pick Up
						</Button>
							</Col>
							<Col span={12} className="height100" style={{ borderTop: '1px solid rgb(196 196 196 / 50%)' }}>
								<Button className="scroll_filter" type="text" onClick={() => { showFilterModal(true) }}>
									<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M14.6668 1H1.3335L6.66683 7.30667V11.6667L9.3335 13V7.30667L14.6668 1Z" stroke="#2C384C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
									</svg>
						Add Filters
						</Button>
							</Col>
						</Row>
					</section>
					{skeletonLoading ? <Skeleton.Avatar shape="square" className="customized_banner_new" size="large" active style={{ width: "100%", height: "550px" }} /> : (brandingDetails !== undefined && brandingDetails !== null) ?
						<>
							<section className={brandingDetails.homeBackgroundUrl !== null && !brandingDetails.err ? "customized_banner_new" : "banner_new"}
								style={{
									backgroundImage: `${brandingDetails.homeBackgroundUrl !== null && !brandingDetails.err ? `url(${brandingDetails.homeBackgroundUrl})` : ''}`,
									backgroundColor: `${brandingDetails.brandingThemeColor !== null && !brandingDetails.err ? brandingDetails.brandingThemeColor : " #265D6C"}`
								}}>
								<div className="banner-div">
									{brandingDetails.homeMainText === null || brandingDetails.err ? <h1 className="title-text"> {title1}<br />{title2} </h1> : <h1 className="title-text"> {brandingDetails.homeMainText}<br />{brandingDetails.homeMainText2}</h1>}
									{brandingDetails.homeSubText === null || brandingDetails.err ? <h6 className="description-text">{description}</h6> :
										<h6 className="description-text">{brandingDetails.homeSubText}</h6>}

								</div>
								<div className="search-card">
									<Row className="height100">
										<Col xs={24} md={10} lg={8} xl={8} xxl={10} className="destination-div" onClick={showPickupByLocation}>
											<div className="search-row-div">
												<div className="search_item_icon">
													<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
														<path d="M425.951,89.021C386.864,32.451,324.917,0,256.006,0S125.148,32.451,86.061,89.021
															c-38.895,56.284-47.876,127.541-24.072,190.496c6.367,17.192,16.488,33.895,30.01,49.547l150.378,176.634
															c3.401,3.998,8.384,6.302,13.629,6.302c5.245,0,10.228-2.303,13.629-6.302l150.336-176.586
															c13.582-15.742,23.69-32.427,30.004-49.481C473.827,216.562,464.846,145.305,425.951,89.021z M416.451,267.093
															c-4.869,13.158-12.818,26.167-23.613,38.68c-0.03,0.03-0.06,0.06-0.084,0.096L256.006,466.487L119.174,305.768
															c-10.789-12.502-18.738-25.51-23.655-38.794c-19.686-52.065-12.215-110.981,19.991-157.592
															c32.307-46.76,83.519-73.578,140.496-73.578c56.976,0,108.182,26.817,140.49,73.578
															C428.708,155.993,436.185,214.909,416.451,267.093z"/>
														<path d="M256.006,106.219c-55.276,0-100.252,44.97-100.252,100.252s44.97,100.252,100.252,100.252s100.252-44.97,100.252-100.252
															C356.258,151.195,311.282,106.219,256.006,106.219z M256.006,270.918c-35.536,0-64.448-28.912-64.448-64.448
															c0-35.536,28.912-64.448,64.448-64.448c35.536,0,64.448,28.912,64.448,64.448S291.542,270.918,256.006,270.918z"/>
													</svg>
												</div>
												{newPickupMenuData !== null && newPickupMenuData.pickupLocation && newPickupMenuData.pickupLocation.name ? (
													<div className="search_item_content" >
														<div className="search-card-text"> {newPickupMenuData.pickupLocation.name}</div>
														<p className="search-card-subtext">{newPickupMenuData.pickupLocation.street_one !== null && <>{newPickupMenuData.pickupLocation.street_one}, </>}{newPickupMenuData.pickupLocation.street_two !== null && <>{newPickupMenuData.pickupLocation.street_two}, </>}{newPickupMenuData.pickupLocation.city !== null && <>{newPickupMenuData.pickupLocation.city}, </>}{newPickupMenuData.pickupLocation.state !== null && <>{newPickupMenuData.pickupLocation.state} </>}{newPickupMenuData.pickupLocation.zip !== null && newPickupMenuData.pickupLocation.zip} </p>
													</div >
												) : (

														<div className="search_item_content" >
															<div className="search-card-text"> {locationText} </div>
															{brandingDetails.homeSearchByLocation === null || brandingDetails.err ? <p className="search-card-subtext"> {locationSubText} </p> : <p className="search-card-subtext"> {brandingDetails.homeSearchByLocation} </p>}
														</div >
													)}
											</div>

										</Col>

										<Col xs={24} md={7} lg={6} xl={6} xxl={5} className="day-div" onClick={showPickupByDate}>
											<div className="search-row-div">
												<div className="search_item_icon">
													<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
														<path d="m446 40h-46v-24c0-8.836-7.163-16-16-16s-16 7.164-16 16v24h-224v-24c0-8.836-7.163-16-16-16s-16 7.164-16 16v24h-46c-36.393 0-66 29.607-66 66v340c0 36.393 29.607 66 66 66h380c36.393 0 66-29.607 66-66v-340c0-36.393-29.607-66-66-66zm-380 32h46v16c0 8.836 7.163 16 16 16s16-7.164 16-16v-16h224v16c0 8.836 7.163 16 16 16s16-7.164 16-16v-16h46c18.748 0 34 15.252 34 34v38h-448v-38c0-18.748 15.252-34 34-34zm380 408h-380c-18.748 0-34-15.252-34-34v-270h448v270c0 18.748-15.252 34-34 34z" />
													</svg>
												</div >
												{newPickupMenuData !== null && newPickupMenuData.dateSelected ?
													(
														<div className="search_item_content">
															{
																process.env.REACT_APP_CHANGE_TIME_ZONE_FORMAT === 'true' && newPickupMenuData.orderType === "For A Week"
																	? <div className="search-card-text"> Week of {moment.utc(newPickupMenuData.dateSelected).format('MMMM DD')} - {moment.utc(newPickupMenuData.dateSelected).add(6, 'days').format('MMMM DD')} </div>
																	: <div className="search-card-text"> {moment(newPickupMenuData.dateSelected).format("MM/DD/YYYY")} </div>

															}

														</div>
													) : (
														<div className="search_item_content">
															<div className="search-card-text"> {dayText} </div>
															<p className="search-card-subtext"> {dateSubText} </p>
														</div>
													)}
											</div>
										</Col>

										<Col xs={24} md={7} lg={6} xl={6} xxl={5} className="time-div" onClick={showPickupByTime}>
											<div className="search-row-div">
												<div className="search_item_icon">
													<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
														<path d="m221.647 0c-122.214 0-221.647 99.433-221.647 221.647s99.433 221.647 221.647 221.647 221.647-99.433 221.647-221.647-99.433-221.647-221.647-221.647zm0 415.588c-106.941 0-193.941-87-193.941-193.941s87-193.941 193.941-193.941 193.941 87 193.941 193.941-87 193.941-193.941 193.941z" />
														<path d="m235.5 83.118h-27.706v144.265l87.176 87.176 19.589-19.589-79.059-79.059z" />
													</svg>
												</div>
												{newPickupMenuData !== null && newPickupMenuData.timeSelected ? (
													<div className="search_item_content">
														{
															process.env.REACT_APP_CHANGE_TIME_ZONE_FORMAT === 'true' && newPickupMenuData.orderType === "For A Week"
																? <div className="search-card-text"> {moment.utc(newPickupMenuData.timeSelected).format('h:mm A')} </div>
																: <div className="search-card-text"> {newPickupMenuData.timeSelected} </div>
														}
													</div >
												) : (
														<div className="search_item_content">
															<div className="search-card-text"> {timeText} </div>
															<p className="search-card-subtext"> {timeSubText} </p>
														</div >
													)}
											</div>
										</Col>
										<Col xs={24} md={24} lg={4} xl={4} xxl={4} >
											<div className="search-btn-div">
												<Button
													className="radius8 font_size_family btn_pad1"
													type="primary"
													onClick={showPickupByLocation}
												>
													Search
										</Button>
											</div>
										</Col>
									</Row>
									<Row className="filters_mobile_div">
										<Col span={24}>
											<Button
												type="primary" htmlType="button" className="filters_mobile_btn" onClick={() => { showFilterModal(true) }}>
												<svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M21 1H1L9 10.46V17L13 19V10.46L21 1Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
												</svg>Add Filters
									</Button>
										</Col>
										<FilterModal filterModal={filterModal} showFilterModal={showFilterModal} {...props} />

									</Row>
									<PickupByLocation locationModalVisible={pickupByLocationVisible} setLocationModalVisible={setPickupByLocationVisible}  {...props} />
									<PickupByDateTime fromTime={fromTime} dateTimeModalVisible={pickupByDateTimeVisible} setDateTimeModalVisible={setPickupByDateTimeVisible} {...props} />
									<ReservationPermission reservationPermissionVisible={reservationPermissionVisible} setReservationPermissionVisible={setReservationPermissionVisible} setLocationModalVisible={setPickupByLocationVisible} {...props} />
									<PickupByOrderType pickupByOrderTypeVisible={pickupByOrderTypeVisible} setPickupByOrderTypeVisible={setPickupByOrderTypeVisible} {...props} />
								</div>
							</section>
						</> :
						<>
							<section className="banner_new">
								<div className="banner-div">
									<h1 className="title-text"> {title1}<br />{title2} </h1>
									{brandingDetails.homeSubText === null || brandingDetails.err ? <h6 className="description-text">{description}</h6> :
										<h6 className="description-text">{brandingDetails.homeSubText}</h6>}

								</div>
								<div className="search-card">
									<Row className="height100">
										<Col xs={24} md={10} lg={8} xl={8} xxl={10} className="destination-div" onClick={showPickupByLocation}>
											<div className="search-row-div">
												<div className="search_item_icon">
													<img
														alt="send"
														src={require("../../assets/images/map-pin.png")}
														width={24}
														height={24}
													/>
												</div>
												{newPickupMenuData !== null && newPickupMenuData.pickupLocation && newPickupMenuData.pickupLocation.name ? (
													<div className="search_item_content" >
														<div className="search-card-text"> {newPickupMenuData.pickupLocation.name}</div>
														<p className="search-card-subtext">{newPickupMenuData.pickupLocation.street_one !== null && <>{newPickupMenuData.pickupLocation.street_one}, </>}{newPickupMenuData.pickupLocation.street_two !== null && <>{newPickupMenuData.pickupLocation.street_two}, </>}{newPickupMenuData.pickupLocation.city !== null && <>{newPickupMenuData.pickupLocation.city}, </>}{newPickupMenuData.pickupLocation.state !== null && <>{newPickupMenuData.pickupLocation.state} </>}{newPickupMenuData.pickupLocation.zip !== null && newPickupMenuData.pickupLocation.zip} </p>
													</div >
												) : (

														<div className="search_item_content" >
															<div className="search-card-text"> {locationText} </div>
															{brandingDetails.homeSearchByLocation === null || brandingDetails.err ? <p className="search-card-subtext"> {locationSubText} </p> : <p className="search-card-subtext"> {brandingDetails.homeSearchByLocation} </p>}
														</div >
													)}
											</div>

										</Col>

										<Col xs={24} md={7} lg={6} xl={6} xxl={5} className="day-div" onClick={showPickupByDate}>
											<div className="search-row-div">
												<div className="search_item_icon">
													<img
														alt="calender"
														src={require("../../assets/images/calendar.png")}
														width={24}
														height={24}
													/>
												</div >
												{newPickupMenuData !== null && newPickupMenuData.dateSelected ?
													(
														<div className="search_item_content">
															<div className="search-card-text"> {newPickupMenuData.dateSelected} </div>
														</div>
													) : (
														<div className="search_item_content">
															<div className="search-card-text"> {dayText} </div>
															<p className="search-card-subtext"> {dateSubText} </p>
														</div>
													)}
											</div>
										</Col>

										<Col xs={24} md={7} lg={6} xl={6} xxl={5} className="time-div" onClick={showPickupByTime}>
											<div className="search-row-div">
												<div className="search_item_icon">
													<img
														alt="clock"
														src={require("../../assets/images/clock.png")}
														width={24}
														height={24}
													/>
												</div>
												{newPickupMenuData !== null && newPickupMenuData.timeSelected ? (
													<div className="search_item_content">
														<div className="search-card-text"> {newPickupMenuData.timeSelected} </div>
													</div >
												) : (
														<div className="search_item_content">
															<div className="search-card-text"> {timeText} </div>
															<p className="search-card-subtext"> {timeSubText} </p>
														</div >
													)}
											</div>
										</Col>
										<Col xs={24} md={24} lg={4} xl={4} xxl={4} >
											<div className="search-btn-div">
												<Button
													className="radius8 font_size_family btn_pad1"
													type="primary"
													onClick={showPickupByLocation}
												>
													Search
										</Button>
											</div>
										</Col>
									</Row>
									<Row className="filters_mobile_div">
										<Col span={24}>
											<Button
												type="primary" htmlType="button" className="filters_mobile_btn" onClick={() => { showFilterModal(true) }}>
												<svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M21 1H1L9 10.46V17L13 19V10.46L21 1Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
												</svg>Add Filters
									</Button>
										</Col>
										<FilterModal filterModal={filterModal} showFilterModal={showFilterModal} {...props} />

									</Row>
									<PickupByLocation locationModalVisible={pickupByLocationVisible} setLocationModalVisible={setPickupByLocationVisible}  {...props} />
									<PickupByDateTime fromTime={fromTime} dateTimeModalVisible={pickupByDateTimeVisible} setDateTimeModalVisible={setPickupByDateTimeVisible} {...props} />
									<ReservationPermission reservationPermissionVisible={reservationPermissionVisible} setReservationPermissionVisible={setReservationPermissionVisible} setLocationModalVisible={setPickupByLocationVisible} {...props} />
									<PickupByOrderType pickupByOrderTypeVisible={pickupByOrderTypeVisible} setPickupByOrderTypeVisible={setPickupByOrderTypeVisible} {...props} />
								</div>
							</section>
						</>}
					<div id="blank_div" className="blank_div_for_mobile" ref={layoutRef}></div>
				</Header >
				<Content className="maincontent">

					<Layout >
						<Sider >
							<SiderAccordian {...props} showFilterModal={showFilterModal} />
						</Sider>
						<Content>
							<Row>
								<Col span={24}>
									<div className="content-heading-col">
										<div className="content-heading-div">
											<h2 className="content-heading">{mainContentHeading}
												<span className="content-heading-distance">{searchCriteria}</span>
											</h2>
										</div>

										{vendorsList !== null && !vendorsList.err && !vendorLoading &&
											<>
												{brandingDetails === null || brandingDetails.homeAvailableOptions === null || brandingDetails.err ?
													<p className="content-sub-heading">{vendorsListInstruction1}<br />{vendorsListInstruction2}</p> :
													<p className="content-sub-heading">{brandingDetails.homeAvailableOptions}</p>}
											</>}

									</div>
								</Col>
							</Row>



							<div className="restaurant_list">
								<MainContent {...props} showPickupByLocation={showPickupByLocation} />
							</div>

						</Content>
					</Layout>
				</Content>
				<Footer>
					<PageFooter {...props} />
				</Footer>
			</Layout>
		</Fragment>

	)
}

const mapStateToProps = (state) => {
	return {
		userDetails: state.UserReducer.userDetails,
		pickupLocationDetails: state.UserReducer.pickupLocationDetails,
		pickupDates: state.UserReducer.pickupDates,
		pickupTimes: state.UserReducer.pickupTimes,
		vendorsList: state.UserReducer.vendorsList,
		filtersList: state.UserReducer.filtersList,
		pickupData: state.UserReducer.selectPickupData,
		shopItems: state.UserReducer.shopItems,
		selectedFiltersList: state.UserReducer.selectedFiltersList,
		addToCartResponse: state.VendorReducer.addToCartResponse,
		productsList: state.VendorReducer.productsList,
		cartDistributor: state.VendorReducer.cartDistributor,
		cartDetails: state.VendorReducer.cartDetails,
		selectedVendorData: state.UserReducer.selectedVendorData,
		newPickupMenuData: state.UserReducer.newPickupMenuData,
		programInfo: state.UserReducer.programInfo,
		brandingDetails: state.UserReducer.brandingDetails,
		reservationStatus: state.UserReducer.reservationStatus,
		onlyReservationAvailable: state.UserReducer.onlyReservationAvailable,
		selectedB2cType: state.UserReducer.selectedB2cType,
		vendorLoading: state.UserReducer.showVendorLoading,
		vendorsListEnded: state.UserReducer.vendorsListEnded,
		pickupWeeks: state.UserReducer.pickupWeeks,
		pickupWeekSlot: state.UserReducer.pickupWeekSlot,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		dispatch,
		getUserDetails: () => dispatch({ type: actionTypes.USER_DETAILS }),
		getPickupLocations: (params) => dispatch({ type: actionTypes.PICKUP_LOCATION_REQUEST, params }),
		getPickupDates: (params) => dispatch({ type: actionTypes.PICKUP_DATE_REQUEST, params }),
		getPickupTimes: (params) => dispatch({ type: actionTypes.PICKUP_TIME_REQUEST, params }),
		getVendorsList: (params) => dispatch({ type: actionTypes.PICKUP_VENDOR_REQUEST, params }),
		getFiltersList: (params) => dispatch({ type: actionTypes.FILTER_ITEM_REQUEST, params }),
		applyFilters: (params) => dispatch({ type: actionTypes.APPLY_FILTER_REQUEST, params }),
		savePickupData: (params) => dispatch({ type: actionTypes.DISPATCH_PICKUP_DATA, params }),
		saveSelectedFilters: (params) => dispatch({ type: actionTypes.SELECTED_FILTERS_LIST, params }),
		getCartDistributor: () => dispatch({ type: vendorActions.GET_CART_DISTRIBUTOR_DETAILS }),
		getCartDetails: (params) => dispatch({ type: vendorActions.GET_CART_DETAILS, params }),
		saveSelectedVendor: (selectedVendor) => dispatch({ type: actionTypes.SAVE_SELECTED_VENDOR, selectedVendor }),
		saveNewPickupMenuData: (data) => dispatch({ type: actionTypes.SAVE_NEW_PICKUP_MENU, data }),
		loadVendorFilters: () => dispatch({ type: actionTypes.VENDOR_FILTER_ITEMS_REQUEST }),
		clearProductList: () => dispatch({ type: vendorActions.SAVE_SHOP_ITEMS, shopItems: null }),
		getProgramInfo: (b2ctype) => dispatch({ type: actionTypes.GET_PROGRAM_INFO, b2ctype }),
		clearSelectedDietaryFilter: () => dispatch({ type: vendorActions.SAVE_SELECTED_DIETARY_FILTER, dietaryFilter: null }),
		saveReservationStatus: (params) => dispatch({ type: actionTypes.SAVE_RESERVATION_STATUS, reservationStatus: params }),
		saveBrandingDetails: (params) => dispatch({ type: actionTypes.SAVE_BRANDING_DETAILS, brandingDetails: params }),
		saveOnlyReservationAvailable: () => dispatch({ type: actionTypes.SAVE_ONLY_RESERVATION_AVAILABLE }),
		clearVendorsList: () => dispatch({ type: actionTypes.PICKUP_VENDOR_RESPONSE, pickupVendors: null }),
		saveSelectedB2cType: (b2cType) => dispatch({ type: actionTypes.SELECTED_B2C_TYPE, selectedB2cType: b2cType }),
		showVendorLoader: (loader) => dispatch({ type: actionTypes.SHOW_VENDOR_LOADER, showLoader: loader }),
		clearProgramInfo: () => dispatch({ type: actionTypes.SAVE_PROGRAM_INFO, programInfo: null }),
		clearPickupDates: () => dispatch({ type: actionTypes.PICKUP_DATE_RESPONSE, pickupDates: null }),
		clearPickupTimes: () => dispatch({ type: actionTypes.PICKUP_TIME_RESPONSE, pickupTimes: null }),
		getVendorsListWithPickupInfo: (params) => dispatch({ type: actionTypes.PICKUP_VENDOR_REQUEST_WITH_INFO, params }),
		loadMoreVendors: (params) => dispatch({ type: actionTypes.LOAD_MORE_VENDORS, params }),
		removeLoadMore: () => dispatch({ type: actionTypes.END_OF_RESTAURANTS_LIST, vendorsListEnded: null }),
		refreshVendorsList: () => dispatch({ type: actionTypes.END_OF_RESTAURANTS_LIST, vendorsListEnded: false }),
		getPickupWeeks: (params) => dispatch({ type: actionTypes.PICKUP_WEEK_REQUEST, params }),
		getPickupWeekSlots: (params) => dispatch({ type: actionTypes.PICKUP_WEEK_SLOT_REQUEST, params }),
		getStudentBrandingDetails: (params) => dispatch({ type: actionTypes.GET_BRANDING_DETAILS, params }),
	}
}



export default connect(mapStateToProps, mapDispatchToProps)(Home);