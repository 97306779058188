import React, { useEffect, useState } from 'react';
import { Modal, Collapse, Checkbox, Button, Row, Col } from 'antd';
import './styles.scss';
import { Link } from 'react-router-dom';
import moment from 'moment';
const { Panel } = Collapse;

const DietryFilterModal = (props) => {
  const {
    dietryFilter,
    showDietryFilter,
    productSKUTags,
    getProductsList,
    distributorId,
    saveSelectedDietaryFilter,
    selectedDietaryFilter,
    clearSelectedDietaryFilter,
    reservationStatus,
    newPickupMenuData,
  } = props;
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [selectedDietaryAttributes, setDietarySelectedAttributes] = useState([]);
  const [selectedAllergenAttributes, setAllergenSelectedAttributes] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [dietaryFilterOptions, setDietaryFilterOptions] = useState([]);
  const [allergenFilterOptions, setAllergenFilterOptions] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(true);

  const handleCancel = e => {
    showDietryFilter(!dietryFilter);
  };

  useEffect(() => {
    if (selectedDietaryFilter !== undefined && selectedDietaryFilter !== null && selectedDietaryFilter.selectedAttributes) {
      if (process.env.REACT_APP_ALLERGEN_FILTER === "true" ) {
        const tempSelectedDietaryAtt = [], tempSelectedAllergenAtt = [];
        if (selectedDietaryFilter.selectedAttributes.length > 0) {
          if (productSKUTags !== undefined && productSKUTags !== null && productSKUTags.length > 0) {
            productSKUTags.map( productSKUTag => {
              if(productSKUTag.name === "Allergens") {
                if (productSKUTag.attributes !== undefined && productSKUTag.attributes !== null && productSKUTag.attributes.length > 0 ) {
                  productSKUTag.attributes.map(item => {
                    if(selectedDietaryFilter.selectedAttributes.includes(item.id)) {
                      tempSelectedAllergenAtt.push(item.id)
                    }
                  })
                }
              }
              if(productSKUTag.name === "Dietary Preferences") {
                if (productSKUTag.attributes !== undefined && productSKUTag.attributes !== null && productSKUTag.attributes.length > 0 ) {
                  productSKUTag.attributes.map(item => {
                    if(selectedDietaryFilter.selectedAttributes.includes(item.id)) {
                      tempSelectedDietaryAtt.push(item.id)
                    }
                  })
                }
              }
            })
          }
          setDietarySelectedAttributes(tempSelectedDietaryAtt);
          setAllergenSelectedAttributes(tempSelectedAllergenAtt);
        }
      } else {
        setSelectedAttributes(selectedDietaryFilter.selectedAttributes);
      }
    }
  }, [selectedDietaryFilter]);


  useEffect(() => { // NOTE Set filters
    if (process.env.REACT_APP_ALLERGEN_FILTER === "true" ) {
      if (productSKUTags !== undefined && productSKUTags !== null && productSKUTags.length > 0) {
        setBtnDisabled(false);
        productSKUTags.map(productSKUTag => {
          if (productSKUTag.name === "Dietary Preferences" || productSKUTag.name === "Allergens") {
            if (productSKUTag.attributes !== undefined && productSKUTag.attributes !== null && productSKUTag.attributes.length > 0 ) {
              let options = [];
              productSKUTag.attributes.map( item => {
                let option = { label: item.name, value: item.id };
                if (options.indexOf(option) === -1) {
                  options.push(option);
                }
              });
              if (productSKUTag.name === "Dietary Preferences") {
                setDietaryFilterOptions(options);
              } else if (productSKUTag.name === "Allergens") {
                setAllergenFilterOptions(options);
              }
            }
          }
        })
      }
    } else if (productSKUTags !== undefined && productSKUTags !== null && productSKUTags.length > 0) {
      setBtnDisabled(false);
      let options = [];
      productSKUTags.map((item, index) => {
        let option = { label: item.name, value: item.id };
        if (options.indexOf(option) === -1) {
          options.push(option);
        }
      });
      setFilterOptions(options);
    }
    else {
      setBtnDisabled(true);
    }
  }, [productSKUTags]);

  const resetAll = () => {
    if(process.env.REACT_APP_ALLERGEN_FILTER === 'true') {
      if (selectedDietaryAttributes !== null && selectedDietaryAttributes.length > 0) {
        setDietarySelectedAttributes([]);
        if (selectedDietaryFilter !== undefined && selectedDietaryFilter !== null && selectedDietaryFilter.selectedAttributes) {
          clearSelectedDietaryFilter();
        }
      }
      if (selectedAllergenAttributes !== null && selectedAllergenAttributes.length > 0) {
        setAllergenSelectedAttributes([]);
        if (selectedDietaryFilter !== undefined && selectedDietaryFilter !== null && selectedDietaryFilter.selectedAttributes) {
          clearSelectedDietaryFilter();
        }
      }
    }
    if (selectedAttributes !== null && selectedAttributes.length > 0) {
      setSelectedAttributes([]);
      if (selectedDietaryFilter !== undefined && selectedDietaryFilter !== null && selectedDietaryFilter.selectedAttributes) {
        clearSelectedDietaryFilter();
      }
    }
  }

  const applyDietaryFilter = () => { // NOTE apply filter
    let filterParams = {};
    if(process.env.REACT_APP_ALLERGEN_FILTER === 'true') {
      filterParams.selectedAttributes = selectedDietaryAttributes.concat(selectedAllergenAttributes);
    }
    else {
      filterParams.selectedAttributes = selectedAttributes ;
    }
    let params = {};
    if (reservationStatus) {
      params = {
        productId: distributorId,
        filterParams: filterParams,
        b2cType: "RESERVATION",
        pickupDateTime: !!newPickupMenuData && !!newPickupMenuData.dateSelected && newPickupMenuData.dateSelected
      }
    }
    else {
      params = {
        productId: distributorId,
        filterParams: filterParams,
        b2cType: "ORDERING",
        pickupDateTime: !!newPickupMenuData && !!newPickupMenuData.dateSelected && newPickupMenuData.dateSelected
      }
    }
    if (process.env.REACT_APP_WEEKLY_RESTAURANT_API === 'true' && newPickupMenuData) {
      params.pickupLocation = newPickupMenuData.pickupLocation;
      if (newPickupMenuData
        && newPickupMenuData.orderType
        && newPickupMenuData.orderType === "For A Week"
      ) {
        params.orderType = 'For A Week';
        params.pickupDateTime =  newPickupMenuData.dateSelected + 'T' + moment.utc(newPickupMenuData.timeSelected ).format('HH:mm:ss');
      }
      else {
        params.pickupDateTime =  newPickupMenuData.dateSelected + 'T' + moment(newPickupMenuData.timeSelected, 'H:mm A').format('hh:mm:ss');
      }
    }
    getProductsList(params);
    saveSelectedDietaryFilter(filterParams);
    showDietryFilter(false);
  }
  const onDietaryChange = (checkedValues) => {
    setDietarySelectedAttributes(checkedValues)
  }
  const onAllergenChange = (checkedValues) => {
    setAllergenSelectedAttributes(checkedValues)
  }
  const onChange = (checkedValues) => {
    setSelectedAttributes(checkedValues);
  }

  return (
    <>
      <Modal className="order-modal" visible={dietryFilter} footer={null} onCancel={handleCancel}>
        <div className="sider-div">
          <Row justify="space-between">
            <Col>
              <h6 className="heading">Filters</h6>
            </Col>
            <Col>
              <Link to="#" className="signup-link-label" onClick={resetAll}>Reset All</Link>
            </Col>
          </Row>
          {
            process.env.REACT_APP_ALLERGEN_FILTER === 'true' 
              ? (productSKUTags !== undefined && productSKUTags !== null && productSKUTags.length > 0)
                ? productSKUTags.map((productSKUTag, i) => {
                  if (productSKUTag.name === "Dietary Preferences" || productSKUTag.name === "Allergens") {
                    return (
                      <Collapse accordion expandIconPosition="right" defaultActiveKey={1} key={i}>
                        <Panel header={productSKUTag.name} key={1} className="panel-div">
                          {
                            productSKUTag.attributes &&
                            productSKUTag.attributes.length > 0 && 
                              <Checkbox.Group 
                                value={
                                  productSKUTag.name === "Dietary Preferences" 
                                  ? selectedDietaryAttributes
                                  : productSKUTag.name === "Allergens"
                                    ? selectedAllergenAttributes
                                    : selectedAttributes
                                }
                                options={
                                  productSKUTag.name === "Dietary Preferences" 
                                  ? dietaryFilterOptions 
                                  : productSKUTag.name === "Allergens"
                                    ? allergenFilterOptions
                                    : filterOptions
                                }
                                onChange={
                                  productSKUTag.name === "Dietary Preferences" 
                                  ? onDietaryChange 
                                  : productSKUTag.name === "Allergens"
                                    ? onAllergenChange
                                    : onChange
                                  
                                }
                              />
                          }
                        </Panel>
                      </Collapse>
                    )
                  }
                })
                : null
             : (
              <Collapse accordion expandIconPosition="right" defaultActiveKey={1}> 
                <Panel header="DIETARY Preferences" key={1} className="panel-div">
                  {productSKUTags !== undefined && productSKUTags !== null && productSKUTags.length > 0 &&
                    <Checkbox.Group value={selectedAttributes} options={filterOptions} onChange={onChange} />
                  }
                </Panel>
              </Collapse>
            )
          }
          <Row>
            <Col xs={24} md={12}>
              <div className="mr-top fixed-button-bottom">
                <Button disabled={btnDisabled} type="primary" htmlType="submit" className="radius12 font_size_family btn_pad" onClick={applyDietaryFilter}>Confirm</Button>
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div className="mr-top fixed-button-bottom">
                <Button htmlType="submit" className="radius12 font_size_family btn_pad bg-grey" onClick={() => { showDietryFilter(false); }}>Cancel</Button>
              </div>
            </Col>
          </Row>

        </div>
      </Modal>
    </>
  )
}

export default DietryFilterModal;