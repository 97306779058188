/**
 * action to get Product list of vendors
 */
export const GET_SHOP_ITEMS = 'GET_SHOP_ITEMS';
export const SAVE_SHOP_ITEMS = 'SAVE_SHOP_ITEMS';


/**
 * action to add to Cart
 */
export const ADD_TO_CART = 'ADD_TO_CART';
export const ADD_TO_CART_RESPONSE = 'ADD_TO_CART_RESPONSE';

/**
 * action to get cart distributor details
 */
export const GET_CART_DISTRIBUTOR_DETAILS = 'GET_CART_DISTRIBUTOR_DETAILS';
export const SAVE_CART_DISTRIBUTOR_DETAILS = 'SAVE_CART_DISTRIBUTOR_DETAILS';

/**
 * action to get cart details
 */
export const GET_CART_DETAILS = 'GET_CART_DETAILS';
export const SAVE_CART_DETAILS = 'SAVE_CART_DETAILS';

/**
 * action to get product SKU Tags
 */
export const GET_SKU_TAGS = 'GET_SKU_TAGS';
export const SAVE_SKU_TAGS = 'SAVE_SKU_TAGS';

/**
 * action to save selected dietary filters
 */
export const SAVE_SELECTED_DIETARY_FILTER = 'SAVE_SELECTED_DIETARY_FILTER';


/**
 * action to get cart items detail only
 */
export const SAVE_CART_ITEM_DETAILS = 'SAVE_CART_ITEM_DETAILS';