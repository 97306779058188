import React from 'react';
import { Modal } from 'antd';
import SiderAccordian from '../SiderAccordian';

const FilterModal = (props) => {

  const { filterModal, showFilterModal } = props
  const handleCancel = e => {
    showFilterModal(!filterModal);
  };

  return (
    <>
      <Modal className="order-modal" visible={filterModal} footer={null} onCancel={handleCancel}>
        <SiderAccordian {...props} />
      </Modal>
    </>
  )
}

export default FilterModal;