import { takeLatest, put, call } from 'redux-saga/effects';
import * as actionTypes from './actions';
import { simplePost } from '../../utils/requests';
import pathconfig from '../../utils/path-config';

function* forgotPassword(params) {
  try {
    let result = yield call(simplePost, pathconfig.forgotPassword, params.params);
    yield put({ type: actionTypes.FORGOT_PASSWORD_RESPONSE, forgotPasswordResponse: result })
  } catch (err) {
    console.log(err)
  }
}

export default function* watchLoginSaga() {
  yield takeLatest(actionTypes.FORGOT_PASSWORD, forgotPassword);
}